<template>

    <div class="">

        <v-form
           id="nodeform"
           ref="form"
           v-model="valid"
           :lazy-validation="lazy">

            <!-- CARD ACTIONS -->

            <v-toolbar
              flat
              style="border-bottom:2px solid #ddd"              
              >
        
                <v-toolbar-title>Infopunkte / POI bearbeiten</v-toolbar-title>
                  
                  <v-checkbox
                  v-model="node.published"
                  label="Veröffentlicht"
                  dense
                  class="check-published"
                  ></v-checkbox>
                  
                  <v-spacer></v-spacer>                    

                    <v-btn class="mr-4"                    
                    :disabled="!valid"
                    @click="formSubmit(false)"
                    style="display:inline-block"
                    title="Speichern"
                    >
                    <v-icon>mdi-content-save-outline</v-icon>
                    </v-btn>

                    <v-btn class="mr-4"                    
                    :disabled="!valid"
                    @click="formSubmit(true)"
                    style="display:inline-block"
                    title="Speichern und Seite verlassen"
                    >
                    <v-icon class="mr-2">mdi-content-save-outline</v-icon> <v-icon>mdi-exit-to-app</v-icon>
                    </v-btn>

                  <!--
                    <v-btn class="mr-4"
                    v-else
                    :disabled="!valid"
                    @click="formSubmit"
                    style="display:inline-block"
                    title="Speichern"
                    >
                    <v-icon>mdi-content-save-outline</v-icon>
                    </v-btn>
                    -->

                  
                    <v-btn-toggle                        
                      borderless                    
                      dense    
                      class="mr-2"
                      >
                      <v-btn      
                        icon
                        :to=" node.icon === 1 || node.icon === 10 ? '/'+$home+'content/link' : '/'+$home+'content/'+nodetype "
                        >      
                        <v-icon>mdi-table-of-contents</v-icon>
                      </v-btn>

                    </v-btn-toggle>
  
            </v-toolbar>

            <!-- / CARD ACTIONS --->

            <v-row class="nodecontent">

              <v-col cols="7" sm="7" >

                <label class="top">Titel</label>                  

                <v-text-field
                  v-model="node.name"
                  :counter="200"
                  :rules="nameRules"                
                  required
                  color="#333333"
                  height=auto
                  class="my-1 py-1"
                  dense
                  outlined
                ></v-text-field>                

                <v-row>

                  <v-col cols="5" sm="5" >

                      <label class="top">Kategorie</label>

                      <v-select
                        v-model="node.icon"
                        v-if="categories"
                        :items="categories"
                        :rules="[v => !!v || 'Kategorie ist erforderlich']"
                        item-value="id"                        
                        required
                        outlined
                        dense
                        persistent-hint
                        hint="Kategorie für Icon im Marker" 
                        class="mt-1 mb-4 pt-1"                       
                      >
                      <template v-slot:item='{item}'> <img class="selecticon" :src="'/'+$home+'graf/' + item.src" /> <span v-html="item.name"></span> </template>
                      <template v-slot:selection='{item}'> <img class="selecticon" :src="'/'+$home+'graf/' + item.src" /> <span v-html="item.name"></span> </template>
                      </v-select>

                  </v-col>                

                  <v-col cols="5" sm="5" v-if="node.icon != 1">

                    <label class="top">Tour / Rundgang</label>

                    <v-select
                          v-model="node.tour"
                          v-if="tours"
                          :items="tours"
                          :menu-props="{ maxHeight: '400' }"                          
                          multiple                          
                          item-value="id"
                          persistent-hint
                          hint="Rundgang nach Thema"
                          outlined
                          dense
                          class="mt-1 pt-1"
                          @change="getIntrospot(node.tour)"                        
                        >                        
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index === 0" small>
                            <span>{{ item.name }}</span>
                          </v-chip>
                          <span
                            v-if="index === 1"
                            class="grey--text caption"
                          >
                            (+{{ node.tour.length - 1 }} weitere)
                          </span>
                        </template>
                        <template v-slot:item="{ item, attrs, on }">
                          <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                            <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                <v-row no-gutters align="center">
                                <span>{{ item.name }}</span>                                        
                                </v-row>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                        
                    </v-select>
                   
                  </v-col>
                
                  <v-col cols="2" sm="2" v-if="node.icon != 1">
                    
                    <label class="top" v-if="node.tour && node.tour.length < 2">Startpunkt</label>
                    
                    <v-checkbox                                            
                      dense
                      class="pt-2 mt-2"                      
                      v-model="node.tourstart"
                      v-if="node.tour && node.tour.length < 2"                      
                    ></v-checkbox>
                  </v-col>

                </v-row>

                <div class="pdfwrap">
                  
                  <label>
                    <v-input type="file" id="file" ref="pdf" v-on:change="onChangePDFUpload()" />
                  </label>                  
                  
                  <label class="top">PDF für Veranstaltung (Wissenschaftswoche)</label>

                  <v-file-input 
                    v-if="!node.pdfpath"
                    id="file" ref="pdf"
                    label="PDF Upload (für Wissenschaftswoche)"
                    dense
                    outlined
                    v-model="node.pdffile"
                    class="mt-4"
                    prepend-icon="mdi-pdf-box"
                    >
                  </v-file-input>
                                  
                  <div class="previewwrap" v-if="node.pdfpath">
                    <v-icon
                      size="30"
                      class="mr-2"
                      >
                      mdi-pdf-box
                    </v-icon>
                    <a :href="node.pdfpath" target="_blank">{{ node.pdfsrc }}</a>                  
                    <v-btn
                      depressed
                      rounded
                      fab
                      small
                      @click="deletePdf"
                      >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                  <v-text-field
                    v-model="node.pdfsrc"                                       
                    class="hidden"
                  ></v-text-field>                  

                </div>                

                <div class="txtwrap">

                  <v-subheader>Inhalt Popup-Fenster</v-subheader>

                  <Editor :description="node.body" @update="updateEditor"></Editor>

                  <div>
                    <v-textarea
                      label="HTML Source Code"
                      :value="node.body"
                      v-model="node.body"                      
                    >
                    </v-textarea>
                  </div>

                </div>

                <v-text-field
                      v-model="node.id"
                      type="hidden"                    
                      style="visibility: hidden;display:inline-block"                      
                  ></v-text-field>
                
               </v-col>

               <v-col cols="5" sm="5" >

                  <label class="top">Panorama</label>

                    <v-autocomplete
                      v-model="node.pano_id"
                      v-on:change="onChangePanoReference"
                      ref="panoRef"
                      v-if="panos"
                      :items="panos"
                      :rules="[v => !!v || 'Referenz ist erforderlich']"
                      item-value="id"
                      item-text="name_admin"                      
                      class="mt-1 mb-0 py-1"                      
                      required
                      outlined
                      dense
                    >      
                    </v-autocomplete>

                   <v-row>                      
                        
                       <v-col cols="4" sm="4" >
                           <v-text-field
                              label="X"
                             v-model="node.pos_x"
                             type="number"
                             outlined
                             dense
                           ></v-text-field>
                       </v-col>
                       <v-col cols="4" sm="4" >
                           <v-text-field
                              label="Y"
                             v-model="node.pos_y"
                             type="number"
                             outlined
                             dense
                           ></v-text-field>
                       </v-col>

                      <v-col cols="4" sm="4" >
                           <v-text-field
                              label="Z"
                             v-model="node.pos_z"
                             type="number"
                             outlined
                             dense
                           ></v-text-field>
                       </v-col>
                   </v-row>

                   <div class="linkwrap">

                  <!--
                       <v-select
                         v-model="node.link_id"
                         v-if="node.icon === 1 || node.icon === 10"
                         v-on:change="onChangeLinkReference"
                         ref="linkRef"
                         :items="panos"
                         :rules="[v => !!v || 'Referenz ist erforderlich']"
                         item-value="id"
                         label="Zielpanorama auswählen"
                         class="my-5 py-2"
                         background-color="#f7f7f7"
                         required
                       >                       
                       <template v-slot:item='{item}'> <div @click="getPanoRef(item)" v-html='item.name_admin ? item.name_admin : item.name' /> </template>
                       <template v-slot:selection='{item}'> <div v-html='item.name_admin ? item.name_admin : item.name' /></template>
                       </v-select>
                       -->

                      <label class="top">Zielpanorama</label>

                      <v-autocomplete
                        v-model="node.link_id"
                        v-if="panos && (node.icon === 1 || node.icon === 10)"
                        v-on:change="onChangeLinkReference"
                        ref="linkRef"                        
                        :items="panos"
                        :rules="[v => !!v || 'Referenz ist erforderlich']"
                        item-value="id"                        
                        item-text="name_admin"                      
                        class="my-0 py-2"                      
                        required
                        outlined
                        dense
                      >      
                      </v-autocomplete>                       

                   </div>

                   <div class="panoembedwrap">

                        <div class="panocontrols"
                          v-if="nodePanRef"
                          >
                          <v-btn
                            small
                            depressed
                            fab     
                            @click="zoom(1)"                       
                            >
                            <v-icon
                            size="20px"
                            >mdi-plus-circle</v-icon>                          
                          </v-btn>
                          <v-btn
                            small
                            fab
                            depressed
                            @click="zoom(0)"
                            >
                            <v-icon>mdi-minus-circle</v-icon>                          
                          </v-btn>
                        </div>

                        <PanoMeSingle
                           v-if="nodePanRef"
                            :sourceSingle="nodePanRef"
                            ref="panoComponent"                            
                            :key="componentKey"
                            @getPosition="getPanoPosition"
                        ></PanoMeSingle>

                   </div>                   

               </v-col>

            </v-row>

        </v-form>

    </div>

</template>

<script>

    import Editor from '../tools/Editor';
    import PanoMeSingle from './PanoMeSingle';

    import UserContent from '../../helper/UserContent.js';

    export default {
        name: 'app',
        data() {
            return {
                componentKey: 0,
                published: false,
                previewpdf: null,
                panoembedsrc: this.node ? this.node.pano_id : null,

                errors: [],
                valid: true,
                pdf: '',
                panos: null,
                nodePanRef: null,
                nodebase: this.node,

                nameRules: [
                  v => !!v || 'Name erforderlich',
                  v => (v && v.length <= 200) || 'Name darf max. 200 Zeichen enthalten',
                ],

                categories: null,
                tours: null,
                lazy: false                
            };
        },
        props: {

            node: {
              type: Object,
              default: function() {
                  return {
                    pos_x: 0,
                    pos_y: 0,
                    pos_z: 0
                  }
              }
            },
            nodetype: {
              type: String
            },
            process: {
              type: String
            }            

        },

        components: {
          PanoMeSingle,
          Editor
        },

        methods: {
          zoom (dir) {
            this.$refs.panoComponent.zoom(dir)
          },
          updateEditor(content) {
            this.node.body = content;          
          },
          checkForm (data) {
            console.log(data);
          },
          validate () {
            console.log("validate!");
            this.$refs.form.validate()
          },
          reset () {
            this.$refs.form.reset()
          },
          resetValidation () {
            this.$refs.form.resetValidation()
          },

          onEditorBlur() {
            //console.log('editor blur!', quill)
          },
          onEditorFocus() {
            //console.log('editor focus!', quill)
          },
          onEditorReady() {
            //console.log('editor ready!', quill)
          },
          onEditorChange({ quill, html, text }) {
            console.log('editor change!', quill, html, text)
            this.content = html
          },
          deletePdf() {

            if(confirm("Wollen Sie die Datei wirklich löschen?")){

              if(this.node.pdfsrc) {

                var self = this;
                const formData = new FormData();
                formData.set('id', this.node.id);
                formData.set('pdfsrc', this.node.pdfsrc);
                
                this.axios.post( this.$config.host+"/pdf/delete", formData)
                .then(function (response) {

                    console.log(response);
                    self.node.pdfsrc = 'null';
                    self.node.pdfpath = null;

                })
                .catch(function (error) {
                    console.log(error);                
                });

              }            

            }

          },
          readURL(file) {

              var self = this;
              if (file) {
                  var reader = new FileReader();
                  
                  reader.onload = function (e) {
                      //console.log("e.target");
                      //console.log(e.target);
                      self.previewpdf = e.target.result;
                      //console.log(self.$refs.preview);
                      self.$refs.preview.attr('src', e.target.result);
                  }                  
                  reader.readAsDataURL(file);

              } else {

                this.pdf = self.previewpdf;

              }

          },
          onChangePDFUpload(){
            console.log("onchangePDFupload!");
            //this.node.pdffile = this.$refs.pdf.files[0];
            console.log("pdffile:");
            console.log(this.node.pdffile);            
            //this.readURL(this.pdf);
          },
          onChangePanoReference(e){

            this.nodePanRef = this.panos.find(pan => pan.id === e);
            //console.log("pano nach Panowechsel gefunden?");
            //console.log(this.nodePanRef);

            this.componentKey += 1;

            /*
            this.axios.get( this.$config.host+"/panos/" + e ).then(function (response) {

                    console.log("Panowechsel Response:");
                    console.log(response.data);

                    if(response.data[0].src) {
                      self.node.src = response.data[0].src;
                    }

                    console.log("PoiContent: db geladen: neuer pano node für Referenz: ");
                    console.log(self.node);

                    // Reload component:
                    self.componentKey += 1;
            });
            */

          },
          onChangeLinkReference(e) {
              console.log(e);
          },
          formSubmit(dir) {
              //e.preventDefault();

              const config = { headers: { 'Content-Type': 'multipart/form-data' } }

              const formData = new FormData();
              formData.set('name', this.node.name);
              formData.set('icon', this.node.icon);              
              formData.set('body', this.node.body);
              formData.set('pano_id', this.node.pano_id);
              formData.set('published', this.node.published);

              formData.set('pos_x', this.node.pos_x);
              formData.set('pos_y', this.node.pos_y);
              formData.set('pos_z', this.node.pos_z);
              formData.set('tourstart', (this.node.tour && this.node.tour.length < 2 && this.node.tourstart) ? this.node.tourstart : null);

              formData.set('link_id', this.node.link_id ? this.node.link_id : null);

              formData.set('process', this.node.id ? 'update' : 'generate');
              formData.set('id', this.node.id ? this.node.id : null);
              formData.set('pdfsrc', this.node.pdfsrc ? this.node.pdfsrc : null);

              formData.append('tour', JSON.stringify(this.node.tour));
              formData.append('pdffile', this.node.pdffile);
                            
              let self = this;

              this.axios.post( this.$config.host+"/poi", formData, config)
              .then(function (response) {
                  console.log(response);
                  if(dir) {
                    self.$router.push({path: '/'+self.$home+'content/poi'});
                  } else {
                    console.log("inhalt gespeichert");

                    self.$toasted.success('Datensatz gespeichert');
                  }
                  self.$emit('panosaved', null)

              })
              .catch(function (error) {
                  self.output = error;
              });
          },

          getPanos() {
              this.axios.get( this.$config.host+"/panos" ).then(response => (
                this.panos = response.data,
                this.panos.sort((a, b) => (a.id > b.id ? -1 : 1))
                //this.panos = UserContent.getContent(response.data, 'pano'),
              ));
          },
          getCategories() {
              var that = this;
              this.axios.get( this.$config.host+"/categories" ).then( function (response) {              
                  that.categories = response.data;                  
                  that.categories = UserContent.getContent(response.data, 'category');                                    
              })
          },
          getTours() {
              this.axios.get( this.$config.host+"/tours" ).then(response => (
                this.tours = response.data
                //this.tours = UserContent.getContent(response.data, 'tour')
              ));
          },          
          getPanoPosition(x,y,z) {
              this.node.pos_x = x;
              this.node.pos_y = y;              
              this.node.pos_z = z;
          },
          getPanoRef() {
            
          },
          getIntrospot(spotarray) {
            console.log("check if selected tour has introspot already");
            console.log(spotarray);
            console.log("check now!");
          }

        },
        created() {
            this.getPanos();
            this.getCategories();
            this.getTours();                      
        },
        mounted() {
            //console.log("PoiContent: node wert (pano_id) on mounted?");                        
        },
        watch: {
            node: function(newValue) {
                if(this.panos) {                  
                  this.nodePanRef = this.panos.find(pan => pan.id === this.node.pano_id);
                }
                console.log("NODE");
                console.log(this.node);
                this.node = newValue;
            },
            panos: function(newValue) {
                this.panos = newValue;

                // Fill pano reference data:
                if(this.node.pano_id && this.panos) {
                  this.nodePanRef = this.panos.find(pan => pan.id === this.node.pano_id);
                }

            }

        }

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

#nodeform label.top {
  margin-bottom:0.5rem;
  font-weight: 500;  
  color:#333;
  font-size:0.88rem;
}

.form-wrap {
  margin:30px 0;
}
#nodeform .v-subheader {
  padding-left:0;
  padding-right:0;
}
#nodeform .v-label {
  color:#666666;
}
.pdfwrap {
  padding:8px 24px 8px 14px;
  background-color:#f7f7f7;
  border-radius: 8px;
}
.txtwrap {
  padding: 10px 0;
  margin: 10px 0;
}
.panoembedwrap {
  position:relative;
  width:100%;
  height:auto;
  min-height:440px;
}

.panoembedwrap::after {
  content: " ";
  display:block;
  clear:both;
  height:1px;
}

.panocontrols {
  position:absolute;
  top:2px;
  right:2px;
  z-index:2;
}
.panoembedwrap img {
  max-width:100%;
  height:auto;
}
.preview {
  display:block;
  max-height:500px;
  max-width:100%;
}
.selecticon {
  height:20px;
  width: auto;
  margin-right:20px;
}
</style>
