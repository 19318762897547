<template>

    <div class="">

        <v-form
           id="nodeform"
           ref="form"
           v-model="valid"
           :lazy-validation="lazy">
        <v-row>

               <v-col cols="7" sm="7" >

                     <v-text-field
                       v-model="node.name"
                       :counter="200"
                       :rules="nameRules"
                       label="Name"
                       background-color="#f7f7f7"
                       required
                       color="#333333"
                       height=auto
                       class="my-5 py-2"
                     ></v-text-field>

                     <div class="txtwrap">

                      <v-subheader>Inhalt</v-subheader>

                       <vue-editor
                        :editorOptions="editorSettings"
                        useCustomImageHandler
                        @image-added="handleImageAdded"
                        v-model="node.content" />
                     </div>

                     <v-subheader>Veröffentlicht</v-subheader>

                     <v-checkbox
                      v-model="node.published"
                      label="Veröffentlicht"
                      ></v-checkbox>

                      <v-text-field
                        v-model="node.id"
                        type="hidden"
                      ></v-text-field>

                     <v-btn class="mr-4"
                      v-if="node.id"
                      :disabled="!valid"
                     @click="formSubmit"
                     >Aktualisieren</v-btn>

                     <v-btn class="mr-4"
                      v-else
                      :disabled="!valid"
                     @click="formSubmit"
                     >Speichern</v-btn>

               </v-col>

               <v-col cols="5" sm="5" >

                     <v-select
                       v-model="node.contenttype"
                       v-on:change="onChangeType"
                       ref="locRef"
                       :items="contenttypes"
                       :rules="[v => !!v || 'Ort ist erforderlich']"
                       item-value="type"
                       label="Inhaltstype auswählen"
                       required
                     >
                     <template v-slot:item='{item}'> <span v-html="item.name"></span> </template>
                     <template v-slot:selection='{item}'> <span v-html="item.name"></span> </template>
                     </v-select>

                    <v-autocomplete
                        v-model="node.content_id"
                        v-if="node.contenttype == 'testfield'"
                        ref="fieldRef"
                        :items="testfields"
                        :rules="[v => !!v || 'Testfeld-Referenz ist erforderlich']"
                        item-value="id"
                        item-text="name"
                        label="In Testfeld.."
                        class="mt-5 mb-0 py-2"
                        background-color="#f7f7f7"
                        required
                         >
                         <template v-slot:item='{item}'> <div v-html='item.title'/> </template>
                         <template v-slot:selection='{item}'> <div v-html='item.title'/></template>
                         </v-autocomplete>

                     <v-autocomplete
                         v-model="node.content_id"
                         v-if="node.contenttype == 'usecase'"
                         ref="fieldRef"
                         :items="usecases"
                         :rules="[v => !!v || 'Usecase-Referenz ist erforderlich']"
                         item-value="id"
                         item-text="name"
                         label="In Usecase.."
                         class="mt-5 mb-0 py-2"
                         background-color="#f7f7f7"
                         required
                          >
                          <template v-slot:item='{item}'> <div v-html='item.name'/> </template>
                          <template v-slot:selection='{item}'> <div v-html='item.name'/></template>
                          </v-autocomplete>
               </v-col>

        </v-row>

        </v-form>

    </div>

</template>

<script>

    import Quill from 'quill';
    import { ImageDrop } from 'quill-image-drop-module';
    import ImageResize from 'quill-image-resize-module';

    Quill.register('modules/imageDrop', ImageDrop);
    Quill.register('modules/imageResize', ImageResize);

    export default {
        name: 'ElementContent',
        data() {
            return {
                editorSettings: {
                    modules: {
                      imageDrop: true,
                      imageResize: true
                    }
                },
                componentKey: 0,
                published: false,
                errors: [],
                valid: true,
                file: '',
                previewsrc: null,
                resps: [],
                contenttypes: [
                  {
                    type: 'usecase',
                    name: 'Usecase'
                  },
                  {
                    type: 'testfield',
                    name: 'Testfeld'
                  }
                ],
                testfield: 2,
                testfields: [],
                usecases: [],
                autoUpdate: true,
                isUpdating: false,
                nodeRespRef: null,
                nameRules: [
                  v => !!v || 'Name erforderlich',
                  v => (v && v.length <= 200) || 'Name darf max. 200 Zeichen enthalten',
                ],
                lazy: false
            };
        },
        props: {

            node: {
                type: Object
            }

        },

        components: {

        },

        methods: {
          checkForm (data) {
            console.log(data);
          },
          validate () {
            this.$refs.form.validate()
          },
          reset () {
            this.$refs.form.reset()
          },
          resetValidation () {
            this.$refs.form.resetValidation()
          },

          readURL(input) {
              var self = this;
              if (input.file) {
                  var reader = new FileReader();
                  reader.onload = function (e) {
                      self.previewsrc = e.target.result;
                  }
                  reader.readAsDataURL(input.file);
              } else {
                this.file = self.previewsrc;
              }
          },

          onEditorChange({ quill, html, text }) {
            console.log('editor change!', quill, html, text)
            this.content = html
          },

          onChangeType(e){

            // Get new single pano data:
            //let self = this;

            this.nodeRespRef = this.resps.find(resp => resp.id === e);

            this.componentKey += 1;

          },

          handleImageAdded(file, Editor, cursorLocation, resetUploader) {

              var formData = new FormData();
              formData.append("image", file);

              var self = this;

              this.axios.post( this.$config.host+"/image", formData)
                .then(result => {

                  if(result.data == "max") {

                      self.$toasted.error('Datei leider zu groß');

                  } else {

                      let url = self.$config.imagepath + result.data.name;

                      console.log("URL für den Editor:");
                      console.log(url);

                      Editor.insertEmbed(cursorLocation, "image", url);
                      resetUploader();

                  }

                })
                .catch(err => {
                  console.log(err);
                });
          },

          formSubmit() {
              //e.preventDefault();

              const config = { headers: { 'Content-Type': 'multipart/form-data' } }

              const formData = new FormData();
              formData.set('name', this.node.name);
              formData.set('content', this.node.content);
              formData.set('content_id', this.node.content_id);
              formData.set('contenttype', this.node.contenttype);
              formData.set('published', this.node.published);
              formData.set('process', this.node.id ? 'update' : 'generate');
              formData.set('id', this.node.id ? this.node.id : null);
              //formData.append('testfields', JSON.stringify(this.node.testfields));
              //formData.append('file', this.file);

              // Was ist das hier??
              let currentObj = this
              let self = this;

              this.axios.post( this.$config.host+"/element", formData, config)
              .then(function (response) {
                  console.log("hier then..");
                  console.log(response);
                  self.$router.push({path: '/'+self.$home+'content/element'});
                  self.$emit('casesaved', null)

              })
              .catch(function (error) {
                  currentObj.output = error;
              });
          },

          getResps() {
              this.axios.get( this.$config.host+"/resps" ).then(response => (
                this.resps = response.data,
                console.log("respsContent: resps Referenzen erhalten:"),
                console.log(this.resps)
              ));
          },
          getTestfields() {
              this.axios.get( this.$config.host+"/testfields" ).then(response => (
                this.testfields = response.data,
                console.log("testfields in usecaseContent verfügbar:"),
                console.log(this.testfields)
              ));
          },
          getUsecases() {
              this.axios.get( this.$config.host+"/usecases" ).then(response => (
                  this.usecases = response.data,
                  console.log(this.usecases)
              ));
          },
          remove (item) {
                const index = this.node.testfields.indexOf(item.id)
                if (index >= 0) this.node.testfields.splice(index, 1)
          }

        },

        created() {
            this.getResps();
            this.getTestfields();
        },
        computed: {
            editor() {
              return this.$refs.myQuillEditor.quill
            }
        },
        mounted() {

        },
        watch: {
            node: function (values) {
                this.node = values;
                if(this.previewsrc == null) {
                    this.previewsrc =  this.node.pathimg ? this.node.pathimg : null;
                }
            }
        }

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
