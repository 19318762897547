<template>

  <v-card class="dashboardcontent">

    <v-card-actions>  
    <v-btn      
      icon
      :to="'/'+$home+'content/user'"
      >      
      <v-icon>mdi-table-of-contents</v-icon>
    </v-btn>
    </v-card-actions>    

  <v-list-item two-line>
      <v-list-item-icon
        class="dash-icon"
        >
        <v-icon
          size="24"          
          >mdi-account-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="d-title">
          Nutzer
        </v-list-item-title>        
      </v-list-item-content>
    </v-list-item>
  
  <v-data-table
      v-model="selected"
      :headers="headers"
      :items="users"
      :items-per-page="5"
      :footer-props="{
        'disable-items-per-page': true        
      }"      
      :single-select="singleSelect"
      :search="search"
      item-key="id"
      class="list-blank elevation-1"      
    >

    <template v-slot:body="{ items }">
      <tbody>
        <tr
          v-for="(item) in items"
          :key="item.id"
        >
          <td>{{ item.id }}</td>
          <td>
            <router-link :to="'/'+$home+'node/user/' + item.id" text small>
            {{ item.name }}
            </router-link>
          </td>                                             
          <td>
            {{ item.role_name }}
          </td>
          <td>
            {{ item.team_name }}
          </td>          
        </tr>
      </tbody>
    </template>

  </v-data-table>

  </v-card>
</template>

<script>
export default {
  name: 'DashboardUser',  
  props: ['type'],
  data () {
    return {
      singleSelect: false,
      selected: [],
      search: '',
      headers: [
        { text: 'ID',
          align: 'start',
          value: 'id'
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Rolle',
          value: 'role_name'
        },
        {
          text: 'Team',
          value: 'team_name'
        }        
      ],
      users: []      
    }
  },
  methods: {
      getUsers() {          
          this.axios.get( this.$config.host+"/users" ).then(response => (
            this.users = response.data
          ));
      }
  },
  mounted() {    
    this.getUsers();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.list-blank .v-data-footer {
    padding: 6px 8px;
 
}
.list-blank .v-data-footer__select {
  display:none;  
}
</style>
