<template>

  <v-app id="admincontent">

    <v-row>

        <v-col cols="2" sm="2">

            <SideMenu class=""></SideMenu>

        </v-col>

        <v-col cols="10" sm="10" class="bg" >            
            <v-card class="admincontent">   
                                      
                <div v-if="nodetype == 'pano'">
                  <PanoContent @panosaved="contentSaved" :node="node" :nodetype="nodetype" :process="'edit'"></PanoContent>
                </div>

                <div v-if="nodetype == 'poi'">
                  <PoiContent @panosaved="contentSaved" :node="node" :nodetype="nodetype" :process="'edit'"></PoiContent>
                </div>

                <div v-if="nodetype == 'resp'">
                  <RespContent @respsaved="contentSaved" :node="node" :nodetype="nodetype" :process="'edit'"></RespContent>
                </div>

                <div v-if="nodetype == 'user'">
                  <UserContent @usersaved="contentSaved" :node="node" :nodetype="nodetype" :process="'edit'"></UserContent>
                </div>

                <div v-if="nodetype == 'tour'">
                  <TourContent @usersaved="contentSaved" :node="node" :nodetype="nodetype" :process="'edit'"></TourContent>
                </div>

                <div v-if="nodetype == 'field'">
                  <FieldContent @fieldsaved="contentSaved" :node="node" :nodetype="nodetype" :process="'edit'"></FieldContent>
                </div>

                <div v-if="nodetype == 'usecase'">
                  <UsecaseContent @casesaved="contentSaved" :node="node" :nodetype="nodetype" :process="'edit'"></UsecaseContent>
                </div>

                <div v-if="nodetype == 'device'">
                  <DeviceContent @devicesaved="contentSaved" :node="node" :nodetype="nodetype" :process="'edit'"></DeviceContent>
                </div>

                <div v-if="nodetype == 'element'">
                  <ElementContent @elementsaved="contentSaved" :node="node" :nodetype="nodetype"></ElementContent>
                </div>

            </v-card>

            <!--
            <div class="noaccess">
              <p>{{ $t('noaccess') }}</p>            
            </div>
            -->

        </v-col>

    </v-row>

    <v-footer
      fixed
      bottom
    >

    </v-footer>

  </v-app>
  
</template>

<script>

import PanoContent from "./content/PanoContent.vue";
import PoiContent from "./content/PoiContent.vue";
import FieldContent from "./content/FieldContent.vue";
import RespContent from "./content/RespContent.vue";
import UserContent from "./content/UserContent.vue";
import TourContent from "./content/TourContent.vue";
import UsecaseContent from "./content/UsecaseContent.vue";
import DeviceContent from "./content/DeviceContent.vue";
import ElementContent from "./content/ElementContent.vue";
import SideMenu from "./navigation/SideMenu.vue";


export default {
  name: 'Node',

  components: {
    PanoContent,
    PoiContent,
    RespContent,
    UserContent,
    TourContent,
    UsecaseContent,
    DeviceContent,
    ElementContent,
    FieldContent,
    SideMenu
  },
  data() {
    return {
      nodetype: this.$route.params.nodetype,
      nodeid: this.$route.params.nodeid,
      node: {}
    }
  },
  /*
  beforeRouteUpdate(to, from, next) {
    next()
  },
  */
  methods: {
      getNode() {
          let self = this;
          this.axios.get( this.$config.host+"/" + this.nodetype + "/" + this.nodeid ).then(function (response) {

                  //console.log("get flex NODE type");
                  //console.log(response.data);
                  self.node = response.data;
          });
      },
      contentSaved() {
          console.log("Fkt contentSaved");
      }
  },
  mounted() {

    this.getNode();

  },
  computed: {
      user() {
        return this.$store.state.user
      }
  }

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.headline {
  border-bottom:1px solid #999;
  padding-bottom:10px;
}
.small {
  text-transform: none;
}

</style>

<style>

.hidden {
  visibility: hidden;
  height:0;
  overflow: hidden;
}

.admincontent .imgwrap {
  background-color:#f7f7f7;
  padding: 10px 10px;
  margin: 0px 0;
}
.admincontent .previewwrap {  
   padding:10px 0;
   height:360px;
   text-align:center;
}
.admincontent .previewwrap img {  
   max-height:100%;
   width:auto;    
   margin:0 auto;
}
.previewwrap .mdi-pdf-box {
  margin-top: -4px;
}

.previewwrap .v-btn {
  float:right;
  padding:0;
  height:28px !important;
  width:28px !important;
}

</style>
