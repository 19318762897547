<template>

    <div class="editor">
        
        <vue-editor
          :editorOptions="editorSettings"
          useCustomImageHandler          
          @image-added="handleImageAdded"
          v-model="content"                 
          ref="texteditor"   
           />

    </div>

</template>

<script>

    import Quill from 'quill';

    var Image = Quill.import('formats/image');
    Image.className = 'img';
    Quill.register(Image, true);

    var Inline = Quill.import('blots/inline');

    class DivBlock extends Inline{          
        static create(value){
            let node = super.create();
            console.log("value in divBlock");
            console.log(value);
            node.setAttribute('class','divblock');
            return node;    
        }         
    }

    DivBlock.blotName = 'divblock';
    DivBlock.tagName = 'div';
    Quill.register(DivBlock);

    import { ImageDrop } from 'quill-image-drop-module';
    import ImageResize from 'quill-image-resize-module';

    Quill.register('modules/imageDrop', ImageDrop);
    Quill.register('modules/imageResize', ImageResize);

    export default {

        name: 'Editor',
        props: {
            description: {
                type: String
            },
            elementindex: {
                type: Number
            }
        },
        data() {
            return {
                editorSettings: {
                    modules: {
                      imageDrop: true,
                      imageResize: true,
                      toolbar: {
                        container: [
                          [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                          ["bold", "italic", "underline", "strike"], // toggled buttons
                          [
                            { align: "" },
                            { align: "center" },
                            { align: "right" },
                            { align: "justify" }
                          ],
                          ["blockquote", "code-block"],
                          [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                          ["link", "image", "video"],
                          ["clean"], // remove formatting button                          
                          ['customControl'],
                          //['divblock']
                        ],
                        handlers: {

                          customControl: () => {                        
                            var value = prompt('Geben Sie hier den Text ein');                                                                        
                            var cursorPosition = this.$refs.texteditor.quill.getSelection(true);                                                           

                            //this.$refs.texteditor.quill.format('align', 'right');                                
                            //this.$refs.texteditor.quill.insertEmbed(cursorPosition, 'image', 'https://via.placeholder.com/150');
                            //this.$refs.texteditor.quill.insertEmbed(cursosPosition);
                            this.$refs.texteditor.quill.insertText(cursorPosition, value);
                            //this.$refs.texteditor.quill.insertText(caretPosition, value);

                          }
                        }
                      }                          
                    },                   
                },
                //content: null
            };
        },

        methods: {

          contentUpdate() {
            
            this.$emit('update',this.description)

          },

          onEditorChange({ quill, html, text }) {

            console.log('editor change!', quill, html, text)
            this.content = html

          },

          handleImageAdded(file, Editor, cursorLocation, resetUploader) {

              const config = { headers: { 'Content-Type': 'multipart/form-data' } }

              var formData = new FormData();
              formData.append("image", file);
              
              var self = this;
                                                        
              this.axios.post( this.$config.host+"/image", formData, config)

                .then(result => {
                  
                  //console.log("result?");
                  //console.log(result);
                  
                  if(result.data == "max") {                      

                      self.$toasted.error('Datei leider zu groß');

                  } else {
                      
                      let url = self.$config.imagepath + result.data.name;

                      console.log("url?");
                      console.log(url);

                      Editor.insertEmbed(cursorLocation, "image", url);
                      //console.log("resetUploader ?");                      
                      resetUploader();                      
                                          
                  }                  

                }).catch(err => {
                  console.log(err);
                });                                           
              
          },
          onChangeFileUpload(){
            //console.log("onchangefileupload!");
            this.file = this.$refs.file.files[0];
            //console.log("this.file:");
            //console.log(this.file);
            this.readURL(this);
          },

        },
        computed: {
            editor() {
              //console.log("editor computed!");
              return this.$refs.myQuillEditor.quill
            },            
            content: {
                get()
                {
                  //console.log("get content!");
                  return this.description
                },
                set(value)
                {
                  //console.log("set content: " + value);
                  this.$emit('update',value,this.elementindex)
                }            
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.editor {
  border:1px solid #9e9e9e;
  padding:10px;
  border-radius: 4px;
}
.ck-editor__editable {
  min-height: 300px;
}

.ql-editor p {
  margin:0 0 1rem !important;
}
.ql-editor .ql-video {
  width:100%;
  height:450px;
}
.ck-editor__editable {
  min-height: 300px;
}
.ql-customControl {
  background-color: orange !important;
  border:1px solid blue !important;
}
/*
.ql-divblock {
  width:80px;
  padding:0;
}
.ql-divblock:after {
  content: "custom";
  font-size: 0.7rem;
}
*/

</style>
