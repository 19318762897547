<template>
    <div class="page device">
        
            <!-- Header -->
            <v-layout class="first header">
                <div class="pbackground">
                    <v-container>
                        <v-row justify="center" align="center">
                            <v-col class="wrap" cols="10" sm="10">
                                <!--
                                <v-row>
                                    <v-col cols="12" sm="12" class="py-2 pr-2 text-right">
                                        <v-toolbar
                                            dense                                            
                                            flat  
                                            color="transparent"                                                                                      
                                        >   
                                            <v-spacer></v-spacer>                                                                                 
                                            <v-btn icon>
                                                <v-icon color="white">mdi-magnify</v-icon>
                                            </v-btn>
                                            <v-btn icon>
                                                <v-icon color="white">mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </v-toolbar>
                                    </v-col>                                
                                </v-row>
                                -->
                                <v-row >
                                    <v-col cols="7" sm="7" class="title">
                                        <div>
                                            <span class="headtitle">{{ $t(type) }}</span>
                                            <h3 v-if="node.name">{{ node.name }}</h3>
                                        </div>
                                    </v-col>   
                                    <v-col cols="5" sm="5" class="img">                                                                                
                                        <v-img                                        
                                        max-height="220"
                                        max-width="400"
                                        :src="node.pathimg"
                                        @click="index = 0"                                        
                                        >
                                        <v-icon                                            
                                        >mdi-magnify</v-icon>
                                        </v-img>                                                 
                                    </v-col>   
                                </v-row>

                                <div class="gallery">
                                    <vue-gallery-slideshow 
                                        :images="images" 
                                        :index="index" 
                                        @close="index = null"                                    
                                    ></vue-gallery-slideshow>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
            </v-layout>                

            <v-layout class="content">
                <v-container class="">
                      <v-row justify="center" align="center">
                           <v-col class="wrap" cols="10" sm="10">
                               <v-row>
                                   <v-col cols="8">                                                                              
                                        <div v-html="node.description"></div>
                                    </v-col>
                                    <v-col cols="4" style="padding-top:20px;">
                                        
                                        <div class="modul" v-if="node.testfield && node.testfield.length > 0">
                                        
                                            <span class="topicheader">Testfelder</span>
                                            <div 
                                                v-for="(field, key) in node.testfield"
                                                :key="key"
                                                >

                                            <v-btn
                                                class="ma-2 field-btn"
                                                depressed                                                                                                                           
                                                :to="'/p/field/'+field.tf_id" 
                                            >                                        
                                                {{ field.title }}                                        
                                            </v-btn>                

                                            <router-link
                                                class="ma-2 link"                                                                                                
                                                depressed                                            
                                                color="#ddd"                                                                                            
                                                :to="'/p/devices/'+field.tf_id" 
                                            >                                        
                                            Gesamtliste aus diesem Testfeld
                                            </router-link>
                                            </div>

                                        </div>                                       

                                    </v-col>
                                   </v-row>
                                                                                                                              
                           </v-col>
                      </v-row>
                </v-container>
            </v-layout>

            <v-layout>

                <v-container>

                    <v-row justify="center" align="center">

                        <v-col class="wrap" cols="9" sm="9">

                            <div class="servicewrap"
                                v-for="(element, key) in node.elements"
                                :key="key"                            
                                >
                                <v-row>
                                    <v-col cols="12" sm="12">
                                    <h4>{{ element.name }}</h4>
                                    <div v-html="element.content"></div>
                                    </v-col>
                                </v-row>                 
                            </div>               
                        </v-col>
                    </v-row>
                </v-container>
            </v-layout>

            <v-layout class="iblue">
                <v-container>
                      <v-row justify="center" align="center">
                          <v-col class="wrap" cols="9" sm="9">
                                <Contact></Contact>
                          </v-col>
                      </v-row>
                </v-container>
            </v-layout>

            <v-layout class="iwhite">
                <v-container>
                      <v-row justify="center" align="center">
                          <v-col class="wrap" cols="10" sm="10">
                              <h4>Das könnte Sie auch interessieren</h4>
                              <Testfields></Testfields>
                              <br><br><br>
                          </v-col>
                      </v-row>
                </v-container>
            </v-layout>

    </div>
</template>

<script>

import Testfields from './Testfields';
import Contact from '../elements/Contact';
import VueGallerySlideshow from 'vue-gallery-slideshow';

    export default {
        name: 'Page',
        data() {
            return {
                type: 'device',
                images: [],
                index: null
            };
        },
        props: {
            node: {
                type: Object
            }
        },
        components: {          
          Testfields,
          Contact,
          VueGallerySlideshow
        },
        methods: {
            getImages(img) {
                this.images.push(img);                                
            }
        },
        mounted() {
            console.log("DeviceContent: node wert (testfield) on mounted?");
            console.log(this.node);
            if(this.node.pathimg) {
                this.getImages(this.node.pathimg);
            }            
        },
        watch: {
            node: function (values) {
                console.log("watch device:");
                console.log(values);
                this.node = values;
                if(this.node.pathimg) {
                    this.getImages(this.node.pathimg);
                }            
            }
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.page.device .header .title {
    padding-top:50px;
}
.page.device .header h3 {
    font-size:2.4rem;
}

.page.device .header h3,
.page.device .header .headtitle {
    color:#333;
}

.gallery .vgs__container {
    max-width: 100vh;
    margin: .5rem auto 0;
    left: .5rem;
    right: .5rem;
    top:1.5rem;
    bottom: 1.5rem;
    height: 90vh !important; 
    border-radius: 12px;
    background-color:transparent;
}
.img .v-icon {
    position: absolute;
    bottom:10px;
    left:10px;    
    color:white;
}
.device .v-image:hover {
    cursor: pointer;
}

</style>

