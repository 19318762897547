<template>
  <div>
    <div class="logos">    
      <a href="https://innohub13.de" target="_blank" title="Innovation Hub 13">
        <img :src="$config.graphpath + 'Logoleiste_InnovationHub13_Webseite.png'" alt="" title="" />                
      </a>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
      }
    },
    props: {

    },    
    methods:{

    },
    mounted() {
        //console.log("Mounted contacts module");
        //console.log(this.contacts);            
    } 
  }
</script>

<style scoped>
.logos {
  position: fixed;
  bottom:50px;
  left:auto;
  padding:0 14px 0 4px;
}
.logos img {
  max-width:100%;
}

</style>>

