<template>
  <div id="chatbot" style="overflow:hidden;">    

    <splitpanes class="default-theme" @resize="paneSize = $event[0].size">
      <pane class="pane1" :size="paneSize">                      
        <Chatbotinfo
          :infocontent="infotext"
          :sizeWidth="paneSize"
        ></Chatbotinfo>
      </pane>
      <pane class="pane2" :size="100 - paneSize">            
    
        <loading :active="isLoading"
          loader="dots"
          :height="100"
          :width="200"
          color="#005CA9"
          :opacity="0.98"
          :is-full-page="true"
          ></loading>

        <!-- Stage overlay -->
        <div class="chatbot-overlay" v-if="stageoverlay"></div>              
        
        <iframe width="100%" height="100%" src="https://chatbot.th-wildau.de/" ></iframe>
                          
      </pane>
    </splitpanes>

  </div>
</template>

<script>

import { Splitpanes, Pane } from 'splitpanes';
import Chatbotinfo from './info/info_chatbot.vue';
import 'splitpanes/dist/splitpanes.css';
import Loading from 'vue-loading-overlay';

export default {
  name: 'Chatbot',

  components: {
    Splitpanes,
    Pane,
    Chatbotinfo,    
    Loading    
  },

  data: function () {
    return {
      isLoading: true,
      stageoverlay: false,
      paneSize: 28,
      infotext: 'infotext'
    }
  },
  methods:{

  },
  mounted() {

  },
  created() {
    setTimeout(() => {
      this.isLoading = false
    },2000)
  },
    beforeRouteLeave (to, from , next) {
    const answer = window.confirm('Wollen Sie die Anwendung wirklich verlassen?')    
    if (answer) {
      next()
    } else {
      next(false)
    }    
  }  

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.vld-overlay {
  z-index:4 !important;
}
#chatbot iframe {
  border:0;
  margin-right:7%;
}
#chatbot #controls {
  position:fixed;
  top:0;
  right:0;
  width:7%;
  height:100%;
  padding-top:60px;
  z-index: 1;
  background-color:rgba(45,89,134,0.96);
}

#chatbot #controls .ucnav {
  float:none;
  text-align:center;
}
#chatbot {
  height:100%;
  width:100%;
  padding-top:48px;  
  position:static;
  border:0px solid green;    
}
.chatbot-overlay {
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  /*background-color: rgba(255, 153, 0, 0.8);*/
  background-color: rgba(92,175,254,0.8);
}

</style>
