const store = require('./store');

module.exports = {
    getContent: (content, type) => {
        
        var user = store.default.state.user; 

        // return allowed content:
        return content.filter(item => store.default.state.access.readitem(item,type,user) || item.user_id == user.ldap_id)
       
    },
    anotherHelper: (value) => {
        console.log(value);
    }

}