var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('v-form',{ref:"form",attrs:{"id":"nodeform","lazy-validation":_vm.lazy},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"7","sm":"7"}},[_c('v-text-field',{staticClass:"my-5 py-2",attrs:{"counter":200,"rules":_vm.nameRules,"label":"Name","background-color":"#f7f7f7","required":"","color":"#333333","height":"auto"},model:{value:(_vm.node.name),callback:function ($$v) {_vm.$set(_vm.node, "name", $$v)},expression:"node.name"}}),_c('div',{staticClass:"txtwrap"},[_c('v-subheader',[_vm._v("Inhalt")]),_c('vue-editor',{attrs:{"editorOptions":_vm.editorSettings,"useCustomImageHandler":""},on:{"image-added":_vm.handleImageAdded},model:{value:(_vm.node.content),callback:function ($$v) {_vm.$set(_vm.node, "content", $$v)},expression:"node.content"}})],1),_c('v-subheader',[_vm._v("Veröffentlicht")]),_c('v-checkbox',{attrs:{"label":"Veröffentlicht"},model:{value:(_vm.node.published),callback:function ($$v) {_vm.$set(_vm.node, "published", $$v)},expression:"node.published"}}),_c('v-text-field',{attrs:{"type":"hidden"},model:{value:(_vm.node.id),callback:function ($$v) {_vm.$set(_vm.node, "id", $$v)},expression:"node.id"}}),(_vm.node.id)?_c('v-btn',{staticClass:"mr-4",attrs:{"disabled":!_vm.valid},on:{"click":_vm.formSubmit}},[_vm._v("Aktualisieren")]):_c('v-btn',{staticClass:"mr-4",attrs:{"disabled":!_vm.valid},on:{"click":_vm.formSubmit}},[_vm._v("Speichern")])],1),_c('v-col',{attrs:{"cols":"5","sm":"5"}},[_c('v-select',{ref:"locRef",attrs:{"items":_vm.contenttypes,"rules":[function (v) { return !!v || 'Ort ist erforderlich'; }],"item-value":"type","label":"Inhaltstype auswählen","required":""},on:{"change":_vm.onChangeType},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.name)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.name)}})]}}]),model:{value:(_vm.node.contenttype),callback:function ($$v) {_vm.$set(_vm.node, "contenttype", $$v)},expression:"node.contenttype"}}),(_vm.node.contenttype == 'testfield')?_c('v-autocomplete',{ref:"fieldRef",staticClass:"mt-5 mb-0 py-2",attrs:{"items":_vm.testfields,"rules":[function (v) { return !!v || 'Testfeld-Referenz ist erforderlich'; }],"item-value":"id","item-text":"name","label":"In Testfeld..","background-color":"#f7f7f7","required":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.title)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.title)}})]}}],null,false,329046108),model:{value:(_vm.node.content_id),callback:function ($$v) {_vm.$set(_vm.node, "content_id", $$v)},expression:"node.content_id"}}):_vm._e(),(_vm.node.contenttype == 'usecase')?_c('v-autocomplete',{ref:"fieldRef",staticClass:"mt-5 mb-0 py-2",attrs:{"items":_vm.usecases,"rules":[function (v) { return !!v || 'Usecase-Referenz ist erforderlich'; }],"item-value":"id","item-text":"name","label":"In Usecase..","background-color":"#f7f7f7","required":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.name)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.name)}})]}}],null,false,145413692),model:{value:(_vm.node.content_id),callback:function ($$v) {_vm.$set(_vm.node, "content_id", $$v)},expression:"node.content_id"}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }