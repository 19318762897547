<template>
  <v-app>
    <div>      
        <Page_Devices :testfield="testfield" ></Page_Devices>      
    </div>
    <Footer></Footer>
  </v-app>
</template>

<script>

import Page_Devices from './content/Page_Devices';
import Footer from './elements/Footer';

export default {
  name: 'ContentFront',

  components: {
    Page_Devices,
    Footer
  },
  data() {
      return {
        nodetype: this.$route.params.nodetype,
        nodeid: this.$route.params.nodeid,
        testfield: {}
      }
  },
  methods: {

      getDevices() {
          let self = this;
          this.axios.get( this.$config.host+"/field/" + this.nodeid ).then(function (response) {
                  console.log("get flex CONTENT FRONT DEVICES by field id");
                  console.log(response.data);
                  self.testfield = response.data;
          });
      },

  },
  mounted() {
      this.getDevices();
  },
  watch: {

  },
  computed: {

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

#content {
    margin-top:0px;
}
.modul {
  margin:0 0 30px;
}
.modul .body {
  padding: 0 6px;
}
.modul .body p {
  margin-bottom:2px;
  font-size:0.94rem;
}
.topicheader {
  display: block;
  padding:10px 8px;
  margin:0;
  font-size:1rem;
  font-weight: 500;
  text-transform: uppercase;
}
.modul .link {
  display:inline-block;
  padding:6px 8px 6px 6px;
  color:#333333 !important;  
}
.modul .link:hover {
  text-decoration: none;
  background-color:#f3f3f3;
}
.page {
    padding-top:40px;
}
.page .category {
  font-size:0.8rem;
  padding:0;
}
.page .header {
    padding-top:4px !important;
}
.page .headtitle {
    color:#ffffff;
}
.page .header .wrap {
    /*padding:0;*/
}
.page .header .container .row {
  padding-top:0px;
}
.page .main {
  padding-right:10px;
}

.pagetoolbar {
  padding-top:0px;
}
/*
.page .header .logocol {
  display: flex;
  flex-direction: column-reverse;  
}
*/
.page .header .logocol {  
  background-color: rgba(255,255,255, 0.9);
  text-align: center;
  border-radius: 40px;
  padding:14px;
  width:60px;
}
.page .header .logo {
}
.logosub {
    padding-top:12px;
}
.page .header h4 {
  margin:0;
  padding:10px 0;
  font-size:1.4rem;
}
.page .content h4 {
    font-size:1.5rem;
    margin-bottom:1.5rem;
    margin-top:1.5rem;
}
.page .content li {
    padding-left:20px;
}
.page .imgsection {
    margin-bottom: 40px;
}

</style>
