<template>

  <div id="footer" class="container">
    <v-row justify="center">
      <v-col cols="10" class="ref">
        <p>Der „<b>Innovation Hub 13 – fast track to transfer</b>“ der Technischen Hochschule Wildau und der Brandenburgischen Technischen Universität Cottbus-Senftenberg gehört zu den 29 ausgewählten Gewinnern der Bund-Länder-Förderinitiative „Innovative Hochschule”, ausgestattet mit Mitteln des Bundesministeriums für Bildung und Forschung BMBF und des Landes Brandenburg. Weitere Informationen finden Sie unter <a href="www.innovative-hochschule.de" target="_blank" title="Innovative Hochschule">www.innovative-hochschule.de</a></p>
        <img :src="$config.graphpath + 'Logoleiste_InnovationHub13_Webseite.png'" alt="" title="" />        
      </v-col>    
    </v-row>
  </div>

</template>

<script>
  export default {
    data () {
      return {
      }
    },
    props: {

    },    
    methods:{

    },
    mounted() {
        //console.log("Mounted contacts module");
        //console.log(this.contacts);            
    } 
  }
</script>

<style>

</style>
