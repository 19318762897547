<template>
    <div class="">
        <v-form
           id="nodeform"
           ref="form"
           v-model="valid"
           :lazy-validation="lazy">

            <!-- CARD ACTIONS -->
            <v-toolbar
              flat
              style="border-bottom:2px solid #ddd"              
              >
        
                <v-toolbar-title>EQUIPMENT 
                  <span v-if="process=='add'" class="add">{{ $t('add') }}</span>
                  <span v-else class="edit">{{ $t('edit') }}</span>
                </v-toolbar-title>                  
                  
                    <v-checkbox
                    v-model="node.published"
                    label="Veröffentlicht"
                    dense
                    class="check-published"
                    ></v-checkbox>
                  
                    <v-spacer></v-spacer>                    

                    <v-btn class="mr-4"
                    v-if="node.id"
                    :disabled="!valid"
                    @click="formSubmit"
                    style="display:inline-block"
                    >Aktualisieren</v-btn>

                    <v-btn class="mr-4"
                    v-else
                    :disabled="!valid"
                    @click="formSubmit"
                    style="display:inline-block"
                    >Speichern</v-btn>
                  
                    <v-btn-toggle                        
                      borderless                    
                      dense    
                      class="mr-2"
                      >
                      <v-btn      
                        icon
                        :to="'/'+$home+'content/'+nodetype"
                        >      
                        <v-icon>mdi-table-of-contents</v-icon>
                      </v-btn>

                      <v-btn 
                        icon                                              
                        :to="'/'+$home+'p/device/'+node.id" 
                        target="_blank" 
                        title="Vorschau"
                        >                        
                        <v-icon>
                          mdi-open-in-new
                        </v-icon>
                      </v-btn>

                    </v-btn-toggle>
  
            </v-toolbar>
            <!-- / CARD ACTIONS --->

            <v-row class="nodecontent">

               <v-col cols="7" sm="7" >

                    <v-text-field
                      v-model="node.name"
                      :counter="200"
                      :rules="nameRules"
                      label="Name"
                      background-color="#f7f7f7"
                      required
                      color="#333333"
                      height=auto
                      class="my-5 py-2"
                    ></v-text-field>

                    <v-row>
                    <v-col cols="6" sm="6">
                      <v-subheader>Aus den Testfeldern</v-subheader>
                        <v-autocomplete
                          v-model="node.testfields"
                          :disabled="isUpdating"
                          :items="testfields"
                          filled
                          chips
                          color="blue-grey lighten-2"
                          label=""
                          item-text="title"
                          item-value="id"
                          multiple
                          dense
                        >
                          <template v-slot:selection="data">
                            <v-chip
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              close
                              @click="data.select"
                              @click:close="remove(data.item)"
                            >
                              {{ data.item.title }}
                            </v-chip>
                          </template>

                          <template v-slot:item="data">

                            <template>
                              <v-list-item-content>
                                <v-list-item-title v-html="data.item.title"></v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </template>

                        </v-autocomplete>

                      </v-col>
                      <v-col cols="6" sm="6">
                        <v-subheader>Kategorie</v-subheader>
                        <v-select
                          v-model="node.category"
                          :items="categories"
                          :rules="[v => !!v || 'Kategorie ist erforderlich']"
                          item-value="id"
                          label="Kategorie auswählen"
                          required
                          class="my-1 py-1"
                          >
                          <template v-slot:item='{item}'> <span v-html="item"></span> </template>
                          <template v-slot:selection='{item}'> <span v-html="item"></span> </template>
                        </v-select>

                      </v-col>
                    </v-row>

                     <div class="txtwrap">

                      <v-textarea
                        background-color="#f7f7f7"
                        v-model="node.features"
                        color="black"
                        label="Funktionen"
                      ></v-textarea>

                      <v-subheader>Beschreibung</v-subheader>

                      <Editor :description="node.description" @update="updateEditor"></Editor>

                     </div>

                     <v-subheader>Veröffentlicht</v-subheader>

                     <!--
                     <v-checkbox
                      v-model="node.published"
                      label="Veröffentlicht"
                      ></v-checkbox>
                      -->

                      <v-text-field
                        v-model="node.id"
                        type="hidden"
                      ></v-text-field>

                     <v-btn class="mr-4"
                      v-if="node.id"
                      :disabled="!valid"
                     @click="formSubmit"
                     >Aktualisieren</v-btn>

                     <v-btn class="mr-4"
                      v-else
                      :disabled="!valid"
                     @click="formSubmit"
                     >Speichern</v-btn>

               </v-col>

               <v-col cols="5" sm="5" >

                  <v-row>
                    <v-col cols="6" sm="6" >
                      <v-text-field
                        type="number"
                        v-model="node.count"
                        class="count"
                        value="0"
                        label="Stückzahl"
                        >                    
                        </v-text-field>
                    </v-col>
                    <v-col cols="6" sm="6" >

                      <v-menu
                        v-model="datepicker"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        lazy
                        transition="scale-transition"
                        offset-y
                        full-width
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            label="Anschaffungsdatum"
                            prepend-icon="mdi-calendar-check"
                            readonly
                            :value="fromDateDisp"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          locale="en-in"
                          v-model="node.purchasedate"
                          no-title
                          @input="datepicker = false"
                        ></v-date-picker>
                      </v-menu>

                    </v-col>                   
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="12" >
                      <v-text-field
                        class="mt-0"
                        v-model="node.manufacturer"                                                
                        label="Hersteller"                                                                                                                        
                      ></v-text-field>
                    </v-col>
                  </v-row>


                  <v-autocomplete
                      v-model="node.responsible_id"
                      v-on:change="onChangeResponsibility"
                      ref="fieldRef"
                      :items="resps"                      
                      item-value="id"
                      item-text="name"
                      label="verantworlich"
                      class="mt-5 mb-0 py-2"
                      background-color="#f7f7f7"
                      required
                        >
                  <template v-slot:item='{item}'> <div v-html='item.name'/> </template>
                  <template v-slot:selection='{item}'> <div v-html='item.name'/></template>
                  </v-autocomplete>

                  <v-subheader>Kopfbild</v-subheader>
                  <div class="imgwrap">                     
                    <label>
                    <input type="file" id="file" ref="file" v-on:change="onChangeFileUpload()" />
                    </label>
                    <v-text-field
                      v-model="node.src"
                      type="hidden"
                    ></v-text-field>
                    <div class="previewwrap">
                    <img class="preview" ref="preview" v-if="previewsrc" :src="previewsrc" alt="Preview Image" />
                    </div>
                  </div>

                  <div class="gallerywrap">

                    <label class="top">Bildergalerie</label>

                    <vue-upload-multiple-image
                    v-model="node.gallery"
                    @upload-success="uploadImageSuccess"
                    @before-remove="beforeRemove"
                    @edit-image="editImage"
                    :data-images="images"        
                    primaryText="Text Startbild"
                    markIsPrimaryText="Als Startbild fertlegen"            
                    dragText="Datei hier ablegen"
                    browseText="Datei auswählen"
                    popupText="Beschreibung Standardbild"
                    ></vue-upload-multiple-image>

                  </div>          

               </v-col>

            </v-row>

        </v-form>
    </div>
</template>

<script>

    import Editor from '../tools/Editor';
    import VueUploadMultipleImage from 'vue-upload-multiple-image';

    export default {
        name: 'DeviceContent',
        data() {
            return {
                editorSettings: {
                    modules: {
                      imageDrop: true,
                      imageResize: true
                    }
                },
                componentKey: 0,
                published: false,
                errors: [],
                valid: true,
                file: '',
                previewsrc: null,
                resps: [],
                testfield: 2,
                testfields: [],
                autoUpdate: true,
                isUpdating: false,
                nodeRespRef: null,
                nameRules: [
                  v => !!v || 'Name erforderlich',
                  v => (v && v.length <= 200) || 'Name darf max. 200 Zeichen enthalten',
                ],
                lazy: false,
                images: [],
                datepicker: false,                
                categories: ['Software','Produktionssystem','Drucker','Gerät','Messgerät','Sensor','Möblierung','Werbematerial','Verbrauchsmaterial']
            };
        },
        props: {
            nodetype: {
                type: String
            },
            node: {
                type: Object
            },
            process: {
                type: String
            }

        },

        components: {
          Editor,
          VueUploadMultipleImage
        },

        methods: {
          checkForm (data) {
            console.log(data);
          },
          validate () {
            this.$refs.form.validate()
          },
          reset () {
            this.$refs.form.reset()
          },
          resetValidation () {
            this.$refs.form.resetValidation()
          },
          onEditorChange({ quill, html, text }) {
            console.log('editor change!', quill, html, text)
            this.content = html
          },
          onChangeResponsibility(e){
            this.nodeRespRef = this.resps.find(resp => resp.id === e);
            this.componentKey += 1;
          },
          updateEditor(content) {
            this.node.description = content;          
          },
          readURL(file) {
              var self = this;
              if (file) {
                  var reader = new FileReader();
                  reader.onload = function (e) {
                      self.previewsrc = e.target.result;
                  }
                  reader.readAsDataURL(file);
              } else {
                this.file = self.previewsrc;
              }
          },          
          onChangeFileUpload(){
            console.log("onchangefileupload!");
            this.file = this.$refs.file.files[0];
            console.log("this.file:");
            console.log(this.file);
            this.readURL(this.file);
          },
          /**
           * Methods lazy upload images
           */
          uploadImageSuccess(formData, index, fileList) {
              console.log("call uploadImageSuccess");
              //const config = { headers: { 'Content-Type': 'multipart/form-data' } }
              console.log('images data', formData, index, fileList)
              console.log('list länge?');
              console.log(fileList);
              console.log("images?");              
              this.images = fileList;
              console.log(this.images);
              // Upload image api
              /*
              this.axios.post('http://localhost:3003/galleryupload', formData, config).then(response => {
                console.log(response)
              })
              */
          },
          beforeRemove (index, done, fileList) {
            console.log('before remove: index', index, fileList)
            var r = confirm("remove image")
            if (r == true) {
              done()
            } else {
              console.log("not remove img");
            }
          },
          editImage (formData, index, fileList) {
            console.log('edit image data', formData, index, fileList)
          },
          formSubmit() {
              //e.preventDefault();

              const config = { headers: { 'Content-Type': 'multipart/form-data' } };

              console.log("vor submit device");
              console.log(this.node);
              console.log(this.file);

              const formData = new FormData();
              formData.set('name', this.node.name);              
              formData.set('description', this.node.description);
              formData.set('features', this.node.features ? this.node.features : null);
              formData.set('category', this.node.category ? this.node.category : null);
              formData.set('purchasedate', this.node.purchasedate ? this.node.purchasedate : null);
              formData.set('count', this.node.count ? this.node.count : 0);
              formData.set('manufacturer', this.node.manufacturer);
              formData.set('responsible_id', this.node.responsible_id ? this.node.responsible_id : null);
              formData.set('published', this.node.published);
              formData.set('process', this.node.id ? 'update' : 'generate');
              formData.set('src', this.node.src ? this.node.src : null); 
              formData.set('id', this.node.id ? this.node.id : null);
              formData.append('testfields', JSON.stringify(this.node.testfields));
              formData.append('file', this.file);

              // Was ist das hier??
              let currentObj = this
              let self = this;

              this.axios.post( this.$config.host+"/device", formData, config)
              .then(function (response) {
                  //console.log("hier then..");
                  console.log(response);
                  self.$router.push({path: '/'+self.$home+'content/device'});
                  self.$emit('casesaved', null)

              })
              .catch(function (error) {
                  currentObj.output = error;
              });
          },
          getResps() {
              this.axios.get( this.$config.host+"/resps" ).then(response => (
                this.resps = response.data,
                console.log("respsContent: resps Referenzen erhalten:"),
                console.log(this.resps)
              ));
          },
          getTestfields() {
              this.axios.get( this.$config.host+"/testfields" ).then(response => (
                this.testfields = response.data,
                console.log("testfields in usecaseContent verfügbar:"),
                console.log(this.testfields)
              ));
          },
          remove (item) {
                const index = this.node.testfields.indexOf(item.id)
                if (index >= 0) this.node.testfields.splice(index, 1)
          }

        },

        created() {
            this.getResps();
            this.getTestfields();
        },
        computed: {
            editor() {
              return this.$refs.myQuillEditor.quill
            },
            fromDateDisp() {
              return this.node.purchasedate;
            },            
        },
        mounted() {

        },
        watch: {
            node: function (values) {
                this.node = values;
                if(this.previewsrc == null) {
                    this.previewsrc =  this.node.pathimg ? this.node.pathimg : null;
                }
            }
        }

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
