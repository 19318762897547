<template>
<div id="pano" style="width:100%;height:100%;">
  <noscript>
        <table style="width:100%;height:100%;">
          <tr style="vertical-align:middle;">
            <td>
              <div style="text-align:center;">
                ERROR:
                <br/>
                <br/>Javascript not activated
                <br/>
                <br/>
              </div>
            </td>
          </tr>
        </table>
  </noscript>      
</div>
</template>

<script>

//import {embedpano} from '@/krpano/krpano.js';
import xmlbuilder from 'xmlbuilder';
//import * as THREE from 'three';

export default {
  name: 'Krpano',
  components: {
    //Loading
  },

  props: {
    unlocked: {
      type: Boolean
    },
    source: {
      type: Array,
      default: function () { return [] }
    },
    queryid: {
      //type: String
      type: Number
    },
    sourceSingle: {
      type: Object
    },
    type: {
      type: String,
      //default: 'video'
      default: 'image'
      //default: 'cube'
    },
    width: {
      type: Number,
      default: 100
    },
    height: {
      type: Number,
      default: 100
    },
    password: {
      type: String
    }
  },

  data () {
    return {
      dataready: false,
      xml: null,
      krpano: null,
      size: {
        width: this.width,
        height: this.height
      },      
      thisSource: null,
      state: null,
      panotitle: null,
      panoinfo: null,
      panocurrent: null,
      infospots: [],
      pos: 0
    }
  },

  created () {
    window.addEventListener('resize', this.onResize, false);
  },

  destroyed () {
    if (this.panorama && this.panorama.dispose) {
      this.panorama.dispose();
    }
  },

  mounted () {

    if(this.source) {          
      this.loadPano();
    }    
    
    //this.getHotspots(1, 3);       
    this.onResize();
    this.listen();

  },

  watch: {
    source () {
      //if (!this.viewer) return
      //if (!this.panorama) return    
      if(!this.dataready) {
        this.loadPano();
      }
      this.dataready = true;      
    },    
    unlocked (v) {
      console.log("unlocked watched!");
      console.log(v);
    }    
  },

  methods: {

    generateXml( pano ) {

      // Create xml    
      var krpanoXml = xmlbuilder.create('krpano',{ encoding: 'utf-8' });

      /* Set bottom log */

      var logo_dyn = 0;

      switch (pano.bottomlogo) {
        case 1:
          logo_dyn = 'logo_thwildau.png';
          break;

        case 2:
          logo_dyn = 'logo_btu.png';
          break;

        case 3:
          logo_dyn = 'logo_gewerbehof.png';
          break;

        case 4:
          logo_dyn = 'logo_startinn.png';
          break;

        case 5:
          logo_dyn = 'logo_vinnlab.png';
          break;

        default:
          logo_dyn = 0;
          break;
      }
  
      // Create an XML fragment
      /*
      var preview = xmlbuilder.create({
          preview: {
          '@type': 'grid(cube,64,64,512,0xCCCCCC,0xF6F6F6,0x999999)'
          }
      });
      krpanoXml.importDocument(preview);
      */

     // Startview correction

      var lookAtPositions = [
        0, // front  | 0
        45, // 45° right  | 1
        90, // 90° right  | 2
        135, // 135° right  | 3
        180, // back  | 4
        -135, // 135° left  | 5
        -90, // 90° left  | 6
        -45, // 45° left  | 7

        22, // 22° right  | 8
        67, // 67° right  | 9
        112, // 112° right  | 10
        157, // 157° right  | 11
        -157, // 157° left  | 12
        -112, // 112° left  | 13
        -67, // 67° left  | 14
        -22 // 22° left  | 15
      ];

      var panID = xmlbuilder.create({
          id: {
            '@panoid': pano.id
          }
      });
      krpanoXml.importDocument(panID);

      // Include XML showtext
      var include = xmlbuilder.create({
          include: {
            '@url': '/'+this.$home+'krpano/plugins/showtext.xml'
          }
      });
      krpanoXml.importDocument(include);

      // Include WEBVR xml      
      var include2 = xmlbuilder.create({
          include: {
            '@url': '/'+this.$home+'krpano/plugins/webvr_custom.xml',
            '@devices': 'html5'
          }
      });
      krpanoXml.importDocument(include2);            

      // Include webvr plugin            
      var plugin1 = xmlbuilder.create({
          plugin: {
            '@name': 'webvr',
            '@devices': 'html5',
            '@keep': 'true',
            '@url': '/'+this.$home+'krpano/plugins/webvr.js',
            '@posttracking': 'auto'
          }
      });
      krpanoXml.importDocument(plugin1);           

      // Set variable
      var set1 = xmlbuilder.create({
          set: {
            '@var': 'modevr',
            '@val': 'false'
          }
      });
      krpanoXml.importDocument(set1);           
      
      /*
      <plugin name="webvr" devices="html5" keep="true"
        url="webvr.js"
        postracking="auto"
        oversampling="1.0"
        fullscreen_mirroring="false"
        mouse_pointerlock="true"
        webvr_highrefreshrate=""
        webvr_foveationlevel="1"
        webvr_ca_correction=""
        mobilevr_support="true"
        mobilevr_fake_support="true"
        mobilevr_touch_support="false"
        mobilevr_profile="80|60|42|35|0.441|0.156"
        mobilevr_screensize="auto"
        mobilevr_database_support="xml|internal|external"
        mobilevr_database_url="https://dpdb.webvr.rocks/dpdb.json"
        flatpanohfov="120.0"
        vr_cursor=""
        vr_cursor_enabled="true"
        vr_cursor_onover=""
        vr_cursor_onout=""
        vr_controller_support="true"
        vr_controller=""
        vr_controller_clickbuttons="0,1"
        onavailable=""
        onunavailable=""
        onunknowndevice=""
        onvrcontrollers=""
        onvrcontrollerbutton=""
        onhavepostracking=""
        onentervr=""
        onexitvr=""
        ondenied=""
        />
      */

      // Set layer for webvr button
      
      var layer1 = xmlbuilder.create({
        layer: {          
          '@name': 'webvr_enterbutton',
          '@keep': 'true',
          '@vr': 'true',          
          '@html': 'VR STARTEN',
          '@align': 'lefttop',
          '@y': '60',
          '@x': '20',
          '@autoalpha': 'true',
          '@alpha': '0.8',
          '@onclick': 'webvr.enterVR()'
        }
      });
      krpanoXml.importDocument(layer1);          
      
      var textstyle = xmlbuilder.create({
          textstyle: {
            '@name': 'hotspottext',
            '@font': 'Lucida Sans Unicode',
            '@fontsize': '13',
            '@bold': 'false',
            '@italic': 'false',
            '@textcolor': '#ffffff',
            '@background': 'true',
            '@border': 'false',
            '@alpha': '0.8',
            '@blendmode': 'normal',
            '@origin': 'cursor',
            '@width': '220',
            '@edge': 'bottom',
            '@textalign': 'center',
            '@xoffset': '0',
            '@yoffset': '-16',
            '@noclip': 'true',
            '@showtime': '0.1',
            '@fadetime': '0.4',
            '@fadeintime': '0.4'
          }
      });
      krpanoXml.importDocument(textstyle);

      var style1 = xmlbuilder.create({
          style: {
            '@name': 'infospottext',            
            '@type': 'text',
            '@keep': 'true',
            '@css': 'color:#000000;font-size:20px;text-align:center;',            
            '@height': 'auto',
            '@width': '600px',
            '@bgcolor': '0xFFFFFF',
            '@padding': '26 36',            
            '@xoffset': '0',
            '@yoffset': '-120',
            '@depth': '100',
            '@enabled': 'true',
            '@autoalpha': 'true',
            '@alpha': '0.8'
          }
      });
      krpanoXml.importDocument(style1);

      // Create an XML fragment
      var view = xmlbuilder.create({
          view: {
            '@hlookat': lookAtPositions[pano.startview], 
            '@vlookat': '0', 
            '@fovtype': 'VFOV', 
            '@fov':'90', 
            '@fovmin': '30', 
            '@fovmax':'150'
          }
      });
      krpanoXml.importDocument(view);

      // Create an XML fragment
      var image = xmlbuilder.create({
          image: {
            sphere: {
              '@url': pano.src
            }
          }
      });
      krpanoXml.importDocument(image);

      var action0 = xmlbuilder.create({
        action: {
          '@name': 'tourlink',
          '@scope': 'local',            
          '@args': 'id',
          '#text': `                    
            js(window.callingVuejsFunction(tourlink,get(id)));                                     		        
          `
        }
      });      
      krpanoXml.importDocument(action0);

      var action2 = xmlbuilder.create({
        action: {
          '@name': 'open_window',
          '@args': 'id,panoid,contentspot',
          '@scope': 'local',            
          '#text': `                                                        
              if(get(modevr) == false,                            
                set(hotspot[%3].alpha, 1);
                );
              if(get(modevr) == false,                            
                js(window.callingVuejsFunction(null,get(id),get(panoid)));
              );
          `
        }
      });            
      krpanoXml.importDocument(action2);

      var action2a = xmlbuilder.create({
        action: {
          '@name': 'close_windows',
          '@scope': 'local',            
          '#text': `     
            for(set(i,0), i LT hotspot.count, inc(i),
              if(hotspot[get(i)].tag == chs,     	          
                set(hotspot[get(i)].alpha, '0');	            
  	          );               
            );
          `
        }
      });            
      krpanoXml.importDocument(action2a);      

      var action3 = xmlbuilder.create({
        action: {
          '@name': 'addNewHotspot',          
          '@scope': 'local',       
          '#text': `        
              set(bla, get(toh));
              set(blu, get(tov));
              js(window.callingVuejsFunction(null,get(blu)));                                     		        
          `
        }
      });      
      /*
      showlog(true);
      screentosphere(mouse.x,mouse.y,toh, tov);                                                                                   
      trace(toh);  
      trace(tov);                         
      trace(" -- ");
      */
      krpanoXml.importDocument(action3);

      var correction = pano.zoom;

      var action4 = xmlbuilder.create({
        action: {
          '@name': 'getAngle',
          '@autorun': 'onstart',
          '@scope': 'local',
          '#text': `                        
            set(coord, get(view.hlookat));                 
            js(window.setPos(get(coord),${correction}));         
          `
        }
      });
      krpanoXml.importDocument(action4);

      var action5 = xmlbuilder.create({
        action: {
          '@name': 'enterVrmode',
          '#text': `                    
            set(modevr, true);                             
          `
        }
      });
      krpanoXml.importDocument(action5);      
      
      var action6 = xmlbuilder.create({
        action: {
          '@name': 'exitVrmode',
          '#text': `                        
            set(modevr, false);
          `
        }
      });
      krpanoXml.importDocument(action6);      
      
      var hotspot_logo = xmlbuilder.create({          
          hotspot: {
            '@name': 'logo',
            '@url': '/'+this.$home+'img/th-wildau.png',              
            '@distorted': 'true',
            '@scale': '0.44',
            '@ath': '0',
            '@atv': '90',   
            '@rx': '0',
            '@ry': '0',
            '@rz': '0',
            '@visible': 'get(modevr)'
            //'@visible': 'true'
          }     
      });
      krpanoXml.importDocument(hotspot_logo);

      if(logo_dyn !== 0) {
          var hotspot_logo_dyn = xmlbuilder.create({          
              hotspot: {
                '@name': 'logo_dyn',
                '@url': '/'+this.$home+'graf/'+logo_dyn,              
                '@distorted': 'true',
                '@scale': '0.6',
                '@ath': '0',
                '@atv': '90'
              }     
          });
          krpanoXml.importDocument(hotspot_logo_dyn);       
      }

      pano.spots.forEach((spot, index) => {
        
        // Check if vr mode: 
        //webvr.isenabled ? // ??
        /*
        function webvr_state() {
          return krpano.webvr && krpano.webvr.isenabled ? krpano.webvr.isfake ? krpano.webvr.iswebvr ? 3 : 1 : 2 : 0;
        }
        */

        //console.log(spot.published);
        // Calculate:        
        var z = spot.y;
        var thisAction = 'open_window('+pano.id+','+spot.id+', spot'+index+'content)';        
        thisAction = spot.link ? 'tourlink('+spot.link+')' : thisAction;
        var angle = (Math.atan2(spot.z, spot.x) * 180 / Math.PI)*-1;         
        var hyper = Math.sqrt(Math.pow(spot.z,2) + Math.pow(spot.x,2));                
        
        var angleVertical = (Math.atan2(z,hyper) * 180 / Math.PI)*-1;

        var loopSpot;
        var loopSpotcontent;

        if(spot.published) {

          if(spot.link) {

            loopSpot = xmlbuilder.create({
                hotspot: {
                  '@name': 'spot'+index,
                  '@url': '/'+this.$home+'img/spot/'+spot.file,
                  '@scale': '0.2',
                  '@onclick': thisAction,
                  '@ath': angle,
                  '@atv': angleVertical,
                  '@onhover': 'showtext('+spot.linktitle+',hotspottext)',
                  '@enabled': 'true'            
                }          
            });          

            loopSpotcontent = xmlbuilder.create({
                hotspot: {                  
                  '@name': 'spot'+index+'content',
                  '@type': 'text',
                  '@scale': '0.6',          
                  '@alpha': '0.4',  
                  '@ath': angle, // horizontal angle
                  '@atv': angleVertical,  // vertical angle 
                  '@distorted': 'false',
                  '@visible': 'false',
                  '@html': ''
                }          
            });                      

          } else {
            
            let parser = new DOMParser();
            const doc = parser.parseFromString(spot.body, 'text/html');

            const iframes = doc.getElementsByTagName("iframe");
            const images = doc.getElementsByTagName("img");

            if(iframes.length > 0) {
              for (var f=0; f<iframes.length; f++) {                
                iframes[f].remove();
              }
            }

            if(images.length > 0) {

              for (var i=0; i<images.length; i++) {
                if(i!=0) {
                  images[i].remove();
                }                
              }              
/*              
              loopSpotcontent = xmlbuilder.create({
                  hotspot: {                  
                    '@name': 'spot'+index+'content',
                    '@style': 'infospottext',
                    '@tag': 'chs',
                    '@type': 'image',
                    '@scale': '0.6',                  
                    '@alpha': '0',  
                    '@url': images[0].src,              
                    '@ath': angle, // horizontal angle
                    '@atv': angleVertical - 12,  // vertical angle 
                    '@distorted': 'false',                                      
                    '@onclick': 'close_windows()',
                    '@zorder': '2'
                  }          
              });                                    
*/
            } else {
/*            
              loopSpotcontent = xmlbuilder.create({
                  hotspot: {                  
                    '@name': 'spot'+index+'content',
                    '@style': 'infospottext',
                    '@tag': 'chs',
                    '@type': 'text',
                    '@scale': '0.6',                  
                    '@alpha': '0',  
                    '@ath': angle, // horizontal angle
                    '@atv': angleVertical - 12,  // vertical angle 
                    '@distorted': 'false',                  
                    '@html': '<h3>' + spot.linktitle + '</h3>' + doc.body.innerHTML,
                    '@onclick': 'close_windows()',
                    '@zorder': '2'
                  }          
              });                      
*/
            }

            loopSpot = xmlbuilder.create({
                hotspot: {
                  '@name': 'spot'+index,
                  '@url': '/'+this.$home+'img/spot/'+spot.file,
                  '@scale': '0.2',
                  '@onclick': thisAction,
                  '@ath': angle,
                  '@atv': angleVertical,
                  '@enabled': 'true',
                  '@onhover': 'showtext('+spot.linktitle+',hotspottext)',
                  '@zorder': '1'
                }          
            });
            
          }
          
          krpanoXml.importDocument(loopSpot);      
          if(loopSpotcontent) {
            krpanoXml.importDocument(loopSpotcontent);      
          }        

        }
              
      });                  

      /* 
      // Get position in panorama
      var event1 = xmlbuilder.create({
        events: {          
          '@keep': 'true',
          //'@onclick': 'screentosphere(mouse.x, mouse.y, toh, tov); trace(ath=,toh); trace(atv=,tov)'          
          '@onclick': 'addNewHotspot'          
        }

      });
      krpanoXml.importDocument(event1);
      */

      // Get position in panorama
      var event2 = xmlbuilder.create({
        events: {          
          '@onviewchange': 'getAngle()'
        }
      });
      krpanoXml.importDocument(event2);     

      // Enter VRmode
      var event3 = xmlbuilder.create({
        events: {          
          '@onentervr': 'enterVrmode()'
        }
      });
      krpanoXml.importDocument(event3);     

      // Exit VRmode
      var event4 = xmlbuilder.create({
        events: {          
          '@onexitvr': 'exitVrmode()'
        }
      });
      krpanoXml.importDocument(event4);     

      // Set xml content
      this.xml = krpanoXml.end({ pretty: true});   
      
    },
    
    krpano_onready_callback (krpano_interface) {        

        // Set krpano object references for xml calls
        this.krpano = krpano_interface;        

        // Turn off loading
        //this.$emit("loading", false);

    },

    loadPano( singlesource ) {
      
      var pano = null;

      if(this.queryid && (this.queryid === 260 || this.queryid === 427 ) ) {

        let obj = this.source.find( p => p.id == 428 );
        pano = obj;

      } else if(this.queryid) {
        
        let obj = this.source.find( p => p.id == this.queryid );
        if(obj) {
          pano = obj;
        } else {
          pano = this.source[0];  
        }        

      } else if(singlesource) {

        let obj = this.source.find( p => p.id == singlesource );
        pano = obj;

      } else {

        pano = this.source[0];
        
      }

      var self = this;

      this.generateXml( pano );
              
      var settings = {};
      settings["onstart"] = "loadxml(" + self.xml + ")";      
                  
      window.embedpano({
        swf: '/'+this.$home+'krpano/krpano.swf',                    
        target: "pano",
        html5: "only",
        id: "panoviewer",
        mobilescale: 1.0,
        passQueryParameters: true,
        onready: self.krpano_onready_callback, //callback function, get Krpano object
        vars: settings
      }); 

      window.addEventListener("poiClick", function clickPoi (e) {
          
          // Get current pano obj:
          let obj = self.source.find( p => p.id == parseInt(e.detail.id) );
          
          // Get tour link
          if(e.detail.cat == 'tourlink') {

            if(self.krpano) {              

              //self.$emit("loading", true);

              var panoImg = new Image();

              self.generateXml( obj );              

              panoImg.onload = function() { 
              
                self.krpano.call('loadxml(' + self.xml + ', null, MERGE, BLEND(0.4) )');
                //self.$emit("loading", false);
                self.$emit('panocurrent', obj);
                
                document.getElementById("panoviewer").focus();
              };

              panoImg.src = obj.src;

            }                      

          } else {

            if(obj.spots) {

              let spot = obj.spots.find( s => s.id == parseInt(e.detail.spotid) );

              // Open popup window
              self.openInfowindow(spot.linktitle, spot.body, spot.id, spot.icon, spot.tours, spot.cat, spot.pdfpath);            

            }        

          }
                          
          //window.removeEventListener ("poiClick", clickPoi,true);   
          //e.currentTarget.removeEventListener("poiClick", clickPoi);
          
          return false;
          
      });                    

      // Set current pano
      setTimeout(() => {
        self.$emit('panocurrent', pano);          
      }, 50);
      
      // Turn off loading
      this.$emit("loading", false);      
      
    },    

    onResize () {    

      if (this.width === undefined || this.height === undefined) {      
        this.$nextTick(() => {
          this.size = {
            width: this.$el.offsetWidth,
            height: this.$el.offsetHeight
          }
        })
      }

    },

    listen () {          
      var self = this;
      window.addEventListener("setPosition", function setPos (e) {
        self.$emit('state', e.detail.value);
      });
    },
    
    zoom (dir) {
      
      var newZoom = this.viewer.camera.fov;

      if(dir === 0) {
        newZoom = newZoom + 10;
        if(newZoom > 140) newZoom = 140;
        this.viewer.setCameraFov(newZoom);
      } else if (dir === 1) {
        newZoom = newZoom - 10;        
        if(newZoom < 50) newZoom = 50;
        this.viewer.setCameraFov(newZoom);
      }

    },

    getHotspots(currentPage, pageSize) {
      console.log(currentPage);
      console.log(pageSize);
      /*
      const dataTest = getHotspot({
        pageNum: currentPage,
        pageSize: pageSize
      });
      */
      //Realize the interaction between js and xml
      //krpano.call('set(hotspot[spot_0_2].title,'+dataTest.data.message+')')
    },       

    getMousePosition() {
          //let rect = canvas.getBoundingClientRect();
          //let x = event.clientX - rect.left;
          //let y = event.clientY - rect.top;
          //console.log("Coordinate x: " + x, "Coordinate y: " + y);
    },

    getPos () {
      //return this.viewer.camera.position;
      //requestAnimationFrame( this.getPos );
    },

    switchPano (item) {

        window.callingVuejsFunction('tourlink',item);

        /*
        let obj = this.source.find(pano => pano.id == item);
                
        if(obj != 0 && obj.locked === true && this.unlocked === false) {

          this.$emit("unlock", item);

        } else {

          this.$emit("loading", true);
          if(obj != 0) {
            this.loadPano(obj);
          } else {
            this.loadPano(this.source[0]);
          }

        } 
        */     

    },

    closePois () {

      this.krpano.call('close_windows');

    },

    openInfowindow (title, content, spotid, icon, tours, cat, pdf) {                
        //document.querySelector(".panolens-infospot").style.display = 'none';
        this.$emit('clicked', title, content, spotid, icon, tours, cat, pdf)
    },

    setSpots (value) {

        console.log(value);

        /*

        for (var i = 0; i < this.infospots.length; i++) {

          if(this.infospots[i].category == value) {

            if(this.infospots[i].visible == false) {
              this.infospots[i].visible = true;
            } else {
              this.infospots[i].visible = false;
            }

          }

        }
        */

    }

   }
}

</script>

<style>
#krpanoSWFObject,
#krpanoSWFObject2 {
  position: absolute !important;
}

.v-pano {
  position:absolute;
  top:0;
  width: 100%;
  height: 100%;
  overflow:hidden;
  margin: 0;
  padding: 0;
  border: 0px solid red;
}

#v-pano div:not(.panolens-infospot) {
  z-index:5;
  position:relative;
}

.admincontent .v-pano {
  height:400px;
}
.v-dialog iframe {
  width:100%;
  height:450px;
}
.makeorange {
  border:4px solid orange;
}
.markedtext {
  padding:14px;
  background-color:#fff;
  font-size:0.88rem !important;
  color:#333 !important;
  border-radius: 4px;
  top:-10px !important;  
  text-shadow: none !important;
}

#panelx {
  width: 1px;
  height: 1px;  
  position: absolute;
  display:block !important;
  top:0;
  left:0;
}

</style>
