<template>
  <div id="nlp">

    <splitpanes class="default-theme" @resize="paneSize = $event[0].size">
      <pane class="pane1" :size="paneSize">        
        <NLPinfo
          ref="infocol"
          :infocontent="infotext"
          :sizeWidth="paneSize"
        ></NLPinfo>
      </pane>
      <pane class="pane2" :size="100 - paneSize">            
        <v-container class="formset">

          <loading 
            :active="isLoading"      
            loader="bars"
            :height="100"
            :width="200"
            color="#005CA9"
            :opacity="0.98"
            :is-full-page="true"></loading>

          <v-form
            id="audioform"
            ref="form"            
          >

              <v-row>
                    <v-col cols="3" sm="3" >                 
                      <div style="width:100%;margin-top:0px;text-align:center"><img src="/graf/nlp-logo.png" /></div>
                    </v-col>

                  <v-col cols="9" sm="9" >

                      <h4>NLP: Prozess zur Verarbeitung von Sprachdateien</h4>
                      <p><br>
                      Dieses Testfeld demonstriert die Wirkung von digitalen Vorverarbeitungsverfahren auf die Leistung von Spracherkennungssoftware.                 
                      </p>
                      <p>
                        Das Testfeld bietet eine interaktive Experimentierplattform, um die Wahl und Konfiguration von Signalverarbeitungsverfahren zu evaluieren.
                      </p>
                      <!--
                      <p style="padding:20px;background-color:lightblue">{{ $cookies.get('USERID') }}</p>
                      -->
                  </v-col>
              </v-row>

              <!-- Audio source section -->
              <v-row>                    
                    <v-col cols="12" sm="12" >                        
                        <v-expansion-panels accordion :value="0">
                        <v-expansion-panel>
                          <v-btn class="helpbtn" small fab depressed @click="help('audiosource')">
                          <v-icon size="18" >mdi-information-variant</v-icon> 
                          </v-btn>
                        <v-expansion-panel-header @click="scrollMeTo('nlp_audiosource')">
                          <b>Audioquelle</b></v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row>
                                <v-col cols="12">                            
                                <div class="helptxt">
                                <p>Wählen Sie zunächst eine Quelle für die Verarbeitung aus.</p>
                                <p>Sie können aus vorhandenen Beispielsets auswählen oder eine eigene Audiodateien hochladen.</p>
                                </div>
                                <!--
                                <WavePlayer :id="'id0'" :set="'results'" :audiofile="'result_dsp.wav'"></WavePlayer>   
                                -->
                                </v-col>
                            </v-row>

                            <v-row>

                                <v-col cols="6" class="pb-0">

                                    <v-select
                                      v-model="formData.audio_source.set"
                                      ref="set"
                                      v-on:change="onSetchange"
                                      :items="sets"
                                      :rules="[v => !!v || 'Quelle ist erforderlich']"
                                      item-value="name"
                                      label="Quelle auswählen"
                                      required
                                      outlined
                                      densed
                                    >
                                    <template v-slot:item='{item}'> <span v-html="$t(item.name)"></span> </template>
                                    <template v-slot:selection='{item}'> <span v-html="$t(item.name)"></span></template>
                                    </v-select>

                                </v-col>

                                <v-col cols="6" class="pb-0">

                                      <v-select
                                        v-model="formData.audio_source.file"
                                        ref="setfile"
                                        v-on:change="onSetfilechange"
                                        v-if="setfiles.length > 0"
                                        :items="setfiles"
                                        
                                        item-value="name"
                                        label="Datei auswählen"
                                        outlined
                                      >
                                      <template v-slot:item='{item}'> <span v-html="item.name"></span> </template>
                                      <template v-slot:selection='{item}'> <span v-html="item.name"></span></template>
                                      </v-select>

                                </v-col>
                            </v-row>

                            <v-row >
                              <v-col cols="12 mx-0" :class="viewUpload ? 'open densetitle' : 'densetitle'">
                                  <p style="background-color:#ecf9f2">
                                    <a @click="toggleOwnfile" class="closeownfile" v-if="viewUpload">
                                      <v-icon fab size="20">
                                        mdi-close
                                      </v-icon>
                                    </a>
                                    <a @click="toggleOwnfile" style="display:block">
                                      <v-icon fab size="20" class="mr-4" v-if="viewUpload">                                          
                                          mdi-download
                                      </v-icon>
                                      <v-icon fab size="20" class="mr-4" v-else>
                                          mdi-upload
                                      </v-icon>
                                      Eigene Aufnahme hinzufügen                                      
                                    </a>
                                  </p>
                              </v-col>
                            </v-row>

                            <v-row v-if="viewUpload" class="mx-0 py-2 upload">

                              <v-col style="padding:0">
                                <v-tabs v-model="tab" color="#666" style="background-color:#f9f9f9" class="mx-0">
                                  <v-tab>
                                    Datei hochladen
                                  </v-tab>
                                  <v-tab>
                                    Audiodatei aufnehmen
                                  </v-tab>
                                </v-tabs>
                                <v-tabs-items v-model="tab" class="mx-0">
                                  <v-tab-item :transition="false" :reverse-transition="false">
                                    <v-row class="ownaudio mx-0 pt-4 pb-6">
                                        <v-col cols="12" class="pb-0 px-5 my-0">                                                    
                                          <div> 
                                            <!--
                                            <v-btn fab small class="helpbtn" @click="help('uploadownfile')" style="margin-top:8px;float:right;">
                                              <v-icon size="18" >mdi-information-variant</v-icon> 
                                            </v-btn>                           
                                            -->
                                            <v-file-input
                                              v-model="upload.file"
                                              @change="onUploadchange"
                                              ref="upload"
                                              :rules="filerules"
                                              show-size
                                              outlined
                                            >
                                            </v-file-input>
                                            <p class="helptxtsm">
                                              Max. Dateigröße: 20 MB.<br>
                                              Max. Länge der Audiodatei: 1 Min<br>
                                              Erlaubte Formate: wav und mp3<br>
                                              Geben Sie in das folgende Feld den gesprochenen Text der Audiodatei ein.
                                            </p>                              
                                          </div>
                                        </v-col>
                                        <v-col cols="12" class="py-0 px-5 my-0">
                                          <v-text-field
                                            v-model="upload.text"
                                            placeholder="Geben Sie den Text zur Audiodatei ein"                            
                                            required
                                            color="#333333"
                                            height=auto
                                            class="my-0 py-2"
                                            filled                    
                                          ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" class="pt-0 px-5 my-0">
                                          <v-btn class="py-6" style="width:100%;"                              
                                            @click="submitAudio"
                                            v-if="upload.file && uploadvalid"
                                          >Upload</v-btn>
                                        </v-col>

                                    </v-row>                                    
                                  </v-tab-item>
                                  <v-tab-item :transition="false" :reverse-transition="false">
                                    <div class="recwrap ownaudio mx-0">
                                      <!--
                                      <vue-record-audio mode="press" class="recorder" @stream="onAudioStream" @result="onAudioResult" />                            
                                      -->
                                      <div class="recinfo">
                                        <p><i>Klicken Sie zur Aufnahme auf den Mikro-Knopf.<br>Zur Aufnahme werden die Einstellungen Ihres lokalen Computers übernommen.<br>Mit einem weitern Klick auf den Button wird die Aufnahme beendet.<br>Die Länge der Aufnahme ist auf 1 Minute begrenzt.</i></p>
                                      </div>
                                    </div>
                                    <WavePlayer v-if="ownAudioFile" :id="'id3'" :ownaudiofile="ownAudioFile"></WavePlayer>
                                  </v-tab-item>                          
                                </v-tabs-items>                                

                              </v-col>

                            </v-row>
                            
                            <v-row class="audioinfo">

                                <v-col cols="12" class="pt-0">

                                    <div  v-if="setfiles.length > 0 && Object.entries(setfile).length != 0 && viewUpload == false" class="lightbg">

                                        <div class="fileinfo" style="margin-bottom:10px;">
                                          
                                          <v-row>
                                            <v-col cols="2" class="center p-2">
                                              <v-icon fab class="" size="30">
                                                  mdi-waveform
                                              </v-icon>     
                                            </v-col>     
                                            
                                            <v-col cols="10" class="">                                    
                                              <div style="margin-bottom:2px;">
                                                Datei: {{ setfile.name }} | Kanäle: {{ setfile.channels }} | Dauer: {{ setfile.duration }} s | {{ setfile.sample_rate }} kHz
                                              </div>
                                            </v-col>
                                          </v-row>
                                          <v-row>
                                            <v-col cols="2" class="center p-2 mt-5">
                                              <v-icon fab class="" size="24">
                                                  mdi-message-text-outline
                                              </v-icon>     
                                            </v-col>     
                                            <v-col cols="10" class="">                                    
                                              
                                              <div style="margin-bottom:2px;">                                        
                                                <div class="txt"> 
                                                  <p class="hinttext" v-html="hinttext"></p>                                                                                                          
                                                  <v-text-field
                                                    v-model="setfile.text"                                            
                                                    class="mt-0 pt-0"                                            
                                                    :append-icon="setfile.textchanged ? 'mdi-content-save-outline' : ''"
                                                    filled
                                                    type="text"
                                                    :disabled="!setfile.textchanged"
                                                    @input="inputtext"
                                                    @click:append="saveAudiotext(set, setfile.name, setfile.text)"                                              
                                                  ></v-text-field>                                            
                                                </div>
                                              </div>
                                            </v-col>
                                          </v-row>                                    

                                        </div>
                                          
                                        <WavePlayer :id="'id1'" :set="set" :audiofile="setfile.name"></WavePlayer>                                  

                                    </div>                                                                                            

                                  <!--                            
                                    <div v-if="formData.audio_source.set == 'pepper'">
                                        <audio-recorder
                                          upload-url="YOUR_API_URL"
                                          :attempts="1"
                                          :time="1"
                                          :filename="'meinAudio'"
                                          :before-recording="callback"
                                          :pause-recording="callback"
                                          :after-recording="callbackAfter"
                                          :select-record="callback"
                                          :before-upload="callback"
                                          :successful-upload="callback"
                                          :failed-upload="callback"/>
                                    </div>                            
                                    -->

                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                        </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
              </v-row>

              <!-- Process modus section -->
              <v-row>

                      <v-col cols="12" sm="12" >

                          <v-expansion-panels accordion>
                          <v-expansion-panel>
                          <v-btn class="helpbtn" small fab depressed @click="help('modus')">
                          <v-icon size="18" >mdi-information-variant</v-icon> 
                          </v-btn>
                          <v-expansion-panel-header  @click="scrollMeTo('nlp_signal')"><b>Prozessmethode</b></v-expansion-panel-header>
                          <v-expansion-panel-content>

                              <v-row>
                                  <v-col cols="12">
                                  <div class="helptxt">
                                  <p>Wählen Sie den Verarbeitungsmodus aus.</p>
                                  </div>
                                  </v-col>
                              </v-row>

                              <v-row>

                                  <v-col cols="6">

                                      <v-select
                                        v-model="formData.processing.method"
                                        ref="set"
                                        v-on:change="onMethodchange"
                                        :items="methods"
                                        :rules="[v => !!v || 'Verfahren ist erforderlich']"
                                        item-value="name"
                                        label="Verfahren auswählen"
                                        required
                                        outlined
                                        densed
                                      >
                                      <template v-slot:item='{item}'> 
                                        <span v-html="$t(item.name)"></span> 
                                        <span v-if="item.name == 'bypass'" v-html="$t('noprocessing')"></span>
                                        </template>
                                      <template v-slot:selection='{item}'> 
                                        <span v-html="$t(item.name)"></span>
                                        &nbsp;<span v-if="item.name == 'bypass'" v-html="$t('noprocessing')"></span>
                                        </template>
                                      </v-select>

                                  </v-col>

                                  <v-col cols="6">
                                      <v-btn fab depressed small class="helpbtn" @click="help('methods')" style="margin-top:0;margin-left:10px;">
                                        <v-icon size="18" >mdi-information-variant</v-icon> 
                                      </v-btn>
                                  </v-col>

                              </v-row>

                              <div v-if="formData.processing.method">

                                  <v-row 
                                    v-for="(name, index) in formData.processing.parameters" :key="index"
                                    :class="[ index == formData.processing.method && formData.processing.method != 'bypass' ? '' : 'hiddenx']"
                                  >
                                  
                                    <v-col cols="4">
                                      
                                    <p>{{ $t(index) }}</p>

                                    </v-col>

                                    <v-col cols="2">

                                        <v-text-field
                                          v-model="formData.processing.parameters[index].channel_selection"
                                          v-if="formData.processing.parameters[index].channel_selection"
                                          class="mt-0 pt-0"
                                          hide-details
                                          label="Kanal"
                                          type="number"
                                          readonly
                                        ></v-text-field>

                                    </v-col>

                                    <v-col cols="5">
                                      
                                        <v-select
                                          v-model="formData.processing.parameters[index].noise_profile.file"
                                          v-if="formData.processing.parameters[index].noise_profile"
                                          :items="noisefiles"
                                          :rules="[x => !!x || 'Noise-Datei ist erforderlich']"
                                          v-on:change="onNoiseProfilechange(name, index)"                                                                                                            
                                          item-text="name"
                                          item-value="name"
                                          required
                                          label="Noice profile"
                                          class="m-0 p-0"
                                        >
                                        <template v-slot:item='{item}'> <span v-html="item.name"></span> </template>
                                        <template v-slot:selection='{item}'> <span v-html="item.name"></span></template>
                                        </v-select>

                                    </v-col>

                                    <v-col cols="1">
                                      <!--
                                        <AudioDialog :ref="index" class="mr-4 hidden"></AudioDialog>
                                      -->
                                    </v-col>

                                    <v-col cols="4">

                                    </v-col>

                                    <v-col cols="8">
                                      
                                        <div                                     
                                          v-if="formData.processing.parameters[index].silence_threshold != undefined"
                                        >
                                          silence threshold
                                          <v-slider v-model="formData.processing.parameters[index].silence_threshold"
                                            :snap="true"
                                            :step="1"
                                            :min="formSettings.parameters[index].silence_threshold.min"
                                            :max="formSettings.parameters[index].silence_threshold.max"
                                            thumb-label
                                            color="#999"
                                            track-color="#999"
                                            thumb-color="brown"
                                            >
                                          </v-slider>
                                        </div>

                                        <div                                     
                                          v-if="formData.processing.parameters[index].silence_factor != undefined"
                                        >
                                          silence factor                                                                                                            
                                          <v-slider v-model="formData.processing.parameters[index].silence_factor"                                      :snap="true"
                                            :step="0.1"
                                            :min="formSettings.parameters[index].silence_factor.min"
                                            :max="formSettings.parameters[index].silence_factor.max"
                                            thumb-label
                                            color="#999"
                                            track-color="#999"
                                            thumb-color="brown"
                                            >
                                          </v-slider>
                                        </div>

                                        <div                                     
                                          v-if="formData.processing.parameters[index].fade_speed"
                                        >
                                          fade speed                                                                                                            
                                          <v-slider v-model="formData.processing.parameters[index].fade_speed"
                                            :snap="true"
                                            :step="0.01"
                                            :min="formSettings.parameters[index].fade_speed.min"
                                            :max="formSettings.parameters[index].fade_speed.max"
                                            thumb-label
                                            color="#999"
                                            track-color="#999"
                                            thumb-color="brown"
                                            >
                                          </v-slider>
                                        </div>

                                        <div                                     
                                          v-if="formData.processing.parameters[index].threshold_factor != undefined"
                                        >
                                          threshold factor
                                          <v-slider v-model="formData.processing.parameters[index].threshold_factor"
                                            :snap="true"
                                            :step="1"
                                            :min="formSettings.parameters[index].threshold_factor.min"
                                            :max="formSettings.parameters[index].threshold_factor.max"
                                            thumb-label
                                            color="#999"
                                            track-color="#999"
                                            thumb-color="brown"
                                            >
                                          </v-slider>
                                        </div>

                                        <div                                     
                                          v-if="formData.processing.parameters[index].reduction_factor != undefined"
                                        >
                                          reduction factor
                                          <v-slider v-model="formData.processing.parameters[index].reduction_factor"
                                            :snap="true"
                                            :step="0.01"
                                            :min="formSettings.parameters[index].reduction_factor.min"
                                            :max="formSettings.parameters[index].reduction_factor.max"
                                            thumb-label
                                            color="#999"
                                            track-color="#999"
                                            thumb-color="brown"
                                            >
                                          </v-slider>
                                        </div>

                                    </v-col>

                                  </v-row>

                              </div>


                              <v-row>
                                <!--
                                <v-col cols="4">

                                      <v-select
                                        v-model="formData.processing.parameters"
                                        ref="methodparam"
                                        v-on:change="onParamschange"
                                        v-if="Object.keys( methodparams ).length != 0"
                                        :items="methodparams"
                                        item-text="name"
                                        label="Methode auswählen"
                                        outlined
                                      >
                                      <template v-slot:item='{item}'> <span v-html="item.name"></span> </template>
                                      <template v-slot:selection='{item}'> <span v-html="item.name"></span></template>
                                      </v-select>

                                </v-col>
                                -->

                                </v-row>

                              <v-row>

                                  <v-col cols="12">

                                      <div  v-if="methodparams.length > 0 && Object.entries(methodparam).length != 0" class="lightbg">

                                      <div class="fileinfo" style="margin-bottom:10px;">
                                        <div style="margin-bottom:10px;">
                                        <i>Param Info</i><br>
                                        {{ methodparam }}
                                        </div>
                                        <img src="/graf/waveform.png" />
                                      </div>

                                      </div>

                                  </v-col>
                              </v-row>

                          </v-expansion-panel-content>
                          </v-expansion-panel>
                          </v-expansion-panels>
                      </v-col>

              </v-row>

              <v-row>

                  <v-col cols="12" sm="12" >

                      <v-expansion-panels accordion>
                      <v-expansion-panel>
                        <v-btn class="helpbtn" small fab depressed @click="help('modus')">
                          <v-icon size="18" >mdi-information-variant</v-icon> 
                          </v-btn>
                      <v-expansion-panel-header  @click="scrollMeTo('nlp_recognition')"><b>Spracherkennung</b></v-expansion-panel-header>
                      <v-expansion-panel-content>

                          <v-row>
                              <v-col cols="12">
                              <div class="helptxt">
                              <p>Wählen Sie die Engine zur automatischen Spracherkennung aus.</p>
                              </div>
                              </v-col>
                          </v-row>

                          <v-row>

                              <v-col cols="6">

                                  <v-select
                                    v-model="formData.asr.engine"
                                    ref="asrengine"
                                    v-on:change="onEnginechange"
                                    :items="engines"
                                    :rules="[v => !!v || 'Engine ist erforderlich']"
                                    item-value="name"
                                    label="ASR Engine auswählen"
                                    outlined
                                    required
                                  >
                                  <template v-slot:item='{item}'> <span v-html="item.name"></span> </template>
                                  <template v-slot:selection='{item}'> <span v-html="item.name"></span></template>
                                  </v-select>

                              </v-col>

                              <v-col cols="6">                          
                                    <v-select
                                      v-model="formData.asr.models[formData.asr.engine]"
                                      ref="enginemodels"
                                      v-if="formData.asr.engine"
                                      :items="enginemodels"
                                      item-value="name"
                                      label="Model auswählen"
                                      outlined
                                    >
                                    <template v-slot:item='{item}'> <span v-html="item"></span> </template>
                                    <template v-slot:selection='{item}'> <span v-html="item"></span></template>
                                    </v-select>                                                      
                              </v-col>
                          </v-row>

                      </v-expansion-panel-content>
                      </v-expansion-panel>
                      </v-expansion-panels>

                  </v-col>
              </v-row>

              <v-row v-if="isValid">

                  <v-col cols="3" sm="3" class="timeline" >
                      <div class="timestep">
                        <div class="ptitle">Gewichtung</div>
                      </div>
                  </v-col>

                  <v-col cols="9" sm="9" >

                      <v-row>
                          <v-col cols="12">
                          <div class="helptxt">
                          <p>Legen Sie die Gewichtung der Textverarbeitung fest.</p>
                          </div>
                          </v-col>
                      </v-row>

                      <v-row v-if="isValid">

                          <v-col cols="2">
                            {{ $t('insertion_weight') }}
                          </v-col>
                          <v-col cols="10">
                              <v-slider                             
                                v-model="formData.evaluation['insertion_weight']"
                                :snap="true"
                                :step="1"
                                :min="1"
                                :max="5"
                                thumb-label="always"                                             
                                color="#999"
                                track-color="#999"
                                thumb-color="brown"
                                >
                                <template v-slot:prepend>
                                  <v-icon                              
                                    @click="decrement('insertion_weight')"
                                  >
                                    mdi-minus
                                  </v-icon>
                                </template>

                                <template v-slot:append>
                                  <v-icon                              
                                    @click="increment('insertion_weight')"
                                  >
                                    mdi-plus
                                  </v-icon>
                                </template>                          
                              </v-slider>
                          </v-col>
                          <v-col cols="2">
                            {{ $t('deletion_weight') }}
                          </v-col>
                          <v-col cols="10">                        
                              <v-slider                             
                                v-model="formData.evaluation['deletion_weight']"
                                :snap="true"
                                :step="1"
                                :min="1"
                                :max="5"
                                thumb-label="always"                          
                                color="#999"
                                track-color="#999"
                                thumb-color="brown"
                                >
                                <template v-slot:prepend>
                                  <v-icon                              
                                    @click="decrement('deletion_weight')"
                                  >
                                    mdi-minus
                                  </v-icon>
                                </template>

                                <template v-slot:append>
                                  <v-icon                              
                                    @click="increment('deletion_weight')"
                                  >
                                    mdi-plus
                                  </v-icon>
                                </template>
                              </v-slider>
                          </v-col>
                          <v-col cols="2">
                            {{ $t('substitution_weight') }}
                          </v-col>                    
                          <v-col cols="10">                        
                              <v-slider                             
                                v-model="formData.evaluation['substitution_weight']"
                                :snap="true"
                                :step="1"
                                :min="1"
                                :max="5"
                                thumb-label="always"                          
                                color="#999"
                                track-color="#999"
                                thumb-color="brown"
                                >
                                <template v-slot:prepend>
                                  <v-icon                              
                                    @click="decrement('substitution_weight')"
                                  >
                                    mdi-minus
                                  </v-icon>
                                </template>

                                <template v-slot:append>
                                  <v-icon                             
                                    @click="increment('substitution_weight')"
                                  >
                                    mdi-plus
                                  </v-icon>
                                </template>                          
                              </v-slider>                      
                          </v-col>                    

                      </v-row>

                  </v-col>
              </v-row>

              <v-row>

                  <v-col cols="3" sm="3" class="timeline" >
                      <div class="timestep">
                        <div class="ptitle">Verarbeitung starten</div>
                      </div>
                  </v-col>

                  <v-col cols="9" sm="9" >

                      <v-row v-if="isValid">
                          <v-col cols="12">
                          <div class="helptxt">
                          <p>Starten Sie jetzt den Prozess. Nach Beendigung der Bearbeitung sehen Sie die Analyse und Evaluation der Quelldaten.</p>
                          </div>
                          </v-col>
                      </v-row>

                      <v-row>

                          <v-col cols="12">

                              <v-btn class="mr-4"
                                v-if="formData.id"
                                :disabled="!isValid"
                              @click="formSubmit"
                              >Aktualisieren</v-btn>

                              <v-btn class="mr-4"
                                v-else
                                :disabled="!isValid"
                              @click="formSubmit"
                              
                              >Verarbeitung starten</v-btn>

                          </v-col>
                      </v-row>

                  </v-col>
              </v-row>

              <v-row style="padding-bottom:100px;margin-bottom:50px;">

                  <v-col cols="3" sm="3" class="timeline" >                
                      <div class="timestep">
                        <div class="ptitle">Evaluation</div>
                      </div>
                  </v-col>

                  <v-col cols="9" sm="9" >

                      <v-row>
                          <v-col cols="12">

                              <div v-if="result" class="evaluation">

                                  <div class="helptxt">
                                  <p>Textvergleich und Audioresultat</p>
                                  </div>
                                  <NLPresult :result="result"></NLPresult>

                                  <div style="margin-top:20px;background-color:#f7f7f7;padding-right:20px;">

                                  <WavePlayer :id="'id2'" :set="'results'" :audiofile="'result_dsp.wav'"></WavePlayer>

                                  </div>

                              </div>

                          </v-col>
                      </v-row>

                  </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" sm="3" class="timeline">
                  <div class="triangle-down" style="margin-bottom:-20px;padding-bottom:0;"></div>           
                </v-col>
                <v-col cols="9" sm="9">
                  </v-col>
              </v-row>

          </v-form>

          <HelpDialog ref="help" :content="helpcontent" class="mr-4"></HelpDialog>
          <ExitDialog ref="exit" @closeApp="closeApp" class="mr-4"></ExitDialog>
          <InfoDialog ref="info" :content="infocontent" class="mr-4"></InfoDialog>

        </v-container>          
      </pane>      
    </splitpanes>

  </div>
</template>

<script>

document.cookie = "USERID=xx22x;expires=2d;SameSite=None; Secure";

//import { Multipane, MultipaneResizer } from 'vue-multipane';

import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';

import NLPresult from './content/NLPresult.vue';
import NLPinfo from './info/info_nlp.vue';
import schema from '../schema/config_schema_combined.json';
import WavePlayer from './monitor/WavePlayer.vue';
import getBlobDuration from 'get-blob-duration';
import get_result from '../source/result.json';
//import AudioDialog from '../components/monitor/AudioDialog';
import HelpDialog from '../components/monitor/HelpDialog';
import ExitDialog from '../components/monitor/ExitDialog';
import InfoDialog from '../components/monitor/InfoDialog';

//import schema from '../schema/testschema.json';
//import get_config from '../source/get_config.json';
import get_sets from '../source/get_sets.json';

//import get_asr_engines from '../source/get_asr_engines.json';
//import get_processing_methods from '../source/get_processing_methods.json';

// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'NLP',

  components: {
    Splitpanes,
    Pane,
    NLPresult,
    NLPinfo,
    WavePlayer,
    Loading,    
    HelpDialog,
    InfoDialog,
    ExitDialog
  },

  data: function () {
    return {
        paneSize: 33,
        nameRules: [
          v => !!v || 'Name ist erforderlich',
          v => (v && v.length <= 200) || 'Name kann max. 200 Zeichen haben',
        ],
        filerules: [
          value => !value || value.size < 20000000 || 'Max. Dateigröße: 20 MB',          
        ],
        mess: "",
        isLoading: true,
        fullPage: true,
        schema: schema,
        renderedSchema: {},
        audiofile: null,
        set: null,
        tab: null,
        tabitem: [
        { tab: 'Component A', content: 'ComponentA' },
        { tab: 'Component B', content: 'ComponentB' }
        ],
        formData: {
          "audio_source":{
          },
          "processing": {
          },
          "asr": {
            "models": {

            }
          },
          "evaluation": {
          }
        },
        formSettings: {
          "parameters": {}
        },
        model: {},
        inputvalue: '',
        upload: {
          "file": null,
          "text": null
        },
        uploadvalid: false,
        valid: false,
        isValid: false,
        //settings: [],
        sets: [],
        setfiles: [],
        setfile: {
          "textchanged": false
        },
        methods: [],
        noiseprofiles: [],
        noisefiles: [],
        noisefileActive: false,
        ownAudioFile: null,
        methodparams:[],
        hinttext: "",
        enginemodels:[],
        engines: null,
        result: null,
        helpcontent: null,
        infocontent: {},
        methodparamsAll:[],
        methodparam: {},
        tmpparams: {},
        viewUpload: false,
        evaluation: {
        },
        noiseListen: [],
        infotext: 'infotext',
        confirmed: false        
    }
  },
  methods:{

      loading() {
          this.isLoading = true;
      },
      scrollToEvaluation() {

          const el = this.$el.getElementsByClassName('evaluation')[0];

          if (el) {
              el.scrollIntoView({behavior: "smooth"});
          }
      },
      decrement(value) {        
        this.formData.evaluation[value]--
      },
      increment(value) {        
        this.formData.evaluation[value]++
      },
      inputtext() {
        if(this.set == 'my_recordings') {
          this.setfile.textchanged = true
        }
      },
      submit (e) {
      // this.model contains the valid data according your JSON Schema.
      // You can submit your model to the server here
          console.log(e);
      },
      handleSubmit() {
      // do things with the validated submission (this.formData)
      },
      isObject(o) {
        //console.log(o);
        return typeof o == "object";
      },
      getObject () {
        console.log("getObject Funktion")
      },
      printSchema () {
        console.log("Print Schema:");
        console.log(this.renderedSchema);
      },
      validateFormdata () {
      },
      submitAudio() {
        var self = this;

        console.log("sets checken");
        console.log(this.sets);

        //var index = this.sets.findIndex(obj => obj.name === "my_recordings" );
        var index = this.sets.findIndex(obj => obj.name === "my_set" );

        console.log("index für my_recordings:");
        console.log(index);

        if(index == -1) {
          this.axios.get( this.$config.tb_host+"add_set?set=my_set").then(function (response) {
            console.log("response");
            console.log(response);
            self.uploadAudio();
          });              
        } else {
          self.uploadAudio();
        }              

      },
      uploadText(set,file,text) {

        const config = {
          mode: "no-cors",
          header: {            
            'Content-Type': 'multipart/form-data'            
          }
        }

        /*
        var formData = {
          "set": set,
          "file": file,
          "text": text
        }
        */

        var formData = new FormData();
                
        formData.append('set', set);
        formData.append('file', file);
        formData.append('text', text);
        
        console.log("TEXT content?");
        console.log(formData);
        //const formDataX = this.formData;
              
        this.axios.post( this.$config.tb_host+"set_text", formData, config)
        .then(function (response) {

            console.log("response set text");
            console.log(response);
 
        })

      },
      uploadAudio() {

        var self = this;
          
        const config = {
          mode: "no-cors",
          header: {            
            'Content-Type': 'multipart/form-data'            
          }
        }
        
        var formData = new FormData();

        formData.append('file_content', this.upload.file);      
        formData.append('set', 'my_recordings');
        formData.append('file', this.upload.file.name);        

        console.log("AUDIOTEXT?");        
        console.log(this.upload);        
        this.upload.text = this.upload.text ? this.upload.text : ""
                                                
        this.axios.post( this.$config.tb_host+"upload_audio", formData, config)
        .then(function (response) {

            console.log("response audio upload");
            console.log(response);

            self.uploadText('my_set',self.upload.file.name, this.upload.text);
            self.get_sets();
                
            setTimeout(() => {                
                console.log("my_recordings aktiv setzen!");
                //self.closeOwnfile();                
                self.get_sets("my_set");                                                
                //self.scrollToEvaluation();
            },100)

        })
        .catch(function (error) {
            console.log(error)            
            console.log("my_recordings aktiv setzen!");
            self.uploadText('my_recordings',self.upload.file.name, self.upload.text);
            //self.closeOwnfile();
            self.get_sets("my_recordings");
                                         
          //setTimeout(() => {
            //    self.isLoading = false
            //},2000)              
        });      

      },
      onAudioResult (data) {
        let self = this;
        let blob = new Blob([data], { type: "audio/wav" });
        //let file = new File([blob], 'recording.wav');
        getBlobDuration(blob).then(function(duration) {
          console.log(duration + ' seconds');
          if(duration > 60) {
            alert("Die Aufnahme ist zu lang.");
            return;
          }
          self.ownAudioFile = blob;
        });        
      },
      onAudioStream (data) {
        this.ownAudioFile = null;
        console.log("streaming");
        console.log(data);
      },
      formSubmit() {
          console.log("FORM SUBMIT");

          if(!this.validate()) {                      
            console.log("NICHT alles ok");
            return false;
          }

          this.isLoading = true;
          this.result = null;
        
          const config = {
            mode: "no-cors",
            header: {
              //"Content-Type": "application/json"
              'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
          }
                                      
          const formDataX = this.formData;

          let self = this;

          console.log("formDataX:");
          console.log(formDataX);
          console.log(config);
          console.log(self);        
                                                
          this.axios.post( this.$config.tb_host+"run", formDataX, config)
          .then(function (response) {

              console.log("result run:");
              console.log(response);
              self.result = response;
              
              setTimeout(() => {
                  self.isLoading = false;
                  self.scrollToEvaluation();
              },2000)

          })
          .catch(function (error) {

              
              //console.log("vor error");
              //console.log(error.response.data.errors);
              //console.log("nach error");              

              if(error.response.data.errors) {

                var err = null;
                var errObject = error.response.data.errors;

                for (let prop in errObject) {
                  err = prop;                
                  break;
                }

                console.log(err);
                self.info("error", err);
                //console.log(errObject[err]);
              } else {
                self.info("error", "Timeout");
              }

              // example error: {"errors": {"500 Internal Server Error": "RuntimeError something went wrong on executing the process: rosass_start_stt"}}

              setTimeout(() => {
                  self.isLoading = false
              },2000)

              //self.result = get_result;              
          });        
          
      },
      onSetchange () {
        /*
         * Set selection
         */
        this.formData.audio_source.file = null;
        this.setfile = {};
        var index = this.sets.findIndex(obj => obj.name === this.formData.audio_source.set );
        this.formData.audio_source.use_whole_set = false;
        this.setfiles = index != -1 ? this.sets[index].files : [];
        this.set = index != -1 ? this.sets[index].name : null;
        this.validate();
      },
      onNoiseProfilechange (value, index) {
        console.log("onNoiseProfilechange");
        console.log(value.noise_profile.file);
        console.log(index);
        this.noisefileActive = true;
        this.validate();
        /*
        if(index !== '') {
            this.$refs[index][0].$el.classList.add('activeButton');
            this.$refs[index][0].playfile(value.noise_profile.set, value.noise_profile.file);
        }
        */

        //var index = this.sets.findIndex(obj => obj.name === this.formData.audio_source.set );
        //this.formData.audio_source.use_whole_set = false;
        //this.setfiles = index != -1 ? this.sets[index].files : [];
        //this.set = index != -1 ? this.sets[index].name : null;
      },
      onSetfilechange () {
        console.log("onSetfilechange - file:");
        console.log(this.formData.audio_source.file);
        var index = this.setfiles.findIndex(obj => obj.name === this.formData.audio_source.file );        
        this.setfile = index != -1 ? this.setfiles[index] : {};
        this.validate();
      },
      onMethodchange () {
        console.log("onMethodchange");
        var self = this;
        var index = this.methods.findIndex(obj => obj.name === this.formData.processing.method );        
        this.methodparams = index != -1 ? this.methods[index].parameters : [];        

        this.noiseprofiles = get_sets.sets.filter(obj => obj.type === 'noise_profile' && obj.predefined === true);

        this.noiseprofiles.forEach(function (param) {
            if(param.files.length > 0) {                
                param.files.forEach(function (item) {
                    self.noisefiles.push(item);
                });
            }
        });
        this.formData.processing.parameters = {};
        this.fillParameter(this.methods);
        this.validate();
      },
      onUploadchange () {
        console.log("onUploadchange");      
        console.log(this.upload.file.size );        
        if( this.upload.file.size <= 10000000) {
          this.uploadvalid = true;      
        }
        //this.formData.audio_source.upload = files.target.value;
        //console.log(this.formData.audio_source.upload);
      },
      setNull() {
        console.log("SET NULL - Wert bei Click:");        
        console.log(this.formData.audio_source.upload);
        this.formData.audio_source.upload = null;        
      },
      onParamschange () {
        var index = this.methodparams.findIndex(obj => obj.name === this.formData.processing.parameters );
        this.methodparam = index != -1 ? this.methodparams[index] : {};
      },
      onEnginechange () {
        console.log("ENGINE CHANGE");
        var self = this;
        var index = this.engines.findIndex(obj => obj.name === this.formData.asr.engine );
        var engineArray = index != -1 ? this.engines[index] : {};
        self.enginemodels = engineArray.models;

        // Fill engine models in form data:                
        this.engines.forEach(function (engine) {
            if(engine.models.length > 0) {
                console.log("name: " + engine.name);
                console.log("model: " + engine.models[0]);
                self.formData.asr.models[engine.name] = engine.models[0];
            }
        });
        this.validate();
      },
      validate () {
        var isValid = true;

        console.log("validate formData!");
        console.log(this.formData);
        
        if(!this.formData.audio_source.set) {
          console.log("KEIN SET");
          isValid = false;
        }
        if(!this.formData.audio_source.file) {
          console.log("KEINE DATEI");
          isValid = false;
        }
        if(!this.formData.processing.method) {
          console.log("KEINE METHODE");
          isValid = false;
        } 

        if(this.formData.processing.method) {
          console.log("MIT METHODE");          
          if(!this.noisefileActive && this.formData.processing.method != 'bypass') {
            console.log("KEINE NOISE FILE");
            //isValid = false;
          }
        }              

        if(!this.formData.asr.engine) {
          console.log("KEINE ENGINE");
          isValid = false;
        }

        this.isValid = isValid;
      
        return isValid;
      
        //this.$refs.form.validate()
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
      renderSchema (schema, obj) {
          var self = this;
          for (var property in schema) {
              if( property == '$ref') {
                //console.log("HIER $REF!")
              }
              if( self.isObject(schema[property]) ) {
                obj[property] = {};
                self.renderSchema( schema[property], obj[property] )
              } else {
                obj[property] = schema[property];
              }
          }
      },
      callback (data) {
        console.log(data)
      },
      callbackAfter (data) {
        console.log("after recording")
        console.log(data)
      },
      get_sets(goal){

          var self = this;        
          this.axios.get( this.$config.tb_host+"get_sets/", {headers: { 'Cookie': 'USERID=xxx' }, withCredentials: true }).then(function (response) {
          //this.axios.get( "/rosassapi/get_sets/", {headers: { 'Cookie': 'USERID=xxx' }, withCredentials: true }).then(function (response) {

                  console.log("axios get_sets() Response:");
                  
                  self.sets = response.data.sets.filter(item => item.type === 'audio_source');

                  if(goal) {
                    self.formData.audio_source.set = goal;                        
                    self.formData.audio_source.file = {};
                    self.onSetchange();
                    //self.formData.audio_source.upload = null;
                  }
                  
           
                  //var indexmyrecordings = self.sets.findIndex(obj => obj.name === 'my_recordings' );
                  //console.log("index: " + indexmyrecordings);                
                  //if(indexmyrecordings == -1) {

                  /*
                    * Add custom set if not exists
                    */
                   /*
                    self.sets.push(
                      {
                        "name": "my_recordings",
                        "predefined": false,
                        "type": "audio_own",
                        "files": []
                      }
                    )
                    */
                    //console.log(self.sets);

                  //}

          });

          // tmp:
          //self.sets = get_sets.sets.filter(item => item.type === 'audio_source');
      },
      get_processing_methods(){

          var self = this;

          this.axios.get( this.$config.tb_host+"get_processing_methods/", { withCredentials: 'true'} ).then(function (response) {
                  console.log("axios get_processing_methods() Response:");
                  console.log(response.data.methods);
                  self.methods = response.data.methods
          });

          // tmp:
          //console.log("Local get_processing_methods");
          //console.log(get_processing_methods.methods);
          //self.methods = get_processing_methods.methods;
      },
      get_asr_engines(){

          var self = this;

          this.axios.get( this.$config.tb_host+"get_asr_engines/" ).then(function (response) {
                  console.log("axios get_asr_engines() Response:");
                  console.log(response.data.asr_engines);
                  self.engines = response.data.asr_engines
          });

          // tmp:
          //console.log("Local get_asr_engines");
          //console.log(get_asr_engines.asr_engines);
          //self.engines = get_asr_engines.asr_engines;
      },
      get_result(){
          this.result = get_result;
          console.log("result:");
          console.log(this.result);
      },
      fillParameterS(method) {
          var self = this;
          
          console.log("fillParameters!");
          var obj = {};
          obj['channel_selection'] = [];
          obj['channel_selection'].push(method.channels);
          if(method.uses_noise_profile == true) obj['noise_profile'] = {
            "set": "noise_profiles",
            "file": ""
          };
          if(method.parameters.length > 0) {
              method.parameters.forEach(function (item) {                                
                obj[item.name] = new Number();
                obj[item.name]['type'] = item.type;
                obj[item.name]['min'] = item.minimum;
                obj[item.name]['max'] = item.maximum;
              });
          }
          self.formData.processing.parameters[method.name] = obj;
          
          //console.log("parameters:");
          //console.log(self.formData.processing.parameters);
      },      
      fillParameter(methods) {
          var self = this;                            
          methods.forEach(function (param) {
              var obj = {};
              var objS = {};
              obj['channel_selection'] = [];
              obj['channel_selection'].push(param.channels);
              if(param.uses_noise_profile == true) obj['noise_profile'] = {
                "set": "noise_profiles",
                "file": self.noisefiles.length > 0 ? self.noisefiles[0].name : ""
              };
              if(param.parameters.length > 0) {
                  param.parameters.forEach(function (item) {                    
                    obj[item.name] = new Number(); 
                    objS[item.name] = {                                         
                      "type": item.type,
                      "min": item.minimum,
                      "max": item.maximum
                    };                                                          
                  });
              }
              self.formData.processing.parameters[param.name] = obj;
              self.formSettings.parameters[param.name] = objS;
          });
          console.log("formData parameters:");
          console.log(self.formData.processing.parameters);
          console.log("formSettings parameters:");
          console.log(self.formSettings.parameters);

      },
      help(name) {        
        this.helpcontent = name;        
        this.$refs.help.openhelp();
      },
      scrollMeTo(refName) {
          console.log("refName in NLP");
          console.log(refName);
          var infocol = this.$refs['infocol'];
          infocol.scrollMeTo(refName);          
      },
      exit() {            
        this.$refs.exit.openexit();
      },
      closeApp(val) {
        this.confirmed = val;
      },
      info(kat, name) {
        console.log("open info!");        
        this.infocontent = {kat: kat, name: name};
        //console.log(this.$refs.help);
        this.$refs.info.openinfo();
      },      
      toggleOwnfile() {
        this.viewUpload = this.viewUpload ? false : true;
      },
      saveAudiotext(set, filename,text) {                        
        this.uploadText(set,filename, text);        
        this.hinttext = "Text gespeichert";
        setTimeout(() => {
          this.hinttext = ""    
        },4000)        
      },
      /*      
      async getPhoto() {

      }
      */
      
  },
  mounted: function() {

      //var self = this;
      //this.renderSchema(schema, this.renderedSchema);

      this.get_sets();
      this.get_processing_methods();
      this.get_asr_engines();
      //this.get_result();

      //window.addEventListener("scroll", this.onScroll)

      //console.log("this.result:");
      //console.log(this.result);

  },
  beforeMount() {
    //this.getPhoto();
  },
  created() {
    setTimeout(() => {
      this.isLoading = false
    },2000)
  },
  beforeRouteLeave (to, from , next) {
    const answer = window.confirm('Wollen Sie die Anwendung wirklich verlassen?')    
    if (answer) {
      next()
    } else {
      next(false)
    }    
  }  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.center {
  text-align:center;
}
.formset {
  margin-top:100px;
}
#nlp {
  /*
  padding-right:100px;
  padding-left:100px;
  width:auto;
  */
  height:100%;
  overflow:visible;
}
#audioform .row {
  padding-top:0;
}
#audioform .v-label {
  color:#666666;
}
.ownaudio {
  background-color:#ecf9f2;
}
.helptxt {
  background-color:#f9f2ec;
  padding:12px 10px 4px;
}
.recwrap {
  padding:20px 40px;
  display:flex;
}
.recinfo {
  margin-left:30px;
}
.recinfo p {
  font-size:.84rem;
}
.helptxtsm {
  background-color:#f9f2ec;
  padding:12px 10px 4px;
  color:#666;
  font-size:0.9rem;
}
.helpbtn {
  float:right;
  margin-top:12px;
  margin-left:20px;
  margin-right:22px;
}
#audioform .helpbtn {
  margin-top:-32px;
  box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.25);
-webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.25);
-moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.25);
}
.lightbg {
  background-color:#f7f7f7;
  padding:18px 10px 6px;
}
.v-select__selections span {
  overflow:hidden;
  max-width:100%;
}
.ar {
  box-shadow:none !important;
  width:100% !important;
  border:1px solid rgba(0, 0, 0, 0.38);
}
input {
  width:100%;
}
.hidden {
  display:none;
}
.hiddenx {
  visibility: hidden;
  height:0;
}
.densetitle {
  padding-top:0px;
  padding-bottom:0px;
  margin-bottom:20px;
}
.densetitle.open {
  margin-bottom:0;
}
.densetitle > p {
  display:block;
  width:100%;
  padding:8px 14px;
  margin-bottom:0;
  background-color:#f7f7f7;
}
.densetitle p a {
  color:#666;
}
.upload { 
}
.audioinfo {
  margin-top:16px;
}
.closeownfile {
  float:right;
}
.hidden.activeButton {
  display:block;
}
#stagex {
  padding-left:50px;
  margin-top:80px;
  height:95%;
  padding-top:2px;
  position:relative;
  border:0px solid green;
  background-size:cover;
}
.txt .mdi-content-save-outline::before {
  font-size:18px;
}
p.hinttext {
  font-size:0.7rem;
  color:green;
  margin:0 !important;
  padding:0;
}

.v-tab {
  text-transform: none;
  letter-spacing: 0.04em;
}

/* Pulsing */

/*
.ring-container {
    position: relative;
}
.circle {
    width: 15px;
    height: 15px;
    background-color: #62bd19;
    border-radius: 50%;
    position: absolute;
    top: 23px;
    left: 23px;
}
.ringring {
    border: 3px solid #62bd19;
    -webkit-border-radius: 30px;
    height: 25px;
    width: 25px;
    position: absolute;
    left: 15px;
    top: 15px;
    -webkit-animation: pulsate 1s ease-out;
    -webkit-animation-iteration-count: infinite; 
    opacity: 0.0
}
@-webkit-keyframes pulsate {
    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}
*/

</style>

<style lang="scss">

</style>
