<template>

  <v-card class="admincontent">

    <v-card-title>
    Verantwortliche
      <v-btn 
        icon
        class="ml-4"
        :to="'/'+$home+'node/add/'+ct"
        title="Verantwortlichen hinzufügen"
        >
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>          
    <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Suchen"
        single-line
        hide-details
        class="pt-0"
      ></v-text-field>
    </v-card-title>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="resps"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50]
      }"
      :items-per-page="30"
      :single-select="singleSelect"
      :search="search"
      item-key="id"
      class="elevation-1"
    >

    <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="(item,index) in items"
                :key="item.id"
              >
                <!--
                <td>
                <v-checkbox
                style="margin:0px;padding:0px"
                hide-details
                />
                </td>
                -->
                <td>{{ item.id }}</td>
                <td>
                  <router-link :to="'/node/resp/' + item.id" text small>
                  {{ item.name }}
                  </router-link>
                </td>
                <td v-html="item.organisation" class="p"></td>
                <td v-html="item.department" class="p"></td>
                <td v-html="categories.find(a => a.id === item.category).name" class="p"></td>
                <td>
                  <v-btn @click="deleteResp(index, item.id)" class="" text small>
                    <v-icon size="18" >mdi-delete</v-icon>
                  </v-btn>
                </td>
                <td>
                  <v-btn :to="'/node/resp/' + item.id" class="" text small>
                    <v-icon size="18" >mdi-pencil</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>

    <!--
    <template v-slot:top>
      <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
    </template>
    -->


    </v-data-table>

  </v-card>
</template>

<script>
export default {
  name: 'AdminTestFields',
  props: ['ct'],
  data () {
    return {
      singleSelect: false,
      selected: [],
      search: '',
      headers: [
        { text: 'ID',
          align: 'start',
          value: 'id'
        },
        {
          text: 'Name',
          value: 'title',
        },
        {
          text: 'Institution',
          value: 'organisation'
        },
        {
          text: 'Bereich',
          value: 'department'
        },
        {
          text: 'Kategorie',
          value: 'category'
        },
        {
          text: ''
        },
        {
          text: ''
        }        
      ],
      resps: [],
      categories: [
        {
          id: 1,
          name: 'Verantwortliche/r'
        },
        {
          id: 2,
          name: 'Scout'
        },
        {
          id: 3,
          name: 'Testbed'
        }
      ],
      overlaydata: null
    }
  },
  methods: {
      getResps() {
     
          this.axios.get( this.$config.host+"/resps" ).then(response => (
            this.resps = response.data
          ));
      },
      deleteResp(index, id) {
          if(confirm('Soll der Datensatz wirklich gelöscht werden?')) {
            this.axios.post( this.$config.host+"/resp/delete/"+id ).then(response => (
              console.log(response),
              this.removeResp(index)              
            ));
          }
      },
      removeResp(index) {
          this.$delete(this.resps, index)
      }      
  },
  mounted() {

    this.getResps();

  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  margin:0 0 40px 0;
  padding: 40px;
  background-color:#f5f5f5;
}
.item {
  display:block;
  width:100%;
  background-color:lightblue;
  margin-bottom:4px;
}
.p >>> p {
  margin:0;
}
a {
  color: #42b983;
}
</style>
