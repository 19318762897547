<template>

  <v-card class="admincontent">
    
    <v-card-title
      class="stickysub"
      >
      Panoramen
      <v-btn 
        icon
        class="ml-4"
        :to="'/'+$home+'node/add/'+ct"
        title="Panorama hinzufügen"
        >
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>      
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Suchen"
        single-line
        hide-details
        class="pt-0"
      ></v-text-field>
    </v-card-title>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="panos"
      :footer-props="{
        'items-per-page-options': [20, 30, 40, 50, 100]
      }"
      :items-per-page="50"
      :search="search"
      :single-select="singleSelect"      
      item-key="id"
      class="elevation-1"
    >

    <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="(item, index) in items"
                :key="item.id"
              >
              <!--
                <td>
                <v-checkbox
                style="margin:0px;padding:0px"
                hide-details
                />
                </td>
              -->
                <td>{{ item.id }}</td>
                <td>
                  <v-icon 
                    size="18" 
                    v-if="item.published == true"
                    color="green"
                    >mdi-check</v-icon>
                  <v-icon 
                    size="18" 
                    v-if="item.published == false"
                    color="red"
                    >mdi-eye-off-outline</v-icon>                  
                </td>
                <td>
                  <img :src="item.thumb" alt="Pano Preview Image" width="320px" />
                </td>
                <td>
                  <router-link :to="'/'+$home+'node/pano/' + item.id" text small>
                  {{ item.name }}
                  <span style="display:block"><i>{{ item.name_admin }}</i></span>
                  </router-link>
                </td>
                <td>
                  <div style="font-size:.6rem">                  
                      <div style="background-color:rgba(204, 238, 255,.6);padding:4px;" v-if="item.enter == true">
                      thwi: 
                        <v-icon 
                          size="15" 
                          class="mb-1"                          
                          color="green"
                          >mdi-check</v-icon>                
                      </div>
                      <div style="background-color:rgba(255, 221, 204,.6); padding:2px 4px;" v-if="item.enterx == true">
                      inno: 
                          <v-icon 
                          size="15" 
                          class="mb-1"                          
                          color="green"
                          >mdi-check</v-icon>                
                      </div>                                       
                      <div style="background-color:rgba(255, 221, 204,.6); padding:2px 4px;" v-if="item.enterb == true">
                      btu: 
                          <v-icon 
                          size="15" 
                          class="mb-1"                          
                          color="green"
                          >mdi-check</v-icon>                
                      </div>                                                             
                  </div>
                </td>
                <td v-html="item.content" class="p"></td>
                <td>
                  {{ item.user_id }}
                </td>
                <td>
                  <v-btn :to="'/'+$home+'panorama/?q=' + item.id" class="" text small target="_blank">
                    <v-icon size="18" >mdi-open-in-new</v-icon>
                  </v-btn>
                </td>
                <td>
                  <v-btn @click="deletePano(index, item.id)" class="" text small>
                    <v-icon size="18" >mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>

    <!--
    <template v-slot:top>
      <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
    </template>
    -->


    </v-data-table>

  </v-card>
</template>

<script>

import UserContent from '../../helper/UserContent.js';

export default {
  name: 'Fields',
  props: ['ct'],
  data () {
    return {
      search: '',
      singleSelect: false,
      selected: [],
      headers: [
        { text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id'
        },
        {
          text: 'veröffentl.',
          value: 'published'
        },
        {
          text: 'Vorschau',          
          value: 'preview',
        },
        {
          text: 'Name',
          value: 'name_admin',
        },
        {
          text: 'Einstieg',          
          value: 'enter',
        },                
        {
          text: 'Beschreibung',
          value: 'content'
        },
        {
          text: 'Erstellt von',
          value: 'user_id'
        },        
        {
          text: ''
        }
      ],
      panos: [],
      overlaydata: null
    }
  },
  methods: {
      getPanos() {
          console.log("Fkt getPanos");
          this.axios.get( this.$config.host+"/panos" ).then(response => (
            this.panos = response.data,
            this.panos = UserContent.getContent(response.data, 'pano'),
            console.log(this.panos)
          ));
      },
      deletePano(index, id) {
          if(confirm('Soll der Datensatz wirklich gelöscht werden?')) {
            console.log("Fkt deletePano");
            this.axios.post( this.$config.host+"/pano/delete/"+id ).then(response => (
              console.log(response),
              this.removePano(index)
              //console.log(this.panos)
            ));
          }
      },
      removePano(index) {
          console.log("panos alt:");
          console.log(this.panos);
          this.$delete(this.panos, index)
          console.log("panos neu:");
          console.log(this.panos);
      }
  },
  computed: {
      searchContent: {      
          get() {                        
            return this.$store.state.search.pano;
          },
          set(value) {
              this.$store.state.search.pano = value;
          }
      },
  },  
  mounted() {

    this.getPanos();
    this.search = this.searchContent;    

  },
  watch: { 
    search: function(newVal) {
          this.$store.commit("setSearchPano", newVal);
    }
  }    

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  margin:0 0 40px 0;
  padding: 40px;
  background-color:#f5f5f5;
}
.item {
  display:block;
  width:100%;
  background-color:lightblue;
  margin-bottom:4px;
}
.p >>> p {
  margin:0;
  font-size:.8rem;
}
a {
  color: #42b983;
}
</style>
