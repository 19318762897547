<template>
  <div class="landing">
      <v-layout>
          <v-container>          
              
                <!--
                <v-row justify="center" align="center">
                    <v-col class="wrap" cols="8" sm="12" align="center">
                        <v-btn @click="$router.push({ name: 'Panorama', query: { q: 229 }})" class="mx-4">Zum Standort BTU Senftenberg</v-btn>
                        <v-btn href="https://icampus.th-wildau.de/vr" target="_blank" class="mx-4">Zum Standort TH Wildau</v-btn>
                    </v-col>
                </v-row>
                -->           

                <v-row justify="center" align="center" class="introwrap">

                  <v-col cols="12" md="12" lg="5" xl="5" sm="12" class="intro">
                    <h2>Wissenschaft erleben in 360 Grad – <br> Mit unseren virtuellen Rundgängen!</h2>
                    <p>
                    Gehen Sie mit uns auf Entdeckungsreise und erkunden Sie unsere Räume ganz einfach von zuhause oder unterwegs – unsere virtuellen Rundgänge machen es möglich! Werfen Sie einen Blick in unsere Labore, Werkstätten, Makerspaces oder Showrooms und erfahren Sie viele spannende Fakten rund um die zur Verfügung stehende Technik. Ob per Computer, Smartphone oder VR-Brille – mit unseren virtuellen Touren tauchen Sie im Nu in unsere Räume ein und machen sich bereits vorab ein Bild vom Potenzial der technischen Ausstattung.                      
                    </p>
                  </v-col>
                  <v-col class="wrap" cols="12" xl="7" lg="7" md="12">
                      <div class="region">                        
                        <v-btn id="s01" @click="$router.push({ name: 'PanoramaFrame', query: { q: 229, m: 1 }})" class="mx-4"
                          style="top:58%;left:68%"
                          >BTU Senftenberg</v-btn>                      
                        <Drop :size="24" :top="58" :left="65"></Drop>
                        <v-btn id="s02" @click="$router.push({ name: 'PanoramaFrame', query: { q: 128 }})" class="mx-4"
                          style="top:20%;left:58%"
                          >TH Wildau</v-btn> 
                        <Drop :size="24" :top="20" :left="55"></Drop>
                        <v-btn id="s03" @click="$router.push({ name: 'PanoramaFrame', query: { q: 377, m: 1 }})" class="mx-4"
                          style="top:44%;left:79%"
                          >BTU Cottbus</v-btn>
                        <Drop :size="24" :top="44" :left="76"></Drop>
                        <img src="/graf/210323_InnoHub13_Projektregion.svg">
                      </div>
                  </v-col>
                </v-row>


                <v-row justify="center" align="center">

                  <v-col cols="11" sm="11">
                    <HomeTours></HomeTours>
                  </v-col>

                </v-row>


          </v-container>
      </v-layout>

      <Footer></Footer>

  </div>
</template>

<script>

import HomeTours from './content/HomeTours';
import Footer from './elements/Footer';
import Drop from './elements/Drop';

export default {
  name: 'Admin',
  components: {
    HomeTours,
    Footer,
    Drop    
  },
  data() {
    return {
      lorem: 'xxx',
      visible: true
    }
  },
  mounted() {
    this.$modal.show('example')
  },
  created: function() {

    //setTimeout(this.fireStart, 200);
    //this.fireStart();

  },
  methods: {

    fireStart() {

        this.$router.push({ name: 'Layout' })
        //this.$router.push({ name: 'Panorama', query: { q: 140 }})        
        //console.log(this.drawer);

    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.landing,
.layout {
  height:100%;
  background-color: #575756;
}
.wrap {

}
.layout {
  padding-top:20px;
  padding-bottom:20px;
}
.container {
  background-color:transparent;
  max-width:1300px;
}
.layout > .container > .fullheight {
  height:100%;
}
.container.topic {
  background-color:transparent;
}
.container .row {
  padding-top:0px;
}
.introwrap {
  margin-bottom:30px;
}
.intro {
  color:#ffffff;
  padding-top:60px;
}
.intro h2 {
  font-size:1.8rem;
  font-weight: 600;
  margin-bottom:0.9rem;
}
.region {
  position:relative;
  margin-top:0px;
  text-align:center;
}
.region button,
.region a {
  position:absolute;
  box-shadow:none;
}
.wrap img {
  max-width:100%;
}
.bordertop {
  border-top:2px solid #000;
}

</style>
