<template>
  <div>

    <v-row class="toursintro">

      <v-col xs="12" sm="12" md="12">
        <h2>360°-Grad-Touren</h2>
      </v-col>

       <v-col xs="6" sm="6" md="6" lg="4"
           v-for="(tour, i) in tours"
          :key="i"          
        >
        <v-card>                  
            <v-img
              height="150"
              class="tourimg"
              v-if="tour.pathimg"
              :src="tour.pathimg"            
              @click="clickPanel(i)"
            ></v-img>                    
          <v-expansion-panels
            flat
            accordion
            >            
            <v-expansion-panel>              
              <v-expansion-panel-header class="tourtitle" :ref="'button'+i" >
                {{ tour.name }}
              </v-expansion-panel-header>
              <v-expansion-panel-content class="tourdesc">
                <div v-html="tour.description"></div>              
                <a
                  v-if="tour.url"
                  :href="tour.url"                  
                  class="link"
                  target="_blank"
                >                
                Tour starten
                </a>
                <router-link 
                  v-else-if="tour.pano && tour.pano.length > 0"
                  :to="'/pn/'+tour.pano[0].id+'/'+tour.spotid" 
                  class="link"
                >                
                Tour starten
                </router-link>
              </v-expansion-panel-content>
            </v-expansion-panel>            
          </v-expansion-panels>
        </v-card>
       </v-col>

    </v-row>

  </div>
</template>

<script>

export default {
  name: 'HomeTours',
  data () {
    return {
      tours: [],
      tours_selected: [1,2,3,4,5,6,7,8,9,10,11,12,20,21,22,23,24,30,32,34]   
    }
  },
  methods: {
      getTours() {
          this.axios.get( this.$config.host+"/tours" ).then(response => (
            //this.tours = response.data,
            //console.log("TOUREN raw:"),
            //console.log(response.data),
            //this.tours = response.data.filter(tour => this.tours_selected.includes(tour.id) && tour.spotid !== null),
            this.tours = response.data.filter(tour => (tour.spotid !== null || tour.url !== null) )            
          ));
      },
      clickPanel(buttonid) {                        
        const elem = this.$refs['button'+buttonid];
        console.log(elem);
        //if(elem.length > 0 && elem[0].$el) elem[0].$el.click();        
      }                
  },
  mounted() {        
    //console.log("mounted Home Tours");
    this.getTours();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.v-card a {
  color:#575756;
}

.v-expansion-panel-header {
  padding:16px 12px 16px 16px;
}
.v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
  font-size:18px;
}
.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: auto;
}
.tourimg:hover {
  cursor: pointer;
}
.toursintro h2 {
  font-size:1.8rem;
  font-weight: 600;
  margin-bottom:0.9rem;
  color:#ffffff;
}
.tourtitle {
  font-size:1.05rem;
  padding: 18px 12px 14px 16px;
}
.tourdesc {
  font-size:.95rem;
  padding-bottom:12px;
}

</style>

<style>
.tourdesc > div {
  padding:2px 16px 0;  
}
.tourdesc div p {
  font-size:0.86rem;
}
</style>
