<template>

    <div class="">

        <v-form
           id="nodeform"
           ref="form"
           v-model="valid"
           :lazy-validation="lazy"
         >

            <!-- CARD ACTIONS -->

            <v-toolbar
              flat
              style="border-bottom:2px solid #ddd"              
              >
        
                <v-toolbar-title>PANORAMA bearbeiten</v-toolbar-title>
                  
                    <v-checkbox
                    v-model="node.published"
                    label="Veröffentlicht"
                    dense
                    class="check-published"
                    ></v-checkbox>
                  
                    <v-spacer></v-spacer>                    

                    <v-btn class="mr-4"
                    v-if="node.id"
                    :disabled="!valid"
                    @click="formSubmit"
                    style="display:inline-block"
                    >Aktualisieren</v-btn>

                    <v-btn class="mr-4"
                    v-else
                    :disabled="!valid"
                    @click="formSubmit"
                    style="display:inline-block"
                    >Speichern</v-btn>
                  
                    <v-btn-toggle                        
                      borderless                    
                      dense    
                      class="mr-2"
                      >                      
                      <v-btn      
                        icon
                        :to="'/'+$home+'content/'+nodetype"
                        >      
                        <v-icon>mdi-table-of-contents</v-icon>
                      </v-btn>

                      <v-btn 
                        v-if="node.id"
                        icon                                              
                        :to="'/'+$home+'pn/'+node.id"                         
                        target="_blank" 
                        title="Vorschau"
                        >                        
                        <v-icon>
                          mdi-open-in-new
                        </v-icon>
                      </v-btn>

                    </v-btn-toggle>
  
            </v-toolbar>

            <!-- / CARD ACTIONS --->

            <v-row class="nodecontent">

               <v-col cols="7" sm="7" >

                     <v-text-field
                       v-model="node.name"
                       :counter="200"
                       :rules="nameRules"
                       label="Name"
                       background-color="#f7f7f7"
                       required
                       color="#333333"
                       height=auto
                       class="my-5 py-2"
                     ></v-text-field>

                     <v-text-field
                       v-model="node.name_admin"
                       :counter="200"
                       :rules="nameRules"
                       label="Name (intern)"                       
                       required
                       color="#333333"
                       height=auto
                       class="my-5 py-2"
                     ></v-text-field>

                     <v-subheader>Panorama (Equirectangular)</v-subheader>

                      <v-row v-if="panocategories">

                       <v-col cols="12" sm="12" >

                           <v-select
                             v-model="node.cat_id"                                                          
                             :items="panocategories"
                             :rules="[v => !!v || 'Kategorie ist erforderlich']"
                             item-value="id"
                             label="Panoramakategorie auswählen"
                             required
                           >
                           <template v-slot:item='{item}'> <span v-html="item.name"></span> </template>
                           <template v-slot:selection='{item}'> <span v-html="item.name"></span> </template>
                           </v-select>

                       </v-col>

                    </v-row>

                     <div class="imgwrap mb-6">

                             <label>
                             <input type="file" id="file" ref="file" v-on:change="onChangeFileUpload()" />
                             </label>
                             <div class="previewwrap">
                              <img class="preview" ref="preview" v-if="previewsrc" :src="previewsrc" @click="clickPanoNorth" alt="Preview Image" id="panoimg" />
                             </div>

                     </div>

                    <Tooltip
                      :icon="'mdi-information-outline'"
                      :helptext="$t('help_north_correction')"
                      :classes="'pt-2 ml-4'"
                      >
                    </Tooltip>

                     <v-subheader>
                       Korrektur Nord-Richtung                      
                      </v-subheader>

                     <v-text-field
                       v-model="node.zoom"
                       class="mt-0 pt-0 pb-6"
                       hide-details
                       type="number"
                     ></v-text-field>


                     <div class="txtwrap">
                       <v-subheader>Beschreibung</v-subheader>
                       <vue-editor v-model="node.content" />
                     </div>

                     <v-subheader>Startwinkel</v-subheader>

                     <v-select
                       v-model="node.startview"
                       :items="startviews"
                       item-value="value"
                       label="Startwinkel auswählen"
                     >
                     <template v-slot:item='{item}'> <span v-html="item.name"></span> </template>
                     <template v-slot:selection='{item}'> <span v-html="item.name"></span> </template>
                     </v-select>

                    <v-select
                       v-model="node.bottomlogo"
                       :items="bottomlogos"
                       item-value="value"
                       label="Logo auswählen"
                     >
                     <template v-slot:item='{item}'> <span v-html="item.name"></span> </template>
                     <template v-slot:selection='{item}'> <span v-html="item.name"></span> </template>
                     </v-select>

                     <!--
                     <v-card-text>
                           <v-row>
                             <v-col class="pr-4">
                               <v-slider
                                 v-model="slider"
                                 class="align-center"
                                 :max="max"
                                 :min="min"
                                 hide-details
                               >
                                 <template v-slot:append>
                                   <v-text-field
                                     v-model="slider"
                                     class="mt-0 pt-0"
                                     hide-details
                                     single-line
                                     type="number"
                                     style="width: 60px"
                                   ></v-text-field>
                                 </template>
                               </v-slider>
                             </v-col>
                           </v-row>
                     </v-card-text>
                     -->

                     <v-row>

                        <v-col cols="6" sm="6" >
                            <v-checkbox
                             v-model="node.enter"
                             label="Einstiegspunkt THWi"
                             ></v-checkbox>
                            <v-checkbox
                             v-model="node.enterb"
                             label="Einstiegspunkt BTU"
                             ></v-checkbox>                             
                            <v-checkbox
                             v-model="node.enterx"
                             label="Einstiegspunkt INNOXR"
                             ></v-checkbox>                                                          
                        </v-col>

                        <v-col cols="6" sm="6" >

                          <v-checkbox
                            v-model="node.locked"
                            label="Panorama mit Passwort geschützt"
                          ></v-checkbox>

                        </v-col>

                      </v-row>

                      <v-text-field
                        v-model="node.id"
                        type="hidden"
                      ></v-text-field>

               </v-col>

               <v-col cols="5" sm="5" >

                   <v-row v-if="locations">

                       <v-col cols="12" sm="12" >

                          <Tooltip
                            :icon="'mdi-information-outline'"
                            :helptext="$t('help_select_location')"
                            :classes="'pt-6 ml-4'"
                            >
                          </Tooltip>

                           <v-autocomplete
                             v-model="node.loc_id"
                             v-on:change="onChangeLocReference"
                             ref="locRef"
                             :items="locations"
                             :rules="[v => !!v || 'Ort ist erforderlich']"
                             item-value="id"
                             item-text="name"                             
                             label="Ort auswählen"
                             required
                           >
                           <template v-slot:item='{item}'> <span v-html="item.loc_key + ': ' + item.name"></span></template>
                           <template v-slot:selection='{item}'> <span v-html="item.loc_key + ': ' + item.name"></span> </template>
                           </v-autocomplete>

                       </v-col>

                   </v-row>

                   <v-row  v-if="nodeLocRef">
                       <v-col cols="6" sm="6" >
                           <v-text-field
                              label="X"
                             v-model="node.loc_x"
                             type="number"
                           ></v-text-field>
                       </v-col>
                       <v-col cols="6" sm="6" >
                           <v-text-field
                              label="Y"
                             v-model="node.loc_y"
                             type="number"
                           ></v-text-field>
                       </v-col>
                   </v-row>

                   <v-row class="locembedwrap">

                      <v-container v-if="nodeLocRef">
                          <v-subheader>Position auf dem Campus:</v-subheader>
                          <img :src="nodeLocRef.src" style="max-width:100%;height:auto;" @click="clickImage" id="locimg">
                      </v-container>

                   </v-row>

                  <v-row>                  

                    <v-col cols="12" sm="12">

                      <v-subheader>POIs in diesem Panorama</v-subheader>

                      <v-list dense style="background-color:transparent">

                        <v-list-item-group color="#333">

                          <div style="background-color: #f7f7f7">                            

                            <v-list-item
                              v-for="(item, i) in node.spots"
                              :key="i"
                            >

                              <v-list-item-icon class="mx-0">
                                <v-icon>mdi-map-marker</v-icon>
                              </v-list-item-icon>

                              <v-list-item-content>
                                <router-link :to="'/'+$home+'node/poi/'+item.id">                            
                                  <v-list-item-title v-text="item.linktitle"></v-list-item-title>
                                </router-link>
                              </v-list-item-content>

                            </v-list-item>

                          </div>

                        </v-list-item-group>

                        <router-view :key="$router.history.current.path"></router-view>

                      </v-list>

                    </v-col>

                  </v-row>

               </v-col>

        </v-row>

        </v-form>

    </div>

</template>

<script>

    import { VueEditor } from "vue2-editor";
    import Tooltip from "../tools/tooltip.vue";

    export default {
        name: 'PanoContent',
        data() {
            return {
                editorOption: {
                  // Some Quill options...
                },

                previewsrc: null,

                nodeLocRef: null,

                min: -2000,
                max: 2000,
                slider: 0,
                range: [-70, 70],

                errors: [],
                valid: true,
                file: '',

                nameRules: [
                  v => !!v || 'Name ist erforderlich',
                  v => (v && v.length <= 200) || 'Name kann max. 200 Zeichen haben',
                ],

                select: null,
                items: [
                  'Item 1',
                  'Item 2',
                  'Item 3',
                  'Item 4',
                ],
                bottomlogos: [
                  {
                    value:0,
                    name:'None',
                    src: ''
                  },
                  {
                    value:1,
                    name:'TH Wildau',
                    src: 'logo_thwildau.png'
                  },                                    
                  {
                    value:2,
                    name:'BTU',
                    src: 'logo_btu.png'
                  },
                  {
                    value:3,
                    name:'Luckenwalde',
                    src: 'logo_gewerbehof.png'
                  },
                  {
                    value:4,
                    name:'startINN',
                    src: 'logo_startinn.png'
                  },
                  {
                    value:5,
                    name:'vinn:Lab',
                    src: 'logo_vinnlab.png'
                  }                                                      
                ],
                startviews: [
                  {
                    value:0,
                    name:'Front'
                  },
                  {
                    value:8,
                    name:'22° rechts'
                  },                                    
                  {
                    value:1,
                    name:'45° rechts'
                  },
                  {
                    value:9,
                    name:'67° rechts'
                  },                                                      
                  {
                    value:2,
                    name:'90° rechts'
                  },
                  {
                    value:10,
                    name:'112° rechts'
                  },                                                                        
                  {
                    value:3,
                    name:'135° rechts'
                  },
                  {
                    value:11,
                    name:'157° rechts'
                  },                                                                                          
                  {
                    value:4,
                    name:'Back'
                  },
                  {
                    value:12,
                    name:'157° links'
                  },                                                                                                            
                  {
                    value:5,
                    name:'135° links'
                  },
                  {
                    value:13,
                    name:'112° links'
                  },                                                                                                                              
                  {
                    value:6,
                    name:'90° links'
                  },
                  {
                    value:14,
                    name:'67° links'
                  },                                                                                                                                                
                  {
                    value:7,
                    name:'45° links'
                  },
                  {
                    value:15,
                    name:'22° links'
                  }
                ],
                locations: null,
                panocategories: null,
                lazy: false,
                search: ''
            };
        },
        props: {
            nodetype: {
              type: String
            },
            node: {
                type: Object
            }
        },

        components: {
          VueEditor,
          Tooltip
        },

        mounted() {

            //console.log("props?");
            //console.log(this.node);

        },
        watch: {

            node: function (values) {
                //console.log("watch! - node:");
                this.node = values;
                console.log(this.node);
                if(this.previewsrc == null) {
                    this.previewsrc =  this.node.src ? this.node.src : null;
                }
                if(this.locations) {
                    this.nodeLocRef = this.locations.find(loc => loc.id === this.node.loc_id);
                }
            },
            locations: function (values) {                
                this.locations = values;
                if(this.locations) {
                    this.nodeLocRef = this.locations.find(loc => loc.id === this.node.loc_id);
                }
            },
            search(n, o) {
                console.log("watch search", n, o);
                // at some point search is set to null
                // reset it to the old value
                if (n === null) this.search = o;
            }            

        },
        created() {
            this.getLocations();
            this.getPanoCategories();
        },
        methods: {
          checkForm (data) {
            console.log(data);
          },
          validate () {
            this.$refs.form.validate()
          },
          reset () {
            this.$refs.form.reset()
          },
          resetValidation () {
            this.$refs.form.resetValidation()
          },
          readURL(input) {
              console.log("read preview URL");
              console.log("input:");
              console.log(input);
              var self = this;
              if (input.file) {
                  var reader = new FileReader();
                  reader.onload = function (e) {
                      console.log("e.target");
                      console.log(e.target);
                      self.previewsrc = e.target.result;
                      //self.$refs.preview.attr('src', e.target.result);
                  }
                  reader.readAsDataURL(input.file);
              } else {

                //console.log("leer abgebrochen");
                //console.log("zurückgreifen:");
                //console.log(self.previewsrc);
                this.file = self.previewsrc;

              }
          },
          onChangeLocReference(e){
            this.nodeLocRef = this.locations.find(loc => loc.id === e);
            this.componentKey += 1;
          },
          getLocations() {
              this.axios.get( this.$config.host+"/locations" ).then(response => (
                this.locations = response.data
                //this.locations = response.data.filter(item => ( item.loc_key.includes(this.user.loc_key)) || this.user.loc_key != 'btu' )
                //console.log("LOCATIONS:"),
                //console.log(this.locations)                              
              ));
          },
          getPanoCategories() {
              this.axios.get( this.$config.host+"/panocategories" ).then(response => (
                this.panocategories = response.data
                //console.log("PanoCats:"),
                //console.log(this.panocategories)
              ));
          },
          onChangeFileUpload(){
            //console.log("onchangefileupload!");
            this.file = this.$refs.file.files[0];
            this.readURL(this);
          },
          formSubmit() {
              //e.preventDefault();

              const config = { headers: { 'Content-Type': 'multipart/form-data' } };
              const formData = new FormData();

              formData.set('name', this.node.name);
              formData.set('name_admin', this.node.name_admin);
              formData.set('content', this.node.content);
              formData.set('process', this.node.id ? 'update' : 'generate');
              formData.set('id', this.node.id);
              formData.set('loc_id', this.node.loc_id);
              formData.set('cat_id', this.node.cat_id);
              formData.set('loc_x', this.node.loc_x);
              formData.set('loc_y', this.node.loc_y);
              formData.set('published', this.node.published);
              formData.set('locked', this.node.locked);
              formData.set('enter', this.node.enter);
              formData.set('enterb', this.node.enterb);
              formData.set('enterx', this.node.enterx);
              formData.set('zoom', this.node.zoom);
              formData.set('startview', this.node.startview);
              formData.set('bottomlogo', this.node.bottomlogo);

              formData.append('file', this.file);

              // Was ist das hier??
              let currentObj = this;
              let self = this;

              this.axios.post( this.$config.host+"/pano", formData, config)
              .then(function (response) {

                  console.log(response);

                  console.log("hier push content");

                  //self.$router.push({name: 'Content'});
                  self.$router.push({path: '/'+self.$home+'content/pano'});
                  //self.$router.push({path: '/content'});
                  //self.$emit('panosaved', null)

              })
              .catch(function (error) {
                  currentObj.output = error;
              });
          },
          setImgPosition() {

          },
          clickImage(e) {            
              this.node.loc_x = e.offsetX / document.getElementById("locimg").width;
              this.node.loc_y = e.offsetY / document.getElementById("locimg").height;
          },
          clickPanoNorth(e) {                     
              this.node.zoom = ((e.offsetX / document.getElementById("panoimg").width)*360)-180;
          }          

        },
        computed:{
            user () {
              return this.$store.getters.userdata
            }
        },        

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.form-wrap {
  margin:30px 0;
}
#nodeform .v-subheader {
  padding-left:0;
  padding-right:0;
}

.txtwrap {
  padding: 10px 0;
  margin: 10px 0;
}
.preview {
  display:block;
  max-height:500px;
  max-width:100%;
}
.ck-editor__editable {
  min-height: 300px;
}

</style>
