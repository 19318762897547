<template>
  <v-app>
  <v-row justify="center" align="">
    <v-col cols="10" sm="10">

      <div class="wrap">

          <v-container class="">
            <v-row>        
              <v-col cols="12" sm="12">
              <h2>Ausgewähltes Thema: {{ query }}</h2>
              </v-col>
            </v-row>
          </v-container>

          <v-container class="">

              <v-row style="padding-top:20px">
                                  
                  <v-col cols="12" sm="6" md="4" lg="3" v-for="(post, key1) in filteralltypes"
                      :key="key1+0"
                      class=" card"
                  >
                    
                    <div contain class="card-content h-100">
                        
                          <div v-if="post.src">
                              <router-link :to="'/p/'+post.type+'/'+post.id" >
                              <img :src="post.paththumb" :alt="post.title" class="img-responsive">
                              </router-link>
                          </div>
                          <div class="item-desc">
                              <span class="type">{{ $t(post.type) }}</span>
                              <router-link :to="'/p/'+post.type+'/'+post.id">
                                  <h3>{{ post.title }}</h3>
                              </router-link>
                              <div class="tags">
                              <span v-for="(tag, key2) in post.tags" :key="key2+0">
                                <router-link :to="'/thema/'+tag.name">
                                {{ tag.name }}
                                </router-link>
                              </span>
                              </div>

                          </div>

                    </div>

                  </v-col>
                              
              </v-row>
            
          </v-container>

      </div>

    </v-col>
  </v-row>
  </v-app>
</template>

<script>

//import Setting from '../components/footer/Setting';

export default {
  name: 'Layout',
  props: ['query'],
  components: {

  },
  data() {
    return {
      loaded: false,    
      selectedTags: [],
      search: '',
      searcht: null,
      usecase: false,
      testfield: false,
      testfields: [],
      devices: [],
      tags: [],
      //partners: [],
      tagssearch: [],
      tagsselected: [],
      usecases: [],
      svitch: false,
      selection: {
        testfield:false,
        usecase:false,
        device:false,
        partners: [],
        areas: []
      },
      alltypes: []
    }
  },

  methods: {
      getTestfields() {
          this.axios.get( this.$config.host+"/testfieldsf" ).then(response => (
            this.testfields = response.data,
            this.getUsecases()
          ));
      },
      getUsecases() {
          this.axios.get( this.$config.host+"/usecasesf" ).then(response => (
            this.usecases = response.data,            
            this.merge(this.testfields, this.usecases)            
          ));
      },
      merge(a,b) {
          this.alltypes = a.concat(b)
          console.log("ALLTYPES")
          console.log(this.alltypes)
      },
      remove (item) {
        const index = this.tagsselected.indexOf(item.id);
        if (index >= 0) this.tagsselected.splice(index, 1)
      }

  },
  mounted() {

    this.getTestfields();        
    //this.getUsecases();

  },
  computed: {
      filteralltypes() {
          return this.alltypes.filter(post => {
            return (post.tags.some(tag => tag.name.toLowerCase() === this.query.toLowerCase()))
          })    
      }
  },
  watch: {
    searcht(val) {
      if(val !== '') {
        this.tagssearch = this.tags;
      } else {
        this.tagssearch = []
      }
    }
  }

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.wrap {  
  padding-top:60px;
  padding-bottom:20px;
}
.container {
  background-color:#ffffff;
  max-width:1300px;
}
.container.topic {
  background-color:transparent;
}
.container .row {
  padding-top:30px;
}
.col img {
  max-width:100%;
}
.bordertop {
  border-top:2px solid #000;
}
.search-wrapper {
  margin-right:10px;
}
.search-wrapper input {
  padding:11px 40px;
}
.v-data-table {
  width:100%;
}
.v-item-group .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.tags a {
  display:inline-block;
  font-size:0.8rem;
  color:#999;
  font-style:italic;
  padding:2px 10px;
  margin:2px 2px;
  white-space: nowrap;
  color:#fff !important;
  background-color:#0076bc;
  border-radius:14px;
}
.tags a:hover {
  text-decoration:none;
  background-color: rgb(0,118,188,0.8);
}
.tags i {
    background-color: #f7f7f7;
    margin-right:10px;
    display:inline-block;
    margin:2px 4px;
}
.type {
  font-size:0.76rem;
  color:#999;
  font-style:italic;
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 1s;
}
.fade-leave-active {
  transition: 1s;
  opacity: 0;
}
.row span {
  width:100%;
}
.tablogo {
  float:left;
  margin:-6px 20px 0 0;
}
.tablogo img {
  height:30px;
}
</style>

<style>

.card-content {
  padding:0px;
  background-color:#f7f7f7;
  min-height:290px;
}
.card-content img {
  max-width:100%;
}
.card-content .item-desc {
  padding:4px 8px 12px;
}
.page.list .card-content {
  min-height:200px;
}
.card {
  border:0px solid red;
  float:left;
  width:33.33%;
}
.card a h3 {
  font-size:1rem;
  color:#333;
  padding:4px 2px 8px;
}

</style>
