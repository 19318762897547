<template>
    <div class="">
        <v-form
           id="nodeform"
           ref="form"
           v-model="valid"
           :lazy-validation="lazy">

            <!-- CARD ACTIONS -->

            <v-toolbar
              flat
              style="border-bottom:2px solid #ddd"              
              >
        
                <v-toolbar-title>ANWENDUNGSFALL
                  <span v-if="process=='add'" class="add">{{ $t('add') }}</span>
                  <span v-else class="edit">{{ $t('edit') }}</span>
                </v-toolbar-title>                  
                  
                    <v-checkbox
                    v-model="node.published"
                    label="Veröffentlicht"
                    dense
                    class="check-published"
                    ></v-checkbox>
                  
                    <v-spacer></v-spacer>                    

                    <v-btn class="mr-4"
                    v-if="node.id"
                    :disabled="!valid"
                    @click="formSubmit"
                    style="display:inline-block"
                    >Aktualisieren</v-btn>

                    <v-btn class="mr-4"
                    v-else
                    :disabled="!valid"
                    @click="formSubmit"
                    style="display:inline-block"
                    >Speichern</v-btn>
                  
                    <v-btn-toggle                        
                      borderless                    
                      dense    
                      class="mr-2"
                      >
                      <v-btn      
                        icon
                        :to="'/'+$home+'content/'+nodetype"
                        >      
                        <v-icon>mdi-table-of-contents</v-icon>
                      </v-btn>

                      <v-btn 
                        icon                                              
                        :to="'/'+$home+'p/usecase/'+node.id" 
                        target="_blank" 
                        title="Vorschau"
                        >                        
                        <v-icon>
                          mdi-open-in-new
                        </v-icon>
                      </v-btn>

                    </v-btn-toggle>
  
            </v-toolbar>

            <!-- / CARD ACTIONS --->

            <v-row class="nodecontent">

               <v-col cols="7" sm="7" >

                  <v-text-field
                    v-model="node.name"
                    :counter="200"
                    :rules="nameRules"
                    label="Name"
                    background-color="#f7f7f7"
                    required
                    color="#333333"
                    height=auto
                    class="my-5 py-2"
                  ></v-text-field>

                  <v-row>
                    <v-col
                      cols="10"
                      sm="10"
                      md="10"
                    >

                      <v-text-field
                        v-model="node.testbed"
                        :counter="300"
                        label="Testbed digital"
                        background-color="#f7f7f7"
                        color="#333333"
                        height=auto
                        class="my-5 py-2"                    
                      ></v-text-field>

                    </v-col>

                    <v-col
                      cols="2"
                      sm="2"
                      md="2"
                    >
                      
                      <v-checkbox
                        v-model="node.absolute"
                        dense
                        class="checkbox"                        
                        label="Absolutpfad"                        
                        ></v-checkbox>

                    </v-col>
                  </v-row>

                  <v-autocomplete
                      v-model="node.tags"
                      ref="tagsRef"
                      :items="tags"
                      item-value="id"
                      item-text="name"
                      label="Tags / Schlagworte"
                      outlined
                      dense
                      small-chips
                      multiple
                      return-object
                      >
                      <template v-slot:selection="data">
                        <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                          @click="data.select"
                          @click:close="removeTag(data.item)"
                        >
                          {{ data.item.name }}
                        </v-chip>
                      </template>
                      <template v-slot:item="data">
                          <v-list-item-content>
                            <v-list-item-title v-html="data.item.name"></v-list-item-title>
                          </v-list-item-content>
                      </template>

                   </v-autocomplete>

                  <div class="txtwrap">

                    <v-textarea
                      v-model="node.preview"
                      color="#333333"
                      class="my-5 py-2"
                      >
                      <template v-slot:label>
                        <div>
                          Preview <small>(optional)</small>
                        </div>
                      </template>
                    </v-textarea>

                  </div>
                    
               </v-col>

               <v-col cols="5" sm="5" >

                    <v-autocomplete
                        v-model="node.responsible_id"
                        v-on:change="onChangeResponsibility"
                        ref="fieldRef"
                        :items="resps"
                        :rules="[v => !!v || 'Referenz ist erforderlich']"
                        item-value="id"
                        item-text="name"
                        label="verantworlich"
                        class="mt-5 mb-0 py-2"
                        background-color="#f7f7f7"
                        required
                         >
                         <template v-slot:item='{item}'> <div v-html='item.name'/> </template>
                         <template v-slot:selection='{item}'> <div v-html='item.name'/></template>
                     </v-autocomplete>

                     <v-subheader>Testfelder</v-subheader>

                     <v-autocomplete
                       v-model="node.testfields"
                       :disabled="isUpdating"
                       :items="testfields"
                       filled
                       chips
                       item-text="title"
                       item-value="id"
                       multiple
                       dense
                     >
                       <template v-slot:selection="data">
                         <v-chip
                           v-bind="data.attrs"
                           :input-value="data.selected"
                           close
                           @click="data.select"
                           @click:close="remove(data.item)"
                          >
                           {{ data.item.title }}
                         </v-chip>
                       </template>

                       <template v-slot:item="data">
                         <!--
                         <div v-if=" typeof data.item !== 'object' ">
                           <v-list-item-content v-text="data.item"></v-list-item-content>
                         </div>                         
                         <div v-else>
                           -->
                           <v-list-item-content>
                             <v-list-item-title v-html="data.item.title"></v-list-item-title>                    
                           </v-list-item-content>                         
                       </template>

                     </v-autocomplete>

                     <div class="imgwrap">

                             <label>
                              <input type="file" id="file" ref="file" v-on:change="onChangeFileUpload()" />
                             </label>
                             <v-text-field
                               v-model="node.src"
                               type="hidden"
                             ></v-text-field>
                             <div class="previewwrap">
                              <img class="preview" ref="preview" v-if="previewsrc" :src="previewsrc" alt="Preview Image" />
                             </div>

                     </div>

               </v-col>

            </v-row>

            <v-row class="nodecontent">   

              <v-col cols="12" sm="12">

                    <v-subheader>Beschreibung</v-subheader>
                    
                    <Editor :description="node.description" @update="updateEditor"></Editor>

                    <v-subheader>Elemente</v-subheader>

                    <v-row
                      class="elements"
                      >

                      <v-col cols="12" md="6" v-for="(element, index) in node.elements" :key="index" class="item">

                        <div>

                        <div style="text-align:left;width:50%;float:left;">
                          <v-text-field
                            v-model="element.cols"                                                                                                    
                            type="number"
                            style="width:3rem;display:inline-block"                            
                            dense
                            :min="1"
                            :max="12"       
                            placeholder="6"                            
                            aria-required="true"                                                 
                            ></v-text-field>
                            <v-text-field                            
                              style="width:3rem;display:inline-block"
                              dense
                              value=" /12"
                            ></v-text-field>
                        </div>

                        <div style="text-align:right; width:50%;float:right;">
                          <v-btn 
                            class="mr-4"                          
                            @click="deleteElement(index)"
                            depressed
                            fab
                            small
                            >
                            <v-icon                                                                               
                            >mdi-close</v-icon>
                          </v-btn>
                        </div>


                        <v-text-field
                          v-model="element.name"                                          
                          label="Titel"
                          background-color="#f7f7f7"
                          required
                          color="#333333"
                          height=auto
                          class="my-0 py-2"
                          style="clear:both"
                        ></v-text-field>
                                              
                        <Editor :key="index" :elementindex="index" :description="element.content" @update="updateElement"></Editor>                                                                    

                        </div>
                      </v-col>                                                                        

                    </v-row>                                                    

                    <div class="addelement">
                      <v-btn class="mr-4"
                      @click="addElement"
                      >Element hinzufügen</v-btn>
                    </div>

                    <v-subheader>Veröffentlicht</v-subheader>

                     <!--
                     <v-checkbox
                      v-model="node.published"
                      label="Veröffentlicht"
                      ></v-checkbox>
                      -->

                      <v-text-field
                        v-model="node.id"
                        type="hidden"
                      ></v-text-field>

                     <v-btn class="mr-4"
                      v-if="node.id"
                      :disabled="!valid"
                     @click="formSubmit"
                     >Aktualisieren</v-btn>

                     <v-btn class="mr-4"
                      v-else
                      :disabled="!valid"
                     @click="formSubmit"
                     >Speichern</v-btn>

              </v-col>            

            </v-row>

        </v-form>

    </div>

</template>

<script>

    import Editor from '../tools/Editor';

    export default {
        name: 'CaseContent',
        data() {
            return {
                componentKey: 0,
                published: false,
                errors: [],
                valid: true,
                file: '',
                tags: [],
                previewsrc: null,
                resps: [],
                testfield: 2,
                testfields: [],
                autoUpdate: true,
                isUpdating: false,
                nodeRespRef: null,
                nameRules: [
                  v => !!v || 'Name erforderlich',
                  v => (v && v.length <= 200) || 'Name darf max. 200 Zeichen enthalten',
                ],
                lazy: false
            };
        },
        props: {
            nodetype: {
              type: String
            },
            node: {
                type: Object
            },
            process: {
              type: String
            }
        },
        components: {
          Editor
        },
        methods: {
          checkForm (data) {
            console.log(data);
          },
          validate () {
            this.$refs.form.validate()
          },
          reset () {
            this.$refs.form.reset()
          },
          resetValidation () {
            this.$refs.form.resetValidation()
          },
          readURL(input) {
              var self = this;
              if (input.file) {
                  var reader = new FileReader();
                  reader.onload = function (e) {
                      self.previewsrc = e.target.result;
                  }
                  reader.readAsDataURL(input.file);
              } else {
                this.file = self.previewsrc;
              }
          },
          onEditorChange({ quill, html, text }) {
            console.log('editor change!', quill, html, text)
            this.content = html
          },
          onChangeResponsibility(e){
            this.nodeRespRef = this.resps.find(resp => resp.id === e);
            console.log("resps nach respsWechsel gefunden?");
            console.log(this.nodeRespRef);
            this.componentKey += 1;
          },
          onChangeFileUpload(){
            console.log("onchangefileupload!");
            this.file = this.$refs.file.files[0];
            console.log("this.file:");
            console.log(this.file);
            this.readURL(this);
          },
          formSubmit() {
              //e.preventDefault();

              const config = { headers: { 'Content-Type': 'multipart/form-data' } }

              console.log("vor submit");
              console.log(this.node);

              const formData = new FormData();
              formData.set('name', this.node.name);
              formData.set('testbed', this.node.testbed);
              formData.set('preview', this.node.preview);
              formData.set('description', this.node.description);
              formData.set('responsible_id', this.node.responsible_id);
              formData.set('published', this.node.published);
              formData.set('absolute', this.node.absolute);
              formData.set('process', this.node.id ? 'update' : 'generate');
              formData.set('id', this.node.id ? this.node.id : null);
              formData.set('src', this.node.src ? this.node.src : null);
              formData.append('tags', JSON.stringify(this.node.tags));
              formData.append('testfields', JSON.stringify(this.node.testfields));
              formData.append('file', this.file);
              formData.append('elements', JSON.stringify(this.node.elements));                            

              // Was ist das hier??
              let currentObj = this
              let self = this;

              this.axios.post( this.$config.host+"/usecase", formData, config)
              .then(function (response) {
                  console.log("hier then..");
                  console.log(response);
                  self.$router.push({path: '/'+self.$home+'content/usecase'});
                  self.$emit('casesaved', null)

              })
              .catch(function (error) {
                  currentObj.output = error;
              });
          },
          updateEditor(content) {
            this.node.description = content;          
          },
          updateElement(content,index) {
            //this.node.elements[index].name = 'name platzhalter';
            this.node.elements[index].content = content;
          },          
          getResps() {
              this.axios.get( this.$config.host+"/resps" ).then(response => (
                this.resps = response.data,
                console.log("respsContent: resps Referenzen erhalten:"),
                console.log(this.resps)
              ));
          },
          getTestfields() {
              this.axios.get( this.$config.host+"/testfields" ).then(response => (
                this.testfields = response.data,
                console.log("testfields in usecaseContent verfügbar:"),
                console.log(this.testfields)
              ));
          },
          getTags() {
              this.axios.get( this.$config.host+"/tags" ).then(response => (
                this.tags = response.data,
                console.log("tagContent: tags erhalten:"),
                console.log(this.tags)
              ));
          },
          removeTag (item) {
            console.log("tags?");
            console.log(this.node.tags);
            const index = this.node.tags.indexOf(item.id);
            console.log("index?");
            console.log(index);
            if (index >= 0) this.node.tags.splice(index, 1)
          },
          remove (item) {
                const index = this.node.testfields.indexOf(item.id)
                if (index >= 0) this.node.testfields.splice(index, 1)
          },
          addElement() {
            this.node.elements.push({
              name: null,
              content: null,
              cols: 6
            })            
          },
          deleteElement(index) {
            console.log("elem löschen:");
            console.log(index);
            this.node.elements.splice(index, 1);
          }          

        },
        created() {
            this.getResps();
            this.getTags();
            this.getTestfields();
        },
        computed: {
            editor() {
              return this.$refs.myQuillEditor.quill
            }
        },
        mounted() {

        },
        watch: {
            node: function (values) {
                this.node = values;
                if(this.previewsrc == null) {
                    this.previewsrc =  this.node.pathimg ? this.node.pathimg : null;
                }
            }
        }

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.form-wrap {
  margin:30px 0;
}
#nodeform .v-subheader {
  padding-left:0;
  padding-right:0;
}
#nodeform .v-label {
  color:#666666;
}
.txtwrap {
  padding: 10px 0;
  margin: 0px 0;
}
.preview {
  display:block;
  max-height:500px;
  max-width:100%;
}
.selecticon {
  height:20px;
  width: auto;
  margin-right:20px;
}
.ck-editor__editable {
  min-height: 300px;
}
.ql-container {
  font-size:1rem;
}
.ql-editor .ql-video {
  width:100%;
  height:450px;
}

</style>
