<template>

  <div class="sidemenu overflow-y-auto" id="scroll-target-l">

  <v-list dense
    v-scroll:#scroll-target-l="onScroll"
    class="px-4"
    rounded
  >

    <!-- Profile -->
    <v-list-item two-line>
      <v-list-item-avatar>        
        <img :src="user.image" height="40" width="auto" v-if="user.image">
        <div v-else style="background-color:lightblue;height:40px;width:100%;"></div>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>{{user.name}}</v-list-item-title>
        <v-list-item-subtitle>{{user.type}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list-item :to="'/'+$home+'admin'">
      <v-list-item-icon class="mr-6">
        <v-icon color="#666">mdi-view-dashboard</v-icon>
      </v-list-item-icon>

      <v-list-item-title>Übersicht</v-list-item-title>
    </v-list-item>
    
    <!-- Content -->
    <v-menu  
    offset-x        
    min-width="100"    
    >
      <template v-slot:activator="{ on }">
        <v-list-item
          v-on="on"
          dense
          class="my-2 mr-1"        
          append-icon="mdi-home"
          >
            <v-list-item-icon class="mr-6">
              <v-icon>mdi-table-of-contents</v-icon>
            </v-list-item-icon>

            <v-list-item-content class="mr-3">
              <div class="append-icon">
                <v-icon size="16" class="mt-3 mr-1">mdi-menu-down</v-icon>
              </div>
              <v-list-item-title>Inhaltstypen</v-list-item-title>              
            </v-list-item-content>        
        </v-list-item>
      </template>

      <v-list>
        <v-list-item
            v-for="(item, i) in MenuList"
            :key="i"
            :to="item.link"          
            link
            dense
          >

          <v-list-item-content class="px-4">
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>

        </v-list-item>
      </v-list>

    </v-menu>
    
    <v-menu
      right
      offset-x    
    >
      <template v-slot:activator="{ on }">
        <v-list-item
          v-on="on"
          dense
          class="my-2 mr-1"
          >
            <v-list-item-icon class="mr-6">
              <v-icon>mdi-file-plus</v-icon>
            </v-list-item-icon>

            <v-list-item-content class="mr-3">
              <div class="append-icon">
                <v-icon size="16" class="mt-3 mr-1">mdi-menu-down</v-icon>
              </div>
              <v-list-item-title>Inhalt hinzufügen</v-list-item-title>
            </v-list-item-content>        
        </v-list-item>
      </template>

      <v-list>
        <v-list-item
            v-for="(item, i) in MenuAdd"
            :key="i"
            :to="item.link"          
            link
            dense
          >

          <v-list-item-content class="px-4">
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>

        </v-list-item>
      </v-list>

    </v-menu>

    <v-divider></v-divider>

    <v-list-item
      :to="{ name: 'Login' }"    
      >
      <v-list-item-icon class="mr-6">
        <v-icon color="#666">mdi-account</v-icon>
      </v-list-item-icon>

      <v-list-item-title>Profil</v-list-item-title>
    </v-list-item>

    <v-list-item
      :href="'/' + $home + 'pdf/2021_11_16_Tutorial_VR-Touren_Online.pdf'"      
      :target="'_blank'"
      >
      <v-list-item-icon class="mr-6">
        <v-icon color="#666">mdi-information-outline</v-icon>
      </v-list-item-icon>

      <v-list-item-title>Dokumentation</v-list-item-title>
    </v-list-item>


    

  </v-list>
      
  </div>


</template>

<script>

export default {
  name: 'ContentMenue',

  components: {

  },
  data() {
    return {
      menuitems: [
        
        { title: 'Panoramen', link: '/'+this.$home+'content/pano', icon: 'mdi-table-of-contents', contenttype: 'pano', menutype: 'list'  },
        { title: 'POIs', link: '/'+this.$home+'content/poi', icon: 'mdi-table-of-contents', contenttype: 'poi', menutype: 'list'  },
        { title: 'Touren', link: '/'+this.$home+'content/tour', icon: 'mdi-table-of-contents', contenttype: 'tour', menutype: 'list'  },
        { title: 'Links', link: '/'+this.$home+'content/link', icon: 'mdi-table-of-contents', contenttype: 'link', menutype: 'list'  },
        { title: 'Testfelder', link: '/'+this.$home+'content/field', icon: 'mdi-table-of-contents', contenttype: 'testfield', menutype: 'list'  },
        { title: 'Usecases', link: '/'+this.$home+'content/usecase', icon: 'mdi-table-of-contents', contenttype: 'usecase', menutype: 'list'  },
        
        //{ title: 'Layout', link: '/'+this.$home+'content/element', icon: 'mdi-table-of-contents', contenttype: 'element', menutype: 'list'  },
        { title: 'Geräte/Services', link: '/'+this.$home+'content/device', icon: 'mdi-table-of-contents', contenttype: 'device', menutype: 'list'  },
        { title: 'Verantwortliche Personen', link: '/'+this.$home+'content/resp', icon: 'mdi-table-of-contents', contenttype: 'resp', menutype: 'list'  },
        { title: 'Benutzer', link: '/'+this.$home+'content/user', icon: 'mdi-table-of-contents', contenttype: 'user', menutype: 'list'  },
                      
        { title: 'Panorama', link: '/'+this.$home+'node/add/pano', contenttype: 'pano', menutype: 'add'  },
        { title: 'POI', link: '/'+this.$home+'node/add/poi', contenttype: 'poi', menutype: 'add'  },
        { title: 'Testfeld', link: '/'+this.$home+'node/add/field', contenttype: 'testfield', menutype: 'add'  },        
        { title: 'Usecase', link: '/'+this.$home+'node/add/usecase', contenttype: 'usecase', menutype: 'add'  },
        { title: 'Device', link: '/'+this.$home+'node/add/device', contenttype: 'device', menutype: 'add'  },
        //{ title: 'Element', link: '/'+this.$home+'node/add/element', contenttype: 'element', menutype: 'add'  },
        { title: 'Verantwortliche/r', link: '/'+this.$home+'node/add/resp', contenttype: 'resp', menutype: 'add'  },
        { title: 'Benutzer', link: '/'+this.$home+'node/add/user', contenttype: 'user', menutype: 'add'  },
        { title: 'Tour', link: '/'+this.$home+'node/add/tour', contenttype: 'tour', menutype: 'add'  }
      ],
      item1: true,
      item2: false,
      testfields: [
        { title: 'NAO-Leseroboter', icon: '', link: 'Stage' },
        { title: 'NLP', icon: '', link: 'NLP' },
        { title: 'Digitale Bibliothek', icon: '', link: 'SpaceBib' }
      ]
    }
  },
  methods: {
      onScroll () {
          //console.log("scrolling");
      },
  },
  computed: {
      user() {
        return this.$store.state.user
      },      
      MenuAdd: function(){
          //return this.menuitems.filter(item => (item.usertype == this.user.type || this.user.type == 'admin') && item.menutype == 'add' )
          return this.menuitems.filter(item => this.$store.state.access.add[item.contenttype] && item.menutype == 'add' )
      },
      MenuList: function(){
          return this.menuitems.filter(item => this.$store.state.access.read[item.contenttype] && item.menutype == 'list')
      }
  }

};
</script>

<style scoped>
.v-list-item {
    text-decoration: none;
}
.overflow-y-auto {
   height:100%;
}
.v-avatar img {
  width:auto !important;
}
.append-icon {
  position:absolute;  
  top:0;
  right:0;
}
</style>

<style>
.sidemenu {
  position:fixed;
  width:16%;
  scrollbar-width: none;
}
</style>
