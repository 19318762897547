<template>
    <div class="page">

        <!-- Header -->
        <v-layout class="first header">

            <div class="pbackground" :style="{ 'background-image': 'linear-gradient(0deg, rgba(9, 93, 169, 0.74), rgba(9, 93, 169, 0.74)), url(' + node.pathimg + ')' }">

                <v-container
                    fluid
                    >
                    <v-row justify="center" align="center">
                        <v-col class="wrap" cols="10" sm="10">
                                <v-row >
                                    <v-col cols="10" sm="10" class="title">
                                        <div>
                                            <span class="headtitle">
                                                <v-icon
                                                    size="16"
                                                    color="#fff"
                                                    class="mr-1"
                                                    >mdi-cogs</v-icon>
                                                {{ $t(type) }}
                                            </span>
                                            <h3 v-if="node.name">{{ node.name }}</h3>
                                        </div>
                                    </v-col>   
                                    <v-col cols="2" sm="2" class="titleright">
                                        <div v-if="iconpath" class="logocol">  
                                            <img :src="$config.graphpath+'/'+iconpath" title="" width="50px" class="logo" />
                                        </div> 
                                    </v-col>                                    
                                </v-row>
                            </v-col>
                    </v-row>
                </v-container>

            </div>

        </v-layout>                

        <v-layout class="content">
            <v-container fluid>
                    <v-row justify="center" align="center">
                        <v-col class="wrap" cols="10" sm="10">
                            <v-row>
                                
                                <v-col cols="8">
                                    <div v-html="node.description"></div>
                                </v-col>

                                <v-col cols="4" style="padding-top:0px;">

                                    <span class="topicheader" style="padding-bottom:24px;">Anwendung testen</span>

                                    <div class="pb-10 mb-4" v-if="node.testbed && node.testbed != ' '" style="border-bottom:1px solid #777">
                                        <v-toolbar
                                            dense                                            
                                            flat                                                                                                                                                                                         
                                        >   
                                            <v-spacer></v-spacer>                                        
                                            <v-tooltip left color="#333333">
                                            <template v-slot:activator="{ on, attrs }">
                                                
                                                <v-btn 
                                                    :to="'/t/id/'+node.id" 
                                                    width="100%" 
                                                    dark 
                                                    x-large
                                                    color="#ec9129"                                            
                                                    v-on="on"
                                                    v-bind="attrs"          
                                                    v-if="node.absolute"
                                                    >
                                                    <!--<v-btn icon  outlined>-->
                                                    <v-icon
                                                        size="32px"
                                                        >                                                        
                                                        mdi-application-cog
                                                    </v-icon>
                                                </v-btn>

                                                <v-btn 
                                                    v-else
                                                    :to="node.testbed" 
                                                    width="100%" 
                                                    dark 
                                                    x-large
                                                    color="#ec9129"                                            
                                                    v-on="on"
                                                    v-bind="attrs"          
                                                    >
                                                    <!--<v-btn icon  outlined>-->
                                                    <v-icon
                                                        size="32px"
                                                        >                                                        
                                                        mdi-application-cog
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Zur Anwendung</span>
                                            </v-tooltip>                                            
                                        </v-toolbar>
                                    </div>


                                    <div class="modul" v-if="node.testfield && node.testfield.length > 0">
                                        <span class="topicheader">Testfelder</span>
                                        <v-btn
                                            class="ma-2 field-btn"
                                            v-for="(field, key) in node.testfield"
                                            :key="key"
                                            depressed                                                                                                                           
                                            :to="'/p/field/'+field.id" 
                                        >                                        
                                            {{ field.title }}                                        
                                        </v-btn>                
                                    </div>                                                                           

                                    <div class="modul">
                                        <span class="topicheader">Themen</span>
                                        <v-btn
                                            class="ma-2"
                                            v-for="(tag, key) in node.tags"
                                            :key="key"
                                            depressed                                                                                        
                                            :to="'/suche?t='+tag.name" 
                                        >                                        
                                            {{ tag.name }}                                        
                                        </v-btn>                
                                    </div>                                       
                                    
                                </v-col>                                    
                                                                                                       
                            </v-row>
                                                                                                                                                                            
                            <!--
                                <div class="tb-wrap" v-if="node.testbed && node.testbed != ' '">
                                
                                    <div class="tb-desc">
                                        <span>Anwendung testen</span>
                                    </div>

                                    <div class="tb-button">
                                        <v-btn :to="node.testbed" class="mx-2" fab dark large color="#ec9129">
                                            <v-icon>mdi-application-cog</v-icon>
                                            <img src="/files/images/logo-testbed.svg">
                                        </v-btn>
                                    </div>

                                </div>
                            -->

                        </v-col>
                    </v-row>
            </v-container>
        </v-layout>
        

        <v-layout class="iblue">
            <v-container>
                  <v-row justify="center" align="center">
                      <v-col class="wrap" cols="10" sm="10">
                          <h4>Welche Testfelder sind an diesem Anwendungsfall beteiligt?</h4>
                          <v-row >
                              <Usecase_Connected :usecaseid="node.id"></Usecase_Connected>
                          </v-row>

                      </v-col>
                  </v-row>
            </v-container>
        </v-layout>

        <v-layout>

            <v-container>

                <v-row justify="center" align="center">

                    <v-col class="wrap" cols="10" sm="10">

                        <div class="servicewrap"
                            v-for="(element, key) in node.elements"
                            :key="key"                            
                            >
                            <v-row>
                                <v-col cols="12" sm="12">
                                <h4>{{ element.name }}|{{element.cols}}</h4>
                                <div v-html="element.content"></div>
                                </v-col>
                            </v-row>                 
                        </div>               

                    </v-col>

                </v-row>

            </v-container>

        </v-layout>

        <v-layout class="iblue">
            <v-container>
                  <v-row justify="center" align="center">
                      <v-col class="wrap" cols="9" sm="9">                        
                        <Contact :contacts="node.responsible"></Contact>
                      </v-col>
                  </v-row>
            </v-container>
        </v-layout>

        <v-layout class="iwhite">
            <v-container>
                  <v-row justify="center" align="center">
                      <v-col class="wrap" cols="10" sm="10">
                          <h4>Das könnte Sie auch interessieren</h4>
                          <Testfields></Testfields>
                          <br><br><br>
                      </v-col>
                  </v-row>
            </v-container>
        </v-layout>


    </div>
</template>

<script>

import Usecase_Connected from './Usecase_Connected';
import Testfields from './Testfields';
import Contact from '../elements/Contact';

    export default {
        name: 'Page',
        data() {
            return {
                type: 'usecase',
                iconpath: null
            };
        },
        props: {
            node: {
                type: Object
            }
        },
        components: {
          Usecase_Connected,
          Testfields,
          Contact
        },
        methods: {
            setIcons() {                                
                switch (this.node.testfields[0]) {
                    case 2:
                        this.iconpath = 'Testfeld_AutonomesFahren_Icon_klein.svg';
                        break;
                    case 3:
                        this.iconpath = 'Testfeld_NLP_Icon_klein.svg';
                        break;
                    case 4:
                        this.iconpath = 'Testfeld_SocialRobotics_Icon_klein.svg';
                        break;                
                    default:
                        break;
                }                                                            
            }
        },
        created() {
        },
        computed: {
        },
        mounted() {
            //console.log("PageContent: node wert (usecase) on mounted?");
            //console.log(this.node);
        },
        watch: {
            node: function (values) {
                //console.log("watch Usecase:");
                //console.log(values);
                this.node = values;
                this.setIcons();               
            }
        },
    }
</script>

<style>
.imgsection .leading {
    padding-top:6px;
}
.v-btn--fab.v-size--large {
    height: 84px;
    width: 84px;
}
.v-btn--fab span {
  padding:18px;
}
.tb-wrap {
  background-color:#f7f7f7;
  border-radius:6px;
  padding:14px 8px 16px;
}
.tb-desc {
  display:inline-block;
  width:82%;
  padding:0 40px;
  font-size:1.2rem;
}
.tb-button {
  display:inline-block;
  text-align:center;
  width:18%;
}
</style>
