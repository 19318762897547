<template>

<v-card class="admincontent">

    <v-card-title
      class="stickysub"
      >
      Infopunkte / POIs
      <v-btn 
        icon
        class="ml-4"
        :to="'/'+$home+'node/add/'+ct"
        title="POI hinzufügen"
        >
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>      
      <v-spacer></v-spacer>
        <v-text-field
          v-model="search"        
          append-icon="mdi-magnify"
          label="Suchen"
          single-line
          hide-details
          class="pt-0"
          clearable
        ></v-text-field>
    </v-card-title>
    
   <v-data-table
      v-model="selected"
      :headers="headers"
      :items="pois"
      :footer-props="{
        'items-per-page-options': [20, 30, 40, 50, 100]
      }"
      :items-per-page="50"
      :single-select="singleSelect"
      :search="search"
      item-key="id"
      class="elevation-1"
    >

    <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="(item, index) in items"
                :key="'a'+index"
              >
                <!--
                <td>
                <v-checkbox
                style="margin:0px;padding:0px"
                hide-details
                />
                </td>
                -->
                <td>{{ item.id }}</td>
                <td><img :src="'/'+$home+'graf/' + item.src" :alt="item.id" class="selecticon" :title="item.name" /></td>
                <td>
                  <v-icon size="18" v-if="item.published == true">mdi-check</v-icon>
                  <v-icon size="18" v-if="item.published == false">mdi-eye-off-outline</v-icon>
                </td>
                <td>
                  <router-link :to="'/'+$home+'node/poi/' + item.id" class="" text small>
                  {{ item.name }}
                  </router-link>
                </td>
                <td>
                  <!--
                  <router-link :to="'/node/pano/' + item.pano_id" class="" text small>
                  {{ item.pano_name }}
                  </router-link>
                  -->
                  <span v-if="item.name_admin" class="smaller">{{ item.name_admin }}</span>
                  <span v-else class="smaller">{{ item.pano_name }}</span>
                  <v-btn :to="'/'+$home+'panorama/?q=' + item.pano_id" class="" text small target="_blank">
                    <v-icon size="16" >mdi-open-in-new</v-icon>
                  </v-btn>
                </td>
                <td>
                  {{ item.loc_key }}
                </td>
                <td>
                  {{ item.tour_name }}
                </td>
                <td>
                  {{ item.user_id }}
                </td>                
                <td>
                  <v-btn :to="'/'+$home+'node/poi/' + item.id" class="" text small>
                    <v-icon size="18" >mdi-pencil</v-icon>
                  </v-btn>
                </td>
                <td>
                  <v-btn @click="deletePoi(index, item.id)" class="" text small>
                    <v-icon size="18" >mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>

    <!--
    <template v-slot:top>
      <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
    </template>
    -->


  </v-data-table>

</v-card>
</template>

<script>

import UserContent from '../../helper/UserContent.js';

export default {
  name: 'Fields',
  props: ['ct'],
  data () {
    return {
      search: '',
      singleSelect: false,
      selected: [],
      headers: [
        { text: 'ID',
          align: 'start',
          value: 'id'
        },
        {
          text: 'Kategorie',
          value: 'icon',
        },
        {
          text: 'veröffentl.',
          value: 'published',
        },        
        {
          text: 'Name',
          //sortable: false,
          value: 'name',
        },
        {
          text: 'Panorama',
          value: 'pano_name'
        },
        {
          text: 'Ort',
          value: 'loc_key'
        },        
        {
          text: 'Tour',
          value: 'tour_name'
        },        
        {
          text: 'Eigentümer'
        },        
        {
          text: ''
        },
        {
          text: ''
        }
      ],
      pois: [],
      panos: [],
      overlaydata: null
    }
  },
  methods: {
      getPois() {
          console.log("Fkt getPois");
          this.axios.get( this.$config.host+"/pois" ).then(response => (
            this.pois = response.data,            
            console.log("POIs:"),
            console.log(this.pois),
            this.pois = UserContent.getContent(response.data, 'poi'),
            this.pois = this.pois.filter(item => item.icon != 1)
          ));
      },
      deletePoi(index, id) {
          if(confirm('Soll der Datensatz mit der ID ' + id + 'wirklich gelöscht werden?')) {
            console.log("Fkt deletePoi");
            this.axios.post( this.$config.host+"/poi/delete/"+id ).then(response => (
              console.log(response),
              this.removePoi(index)
              //console.log(this.pois)
            ));
          }
      },
      removePoi(index) {
          this.$delete(this.pois, index)
      }
  },
  computed: {
      searchContent: {      
          get() {                        
            return this.$store.state.search.poi;
          },
          set(value) {
              this.$store.state.search.poi = value;
          }
      },
  },
  mounted() {
    this.getPois();
    this.search = this.searchContent;    
  },
  watch: { 
    search: function(newVal) {
          this.$store.commit("setSearchPoi", newVal);
    }
  }  

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  margin:0 0 40px 0;
  padding: 40px;
  background-color:#f5f5f5;
}
.item {
  display:block;
  width:100%;
  background-color:lightblue;
  margin-bottom:4px;
}
.p >>> p {
  margin:0;
}
a {
  color: #42b983;
}
</style>
