<template>
    <div class="page">
        
            <!-- Header -->
            <v-layout class="first header">

                <div class="pbackground" :style="{ 'background-image': 'linear-gradient(0deg, rgba(9, 93, 169, 0.74), rgba(9, 93, 169, 0.74)), url(' + node.pathimg + ')' }">

                    <v-container>
                        <v-row justify="center" align="center">
                            <v-col class="wrap" cols="10" sm="10">
                                    <v-row>
                                        <v-col cols="10" sm="10" class="title">
                                            <div>
                                                <!--<span class="headtitle">{{ $t(type) }}</span>-->
                                                <span class="headtitle">FORSCHUNGSFELD</span>
                                                <h3 v-if="node.title">{{ node.title }}</h3>
                                            </div>
                                        </v-col>   
                                        <v-col cols="2" sm="2" class="titleright">
                                            <div v-if="iconpath" class="logocol">  
                                                <img :src="$config.graphpath+'/'+iconpath" title="" width="50px" class="logo" />
                                            </div> 
                                        </v-col>
                                    </v-row>
                                </v-col>
                        </v-row>
                    </v-container>

                </div>

            </v-layout>                

            <!-- Description -->
            <v-layout class="content">
                <v-container class="">
                      <v-row justify="center" align="center">
                           <v-col class="wrap" cols="10" sm="10">
                               <v-row>
                                   <v-col cols="8">                                                                              
                                        <div class="main" v-html="node.description"></div>
                                    </v-col>
                                    <v-col cols="4">       
                                                                                                                       
                                        <div class="modul" v-if="usecases && usecases.length > 0">                                            
                                            <span class="topicheader">Anwendungsfälle im Testfeld</span>                                    

                                            <v-btn
                                                class="ma-2 usecase-btn"
                                                v-for="(usecase, key) in usecases"
                                                :key="key"
                                                depressed                                                                                                                           
                                                :to="'/p/usecase/'+usecase.id" 
                                            >       
                                                <v-icon left>
                                                    mdi-cogs
                                                </v-icon>                                                                     
                                                {{ usecase.name }}                                        
                                            </v-btn>                

                                        </div>

                                        <div class="modul" v-if="node.devices && node.devices.length > 0">             
                                            <span class="topicheader">Equipment</span> 

                                            <v-btn
                                                class="ma-2 tools-btn"                                                                                                
                                                depressed                                                                                                                           
                                                :to="'/p/devices/'+node.id" 
                                                v-if="node.devices"
                                            >       
                                                <v-icon left>
                                                    mdi-tools
                                                </v-icon>                                                                     
                                                Hardware und Software ({{ node.devices.length }})
                                            </v-btn>                

                                        </div>

                                        <v-btn
                                            class="ma-0"                                                                                        
                                            depressed                                            
                                            color="#90ee90"
                                            :to="'/suche'" 
                                            style="margin-bottom:20px !important;"
                                        >
                                        <v-icon
                                            right
                                            dark
                                            style="margin-right:10px;margin-left:0"                                            
                                        >
                                            mdi-download-box-outline
                                        </v-icon>                                        
                                            Transfersteckbrief
                                        </v-btn>                

                                       <div class="modul" style="padding:10px 10px 20px;margin-bottom:30px;background-color:#f7f7f7">                                        
                                            <span class="topicheader">Kontakt</span>
                                            <div class="body" v-if="node.responsible && node.responsible.length > 0">
                                                <p class="name">{{ node.responsible[0].name }}</p>
                                                <span v-html="node.responsible[0].contact"></span>                                                
                                            </div>
                                        </div>                                                                                                                                                                                                                   
 
                                        <div class="modul">
                                        <span class="topicheader">Themen</span>
                                        <v-btn
                                            class="ma-2"
                                            v-for="(tag, key) in node.tags"
                                            :key="key"
                                            depressed                                            
                                            color="#ddd"
                                            :to="'/suche?t='+tag.name" 
                                        >                                        
                                            {{ tag.name }}                                        
                                        </v-btn>                
                                        </div>                                       

                                        <div class="modul">
                                        <span class="topicheader">WEITERLESEN</span>
                                        <div
                                            v-for="(link, key) in links"
                                            :key="key"
                                            class="listwrap"
                                            >
                                            <v-btn
                                                class="ma-2 url-btn"           
                                                v-if="node.id == link.tfid"                         
                                                depressed                                            
                                                color="#ddd"
                                                target="_blank"
                                                href="https://www.th-wildau.de/wildauer-maschinen-werke/testfeld/"
                                            >
                                                <v-icon left>
                                                    mdi-link-variant
                                                </v-icon>                                                                                                             
                                                {{ link.name }}                                        
                                            </v-btn>                
                                            <!--
                                            <v-html class="ml-11 url-desc">
                                            </v-html>
                                            -->
                                        </div>
                                        </div>                                       

                                    </v-col>
                                   </v-row>
                                                                                                                              

                                <v-row class="imgsection" align="end">

                                </v-row>                            

                           </v-col>
                      </v-row>
                </v-container>
            </v-layout>

            <!-- Offers -->
            <v-layout class="iblue">
                <v-container>
                      <v-row justify="center" align="center">
                          <v-col class="wrap" cols="10" sm="10">
                              <h4>Was bietet das Testfeld an?</h4>
                              <v-row >
                                  <FieldUsecases :usecases="usecases" v-if="usecases !== null"></FieldUsecases>
                              </v-row>
                          </v-col>
                      </v-row>
                </v-container>
            </v-layout>

            <v-layout>
                <v-container>
                    <v-row justify="center" align="center">
                        <v-col class="wrap" cols="12" md="10">
                            <v-row>
                            <v-col class="servicewrap"
                                v-for="(element, key) in node.elements"
                                :key="key" 
                                cols="12" :md="element.cols"                           
                                >
                                                                    
                                    <h4>{{ element.name }}|{{element.cols}}</h4>
                                    <div v-html="element.content"></div>
                                                                   
                            </v-col>               
                            </v-row>                 
                        </v-col>
                    </v-row>
                </v-container>
            </v-layout>

            <v-layout class="equipment" v-if="node.devices && node.devices.length > 0">
                <v-container>
                    <!-- Equipment cards -->
                    <v-row justify="center" align="center">
                        <v-col class="wrap" cols="10" sm="10">                                        
                        <h4>Equipment im Testfeld</h4>
                        <v-carousel
                            height="300"
                            hide-delimiter-background
                            delimiter-icon="mdi-minus"                        
                            hide-delimiters
                            > 
                            <template v-for="(dev, index) in node.devices"> 
                            <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" 
                                :key="index"
                            > 
                                <v-row class="flex-nowrap" style="height:100%"> 
                                <template v-for="(n,i) in columns"> 
                                    <template v-if="(+index + i) < node.devices.length"> 
                                    <v-col :key="i"> 
                                        <v-sheet v-if="(+index + i) < node.devices.length"                                                 
                                            height="100%"
                                            color="transparent"                                            
                                        >
                                        <v-row class="fill-height"
                                            align="center"
                                            justify="center"
                                        >
                                            <div class="item">
                                                <router-link
                                                    :to="'/p/device/'+node.devices[+index + i].dev_id"
                                                    >
                                                    <img :src="node.devices[+index + i].paththumb" alt="xx" />
                                                    <div class="desc">                                        
                                                        <div class="category">{{ node.devices[+index + i].category }}</div>
                                                        <div>{{ node.devices[+index + i].name }}</div>
                                                        <div
                                                            v-if="node.devices[+index + i].manufacturer"
                                                            >
                                                            Hersteller: {{ node.devices[+index + i].manufacturer }}
                                                        </div>                                                        
                                                        <div
                                                            v-if="node.devices[+index + i].purchasedate"
                                                            >
                                                            Anschaffung: {{ node.devices[+index + i].purchasedate | dateParse('YYYY-MM-DD') | dateFormat('YYYY') }}
                                                        </div>
                                                    </div>
                                                </router-link>
                                            </div>
                                        </v-row>
                                        </v-sheet>
                                    </v-col> 
                                    </template> 
                                </template> 
                                </v-row> 
                            </v-carousel-item> 
                            </template> 
                        </v-carousel>  

                        <!--
                        <v-slide-group                            
                            class="py-4 px-0 mb-2"
                            show-arrows
                            >
                            <v-slide-item
                                v-for="(dev, key) in node.devices"
                                :key="key"
                            >
                                <v-card
                                color="lightgreen"
                                class="ma-1"
                                height="220"
                                width="220px"               
                                :to="'/p/device/'+dev.dev_id"                 
                                >
                                <v-row
                                    class="fill-height ma-0 pa-0"
                                    align="center"
                                    justify="center"
                                >                                
                                <v-img
                                    :src="dev.pathimg"                                    
                                    class="ma-1"
                                    height="140px"
                                    >
                                </v-img>
                                {{ dev.name }}
                                </v-row>
                                </v-card>
                            </v-slide-item>
                        </v-slide-group>
                        -->

                        <div class="modul" v-if="node.devices && node.devices.length > 0">                                     
                            <div class="body">
                            <router-link
                                class="ma-0 pa-0 link"                                                                                                
                                depressed                                            
                                color="#ddd"                                                                                            
                                :to="'/p/devices/'+node.id"
                                v-if="node.devices"
                            >                                        
                                Übersicht Hardware und Software ({{ node.devices.length }})
                            </router-link>    
                            </div>            
                        </div>
                           
                        </v-col>
                    </v-row>        

                </v-container>
            </v-layout>

            <v-layout class="iblue">
                <v-container>
                      <v-row justify="center" align="center">
                          <v-col class="wrap" cols="9" sm="9">
                                <Contact :contacts="node.responsible"></Contact>
                          </v-col>
                      </v-row>
                </v-container>
            </v-layout>

            <v-layout class="iwhite">
                <v-container>
                      <v-row justify="center" align="center">
                          <v-col class="wrap" cols="10" sm="10">
                              <div v-if="node.id">
                              <h4>Das könnte Sie auch interessieren</h4>
                              <Testfields :current="node.id"></Testfields>                              
                              </div>
                          </v-col>
                      </v-row>
                </v-container>
            </v-layout>

    </div>
</template>

<script>

import FieldUsecases from './FieldUsecases';
import Testfields from './Testfields';
import Contact from '../elements/Contact';

    export default {
        name: 'Page',
        data() {
            return {
                type: 'testfield',
                usecases: null,
                iconpath: null,
                links: [
                    {
                        url: 'https://www.th-wildau.de/wildauer-maschinen-werke/testfeld/',
                        name: 'WMW Wildau Maschinenwerke',
                        desc: 'WMW Wildauer Maschinenwerke',
                        tfid: 2
                    },
                    {
                        url: 'https://www.th-wildau.de/wildauer-maschinen-werke/',
                        name: 'WMW Lernplattform',
                        desc: 'xxxZxxx',
                        tfid: 2
                    },
                    {
                        url: 'https://www.th-wildau.de/wildauer-maschinen-werke/testfeld/',
                        name: 'NLP in der Kundenbetreuung',
                        desc: 'WMW Wildauer Maschinenwerke',
                        tfid: 3
                    },
                    {
                        url: 'https://www.th-wildau.de/wildauer-maschinen-werke/',
                        name: 'NLP als Prozess',
                        desc: 'xxxZxxx',
                        tfid: 3
                    },
                    {
                        url: 'https://www.th-wildau.de/wildauer-maschinen-werke/testfeld/',
                        name: 'Robotikanwendung im Smart Home',
                        desc: 'WMW Wildauer Maschinenwerke',
                        tfid: 4
                    },
                    {
                        url: 'https://www.th-wildau.de/wildauer-maschinen-werke/',
                        name: 'Anwendungsfälle Social Robotics',
                        desc: 'xxxZxxx',
                        tfid: 4
                    }                                        
                ]
            };
        },
        props: {
            node: {
                type: Object
            }
        },
        components: {
          FieldUsecases,
          Testfields,
          Contact
        },
        methods: {
            getUsecasesByTestfieldId() {
                //console.log("getUsecasesByTestfieldId()");
                let self = this;
                this.axios.get( this.$config.host+"/usecases/testfield/" + this.node.id ).then(function (response) {
                  //console.log("get USECASES by testfield id");                                                  
                  response.data.sort((a,b) => (b.generated > a.generated) ? 1 : ((a.generated > b.generated) ? -1 : 0));                                    
                  self.usecases = response.data;
                });
            },
            setIcons() {
                switch (this.node.id) {
                    case 2:
                        this.iconpath = 'Testbed_AutonomesFahren_Icon_klein.svg';
                        break;
                    case 3:
                        this.iconpath = 'Testbed_NLP_Icon_klein.svg';
                        break;
                    case 4:
                        this.iconpath = 'Testbed_SocialRobotics_Icon_klein.svg';
                        break;                
                    default:
                        break;
                }                                                            
            }
        },
        created() {

        },
        computed: {
            columns() {
                if (this.$vuetify.breakpoint.xl) {                    
                    return 5;
                }
                if (this.$vuetify.breakpoint.lg) {                    
                    return 4;
                }
                if (this.$vuetify.breakpoint.md) {                    
                    return 3;
                }
                if (this.$vuetify.breakpoint.sm) {                    
                    return 2;
                }                
                return 1;
            }
        },
        mounted() {
            //console.log("PageContent: node wert (testfield) on mounted?");
            //console.log(this.node);            
        },
        watch: {
            node: function (values) {
                //console.log("watch Testfield:");
                //console.log(values);
                this.node = values;
                this.getUsecasesByTestfieldId(); 
                this.setIcons();               
            },
            usecases: function (values) {
                //console.log("watch Usecases in page field:");
                //console.log(values);
                this.usecases = values;
                //this.getUsecasesByTestfieldId();
            }            
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
/*
.equipment .v-slide-group__prev,
.equipment .v-slide-group__next {
    position: absolute;
    bottom:0;
}
.equipment .v-slide-group__prev .v-icon,
.equipment .v-slide-group__next .v-icon {
    font-size: 40px;
}
.equipment .v-slide-group__prev {
    left:0;
}
.equipment .v-slide-group__next {
    right:0;
}
.equipment .v-slide-group__wrapper {
    padding-bottom:30px;
}
*/
.equipment {
    background-color: #f4f4f4;
}
.equipment .v-sheet > .row {
    margin:0;
}
.equipment .item {
    display: block;
    padding:10px;
    border:1px solid #ddd;
}
.equipment .item .desc {
    height:6rem;
    padding:6px;
    font-size: 0.88rem;
}
.equipment .item .desc .category {
    font-style: italic;
}
.equipment .item a {
    text-decoration: none;
    color:rgba(0, 0, 0, 0.87);
}
.equipment .item img {    
    max-width:220px;    
    width:200px;
}
</style>
