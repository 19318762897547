<template>
  <v-app :class="[localhost ? 'testbed' : 'testbed']" data-server-rendered="true">
    
  <!--<SubNav :template="template" v-if="$route.path.indexOf('/p/') !== -1"></SubNav>-->

  <TopNav :drawer="drawer" :controls="controls" :template="template"></TopNav>
  

  <!--<SidebarNav v-if="$route.path.indexOf('/p/') !== -1"></SidebarNav>-->
  
  <div v-if="$route.path.indexOf('content') !== -1 || $route.path.indexOf('node/add') !== -1" id="content">   
    <router-view :controls="controls" :key="$route.path" :template="template"></router-view>        
  </div>  

  <div v-else id="content">
    <!--
    <transition name="testbed">      
      <router-view :controls="controls" :key="$route.path" :template="template"></router-view>            
    </transition>
    -->    
    <router-view :controls="controls" :key="$route.path" :template="template"></router-view>                
  </div>
  
  </v-app>
</template>

<script>

import TopNav from './components/TopNav';
//import SubNav from './components/SubNav';
//import SidebarNav from './components/SidebarNav';

export default {
  name: 'App',
  components: {
      TopNav,
      //SubNav,
      //SidebarNav
  },
  props: [
      'panodata'
  ],
  data: function () {
      return {
        localhost: false,
        template: null,
        testbed: false,
        drawer: {
          visible: false,
        },
        controls: {
          visible: true,
        },
        activeBtn: 1
      }
  },
  created () {
    this.template = this.$template;
    // document.title = "TH Wildau virtuell";
    //document.title = "Testbed - Innovation Hub 13";
    //document.title = "ZFT - Zentrum für Forschung und Transfer";
    document.title = "InnoXR - Innovation Hub 13";
  },
  mounted () {

      if (this.$host === 'local') {
        this.localhost = true
      }
      if (this.$host === 'testbed') {
        this.testbed = true
      }

  }

};
</script>

<style>

body p {
  font-size:1rem;
}
#content {
  height:100%;
  /*background-color: #575756;*/
  background-color:#ffffff;
}
.v-application {
  font-family:"Lucida Sans Unicode", "Lucida Grande", sans-serif !important;
  background-color:#ffffff;
}
.panomenu .v-list-item__title {
  white-space: normal;
}
.list-header {
  font-size:1rem !important;
}
.v-application .orange {
  background-color:transparent;
  background-image: linear-gradient(to right, #f49e30, #ed7724);
}
#footer img {
  max-width: 100%;
}
#footer .ref {
  margin-top:20px;
  margin-bottom:60px;
}

/* USECASES */

#stage {  
  border:0px solid #FFFFE0 !important;
}
#nlp {  
  border:0px solid #FFFFE0 !important;
}

.wrap.usecaseinfo .stepcontent {
  color:#000;
}
.usecaseinfo a {
  color:#005ca9!important;
  text-decoration: none;
}

/* CUSTOM TESTBED */

.theme--light.v-btn {
  color:#666;
}
.v-btn:hover {
  text-decoration: none;
}
.testbed .controlsinner {
    border:0px solid blue;
}
.localhost .controlsinner {
    border:0px solid green;
}
.testbed-enter-active {
    transition: 0.6s ease;
}
.testbed-leave-active {
    transition: 0s;
}
.testbed-enter {
  opacity: 0;
}
.testbed-leave {
  opacity: 0;
}
.v-application a.router-link-active {
  color:orange;
}
</style>
