<template>

  <v-card class="dashboardcontent">

    <v-card-actions>  
    <v-btn      
      icon
      :to="'/'+$home+'content/field'"
      >      
      <v-icon>mdi-table-of-contents</v-icon>
    </v-btn>
    </v-card-actions>    

  <v-list-item two-line>
      <v-list-item-icon
        class="dash-icon"
        >
        <v-icon
          size="24"          
          >mdi-cogs</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="d-title">
          Testfelder
        </v-list-item-title>        
      </v-list-item-content>
    </v-list-item>
    
  <v-data-table
      v-model="selected"
      :headers="headers"
      :items="testfields"
      :footer-props="{
        'disable-items-per-page': true        
      }"      
      :items-per-page="5"
      :single-select="singleSelect"
      :search="search"
      item-key="id"
      class="elevation-1 list-blank"
    >

    <template v-slot:body="{ items }">
      <tbody>
        <tr
          v-for="(item) in items"
          :key="item.id"
        >
          <td>{{ item.id }}</td>
          <td>
            <router-link :to="'/'+$home+'node/field/' + item.id" text small>
            {{ item.title }}
            </router-link>
          </td>                                             
          <td>            
            <v-btn :to="'/'+$home+'node/field/' + item.id" class="" text small>
              <v-icon size="18" >mdi-pencil</v-icon>
            </v-btn>    
            <!--              
            <v-btn @click="deleteTf(index,item.id)" class="" text small>
              <v-icon size="18" >mdi-delete</v-icon>
            </v-btn>
            -->
          </td>
        </tr>
      </tbody>
    </template>

  </v-data-table>

  </v-card>
</template>

<script>
export default {
  name: 'DashboardTestfields',  
  props: ['type'],
  data () {
    return {
      singleSelect: false,
      selected: [],
      search: '',
      headers: [
        { text: 'ID',
          align: 'start',
          value: 'id',                  
          sortable: false
        },
        {
          text: 'Name',
          value: 'title',
        },
        {
          text: '',
          value: 'index'
        }
      ],
      testfields: [],
      overlaydata: null
    }
  },
  methods: {
      getTestfields() {          
          this.axios.get( this.$config.host+"/testfields" ).then(response => (
            this.testfields = response.data
          ));
      }
  },
  mounted() {        
    this.getTestfields();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
