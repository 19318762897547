<template>

  <v-app id="admincontent" v-if="admintype">

    <v-row v-if="$store.state.access.enter['admin']">

        <v-col cols="2" sm="2">

          <SideMenu class=""></SideMenu>

        </v-col>

        <v-col cols="10" sm="10" class="bg">     
                    
          <div>

            <div v-if="contenttype == 'field'">
              <AdminTestfields ref="adminTestfields" :ct="contenttype"></AdminTestfields>            
            </div>

            <div v-else-if="contenttype == 'element'">
              <AdminElements ref="adminElements" :ct="contenttype"></AdminElements>
            </div>

            <div v-else-if="contenttype == 'pano'">
              <AdminPanos ref="adminPanos" :ct="contenttype"></AdminPanos>
            </div>

            <div v-else-if="contenttype == 'resp'">
              <AdminResponsible ref="adminResps" :ct="contenttype"></AdminResponsible>
            </div>

            <div v-else-if="contenttype == 'user'">
              <AdminUsers ref="adminUsers" :ct="contenttype"></AdminUsers>
            </div>

            <div v-else-if="contenttype == 'tour'">
              <AdminTours ref="adminTours" :ct="contenttype"></AdminTours>
            </div>            

            <div v-else-if="contenttype == 'usecase'">
              <AdminUsecases ref="adminUsecases" :ct="contenttype"></AdminUsecases>
            </div>

            <div v-else-if="contenttype == 'device'">
              <AdminDevices ref="adminDevices" :ct="contenttype"></AdminDevices>
            </div>

            <div v-else-if="contenttype == 'poi'">
              <AdminPois ref="adminPois" :ct="contenttype"></AdminPois>
            </div>

            <div v-else-if="contenttype == 'link'">
              <AdminLinks ref="adminLinks" :ct="'poi'"></AdminLinks>
            </div>

            <div v-else-if="admintype == 'Admin'">
              <Admin ref="admin"></Admin>
            </div>

          </div>
        
        </v-col>
    </v-row>

    <v-row class="noaccess" v-else>
      <v-col cols="12" sm="12">
      <p>{{ $t('noaccess') }}</p>  
      <p>Melden Sie sich an, um diese Seite einsehen zu können.</p>      
      <p><a :href="'/' + this.$home + 'login'" title="Login">Login</a></p>
      <p>Sollte Sie Probleme mit der Authentifizierung haben, kontrollieren Sie Ihre Zugangsdaten oder nutzen Sie den <b>Firefox-Browser</b>.</p>          
      </v-col>
    </v-row>

  <v-footer
    fixed
    bottom
  >

  </v-footer>

  </v-app>  
  
</template>

<script>

import AdminPanos from './content/AdminPanos';
import AdminPois from './content/AdminPois';
import AdminLinks from './content/AdminLinks';
import AdminResponsible from './content/AdminResponsible';
import AdminUsers from './content/AdminUsers';
import AdminTours from './content/AdminTours';
import Admin from './content/Admin';
import AdminTestfields from './content/AdminTestfields';
import AdminUsecases from './content/AdminUsecases';
import AdminDevices from './content/AdminDevices';
import AdminElements from './content/AdminElements';
import SideMenu from './navigation/SideMenu';

export default {
  name: 'Content',

  components: {
    AdminPanos,
    AdminPois,
    AdminLinks,
    AdminResponsible,
    AdminUsers,
    AdminTours,
    AdminElements,
    Admin,
    AdminTestfields,
    AdminUsecases,
    AdminDevices,
    SideMenu
  },
  data() {
    return {
      autherror: false,
      contenttype: this.$route.params.nodetype,
      admintype: this.$route.name
    }
  },
  methods: {
      getUserData: function() {
          let self = this;
          //console.log("check user neu");          
          this.axios.get( this.$config.host+"/user")
              .then((response) => {                                    
                  //console.log("user response:")
                  //console.log(response.data);                  
                  self.$store.commit('setUser', response.data)
              })
              .catch((errors) => {
                  //console.log("catch!");
                  console.log(errors)
                  self.$store.commit('clearUser')
                  self.autherror = true;
                  //this.$router.push("/"+self.$home+"login")
              })        
      },
      contentSaved(value) {                    
          this.contenttype = value
          this.$refs.adminPanos.getPanos();
      },
      scrollToTop() {
          window.scrollTo(0,0);
      }
  },
  mounted() {
      this.getUserData()
  },
  watch: {

      $route: function (val) {
          console.log("watch route!")
          console.log(val)
          this.admintype = val.name
          this.contenttype = val.params.nodetype
          this.scrollToTop()
      },

  },
  computed: {
      user() {
        return this.$store.state.user
      }
  }

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
#admincontent {
    margin-top:48px;
}
.admincontent {
  margin:0px 20px 100px 0;  
  padding:0;  
}
.admincontent .dash-icon {
  margin-top:18px;  
  margin-bottom:20px !important;
}
#nodeform {
  width:100%;
  padding:0px;
}
#nodeform header {
  position:sticky;
  top:48px;
  background-color:#f5f5f5;
  z-index:4;
}
.nodecontent {
  padding: 25px 25px 2px;
}

.check-published {
  margin-left:30px;
}

.check-published .v-input__control {
  padding-top:18px;
}
.check-published .v-input__control .v-label {
  padding-top:8px;
}

.add { 
  font-size: 1.1rem;
  font-style: italic;
  padding-right:8px;
}
.edit {
  font-size: 1.1rem;
  font-style: italic;
  padding-right:8px;
}

.contentmenu {
    position:fixed;
    top:48px;
    width:100%;
}
.bg {
  background-color:#e5e5e5;
}

.v-card a {
  text-decoration:none;
}
</style>
