<template>

  <v-card class="admincontent">

    <v-card-title
      class="stickysub"
      >
      Nutzer
      <v-btn 
        icon
        class="ml-4"
        :to="'/'+$home+'node/add/'+ct"
        title="Nutzer hinzufügen"
        >
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>          
    <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Suchen"
        single-line
        hide-details
        class="pt-0"
      ></v-text-field>
    </v-card-title>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="users"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50]
      }"
      :items-per-page="30"
      :single-select="singleSelect"
      :search="search"
      item-key="id"
      class="elevation-1"
    >

    <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="item in items"
                :key="item.id"
              >
                <!--
                <td>
                <v-checkbox
                style="margin:0px;padding:0px"
                hide-details
                />
                </td>
                -->
                <td>{{ item.id }}</td>
                <td>
                  <router-link :to="'/'+$home+'node/user/' + item.id" text small>
                  {{ item.name }}
                  </router-link>
                </td>              
                <td>{{ item.ldap_id }}</td>
                <td v-html="item.role_name" class="p"></td>                
                <td v-html="item.team_name" class="p"></td>                
            
              </tr>
            </tbody>
          </template>

    <!--
    <template v-slot:top>
      <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
    </template>
    -->


    </v-data-table>

  </v-card>
</template>

<script>
export default {
  name: 'AdminTestFields',
  props: ['ct'],
  data () {
    return {
      singleSelect: false,
      selected: [],
      search: '',
      headers: [
        { text: 'ID',
          align: 'start',
          value: 'id'
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'LDAP ID',
          value: 'ldap_id',
        },
        {
          text: 'Rolle',
          value: 'role_name'
        },
        {
          text: 'Team',
          value: 'team_name'
        }
      ],
      users: [],
      overlaydata: null
    }
  },
  methods: {
      getUsers() {
          console.log("Fkt getUsers");
          this.axios.get( this.$config.host+"/users" ).then(response => (
            this.users = response.data,
            console.log(this.users)
          ));
      }
  },
  mounted() {

    this.getUsers();

  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  margin:0 0 40px 0;
  padding: 40px;
  background-color:#f5f5f5;
}
.item {
  display:block;
  width:100%;
  background-color:lightblue;
  margin-bottom:4px;
}
.p >>> p {
  margin:0;
}
a {
  color: #42b983;
}
</style>
