<template>
  <div class="sidemenu overflow-y-auto" id="scroll-target-l">
  <v-list dense
    v-scroll:#scroll-target-l="onScroll" class="listwrap"
  >

        <v-subheader><v-icon class="ml-2 mr-4">mdi-chart-pie</v-icon> FORSCHUNGSFELDER</v-subheader>
  
        <v-list-item-group>
            <v-list-item
                v-for="(item, i) in selection.areas"
                :key="i"
                link
                color="#fff"
                @click="clickArea(item.name)"
              >            
              <v-list-item-icon v-if="item.active">
                  <v-icon v-text="'mdi-toggle-switch'" class="active"></v-icon>
              </v-list-item-icon>
              <v-list-item-icon v-else>
                  <v-icon v-text="'mdi-toggle-switch-off'"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
        </v-list-item-group>
<!--
  <v-subheader><v-icon class="ml-2 mr-4">mdi-chart-pie</v-icon> BEREICHE</v-subheader>

  
      <v-list-item-group>
          <v-list-item
              v-for="(item, i) in selection.areas"
              :key="i"
              link
              color="#fff"
              @click="clickArea(item.name)"
            >            
            <v-list-item-icon v-if="item.active">
                <v-icon v-text="'mdi-toggle-switch'" class="active"></v-icon>
            </v-list-item-icon>
            <v-list-item-icon v-else>
                <v-icon v-text="'mdi-toggle-switch-off'"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
      </v-list-item-group>
-->
      <v-subheader><v-icon class="ml-2 mr-4">mdi-microscope</v-icon> KATEGORIEN</v-subheader>

      <v-list-item-group>
          <v-list-item
              v-for="(item, i) in kategorien"
              :key="i"
              link
              color="#fff"
              @click="selection[item.value] = !selection[item.value]"
            >
            <v-list-item-icon v-if="selection[item.value]">
                <v-icon v-text="item.iconActive" class="active"></v-icon>
            </v-list-item-icon>
            <v-list-item-icon v-else>
                <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>              
                <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
      </v-list-item-group>

      <v-subheader><v-icon class="ml-2 mr-4">mdi-map-marker-radius</v-icon> PARTNER</v-subheader>

      <v-list-item-group>        
          <v-list-item
              v-for="(item, i) in selection.partners"
              :key="i"                            
              color="#fff"
              @click="clickPartner(item.name)"
            >            
            <v-list-item-icon v-if="item.active">
                <v-icon v-text="'mdi-toggle-switch'" class="active"></v-icon>
            </v-list-item-icon>
            <v-list-item-icon v-else>
                <v-icon v-text="'mdi-toggle-switch-off'"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>          
      </v-list-item-group>
      
    </v-list>

  </div>
</template>

<script>

export default {
  name: 'SideMenuFront',

  components: {

  },
  props: ['selection'],
  data() {
    return {
      bereiche: [
        { title: 'Digitale Integration', value: 'Digitale Integration', icon: 'mdi-toggle-switch-off', iconActive: 'mdi-toggle-switch', usertype: 'admin', active: true },
        { title: 'Leichtbau', value: 'Leichtbau', icon: 'mdi-toggle-switch-off', iconActive: 'mdi-toggle-switch', usertype: 'admin', active: false },
        { title: 'Life Sciences', value: 'Life Sciences', icon: 'mdi-toggle-switch-off', iconActive: 'mdi-toggle-switch', usertype: 'admin', active: false }
      ],
      kategorien: [
        { title: 'Testfelder', value: 'field', icon: 'mdi-toggle-switch-off', iconActive: 'mdi-toggle-switch', usertype: 'admin', active: true },
        { title: 'Geräte/Services', value: 'device', icon: 'mdi-toggle-switch-off', iconActive: 'mdi-toggle-switch', usertype: 'admin', active: false },
        { title: 'Anwendungsfälle', value: 'usecase', icon: 'mdi-toggle-switch-off', iconActive: 'mdi-toggle-switch', usertype: 'admin', active: false }
      ],
      partner: [
        { title: 'TH Wildau', value: 'TH Wildau', icon: 'mdi-toggle-switch-off', iconActive: 'mdi-toggle-switch', usertype: 'admin' },
        { title: 'BTU Cottbus', value: 'BTU', icon: 'mdi-toggle-switch-off', iconActive: 'mdi-toggle-switch', usertype: 'admin' },
        { title: 'Frauenhofer', value: 'Frauenhofer', icon: 'mdi-toggle-switch-off', iconActive: 'mdi-toggle-switch', usertype: 'admin' }
      ]
    }
  },
  methods: {
      onScroll () {
          //console.log("scrolling");
      },
      clickPartner (val) {                  
        let index = this.selection.partners.findIndex(el => el.name == val);           
        this.selection.partners[index].active = this.selection.partners[index].active == false ? true : false;
      },
      clickArea (val) {                  
        let index = this.selection.areas.findIndex(el => el.name == val);                              
        this.selection.areas[index].active = this.selection.areas[index].active == false ? true : false;
      }      

  },
  computed: {
      user() {
        return this.$store.state.user
      }
  },
  mounted() {
      //console.log("selection in menuFront");
      //console.log(this.selection);
  },
  watch: {
    selection(val) {
      console.log("watch selection in menuFront");
      console.log(val);
    }
  }

};
</script>

<style scoped>
.listwrap {
  margin-top:30px;
}
.v-list {
  background-color:transparent;
}
.theme--light.v-icon,
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  text-decoration: none;
  color:rgba(255, 255, 255, 0.87) !important;
}
.v-list-item__icon {
  margin-right:16px !important;
}

.theme--light.v-icon.active {
  color:orange !important;
}
.theme--light.v-subheader {
    color:rgba(255, 255, 255, 1) !important;
    font-weight: bold;
    font-size:0.9rem;
    margin-top:1.8rem;
}
.overflow-y-auto {
   height:100%;
}
.sidemenu {
  position:fixed;
  background-color:#575756;
  width:16%;
  scrollbar-width: none;
}
</style>
