<template>
  <div>
    <!-- Location -->
    <div v-if="locationcurrent"> 
        <div class="radarcontainer" v-if="window.width && window.width > 660"
          :class="{ expanded: expand }" 
          :style=" (expand && window.height < 660) ?  'height: '+ window.height + 'px;width: ' + window.height + 'px; margin-left: ' + (window.height/2)*-1 + 'px; margin-bottom: ' + ((window.height/2)+30)*-1 + 'px' : 'border: none;' "
          >             
            <div class="inner">
              <Logo v-if="$template !== 'icampus'" class="logocomp" :locationkey="locationcurrent.loc_key"></Logo>

              <v-btn 
                v-if="expand"
                class="collaps"
                depressed
                tile
                small
                color="rgba(255,255,255,0.7)"
                @click="toggleexpand"
                >       
                <v-icon>mdi-arrow-collapse</v-icon>                
              </v-btn>
              <v-btn 
                v-else
                class="expand"
                depressed
                tile
                small
                color="rgba(255,255,255,0.7)"
                @click="toggleexpand"
                >       
                <v-icon>mdi-arrow-expand</v-icon>
              </v-btn>                
          
              <!-- Level per unit -->              
              <div class="levels">              
                <div
                  v-for="(level, i) in locationdata"
                  :key="i"
                >
                  <div
                    :title="level.name"
                    v-if="level.unit_id == locationcurrent.unit_id"
                    >                                      
                    <span 
                      @click="switchLevel(level)" 
                      class="level"
                      :class="[ activeLevelId == level.id ? 'active' : '' ]"
                      v-if="level.panos"
                      >{{ level.level }}</span>                  
                  </div>
                </div>
              </div>

              <div class="alt" v-if="navLevel">
                
                <img :src="navLevel.src" class="loc" />
                <div
                  v-for="(item, i) in navLevel.panos"
                  :key="i"
                  >
                  <v-tooltip top color="#333">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="redspot"                      
                      v-bind="attrs"
                      v-on="on" 
                      :style="'top:'+(item.loc_y*100)+'%;left:'+(item.loc_x*100)+'%'"
                      @click="switchPanoOff(item.id)"                      
                      >                
                      <img src="../../assets/spotorange.svg" />
                    </div>
                  </template>
                  <span>{{ item.name }}</span>
                  </v-tooltip>                                          
                </div>
                <div v-html="navLevel.name" class="locname"></div>

              </div>

              <div class="active" v-else>                            
                <img :src="panocurrent.loc_src" class="loc" />                
                <div
                  v-for="(item) in panocurrent.location"
                  :key="item.pid/1001"
                  >
                  <v-tooltip top color="#333">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="redspot"
                      v-bind="attrs"
                      v-on="on" 
                      v-if="item.published"
                      :style="'top:'+(item.y*100)+'%;left:'+(item.x*100)+'%'"
                      @click="switchPanoOff(item.pid)"                      
                      >                      
                      <img src="../../assets/spotorange.svg" />
                    </div>
                   </template>
                  <span>{{ item.name }}</span>
                  </v-tooltip>                                           
                </div>
                <div v-html="panocurrent.loc_name" class="locname"></div>

                <div class="radar"               
                >                
                  <div :style="'top:'+(panocurrent.loc_y*100)+'%;left:'+(panocurrent.loc_x*100)+'%'" >
                    <div class="radarshow" :style="`transform: rotate(${ (position+45) }deg);`">
                    <img src="../../assets/radar_white.png" style="max-width:100%;" />
                    </div>
                  </div>
                </div>

              </div>
            </div>
        </div>

        <div class="radarcontainer" v-else
          :class="{ expanded: expand }" 
          :style=" (expand && window.width < 660) ?  'height: '+ window.width + 'px;width: ' + window.width + 'px; margin-left: ' + (window.width/2)*-1 + 'px; margin-bottom: ' + ((window.width/2)+30)*-1 + 'px' : 'border: none;' "
          >                    
            <div class="inner"> 
              <Logo v-if="$template !== 'icampus'" class="logocomp"></Logo>
              
              <v-btn 
                v-if="expand"
                class="collaps"
                depressed
                tile
                small
                color="rgba(255,255,255,0.7)"
                @click="toggleexpand"
                >       
                <v-icon>mdi-arrow-collapse</v-icon>                
              </v-btn>
              <v-btn 
                v-else
                class="expand"
                depressed
                tile
                small
                color="rgba(255,255,255,0.7)"
                @click="toggleexpand"
                >       
                <v-icon>mdi-arrow-expand</v-icon>
              </v-btn>                
                            
              <img :src="panocurrent.loc_src" class="loc" />
              <div
                v-for="(item, i) in panocurrent.location"
                :key="i"
                >                
                <div
                  class="redspot"
                  :style="'top:'+(item.y*100)+'%;left:'+(item.x*100)+'%'"
                  @click="switchPanoOff(item.pid)"
                  :title="item.name"
                  >                                
                  <img src="../../assets/spotorange.svg" />
                </div>              
              </div>
              <div v-html="panocurrent.loc_name" class="locname"></div>

              <div class="radar"               
              >           
                <div :style="'top:'+(panocurrent.loc_y*100)+'%;left:'+(panocurrent.loc_x*100)+'%'" >
                  <div class="radarshow" :style="`transform: rotate(${(position+45)}deg);`"><!-- -56 ?-->
                  <img src="../../assets/radar_white.png" style="max-width:100%;" />
                  </div>
                </div>
              </div>
            </div>
        </div>

    </div>
  </div>
</template>

<script>

//import { useWindowSize } from 'vue-window-size';
import Logo from '../../components/elements/Logo';

export default {
  name: 'PanoRadar',
  props: ['locationdata', 'panocurrent', 'position', 'map'],  
  components: {
    Logo
  },
  /*
  setup() {
    console.log("hier setup!");
    const { width, height } = useWindowSize();
    return {
      windowWidth: width,
      windowHeight: height,
    };
  },
  */  
  data () {
    return {    
      locationcurrent: null,
      expand: false,
      radarheight: 0,      
      //windowWidth: null,
      //windowheight:0,      
      navLevel: null,
      activeLevelId: null,
      window: {
            width: 0,
            height: 0
        }
    }
  },
  methods: {
    getCurrentLocation () {                                
        var value = this.panocurrent;
        this.locationcurrent = this.locationdata.find(loc => loc.id == value.loc_id);                        
        this.activeLevelId = this.locationcurrent.id;          
    },
    switchPanoOff (panoid) {
      this.navLevel = null;
      this.expand = false;
      this.$emit('switchPanoOff', panoid);
    },
    switchLevel (level) {
      this.activeLevelId = level.id;
      if(level.id == this.panocurrent.loc_id) {
        this.navLevel = null 
      } else {
        this.navLevel = level
      }      
    },
    toggleexpand () {
      var self = this;
      this.expand = !this.expand;
      if(this.expand) {
        setTimeout(function(){
          self.windowSize();
        }, 400);        
      }
    },
    windowSize () {      
      //console.log("set radar size");      
      //console.log(this.radarheight);               
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    }

  },
  watch: {
    panocurrent: {            
      //immediate: true, 
      handler (e) {          
        this.panocurrent = e;
        this.getCurrentLocation();        
      }
    },
    locationdata: {
      handler () {
          console.log("watch locationdata")
      }    
    }
  },
  computed: {    
    /*
    _pos () {                        
        //console.log((this.position.yView*-57)+90);              
        console.log("set live angle");        
        return window.getPos();
        //return ((this.position.yView*-57)+90)+(this.panocurrent.zoom*-1);
    } 
    */    
  },
  created () {        
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  mounted () {  
    //console.log("map?");
    //console.log(this.map);    
    if(this.map == 1) this.expand = true;

    this.getCurrentLocation();        
    //window.addEventListener("resize", this.windowSize);
    //this.windowSize();
  }  
}
</script>

<style>

.logocomp {
  position: absolute;
  top:0;
  left:0;
  z-index:9;
  padding:6px 10px;
  background-color: rgba(255,255,255,0.6);
}
.expanded .logocomp {
  padding:10px;
  background-color: rgba(255,255,255,0.6);
}

.radarcontainer {
  position:fixed;
  left:20px;
  bottom:20px;
  width:120px;
  height:120px;
  z-index:5;  
  margin-left:0;
  transition: all .4s;  
}
.radarcontainer.expanded {  
  left:50%;
  bottom:50%;  
  width:680px;
  height:680px;
  margin-bottom:-370px;
  margin-left:-340px;
  display: table;
  padding:40px;  
}
.radarcontainer.expanded .inner {  
  display: table-cell;
  vertical-align: bottom;
  position:relative;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.25);
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.25);
}
.radarcontainer .loc {
  width:100%;
  max-height:100%;
  opacity:0.7;
}
.radarcontainer.expanded .loc {
  opacity:0.92;
}
.radarcontainer .redspot {
  position:absolute;
  width:18px;
  z-index:10;
  margin-left:-9px;
  margin-top:-9px;
  padding:4px;
  line-height:0;
}
.radarcontainer .redspot:hover {
  cursor:pointer;
}
.radarcontainer .redspot img {
  width:100%;
  height:auto;
}

.radarcontainer .locname {
  position:absolute;
  left:0;
  bottom:0;
  font-size:0.82rem;
  padding:4px 10px;
}
.radarcontainer .levels {
  position:absolute;
  right:4px;
  bottom:38px;
  font-size:0.82rem;
  padding:0;  
  width:30px;
  z-index:99;
  text-align:center;  
}
.radarcontainer .level {
  display:block;
  background-color:#ffffff;
  padding:4px 6px;
  margin:0;
  cursor: pointer;
}
.radarcontainer .level.active {
  background-color:#dddddd;
  cursor: default;
  font-weight:600;
}
.radar {
  position:absolute;
  z-index:9;
  width:100%;
  height:100%;
  top:0;
  left:0;
  pointer-events: none;
}
.radarcontainer.expanded .radar {  
  display:none;
}
.radarcontainer.expanded .levels {

  bottom:6px;
}
.radar > div {
  position:absolute;
  margin-left:-50%;
  margin-top:-50%;
  width:100%;
  height:100%;
}
.expand {
  position: absolute;
  bottom:4px;
  right:4px;
  width:30px;
  height:36px;
  min-width:30px !important;
  padding:0 !important;
  z-index:99;
}
.collaps {
  position: absolute;
  top:0px;
  right:-44px;
  width:40px;
  height:40px;
  min-width:40px !important;
  padding:0 !important;
  z-index:99;
}

@media only screen and (min-width: 760px) {
    .radarcontainer {
      width:240px;
      height:240px;
    }
    .radar {
      /*
      position:fixed;
      z-index:9;
      left:20px;
      width:240px;
      height:240px;
      bottom:20px;
      pointer-events: none;
      */
    } 
}

</style>
