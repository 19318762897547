<template>
  <div class="floatmenu">

    <v-card
      class="pa-0"      
    >
        <v-navigation-drawer                              
          permanent
          expand-on-hover
          width="auto"
          floating
        >
          <v-list
          nav             
            dense      
            rounded
          >
            <v-list-item>              
              <v-list-item-icon class="pt-0 pl-2">
                <v-icon>mdi-clipboard-list-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Testfelder</v-list-item-title>                
              </v-list-item-content>
            </v-list-item>
          </v-list>
          
          <v-list
            nav
            dense
            class="listcontent"
          >
            <v-list-item
              v-for="item in items"
              :key="item.title"
              link
            >
              <v-list-item-icon>
                <v-icon> </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  <router-link :to="'/p/field/'+item.id">{{ item.title }}</router-link>
                </v-list-item-title>                                
              </v-list-item-content>

            </v-list-item>
          </v-list>

        </v-navigation-drawer>

    </v-card>

    <!--

      <v-navigation-drawer
        app
        clipped
        left
        v-model="drawer.visible"
      >
        <template v-slot:prepend>
          <v-list-item two-line class="avatarwrap">
            <v-list-item-avatar>    
              <img src="../assets/testuser.png">
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>Testuser</v-list-item-title>
              <v-list-item-subtitle><i>angemeldet</i></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-divider></v-divider>

        <v-list dense>
          <v-list-item
            v-for="item in items"
            :key="item.title"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
              <router-link :to="{ name: item.link }">
              {{ item.title }}
              </router-link>
              </v-list-item-title>

            </v-list-item-content>
          </v-list-item>
        </v-list>


        <v-list dense>

          <v-list-item
          >
            <v-list-item-icon>
              <v-icon>mdi-map-marker</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
              <router-link :to="'/content/poi'">Meine Infopunkte</router-link>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
          >
            <v-list-item-icon>
              <v-icon></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
              <router-link :to="'/node/add/poi'">Info hinzufügen</router-link>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list>

        <v-list dense>
          <v-list-item
            v-for="item in items2"
            :key="item.title"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
              <router-link :to="{ name: item.link }">
              {{ item.title }}
              </router-link>
              </v-list-item-title>

            </v-list-item-content>
          </v-list-item>
        </v-list>

      </v-navigation-drawer>

    -->

  </div>

</template>

<script>
  export default {
    props: ['drawer'],
    data () {
      return {
        items0: [
          { title: 'Home', icon: 'mdi-view-dashboard' },
          { title: 'About', icon: 'mdi-forum' },
        ],
        // Home
        items: [
        ],
        nodetype: this.$route.params.nodetype,
      }
    },
  methods: {
      getTestfields() {
          console.log("Fkt getTestfields");
          this.axios.get( this.$config.host+"/testfields" ).then(response => (
            this.items = response.data,
            console.log("testfelder in sidemenu"),
            console.log(this.items)
          ));
      }  
  },
  mounted() {
    this.getTestfields();

  }    
  }
</script>

<style>
.v-navigation-drawer {
  background-color:transparent !important;
}

.avatarwrap {
  padding:0 9px;
}
.floatmenu {
  z-index:999;
  top:70px;
  position:fixed;
  width:auto !important;
}
.floatmenu .v-list {
  
}
.floatmenu .v-list:first-child {
  /*background-color:#ffffff;  */
  padding: 8px 0 2px;
}
.floatmenu,
.floatmenu > div {
    background-color:transparent !important;
}
.floatmenu .v-list-item__icon {
  
}
.floatmenu .v-list-item__title {
  font-size:1rem !important;
}
.floatmenu .v-list-item__title a {
  color: rgba(0, 0, 0, 0.87);
  font-weight:400;
}
.floatmenu .v-list-item__title a.router-link-active {


}
.floatmenu .v-expansion-panel {
  box-shadow: none;
}
.v-navigation-drawer--is-mouseover {
  
}
.v-navigation-drawer,
.v-navigation-drawer__content {
  border-right:0px solid orange;
}
.v-navigation-drawer--is-mouseover .v-navigation-drawer__content {
  background-color:#ffffff;  
}


</style>
