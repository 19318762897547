<template>
    <div id="v-pano" class="v-pano"></div>    
</template>

<script>

import * as Panolens from 'panolens';
//import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader';
import * as THREE from 'three';
//import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

export default {
  name: 'PanoMeSingle',
  components: {
    //Loading
  },

  props: {
    unlocked: {
      type: Boolean
    },
    source: {
      type: Array,
      default: function () { return [] }
    },
    queryid: {
      //type: String
      type: Number
    },
    sourceSingle: {
      type: Object
    },
    type: {
      type: String,
      //default: 'video'
      default: 'image'
      //default: 'cube'
    },
    width: {
      type: Number,
      default: 100
    },
    height: {
      type: Number,
      default: 100
    },
    password: {
      type: String
    }
  },

  data () {
    return {
      size: {
        width: this.width,
        height: this.height
      },      
      viewer: null,
      scene: null,
      panorama: null,
      thisSource: null,
      state: null,
      panotitle: null,
      panoinfo: null,
      panocurrent: null,
      infospots: [],
      pos: {
        x: 0,
        y: 0,
        xView: 0,
        yView: 0
      }
    }
  },

  created () {
    window.addEventListener('resize', this.onResize, false);
  },

  destroyed () {
    if (this.panorama && this.panorama.dispose) {
      this.panorama.dispose()
    }
  },

  mounted () {

    if (this.width === undefined || this.height === undefined) {
      this.size = {
        width: this.$el.offsetWidth,
        height: this.$el.offsetHeight
      }
    }

    this.viewer = new Panolens.Viewer({
      output: 'console',
      container: this.$el,
      cameraFov: 104,
      controlBar: true,
      //controlButtons: ['setting', 'video' ],
      controlButtons: ['video' ],
      dwellTime: 1500,
      autoHideInfospot: false
    });
    
    //console.log("mounted panomesingle and loadPano");

    this.loadPano();
    this.onResize();
    this.listen();

  },
  watch: {
    source () {
      if (!this.viewer) return
      if (!this.panorama) return
      //console.log("watch pano source an loadPano");
      this.loadPano()
    },    
    unlocked (v) {
      console.log("unlocked watched!");
      console.log(v);
    }    
  },

  methods: {

    onResize () {

      //this.viewer.onWindowResize( width, height )
      //console.log(this.viewer.container._width);

      if (this.width === undefined || this.height === undefined) {      
        this.$nextTick(() => {
          this.size = {
            width: this.$el.offsetWidth,
            height: this.$el.offsetHeight
          }
        })
      }
      if(document.querySelector("div.v-pano")) {
        //document.querySelector("div.v-pano").style.height = (window.innerHeight - 50) + 'px';
      }
    },

    listen () {      
      this.state = setInterval(() => {                    
          this.pos.x = this.viewer.camera.position.x;
          this.pos.y = this.viewer.camera.position.y;
          this.pos.xView = this.viewer.camera.rotation.x;
          //this.pos.yView = this.viewer.camera.rotation.y - (this.thisSource.zoom / 10);
          this.pos.yView = this.viewer.camera.rotation.y;          
          this.$emit('state', this.pos);
      }, 1000);
    },

    zoom (dir) {
      
      console.log("currentZoom: ");
      console.log(this.viewer.camera.fov);

      var newZoom = this.viewer.camera.fov;

      if(dir === 0) {
        newZoom = newZoom + 10;
        if(newZoom > 140) newZoom = 140;
        this.viewer.setCameraFov(newZoom);
      } else if (dir === 1) {
        newZoom = newZoom - 10;        
        if(newZoom < 50) newZoom = 50;
        this.viewer.setCameraFov(newZoom);
      }

    },

    getMousePosition() {
          //let rect = canvas.getBoundingClientRect();
          //let x = event.clientX - rect.left;
          //let y = event.clientY - rect.top;
          //console.log("Coordinate x: " + x, "Coordinate y: " + y);
    },

    loadPano (item) {

          if (!this.source) return

          let pano

          /**
           * Singe item is set
           */
          if(item) {

            console.log("item set");

            this.thisSource = item;            

          } 
          
          /**
           * Singe item is set
           */
          else if(this.sourceSingle) {

            console.log("sourceSinge set");

            this.thisSource = this.sourceSingle;

          } 
          
          /**
           * Query id is set
           */
          else {

            if(this.queryid) {

                //console.log("query id is set");
                
                let queryObj = this.source.find(pano => pano.id == this.queryid);

                /**
                 * Find locked pano file
                 */
                if(queryObj && queryObj.locked === true) {
                
                  this.$emit("unlock", this.queryid);
                  this.thisSource = this.source[0];

                } else {

                  this.thisSource = queryObj;

                }                            

            /**
             * No id is set; get first item out of array
             */
            } else {

                //console.log("nothing set:");

                this.thisSource = this.source[0];

            }

          }

          switch (this.type) {
            case 'video':
              //console.log("yes, video!")
              pano = new Panolens.VideoPanorama(this.thisSource.src, { autoplay: true , loop: false, }, {radius: 1000})
              break
            default:
              pano = new Panolens.ImagePanorama(this.thisSource.src)
              /*
              pano = new Panolens.CubePanorama([              
                '/pano/cube/halle-14-hoersaal_r.jpg',
                '/pano/cube/halle-14-hoersaal_l.jpg',                
                '/pano/cube/halle-14-hoersaal_u.jpg',
                '/pano/cube/halle-14-hoersaal_d.jpg',                                                                
                '/pano/cube/halle-14-hoersaal_f.jpg',
                '/pano/cube/halle-14-hoersaal_b.jpg'              
              ])
              */
              break
          }

          Panolens.Viewer.prototype.setControlss = function () {
            console.log("inside viewer setControls:");
            console.log(this.controlBar);

          }
          
          Panolens.Viewer.prototype.getPosition = function () {

              var intersects, point, panoramaWorldPosition, outputPosition;
              intersects = this.raycaster.intersectObject( this.panorama, true );

              if ( intersects.length > 0 ) {
                  point = intersects[0].point;
                  panoramaWorldPosition = this.panorama.getWorldPosition();

                  // Panorama is scaled -1 on X axis
                  outputPosition = new THREE.Vector3(
                  -(point.x - panoramaWorldPosition.x).toFixed(2),
                  (point.y - panoramaWorldPosition.y).toFixed(2),
                  (point.z - panoramaWorldPosition.z).toFixed(2)
                  );
              }

              return outputPosition;

          };
          
          Panolens.Infospot.prototype.hideTitle = function () {
            
            if(this.element) {
              this.element.style.display = 'none';
            }               

          };

          /*
          Panolens.Infospot.prototype.getLogoPosition = function (  ) {
                                        
              if( this.element ) {                
                //console.log(this);
                //console.log(this.element.offsetLeft);
                //console.log(this.element.left);
                //console.log(this.element.left.clientWidth);

              }

              //console.log(this);
              if ( !this.element ) { 
            
                  //this.element = el.cloneNode( true );                                    
                  //this.element.style.display = 'none';
                  //this.element.style.top = 0;
                  //this.element.style.position = 'absolute';
                  //this.element.classList.add( 'panolens-infospot' );
                  //this.element.verticalDelta = delta !== undefined ? delta : 40;                  

              }

          };
          */

          //Panolens.Infospot.prototype.onHoverStart = function ( ) {

            //console.log("ON HOVER START");

            //return false;

          //};          

          const that = this

          /*
          let canvasElem = document.querySelector("canvas");
          canvasElem.addEventListener("mousedown", function(e) {
                that.getMousePosition(canvasElem, e);
          });
          */

          // Only in admin UI:
          if(this.sourceSingle) {

              pano.addEventListener( 'click', function(){
                  //Get pano lookat
                  //var werthier = that.viewer.OrbitControls.getLastPosition();

                  //Send position to parent:
                  that.$emit('getPosition', that.viewer.getPosition().x, that.viewer.getPosition().y, that.viewer.getPosition().z);

              });

          }

          pano.addEventListener( 'enter-fade-start', function(){
              //that.viewer.tweenControlCenter( lookAtPositions[2], 2000);
          });

          // Load infospots
          if(this.thisSource.spots) {

            for (var i= 0; i < this.thisSource.spots.length; i++) {

                var icon = '';

                if(!this.sourceSingle) {

                  if(this.thisSource.spots[i].file == "advent-candles.png") {
                    icon = '/vr/img/advent-candles.png';
                  } else {
                    icon = 'https://icampus.th-wildau.de/vr/img/' + this.thisSource.spots[i].file;
                  }
                  
                } else {

                  icon = '/'+this.$home+'files/images/red-dot.png';

                }

                this.loadInfospot(pano,icon,i);

            }

          }

          // Load basic logo:
          this.loadInfospot(pano, '/graf/innohub13.jpg', 'x');
          
          //pano.add( cube );
          //pano.link( pano2, infospotPositions[1], 1000);
          //pano2.link( pano, infospotPositions[0], 1000 );

          function firePano() {
            //console.log("fire pano");
            that.setPano(pano);      
            that.$emit('panocurrent', that.thisSource);
            that.$emit('loaded', true);
          }

          setTimeout(firePano, 800);

    },

    loadInfospot: function(pano,icon,i) {

        var that = this;
        var spotscale;
        var innerspot;
        var textureLoader = new THREE.TextureLoader();
        textureLoader.minFilter = THREE.LinearFilter;
        if(i == 'x' ) {

          //textureLoader.load(icon, function(){

            
            spotscale = 1000;

            innerspot = new Panolens.Infospot(
              spotscale,
              icon,
              true
            );

            //console.log("icon:");
            //console.log(icon);
            //console.log("innerspot");            

            //innerspot.position.set(0, -900, 0); // x, y, z
            // z: in die Tiefe und nach vorn
            // x: nach vorn nah und fern

            innerspot.position.set(0, -300, 0);

            //console.log(innerspot);
          
            //pano.add( innerspot );

            return pano;            

          //});

        } else {

          textureLoader.load(icon, function(){

            // Scale WiWo Icons
            var wiwo = [7,17,18,19,20,21,22];            
            var titlepreview;

            spotscale = 1000;

            if(that.thisSource.spots[i].icon == 1 || that.thisSource.spots[i].icon == 10) {
              spotscale = 600;
            }
            if(wiwo.includes(that.thisSource.spots[i].icon)) {
              spotscale = 500;
            }

            innerspot = new Panolens.Infospot(
              spotscale,
              icon,
              true              
            );

            if( that.thisSource.spots[i].linktitle.length > 100 ){
              titlepreview = that.thisSource.spots[i].linktitle.substring(0, 100).trimEnd() + "..."
            } else {
              titlepreview = that.thisSource.spots[i].linktitle
            }

            innerspot.position.set(that.thisSource.spots[i].x, that.thisSource.spots[i].y, that.thisSource.spots[i].z);

            if( that.thisSource.spots[i].icon !== 23 ) {
              innerspot.addHoverText( titlepreview , 80 );
            }            

            // Add link to spot:
            innerspot.link = that.thisSource.spots[i].link;
          
            // Add title and body to spot:        
            innerspot.title = that.thisSource.spots[i].linktitle;
            innerspot.body = that.thisSource.spots[i].body;
            innerspot.spotid = that.thisSource.spots[i].id;
            innerspot.icon = that.thisSource.spots[i].icon;
            innerspot.tours = that.thisSource.spots[i].tours;
            innerspot.cat = that.thisSource.spots[i].cat;
            innerspot.pdfpath = that.thisSource.spots[i].pdfpath;
            //innerspot.titlepreview = titlepreview;
                                
            //Add category to spot:
            innerspot.category = that.thisSource.spots[i].icon;

            if(!that.sourceSingle) {

                if (that.thisSource.spots[i].icon !== 23) {

                  innerspot.addEventListener( "click", function(){
                  
                  //console.log("click and spot:");
                  //console.log(that.thisSource.spots[i].icon);

                  if(this.link != null) {

                    this.setText("");
                    that.switchPano(this.link);

                  } else {
                    
                    this.hideTitle();
                    that.onClickButton(this.title, this.body, this.spotid, this.icon, this.tours, this.cat, this.pdfpath);

                  }
                });

                }

                innerspot.addEventListener( "hoverenter", function(e){

                  if(wiwo.includes(that.thisSource.spots[i].icon)) {
                    e.target.element.classList.add("markedtext");
                  }                  

                  if(this.icon === 23) {

                    this.scale.x = 520;
                    this.scale.y = 520;                          

                  } else {

                    this.scale.x = 700;
                    this.scale.y = 700;                          

                  }

                });

                innerspot.addEventListener( "hoverleave", function(){
                  this.scale.x = 600;
                  this.scale.y = 600;
                  this.hideTitle();
                  //document.querySelector('.panolens-infospot').style.display = 'none !important';
                });
            }

            that.infospots.push(innerspot);

            if(that.thisSource.spots[i].published == true) {
              
              pano.add( innerspot );

            }
            
            return pano;

          });

        }
    },

    getPos () {
      //return this.viewer.camera.position;
      //requestAnimationFrame( this.getPos );
    },

    setPano (pano) {

      const that = this;

      if (!pano) return        
        //console.log(this.panorama);
      if (this.panorama) {        
        
        //this.viewer.remove(this.panorama)
      }
      
      //var loader = new THREE.TextureLoader();      
      //loader.load('/'+this.$home+'img/th-wildau.png', function (  ) {
      //loader.load('/img/th-wildau.png', function ( texture ) {

          that.panorama = pano;                

          //console.log(texture);

          /*

            var sizeLogoWidth = 260;
            var sizeLogoHeight = 240;
            
            var geometry = new THREE.PlaneGeometry( 80, 80 );
            
            var material = new THREE.MeshBasicMaterial( { 
                map:texture, 
                opacity : 1, 
                side : THREE.DoubleSide, 
                transparent : false  // Note, I also set this to false.
            } );           

            var sprite = new THREE.Mesh( geometry, material );

            // Offset the hotspot so it is below the view origin [Updated]
            //sprite.position.set(0,-5,5);
            sprite.position.set(0,0,0);

            // Compute the rotation for the hotspot, 90deg around the x-axis
            var rotation = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(1,0,0), 0);

            // Apply rotation to the hotspot so it "sits flat below the camera"
            sprite.rotation.setFromRotationMatrix(rotation);                              

            var panel = document.querySelector('#panelx');
            //var canvas = document.querySelector('#panel canvas');

            var renderer, camera, scene, logo; 

            logo = new Panolens.Infospot( 350, Panolens.DataImage.Info );          
            logo.position.set( 0, -2000, 0 );
            logo.addHoverElement( panel, 0 );          

            that.panorama.add( logo );

            // Generate logo canvas
            renderer = new THREE.WebGLRenderer({ alpha: true });          
            renderer.setClearColor(0xffff00, 0);                  
            renderer.setSize(sizeLogoWidth,sizeLogoHeight);          
            
            // Generate camera perspective
            camera = new THREE.PerspectiveCamera(140, 1.2, 1, 2000);
            //camera = new THREE.PerspectiveCamera(140, panel.clientWidth / panel.clientHeight, 1, 2000);
            //camera.position.set(0, 0, 0);

            // Generate scene
            scene = new THREE.Scene();
                      
            // Conect canvas with infospot
            if( canvas ) { canvas.remove(); }                  

            logo.element.appendChild( renderer.domElement );        
            //panel.appendChild( renderer.domElement );        

            // Set infospot size
            logo.element.style.top = (sizeLogoHeight/2)*-1 + "px";
            logo.element.style.left = (sizeLogoWidth/2)*-1 + "px"; 

            //var box = new THREE.Mesh(new THREE.BoxGeometry(10, 10, 10), new THREE.MeshNormalMaterial());
            sprite.position.z = -30;
            sprite.position.x = 0;
            sprite.position.y = 0;

            scene.add( sprite );          

            //console.log("VIEWER:");          
            //console.log(that.viewer);        
                                                                                                              
            that.viewer.addUpdateCallback(function(){
              //console.log("updatecallback");       
              //console.log(that.viewer.camera.matrixWorld.elements[0]); // rotation
              //console.log(that.viewer.camera.matrixWorld.elements[1]); // ??
              //console.log(that.viewer.camera.matrixWorld.elements[2]); // rotation
                        
              //console.log(that.viewer.camera.matrixWorld.elements[3]); // immer null
              //console.log(that.viewer.camera.matrixWorld.elements[4]); // rotation
              //console.log(that.viewer.camera.matrixWorld.elements[5]); // vertikaler Neigungswinkel!

              //console.log(that.viewer.camera.matrixWorld.elements[6]); // ??
              //console.log(that.viewer.camera.matrixWorld.elements[7]);  // immer null
              //console.log(that.viewer.camera.matrixWorld.elements[8]); ??

              //console.log(that.viewer.camera.matrixWorld.elements[9]); // vertikaler Neigungswinkel: direkt unten = 1
              //console.log(that.viewer.camera.matrixWorld.elements[10]);  // ??
              //console.log(that.viewer.camera.matrixWorld.elements[11]); // immer null

              //console.log(that.viewer.camera.matrixWorld.elements[12]); // ??
              //console.log(that.viewer.camera.matrixWorld.elements[13]);  // vertikaler Neigungswinkel: direkt unten = 1
              //console.log(that.viewer.camera.matrixWorld.elements[14]); // ??

              var pos = logo.getWorldPosition();              
              pos.project(that.viewer.camera);              
              let widthHalf = that.viewer.container._width / 2;
              let heightHalf = that.viewer.container._height / 2;
              pos.x = (pos.x * widthHalf) + widthHalf;
              pos.y = - (pos.y * heightHalf) + heightHalf;
              pos.z = 0;            

              var v = ( sizeLogoHeight / that.viewer.camera.fov );
              var x = v * sizeLogoWidth;
              var y = v * sizeLogoHeight;                
              renderer.setSize(x,y);
              if(logo && logo.element) {
                logo.element.style.left = ( pos.x + ((x/2)*-1) ) + "px"; 
                logo.element.style.top = ( pos.y + ((y/2)*-1) ) + "px";                   
              }            
                                    
              renderer.render(scene, camera);            
              //sprite.rotation.y += 0.001;  // horizontaler Winkel: 1.6 = 90°            
              //sprite.rotation.x += 0.002;  // vertikaler Neigungswinkel: 1.6 = 90°          
              //sprite.rotation.z += 0.002;  // 3 = 180° Rotation
              //console.log(sprite.rotation.x);
              //sprite.rotation.x = -0.4;
              //sprite.rotation.z = 1;

              
              //panel.style.transform = "translate(" + (that.viewer.camera.position.x * 100) + "px , " + (that.viewer.camera.position.y * 100) + "px)"
              
              //panel.style.right = (window.innerWidth * that.viewer.camera.position.x) + 'px';
              //panel.style.top = (window.innerHeight * that.viewer.camera.position.y) + 'px';
              //console.log("x: " + that.viewer.camera.position.x);
              //console.log("y: " + that.viewer.camera.position.y);

            });

            //++++++++++++          
          
          */
          
          that.viewer.add(that.panorama);                
          that.viewer.setPanorama(that.panorama);

          that.viewer.addEventListener( "mode-change", function(){
            console.log("hier mode change viewer fertig!");
            console.log(that.viewer.mode);
          });
                  
          that.panorama.addEventListener('load', () => {        
            /**
             * Set angle of view in panorama
             */
            that.setAngle();                
            //console.log("ready loading pano");
            that.$emit("loading", false);
          })


      //});    

      //console.log(this.panorama.getZoomLevel());
    },

    setAngle () {

        //let currentPos = this.viewer.camera.position;

        this.viewer.control.enabled = true;

        //console.log("viewer control:");
        //console.log(this.viewer.control);
        
        //this.viewer.OrbitControls.rotateLeft(20);

        // Options
        var lookAtPositions = [
          new THREE.Vector3(4000, 0, 0), // front  | 0
          new THREE.Vector3(2000, 0, -2000), // 45° right  | 1
          new THREE.Vector3(0, 0, -4000), // 90° right  | 2
          new THREE.Vector3(-2000, 0, -2000), // 135° right  | 3
          new THREE.Vector3(-4000, 0, 0), // back  | 4
          new THREE.Vector3(-2000, 0, 2000), // 135° left  | 5
          new THREE.Vector3(0, 0, 4000), // 90° left  | 6
          new THREE.Vector3(2000, 0, 2000), // 45° left  | 7

          new THREE.Vector3(3000, 0, -1000), // 22° right  | 8
          new THREE.Vector3(1000, 0, -3000), // 67° right  | 9
          new THREE.Vector3(-1000, 0, -3000), // 112° right  | 10
          new THREE.Vector3(-3000, 0, -1000), // 157° right  | 11
          new THREE.Vector3(-3000, 0, 1000), // 157° left  | 12
          new THREE.Vector3(-1000, 0, 3000), // 112° left  | 13
          new THREE.Vector3(1000, 0, 3000), // 67° left  | 14
          new THREE.Vector3(3000, 0, 1000), // 22° left  | 15
        ];

        if(this.thisSource.startview) {
           
          var sw = this.thisSource.startview;          
          //this.viewer.tweenControlCenter( lookAtPositions[sw], 1000);
          this.viewer.tweenControlCenter( lookAtPositions[sw], 200);

        } else {

          this.viewer.tweenControlCenter( lookAtPositions[0], 0);

        }

    },

    switchPano (item) {

        //console.log("switchPano!");

        let obj = this.source.find(pano => pano.id == item);
                
        // If Pano is set locked not locked yet

        //console.log("not unlocked yet?");
        //console.log(this.unlocked);

        if(obj != 0 && obj.locked === true && this.unlocked === false) {

          //console.log("not unlocked");

          this.$emit("unlock", item);

        } else {

          //console.log("unlocked and load pano!");

          this.$emit("loading", true);
          if(obj != 0) {
            this.loadPano(obj);
          } else {
            this.loadPano(this.source[0]);
          }

        }      

    },
    onClickButton (title, content, spotid, icon, tours, cat, pdf) {
        //console.log(content);
        // Set all .panolens-infospot display:none !
        document.querySelector(".panolens-infospot").style.display = 'none';
        this.$emit('clicked', title, content, spotid, icon, tours, cat, pdf)
    },

    /*
     *  Set infospots visibility
     */
    setSpots (value) {
        /*
        console.log("value to toggle:");
        console.log(value);
        console.log("get spot visibility:")
        console.log(this.infospots[0].visible);
        console.log("category?");
        console.log(this.infospots[0].category);
        */

        for (var i = 0; i < this.infospots.length; i++) {

          if(this.infospots[i].category == value) {

            if(this.infospots[i].visible == false) {
              this.infospots[i].visible = true;
            } else {
              this.infospots[i].visible = false;
            }

          }

        }
    }

   }
}

</script>

<style>
.v-pano {
  position:absolute;
  top:0;
  width: 100%;
  height: 100%;
  overflow:hidden;
  margin: 0;
  padding: 0;
  border: 0px solid red;
}

#v-pano div:not(.panolens-infospot) {
  z-index:5;
  position:relative;
}

.admincontent .v-pano {
  height:400px;
}
.v-dialog iframe {
  width:100%;
  height:450px;
}

.panolens-infospot {  
  /*max-width:400px !important;*/
}
.makeorange {
  border:4px solid orange;
}
.markedtext {
  padding:14px;
  background-color:#fff;
  font-size:0.88rem !important;
  color:#333 !important;
  border-radius: 4px;
  top:-10px !important;  
  text-shadow: none !important;
}

#panelx {
  width: 1px;
  height: 1px;  
  position: absolute;
  display:block !important;
  top:0;
  left:0;
}

</style>
