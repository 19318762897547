<template>
  <div>    
    <!--
        <v-tooltip top color="#333333">
          <template v-slot:activator="{ on, attrs }">        
          <v-btn
            class="mx-2 my-4 btn"
            color="#7DCEA0"
            @click="reloadframe"
            v-on="on"
            v-bind="attrs"          
          ><v-icon>mdi-reload</v-icon></v-btn>
          </template>
          <span>App neu laden</span>
        </v-tooltip>

        <v-tooltip top color="#333333">
          <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mx-2 my-4 btn-right"
            @click="sheet = !sheet"
            color="#F1948A"
            v-on="on"
            v-bind="attrs"                      
          ><v-icon>mdi-close</v-icon></v-btn>
          </template>
          <span>App schließen</span>
        </v-tooltip>
        -->

       <iframe width="100%" height="520px" :id="$cookies.get('USERID')" src="https://lesenaoappdev.innohub13.de"></iframe>
  </div>
</template>

<script>

  export default {
    data () {
      return {
        sheet: false,
        notifications: false,
        sound: true,
        widgets: false,
      }
    },
    watch: {
      sheet: function () {
        if (this.sheet == false) {
          console.log("Bottom sheet closed");          
        }
      }
    },    
    methods:{
      reloadframe() {
        var f = document.getElementById(this.$cookies.get('USERID'));
        var a = f.src;
        f.src = a;
        this.$emit('reloadApp');
        return false;
        //document.getElementById('naoapp').contentWindow.location.reload();
      },
      openApp() {
        console.log("hier klick open");
        this.$refs.openBtn.$el.click();
      },
      show () {
          console.log("SHOW APP");
          this.$modal.show('naoapp');
      },
      hide () {
        console.log("HIDE APP");
        this.$modal.hide('naoapp');
      },
      onOutside(){
        console.log("Klick outside! - sheet:");
        //console.log('sheet change to', val);
        //console.log(this.sheet);     
        //this.sheet = true;

      }
    }
  }

</script>

<style scoped>

.modalcontrols {
    height:33px;
}
.v-sheet iframe {
  border:0;
}
.btn-right {
  float:right;
}
.help {
  float:left;
  width:20%;
  display:block;
  height:600px;
  background-color:orange;
}
</style>

<style>
#mydiv iframe {
  border:0;
}
.dialogclass { 
  align-self:center;
  margin-right:28%;
  height:auto;
  margin-top:60px;
  margin-bottom:100px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.appSheet {
  border:0px solid blue;
  width:90%;
  margin:0 auto;
  box-shadow: 0 20px 60px -2px rgba(27, 33, 58, 0.4);
  overflow-y: scroll;
}
.appbtn {
  color:#ffffff;
  background-color: transparent !important;
}
/*
.vm--container {
    position: fixed;
    box-sizing: border-box;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
}
.vm--modal {
    position: absolute;
    overflow: visible;
    box-sizing: border-box;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0 20px 60px -2px rgba(27, 33, 58, 0.4);
    z-index: 9999 !important;
}
.vm--modal iframe {
    height:84%;
}
*/
.bottomnav {
  padding:10px 20px;
  position:fixed;
  right:0;
  bottom:20px;
}
.bottomnav > div {
  display:block;
  float:left;
}
.bottomnav .appbtn {
  margin-right:30px;
  padding:8px 0 !important;
  color:#ffffff;
}
.bottomnav .icon-image {
  padding:0 10px;
  height:40px;
}
</style>