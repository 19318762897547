<template>
  <div style="padding-top:2px">
    <div class="uc-nav">
      <v-btn-toggle                        
        borderless                    
        dense    
        class="mr-2 transparent"
      >
      <v-tooltip left color="#333333">
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
            value="right" 
            class="transparent"               
            v-on="on"
            v-bind="attrs"      
            @click="leave($route.path)"    
          >          
            <v-icon right class="mr-0" size="30" color="#e74c3c">
              mdi-exit-to-app
            </v-icon>
          </v-btn>          
        </template>
        <span>Anwendung verlassen</span>
      </v-tooltip>
      </v-btn-toggle>                                  
    </div>        
  </div>
</template>

<script>

//import { useWindowSize } from 'vue-window-size';

export default {
  name: 'UsecaseExit',
  props: ['url'],  
  data () {
    return {    

    }
  },
  methods: {
    leave (path) {

      switch (true) {

        case path.indexOf('nao-roboter') != -1:
                    
          this.$router.push("/p/usecase/2");

          break;

        case path.indexOf('chatbot') != -1:          

          this.$router.push("/p/usecase/4");

          break;          
      
        case path.indexOf('nlp-process') != -1:
          
          this.$router.push("/p/usecase/1");

          break;          

        case path.indexOf('/t/id/') != -1:

          var arr = path.split('/');
          if(arr[3]) {
            var num = Number(arr[3]);
            this.$router.push("/p/usecase/"+num);
          }

          break;          



        default:
          break;

      }
      console.log("LEAVE USECASE!");
      
    }
  },
  watch: {

  },
  created () {        

  },

  mounted () {  

  }
}
</script>

<style>

</style>
