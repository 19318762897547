<template>

    <div class="">

        <v-form
           id="nodeform"
           ref="form"
           v-model="valid"
           :lazy-validation="lazy">

            <!-- CARD ACTIONS -->

            <v-toolbar
              flat
              style="border-bottom:2px solid #ddd"              
              >
        
                <v-toolbar-title>Verantworliche/n bearbeiten</v-toolbar-title>
                  
                    <v-checkbox
                    v-model="node.published"
                    label="Veröffentlicht"
                    dense
                    class="check-published"
                    ></v-checkbox>
                  
                  <v-spacer></v-spacer>                    

                    <v-btn class="mr-4"
                    v-if="node.id"
                    :disabled="!valid"
                    @click="formSubmit"
                    style="display:inline-block"
                    >Aktualisieren</v-btn>

                    <v-btn class="mr-4"
                    v-else
                    :disabled="!valid"
                    @click="formSubmit"
                    style="display:inline-block"
                    >Speichern</v-btn>

                  
                    <v-btn-toggle                        
                      borderless                    
                      dense    
                      class="mr-2"
                      >
                      <v-btn      
                        icon
                        :to="'/'+$home+'content/'+nodetype"
                        >      
                        <v-icon>mdi-table-of-contents</v-icon>
                      </v-btn>

                      <v-btn 
                        class="small" 
                        value="right" 
                        :to="'/p/'+nodetype+'/'+nodeid" 
                        target="_blank" 
                        v-if="nodetype === 'usecase' || nodetype === 'testfield'"
                        >
                        <span class="hidden-sm-and-down">Preview</span>
                        <v-icon right>
                          mdi-open-in-new
                        </v-icon>
                      </v-btn>

                      <v-btn 
                        class="small" 
                        value="right" 
                        :to="'/panorama/?q='+nodeid" 
                        target="_blank" 
                        v-if="nodetype === 'pano'"
                        >
                        <span class="hidden-sm-and-down">Preview</span>
                        <v-icon right>
                          mdi-open-in-new
                        </v-icon>
                      </v-btn>

                    </v-btn-toggle>
  
            </v-toolbar>

            <!-- / CARD ACTIONS --->
              
          <v-row class="nodecontent">

               <v-col cols="7" sm="7" >

                     <v-text-field
                       v-model="node.name"
                       :counter="200"
                       :rules="nameRules"
                       label="Name"
                       background-color="#f7f7f7"
                       required
                       color="#333333"
                       height=auto
                       class="my-5 py-2"
                     ></v-text-field>


                     <div class="txtwrap">

                      <v-subheader>Kontakt</v-subheader>

                       <vue-editor
                        :editorOptions="editorSettings"
                        useCustomImageHandler
                        @image-added="handleImageAdded"
                        v-model="node.contact" />

                     </div>

                     <v-subheader>Veröffentlicht</v-subheader>

                     <!--
                     <v-checkbox
                      v-model="node.published"
                      label="Veröffentlicht"
                      ></v-checkbox>
                      -->

                      <v-text-field
                        v-model="node.id"
                        type="hidden"
                      ></v-text-field>

                     <v-btn class="mr-4"
                      v-if="node.id"
                      :disabled="!valid"
                     @click="formSubmit"
                     >Aktualisieren</v-btn>

                     <v-btn class="mr-4"
                      v-else
                      :disabled="!valid"
                     @click="formSubmit"
                     >Speichern</v-btn>

               </v-col>

               <v-col cols="5" sm="5" >

                  <v-subheader>Profilbild</v-subheader>
                  <div class="imgwrap">                     
                    <!--
                    <input type="file"  />
                    -->

                    <div class="previewwrap">
                      <img class="preview" ref="preview" v-if="previewsrc" :src="previewsrc" alt="Preview Image" />
                    </div>

                    <v-file-input
                      show-size
                      ships
                      dense
                      id="file" 
                      ref="file" 
                      v-model="imgFile"
                      v-on:change="onChangeFileUpload()"
                    ></v-file-input>
                    
                    <v-text-field
                      v-model="node.src"
                      type="hidden"                      
                      class="hidden"
                    ></v-text-field>

                  </div>

                   <v-text-field
                     v-model="node.organisation"
                     :counter="200"
                     :rules="nameRules"
                     label="Institution"
                     background-color="#f7f7f7"
                     required
                     color="#333333"
                     height=auto
                     class="my-5 py-2"
                   ></v-text-field>

                   <v-text-field
                     v-model="node.department"
                     :counter="200"
                     :rules="nameRules"
                     label="Bereich"
                     background-color="#f7f7f7"
                     required
                     color="#333333"
                     height=auto
                     class="my-5 py-2"
                   ></v-text-field>

                   <v-autocomplete
                       v-model="node.category"
                       :items="categories"
                       :rules="[v => !!v || 'Kategorie ist erforderlich']"
                       item-value="id"
                       item-text="name"
                       label="Kategorie"
                       class="mt-5 mb-0 py-2"
                       background-color="#f7f7f7"
                       required
                        >
                        <template v-slot:item='{item}'> <div v-html='item.name'/> </template>
                        <template v-slot:selection='{item}'> <div v-html='item.name'/></template>
                    </v-autocomplete>
                    
               </v-col>

        </v-row>

        </v-form>

    </div>

</template>

<script>

    //import Quill from 'quill';
    //import { ImageDrop } from 'quill-image-drop-module';
    //import ImageResize from 'quill-image-resize-module';
    //Quill.register('modules/imageDrop', ImageDrop);
    //Quill.register('modules/imageResize', ImageResize);

    export default {
        name: 'FieldContent',
        data() {
            return {
                editorSettings: {
                    modules: {
                      imageDrop: true,
                      imageResize: true
                    }
                },
                componentKey: 0,
                published: false,
                errors: [],
                valid: true,                
                imgFile: null,
                resps: [],
                previewsrc: null,
                categories: [
                  {
                    id: 1,
                    name: 'Verantwortliche/r'
                  },
                  {
                    id: 2,
                    name: 'Scout'
                  },
                  {
                    id: 3,
                    name: 'Testbed'
                  },                  
                ],
                nodeRespRef: null,
                nameRules: [
                  v => !!v || 'Name erforderlich',
                  v => (v && v.length <= 200) || 'Name darf max. 200 Zeichen enthalten',
                ],
                lazy: false
            };
        },
        props: {
            nodetype: {
              type: String
            },
            node: {
                type: Object
            }
        },
        components: {
        },
        methods: {
          checkForm (data) {
            console.log(data);
          },
          validate () {
            this.$refs.form.validate()
          },
          reset () {
            this.$refs.form.reset()
          },
          resetValidation () {
            this.$refs.form.resetValidation()
          },
          onChangeResponsibility(e){
            this.nodeRespRef = this.resps.find(resp => resp.id === e);
            this.componentKey += 1;
          },
          readURL() {
              var self = this;
              if (this.imgFile) {
                  var reader = new FileReader();
                  reader.onload = function (e) {
                      self.previewsrc = e.target.result;
                  }
                  reader.readAsDataURL(this.imgFile);
              } else {
                this.imgfile = self.previewsrc;
              }
          },          
          onChangeFileUpload(){            
            console.log("file?");
            console.log(this.imgFile);
            this.readURL();
          },          

          handleImageAdded(file, Editor, cursorLocation, resetUploader) {

              var formData = new FormData();
              formData.append("image", file);

              var self = this;

              this.axios.post( this.$config.host+"/image", formData)
                .then(result => {

                  if(result.data == "max") {

                      self.$toasted.error('Datei leider zu groß');

                  } else {

                      let url = self.$config.imagepath + result.data.name;
                      Editor.insertEmbed(cursorLocation, "image", url);
                      resetUploader();

                  }

                })
                .catch(err => {
                  console.log(err);
                });
          },

          formSubmit() {
              //e.preventDefault();
              
              const config = { headers: { 'Content-Type': 'multipart/form-data' } };

              const formData = new FormData();
              formData.set('name', this.node.name);
              formData.set('contact', this.node.contact);
              formData.set('organisation', this.node.organisation);
              formData.set('department', this.node.department);
              formData.set('category', this.node.category);
              formData.set('published', this.node.published);
              formData.set('process', this.node.id ? 'update' : 'generate');
              formData.set('id', this.node.id ? this.node.id : null);
              formData.set('src', this.node.src ? this.node.src : null);
              formData.append('file', this.imgFile);

              // Was ist das hier??              
              let self = this;

              this.axios.post( this.$config.host+"/resp", formData, config)
              .then(function (response) {
                  console.log("hier then..");
                  console.log(response);
                  self.$router.push({path: '/'+self.$home+'content/resp'});
                  self.$emit('respsaved', null)

              })
              .catch(function (error) {
                  self.output = error;
              });
          },

          getResps() {
              this.axios.get( this.$config.host+"/resps" ).then(response => (
                this.resps = response.data,
                console.log("respsContent: resps Referenzen erhalten:"),
                console.log(this.resps)
              ));
          },

        },

        created() {
            //this.getResps();
        },
        computed: {
            editor() {
              return this.$refs.myQuillEditor.quill
            }
        },
        mounted() {
            console.log("PoiContent: node wert (pano_id) on mounted?");
            console.log(this.node);
        },
        watch: {
            node: function (values) {
                this.node = values;
                if(this.previewsrc == null) {
                    this.previewsrc =  this.node.pathimg ? this.node.pathimg : null;
                }
            }
        }        
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.form-wrap {
  margin:30px 0;
}
#nodeform .v-subheader {
  padding-left:0;
  padding-right:0;
}
#nodeform .v-label {
  color:#666666;
}
.txtwrap {
  padding: 10px 0;
  margin: 0px 0;
}
.preview {
  display:block;
  max-height:500px;
  max-width:100%;
}
.selecticon {
  height:20px;
  width: auto;
  margin-right:20px;
}
.ck-editor__editable {
  min-height: 300px;
}

.ql-container {
  font-size:1rem;
}
.ql-editor .ql-video {
  width:100%;
  height:450px;
}

</style>
