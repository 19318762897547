<template>

  <v-card class="admincontent">

    <v-card-title
      class="stickysub"
      >
      Links
      <v-btn 
        icon
        class="ml-4"
        :to="'/'+$home+'node/add/'+ct"
        >
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>
      
      <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Suchen"
          single-line
          hide-details
          class="pt-0"
        ></v-text-field>
      
      <!--
      <v-spacer></v-spacer>
      <v-toolbar
        dense                                            
        flat                                                                                        
        max-width="160"
      >   
        <v-spacer></v-spacer>                                                                                 
        <v-btn icon>
            <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </v-toolbar>      
      -->
    </v-card-title>

    <v-data-table
        v-model="selected"
        :headers="headers"
        :items="pois"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50]
        }"
        :items-per-page="30"
        :search="search"
        :single-select="singleSelect"
        item-key="id"
        show-select            
        class="elevation-1"
      >

      <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="(item, index) in items"
                  :key="item.id"
                >
                  <td>
                  <v-checkbox
                  style="margin:0px;padding:0px"
                  hide-details
                  />
                  </td>                
                  <td align="center"><img :src="'/'+$home+'graf/' + item.src" :alt="item.id" class="selecticon" :title="item.name" /></td>
                  <td>
                    <router-link :to="'/'+$home+'node/poi/' + item.id" class="" text small>
                    {{ item.name }}
                    </router-link>
                  </td>              
                  <td>
                    <router-link :to="'/'+$home+'node/pano/' + item.pano_id" class="" text small>
                    {{ item.pano_name }} 
                    <span style="display:block"><i>{{ item.name_admin }}</i></span>                                        
                    </router-link>
                  </td>
                  <td>
                    <v-btn :to="'/'+$home+'node/poi/' + item.id" class="" text small>
                      <v-icon size="18" >mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                  <td>
                    <v-btn @click="deletePoi(index, item.id)" class="" text small>
                      <v-icon size="18" >mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>

      <!--
      <template v-slot:top>
        <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
      </template>
      -->


    </v-data-table>

  </v-card>
</template>

<script>

import UserContent from '../../helper/UserContent.js';

export default {
  name: 'Fields',
  props: ['ct'],
  data () {
    return {
      search: '',
      singleSelect: false,
      selected: [],
      headers: [
        {
          text: 'Kategorie',
          align: 'start',
          value: 'icon',
        },
        {
          text: 'Name',
          //sortable: false,
          value: 'name',
        },
        {
          text: 'Panorama',
          value: 'pano_name'
        },
        {
          text: '',
          value: 'name_admin'
        },
        {
          text: ''
        }
      ],
      pois: [],
      panos: [],
      overlaydata: null
    }
  },
  methods: {
      getLinks() {          
          this.axios.get( this.$config.host+"/links" ).then(response => (
            this.pois = response.data,
            this.pois = UserContent.getContent(response.data, 'poi')
          ));
      },
      deletePoi(index, id) {
          if(confirm('Soll der Datensatz wirklich gelöscht werden?')) {
            console.log("Fkt deletePoi");
            this.axios.post( this.$config.host+"/poi/delete/"+id ).then(response => (
              console.log(response),
              this.removePoi(index)              
            ));
          }
      },
      removePoi(index) {
          console.log("pois alt:");
          console.log(this.pois);
          this.$delete(this.pois, index)
          console.log("pois neu:");
          console.log(this.pois);
      }
  },
  computed: {
    searchContent: {      
        get() {                        
          return this.$store.state.search.link;
        },
        set(value) {
            this.$store.state.search.link = value;
        }
    },
  },  
  mounted() {
    this.getLinks();
    this.search = this.searchContent;    
  },
  watch: { 
    search: function(newVal) {
          this.$store.commit("setSearchLink", newVal);
    }
  }      

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  margin:0 0 40px 0;
  padding: 40px;
  background-color:#f5f5f5;
}
.item {
  display:block;
  width:100%;
  background-color:lightblue;
  margin-bottom:4px;
}
.p >>> p {
  margin:0;
}
a {
  color: #42b983;
}
</style>
