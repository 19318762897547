<template>
  <div id="naowrap">
    <div id="scene-container" class="naoscene" ref="sceneContainer"></div>
  </div>
</template>

<script>
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
//import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js'
//import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
//import Stats from 'stats.js'

//import { TWEEN } from "@tweenjs/tween.js";

export default {
  name: 'NAO',

  components: {
  },

  data () {
    return {
      container: null,
      scene: null,
      camera: null,
      cameraGoal: {
        activex: false,
        activey: false,
        activez: false,
        posx: 0,
        posy: 0,
        posz: 0,
        speed: 0.1
      },
      controls: null,
      renderer: null,
      stats: null,
      tween: null,
      animation: null,
      mixer: null,
      mixers: [],
      action: null,
      clock: new THREE.Clock(),
      renderTimer: null
    }
  },
  methods: {
    init () {
      // set container
      this.container = this.$refs.sceneContainer

      // add stats
      //this.stats = new Stats()
      //this.container.appendChild(this.stats.dom)


      // (!) Setting perspective values:
      const fov = 55 // Field of view
      const aspect = this.container.clientWidth / this.container.clientHeight
      const near = 0.4 // the near clipping plane
      const far = 2000 // the far clipping plane
      const camera = new THREE.PerspectiveCamera(fov, aspect, near, far)

      camera.lookAt(0, 1200, -600)

      // (!) (angle links/rechts, angle hoch/tief, distance vor/zurück)      
      // Position in scene
      // NAO:
      camera.position.set(-0.16, 0.10, 4.9 );
      this.camera = camera

      //this.cameraGoal = this.camera.position.x;

      // create scene
      this.scene = new THREE.Scene()


      /* BACKGROUND color */    
      // scene background:
      //this.scene.background = new THREE.Color('#1e4486')
      this.scene.background = new THREE.Color('#f6f6f6')

      /*            
      const txtloader = new THREE.TextureLoader();
      txtloader.setCrossOrigin("");

      var bgTexture;
      //var bgWidth, bgHeight;
      bgTexture = txtloader.load('./files/pano/1.jpg',
        function ( texture ) {
            console.log("texture:");
            console.log(texture);          
            //var img = texture.image;
            //bgWidth= img.width;
            //bgHeight = img.height;
            //resize();
        }
      );
      this.scene.background = bgTexture;
      bgTexture.wrapS = THREE.MirroredRepeatWrapping;
      bgTexture.wrapT = THREE.MirroredRepeatWrapping;
      */
  

      // add lights
      const ambientLight = new THREE.HemisphereLight(
        0xffffff, // bright sky color
        0x222222, // dim ground color
        1 // intensity
      )
      const mainLight = new THREE.DirectionalLight(0xf7f7f7, 3.0)
      mainLight.position.set(10, 10, 10)
      this.scene.add(ambientLight, mainLight)

      // create renderer
      this.renderer = new THREE.WebGLRenderer({ antialias: true })
      this.renderer.setSize(this.container.clientWidth, this.container.clientHeight)
      this.renderer.setPixelRatio(window.devicePixelRatio)
      this.renderer.gammaFactor = 2.2
      this.renderer.gammaOutput = true
      this.renderer.outputEncoding = THREE.sRGBEncoding
      this.renderer.physicallyCorrectLights = true
      this.container.appendChild(this.renderer.domElement)

      // set aspect ratio to match the new browser window aspect ratio
      this.camera.aspect = this.container.clientWidth / this.container.clientHeight
      this.camera.updateProjectionMatrix()
      this.renderer.setSize(this.container.clientWidth, this.container.clientHeight)

      //const pmremGenerator = new THREE.PMREMGenerator( this.renderer );
      //pmremGenerator.compileEquirectangularShader();
      
      // Add controls
      
      this.controls = new OrbitControls(this.camera, this.container)
      this.controls.autoRotate = false;
      this.controls.autoRotateSpeed = 1;    
      this.controls.enableRotate = true;
      this.controls.enableZoom = false;
      //this.controls.minPolarAngle = 0;
      this.controls.maxPolarAngle = Math.PI * 90;
      this.controls.target = new THREE.Vector3(0, 0, 0);      

      var that = this;

      /**
       * Set 3D Background
       */
       
      this.scene.background = new THREE.CubeTextureLoader()
        .setPath( '/files/pano/cube/' )
        .load( [
          '1r.jpg',
          '1l.jpg',
          '1u.jpg',
          '1d.jpg',
          '1f.jpg',
          '1b.jpg'
        ] );
        

        /* 3D MODEL LOADING */

        const loader = new GLTFLoader()            

        // Ladefortschritt
        const onProgress = (message) => { 
          //console.log( "loading models" ); 
          console.log( message );
        };

        // Fehlermeldung an Conole
        const onError = ( errorMessage ) => { console.log("errorMessage"); console.log( errorMessage ); };

        loader.load(                    
          //'/3d/nao-leseroboter.glb',
          '/3d/nao-leseroboter-anim.glb',          
          function( gltf) {
                                        
            const model = gltf.scene;
            
            /**
             * Was bedeuten die Positionen?
             * 1. links/rechts ( 0 = Mitte)
             * 2. Blick von oben oder unten?
             * 3. nah/fern (+5 = nah; +10 = näher)
             */                
            //const naoPosition = new THREE.Vector3( -0.18, -0.14, 4.18 );
            const naoPosition = new THREE.Vector3( -0.20, -0.06, 4.08 );
            model.position.copy(naoPosition);

            console.log("animations:");
            console.log(gltf.animations);
            
            //that.animation = gltf.animations[ 0 ];
            that.animation = gltf.animations;
            that.mixer = new THREE.AnimationMixer( model );      
            that.mixers.push( that.mixer );
            
            //action.play();                  
                                      
            that.scene.add(model);

          },
          onProgress,
          onError
        )

        /* / 3D LOADING */
        
        this.start();         
                
        setTimeout(function(){          
          that.move(1,1,1,6); 
        }, 2000);                  

        this.renderTimer = setTimeout(function(){
          this.stop();          
        }, 10000);                  

    },
    start() {
      this.renderer.setAnimationLoop(() => {
        this.render();
        this.update();        
      })              
    },
    stop() {
      console.log("rendern gestoppt!");
      this.renderer.setAnimationLoop(null);
    },
    move(posx,posy,posz,anim) {

        var that = this;

        this.start();         

        console.log("move:");                
        console.log(posx);
        console.log(posy);
        console.log(posz);
        console.log("anim erhalten: " + anim);

        clearTimeout(this.renderTimer)

        this.renderTimer = setTimeout(function(){

            if(that.action) {
              that.action.reset();
            }
            
            that.action = that.mixer.clipAction( that.animation [ anim ] );        
            that.action.setLoop( THREE.LoopOnce );        
            that.action.play();

            that.mixer.addEventListener( 'finished', function( e ) { 
              console.log(e);
              console.log("Animation beendet!");
              clearTimeout(that.renderTimer);
              that.renderTimer = setTimeout(function(){
                that.stop();          
              }, 8000);                            
            } );            

        }, 1000);                            

    },

    animate() {
      //requestAnimationFrame( );
      //move(0.1);
      //renderer.render( scene, camera );
    },

    render () {
      this.renderer.render(this.scene, this.camera)      
      this.controls.update()
      //this.stats.update()
    },
    update() {
      const delta = this.clock.getDelta();
      for ( const mixer of this.mixers ) {
        mixer.update( delta );
      }
    }

  },
  mounted () {
    this.init()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#naowrap {
  height:99%;
  border:0px solid green;
  position:relative;
  top:0;
  left:0;
  width:100%;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.v-icon {
  margin-right:0 !important;
}
#scene-container {
  margin-top:0px;
  height: 100%;
  pointer-events: none;
}

#controls {
  position:fixed;
  top:0;
  right:0;
  width:15%;
  height:100%;
  padding-top:64px;
  z-index: 1;
  background-color:#777777;
}
</style>
<style>
html, body, #naowrap {
  height:100%;
}
.naoscene canvas {    
  position:absolute;
  margin:auto 0;
  top:0;
  bottom:0;
  height:auto !important;    
  width:100% !important;
  overflow:hidden;
}
</style>