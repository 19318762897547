<template>

  <v-card class="admincontent">

    <v-card-title
      class="stickysub"
      >
      Geräte / Services
        <v-btn 
          icon
          class="ml-4"
          :to="'/'+$home+'node/add/'+ct"
          >
          <v-icon>mdi-plus-circle-outline</v-icon>
        </v-btn>    
      <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Suchen"
          single-line
          hide-details
          class="pt-0"
        ></v-text-field>
    </v-card-title>

    <v-data-table
        v-model="selected"
        :headers="headers"
        :items="devices"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50]
        }"
        :items-per-page="30"
        :single-select="singleSelect"
        :search="search"
        item-key="id"
        class="elevation-1"
      >

      <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="(item,index) in items"
                :key="item.id"
              >
                <!--
                <td>
                <v-checkbox
                style="margin:0px;padding:0px"
                hide-details
                />
                </td>
                -->
                <td>{{ item.id }}</td>
                <td>
                  <v-icon 
                    size="18" 
                    v-if="item.published == true"
                    color="green"
                    >mdi-check</v-icon>
                  <v-icon 
                    size="18" 
                    v-if="item.published == false"
                    color="red"
                    >mdi-eye-off-outline</v-icon>                  
                </td>
                <td>                                         
                  <v-img
                    v-if="item.src !== 'null'"
                    :src="item.paththumb"
                    height="80"
                    width="100"
                    >
                  </v-img>
                </td>
                <td>
                  <router-link :to="'/'+$home+'node/device/' + item.id" text small>
                  {{ item.name }}
                  </router-link>
                </td>
                <!--<td v-html="item.description" class="p"></td>-->
                <td v-html="item.category" class="p"></td>
                <td v-html="item.count" class="p"></td>
                <td>                
                  <router-link 
                      class="field" 
                      v-for="(field, key) in item.testfield"
                      :key="key"                            
                      :to="'/'+$home+'/node/field/' + field.tf_id" text small
                      >
                      {{ field.title }}                      
                  </router-link>               
                </td>
                <td>
                  <v-btn 
                    :to="'/'+$home+'p/device/' + item.id" 
                    class="" 
                    text small
                    target="_blank" 
                    title="Vorschau"
                    >                    
                    <v-icon size="18" >mdi-open-in-new</v-icon>
                  </v-btn>
                </td>
                <td>
                  <v-btn :to="'/'+$home+'node/device/' + item.id" class="" text small>
                    <v-icon size="18" >mdi-pencil</v-icon>
                  </v-btn>
                </td>
                <td>
                  <v-btn @click="deleteDevice(index, item.id)" class="" text small>
                    <v-icon size="18" >mdi-delete</v-icon>
                  </v-btn>
                </td>                
              </tr>
            </tbody>
      </template>

    </v-data-table>

  </v-card>
</template>

<script>
export default {
  name: 'AdminDevices',
  props: ['ct'],
  data () {
    return {
      singleSelect: false,
      selected: [],
      search: '',
      headers: [
        { text: 'ID',
          align: 'start',
          value: 'id'
        },
        {
          text: 'veröffentl.',
          value: 'published',
        },        
        {
          text: '',
          value: 'paththumb',
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Kategorie',
          value: 'category'
        },
        {
          text: 'Stückzahl',
          value: 'count'
        },
        {
          text: 'Testfelder',
          value: 'testfield'
        },        
        {
          text: ''
        },        
        {
          text: ''
        },
        {
          text: ''
        }        
      ],
      devices: [],
      overlaydata: null
    }
  },
  methods: {
      getDevices() {
          console.log("Fkt getDevices");
          this.axios.get( this.$config.host+"/devices" ).then(response => (
              this.devices = response.data
          ));
      },
      deleteDevice(index, id) {
          if(confirm('Soll der Datensatz mit der ID ' + id + 'wirklich gelöscht werden?')) {            
            this.axios.post( this.$config.host+"/device/delete/"+id ).then(response => (
              console.log(response),
              this.removeDevice(index)              
            ));
          }
      },
      removeDevice(index) {
          this.$delete(this.devices, index)
          //console.log("devices neu:");
          //console.log(this.devices);
      }      

  },
  mounted() {

    this.getDevices();

  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  margin:0 0 40px 0;
  padding: 40px;
  background-color:#f5f5f5;
}
.item {
  display:block;
  width:100%;
  background-color:lightblue;
  margin-bottom:4px;
}
.p >>> p {
  margin:0;
}
a {
  color: #42b983;
}
</style>
