<template>

  <v-card class="admincontent">

    <v-card-title
      class="stickysub"
      >
      Usecases
      <v-btn 
        icon
        class="ml-4"
        :to="'/'+$home+'node/add/'+ct"
        title="Anwendungsfall hinzufügen"
        >
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>            
      <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Suchen"
          single-line
          hide-details
          class="pt-0"
        ></v-text-field>
    </v-card-title>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="usecases"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50]
      }"
      :items-per-page="30"
      :single-select="singleSelect"
      :search="search"
      item-key="id"
      class="elevation-1"
    >

    <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="(item,index) in items"
                :key="index"
              >
                <!--
                <td>
                <v-checkbox
                style="margin:0px;padding:0px"
                hide-details
                />
                </td>
                -->
                <td>{{ item.id }}</td>
                <td>
                  <v-icon 
                    size="18" 
                    v-if="item.published == true"
                    color="green"
                    >mdi-check</v-icon>
                  <v-icon 
                    size="18" 
                    v-if="item.published == false"
                    color="red"
                    >mdi-eye-off-outline</v-icon>                  
                </td>
                <td>
                  <router-link :to="'/node/usecase/' + item.id" text small>
                  {{ item.name }}
                  </router-link>
                </td>
                <td class="p">
                    <router-link 
                      style="background-color:#f4f4f4; margin:0 5px;padding:2px 4px"
                      v-for="(field,index) in item.testfield"
                      :key="index"
                      :to="'/'+$home+'node/field/' + field.id" 
                      text 
                      small
                      >
                      {{ field.title }}
                    </router-link>
                </td>
                <td class="p">
                  <div v-if="item.responsible.length > 0" v-html="item.responsible[0].name">
                    </div>
                </td>
                <td>
                  <v-btn :to="'/'+$home+'p/usecase/' + item.id" class="" text small target="_blank">
                    <v-icon size="18" >mdi-open-in-new</v-icon>
                  </v-btn>
                </td>                
                <td>
                  <v-btn :to="'/node/usecase/' + item.id" class="" text small>
                    <v-icon size="18" >mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn @click="deleteUc(index,item.id)" class="" text small>
                    <v-icon size="18" >mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>

    </v-data-table>

  </v-card>
</template>

<script>
export default {
  name: 'AdminUsecases',
  props: ['ct'],
  data () {
    return {
      singleSelect: false,
      selected: [],
      search: '',
      headers: [
        { text: 'ID',
          align: 'start',
          value: 'id'
        },
        {
          text: 'veröffentl.',
          value: 'published'
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Beschreibung',
          value: 'description'
        },
        {
          text: 'verantwortlich',
          value: 'responsible_id'
        },
        {
          text: 'Vorschau'
        },        
        {
          text: ''
        }
      ],
      usecases: [],
      overlaydata: null
    }
  },
  methods: {
      getUsecases() {
          console.log("Fkt getUsecases");
          this.axios.get( this.$config.host+"/usecases" ).then(response => (
              this.usecases = response.data,
              console.log("USECASES:"),
              console.log(this.usecases)
          ));
      },
      deleteUc(index, id) {
          if(confirm('Soll der Datensatz wirklich gelöscht werden?')) {
            console.log("Fkt deleteUsecase");
            this.axios.post( this.$config.host+"/usecase/delete/"+id ).then(response => (
              console.log(response),
              this.removeIndex(index)
            ));
          }
      },
      removeIndex(index) {
          this.$delete(this.usecases, index)
      }
  },
  mounted() {

    this.getUsecases();

  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  margin:0 0 40px 0;
  padding: 40px;
  background-color:#f5f5f5;
}
.item {
  display:block;
  width:100%;
  background-color:lightblue;
  margin-bottom:4px;
}
.p >>> p {
  margin:0;
}
a {
  color: #42b983;
}
</style>
