<template>

  <v-app id="admincontent">

      <v-row>

          <v-col cols="2" sm="2">

              <SideMenu class=""></SideMenu>

          </v-col>

          <v-col cols="10" sm="10" class="bg">

              <v-card class="admincontent">
              
                  <!--
                  <v-card-title>
                      {{ nodetype }} hinzufügen
                  </v-card-title>
                  -->

                  <div v-if="nodetype == 'pano'">
                    <PanoContent @panosaved="contentSaved" :node="nodePano" :nodetype="nodetype"></PanoContent>
                  </div>

                  <div v-if="nodetype == 'element'">
                    <ElementContent @elementsaved="contentSaved" :node="nodeElement"></ElementContent>
                  </div>

                  <div v-if="nodetype == 'poi'">
                    <PoiContent @panosaved="contentSaved" :node="nodePoi" :process="'add'" :nodetype="nodetype"></PoiContent>
                  </div>

                  <div v-if="nodetype == 'field'">
                    <FieldContent @fieldsaved="contentSaved" :node="nodeField" :process="'add'" :nodetype="nodetype"></FieldContent>
                  </div>

                  <div v-if="nodetype == 'usecase'">
                    <UsecaseContent @casesaved="contentSaved" :node="nodeCase" :process="'add'" :nodetype="nodetype"></UsecaseContent>
                  </div>

                  <div v-if="nodetype == 'resp'">
                    <RespContent @respsaved="contentSaved" :node="nodeResp" :nodetype="nodetype"></RespContent>
                  </div>

                  <div v-if="nodetype == 'user'">
                    <UserContent @usersaved="contentSaved" :node="nodeUser" :nodetype="nodetype"></UserContent>
                  </div>

                  <div v-if="nodetype == 'tour'">
                    <TourContent @usersaved="contentSaved" :node="nodeTour" :nodetype="nodetype"></TourContent>
                  </div>                  

                  <div v-if="nodetype == 'device'">
                    <DeviceContent @casesaved="contentSaved" :node="nodeDevice" :nodetype="nodetype"></DeviceContent>
                  </div>

              </v-card>
              <!--
              <div class="noaccess">
                <p>{{ $t('noaccess') }}</p>
              </div>
              -->

          </v-col>

      </v-row>

  </v-app>

</template>

<script>
import PanoContent from "./content/PanoContent.vue";
import PoiContent from "./content/PoiContent.vue";
import FieldContent from "./content/FieldContent.vue";
import RespContent from "./content/RespContent.vue";
import UserContent from "./content/UserContent.vue";
import TourContent from "./content/TourContent.vue";
import UsecaseContent from "./content/UsecaseContent.vue";
import DeviceContent from "./content/DeviceContent.vue";
import ElementContent from "./content/ElementContent.vue";
import SideMenu from './navigation/SideMenu';

export default {
  name: 'Node',

  components: {
    PanoContent,
    PoiContent,
    UsecaseContent,
    DeviceContent,
    ElementContent,
    FieldContent,
    RespContent,
    UserContent,
    TourContent,
    SideMenu
  },
  data() {
    return {
        nodetype: this.$route.params.nodetype,
        nodePoi: {
          published: true,
          pos_x: 0,
          pos_y: 0,
          pos_z: 0
        },
        nodePano: {
          published: true,
          loc_x: 0,
          loc_y: 0
        },
        nodeField: {
          elements: [
            {
              name: null,
              content: null
            }
          ]
        },
        nodeResp: {
        },
        nodeUser: {
        },
        nodeTour: {
        },        
        nodeElement: {
        },
        nodeCase: {
          testfields: []
        },
        nodeDevice: {
          testfields: []
        }
    }
  },
  watch: {
    '$route.params.nodetype': function (type) {
      console.log("WATCH!");
      console.log(type);
      this.nodetype = type;
    }
  },
  methods: {
      contentSaved() {
          console.log("Fkt contentSaved");
      }
  },
  computed: {
      user() {
        return this.$store.state.user
      }
  }

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
