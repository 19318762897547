<template>  
  <div id="panoframe">
    <!-- Loading -->
    <loading 
      :active="isLoading"      
      loader="spinner"
      :height="100"
      :width="200"
      color="#005ca9"      
      :opacity="0.06"
      :is-full-page="true"
    ></loading>    

    <Krpano
      @loading="getLoading"
      @unlock="unLock"
      @clicked="onClickSpot"        
      @panocurrent="getCurrent"
      @loaded="panoloaded"        
      @state="getState"
      :panotitle="panotitle"
      :panoinfo="panoinfo"
      :unlocked="unlocked"
      :panocurrent="panocurrent"
      :source="panoitems"
      :queryid="query"
      ref="panoComponent"
    ></Krpano>      

    <!-- Radar -->          
    <PanoRadar
        v-if="panocurrent && locationdata"
        @switchPanoOff="switchPanoOff"
        :locationdata="locationdata"
        :panocurrent="panocurrent"
        :position="position"
        :map="map"
    ></PanoRadar>  

    <!-- Dialog -->
    <template>
      <v-layout row justify-center class="dialogframe">        
        <v-dialog 
          v-model="dialog" 
          :content-class="currentitem.pdfpath != 'null' ? 'pdf-wide' : 'main-dialog'"           
          persistent @keydown.esc="onClickSpot(false)" 
          ref="panoSpot" v-if="destroyComponent"            
          scrollable
          :retain-focus="false"
          >            
          <v-card>
            <div class="titlewrap">
              <div class="wiwo21" v-if="currentitem.pdfpath != 'null'">                
                <img :src="'/'+$home+'files/images/wiwo23.jpg'" alt="" />
              </div>
              <div class="headline padding" v-html="currentitem.spottitle" v-if="currentitem.spotid != 362">
              </div>
              <div class="actionsmall">
                <v-btn 
                  @click="onClickSpot(false)"
                  depressed
                  icon
                  >
                  <v-icon>mdi-close-thick</v-icon>
                </v-btn>
              </div>

            </div>            
                      
            <div v-if="currentitem.cat == 1">
              WIWO
            </div>                                  
            

            <template v-if="currentitem.pdfpath != 'null'">                            
                <iframe height="100%" width=100% :src="currentitem.pdfpath + '#zoom=page-width'" ></iframe>                              
            </template>            
            
            <v-card-text v-else v-html="currentitem.spotcontent"></v-card-text>

            <!-- Aktion bottom -->
            <v-card-actions class="poi-action bottom mr-1 mb-1">              
              <div v-if="currentitem.tours !== null && tourdata === null" class="tourwrap">              

                <div class="tours">
                  <v-menu                    
                    top                
                    offset-y                                      
                    >
                    <template v-slot:activator="{ on, attrs }">
                      Diese Infotafel ist Teil eines Rundgangs.
                      <v-btn
                        color="#999"                      
                        v-bind="attrs"
                        v-on="on"
                        depressed
                        style="font-size:.8rem;"
                      >
                        <v-icon class="mr-4">mdi-map-marker-multiple-outline</v-icon> Tour starten
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item
                        v-for="(item, index) in currentitem.tours"
                        :key="index"
                        dense
                      >
                        <v-list-item-title class="menuitem" @click="getTourData(item.id, currentitem.spotid)" :title="item.name">
                          {{ item.name }}                      
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                </div>

              </div>
              
            </v-card-actions>

          </v-card>      
        </v-dialog>        
      </v-layout>
    </template>

    <!-- Tour controls -->
    <template>
      <div class="text-center">
        <v-bottom-sheet
          v-model="sheet"
          max-width="900px"
          hide-overlay
          content-class="tourcontrol"        
        >

          <v-sheet
            class="text-center"
            height="54px"
          >         
            <div 
              v-if="tourdata !== null && spotIsInTour(tourstatus.id) && dialog"                     
              class="controlswrap"
              >

              <div 
                class="d-prev" 
                @click="onClickTourSpot(tourdata[touritemPrev].id)" 
                :title="tourdata[touritemPrev].name + ' / pi: ' + touritemPrev"                         
                >                
                <v-icon
                  size="28"
                  dense
                  round
                  color="#666"
                >
                mdi-chevron-left
                </v-icon>                
              </div>

              <div 
                class="d-next" 
                @click="onClickTourSpot(tourdata[touritemNext].id)" 
                :title="tourdata[touritemNext].name"                           
                >                
                  <v-icon
                    size="28"
                    dense                  
                    color="#666"
                  >
                  mdi-chevron-right
                  </v-icon>                
              </div>  

              <div 
                class="tourinfo" 
                v-if="tourdata !== null && spotIsInTour(tourstatus.id) && dialog"       
                >
                <b>{{ spotIsInTour(tourstatus.id).name }}</b>  {{ (touritemActive+1) }} / {{ tourdata.length}}
              </div>

              <v-btn                    
                color="#eee"                                                              
                depressed
                style="font-size:.8rem;"
                @click="resetTourData()"
                v-if="tourdata !== null && spotIsInTour(tourstatus.id) && dialog"                     
                >
                  <v-icon class="mr-4">mdi-map-marker-off-outline</v-icon> Tour stoppen
              </v-btn>

            </div>

          </v-sheet>
        </v-bottom-sheet>
      </div>
    </template>

    <!-- Controls button: toggle menu -->
    <div class="controlsopen" :class="{ active: controls.visible == false }">
      <v-tooltip left color="#333">
      <template v-slot:activator="{ on, attrs }">
      <v-btn       
        @click="toggleMenu()"      
        tile                            
        v-bind="attrs"
        v-on="on"
        color="rgba(255,255,255,0.8"      
        >
        <v-icon        
          >
          mdi-menu-open
        </v-icon>
      </v-btn>
      </template>
      <span>Navigationsleiste einblenden</span>
      </v-tooltip>
    </div>

    <!-- Controls -->
    <v-container class="controls overflow-y-auto" fluid id="scroll-target" :class="{ active: controls.visible }">
      <v-tooltip left color="#333333">
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
            @click="toggleMenu()"
            icon
            depressed
            class="controlsclose"
            v-bind="attrs"
            v-on="on"
            >
            <v-icon
              color="#999"
              >
              mdi-arrow-expand-right
            </v-icon>
          </v-btn>
        </template>
        <span>Navigationsleiste ausblenden</span>
      </v-tooltip>

      <v-row
          v-scroll:#scroll-target="onScroll"
          class="controlsinner"
        >
          <v-card
          class="panoinfo"
          flat        
          tile
          v-if="panocurrent"
          >        
            <h2 class="list-header" v-html="panocurrent.name"></h2>
            <div class="infocontent" v-html="panocurrent.content"></div>
          </v-card>
          
          <!--
          <v-card class="news">
            <div class="img">
            <img :src="'/'+$home+'files/images/wiwo23.jpg'" alt="" />
            </div>
            <h2 class="list-header">12. Wissenschaftswoche #digital</h2>    
            <div class="infocontent">        
            <p>Unter dem Motto "Forschen. Anwenden. Begreifen." findet die <a href="https://www.th-wildau.de/forschung-transfer/neuigkeiten-und-veranstaltungen/12-wildauer-wissenschaftswoche/" target="_blank"><span class="nbsp">12. Wissenschaftswoche</span></a> vom<br>27. Februar bis 03. März 2023 statt.</p>
            
            <a @click="switchPanoOff(645)">Posterausstellung ansehen</a>
            </div>
          </v-card>        
          -->
          
          <v-card
              class="panomenu"
              flat
              v-if="panocategories"
            >
            
              <v-list dense class="panolist">

                <v-list-item-group>

                  <!--<div class="list-header" v-bind="paninfo">{{paninfo}}XXX</div>-->

                  <div class="list-header"><v-icon size="24" class="pr-2" style="margin-top:-3px">mdi-location-enter</v-icon>Einstiegspunkte</div>

                  <!-- Pattern for tours (!)
                  <v-list-item
                    v-for="(item, i) in panoenters"
                    :key="i"
                    @click="switchPanoOff(item.id);toggleMenu()"
                  >

                      <v-list-item-icon class="mx-0" >
                        <v-icon>mdi-map-marker</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title v-text="item.name"></v-list-item-title>
                      </v-list-item-content>

                  </v-list-item>
                  -->
                
                  <v-treeview
                    v-model="tree"                  
                    :items="panoenters"                  
                    activatable
                    item-key="id"
                    open-on-click
                    off-icon=""
                    transition                    
                  >                  
                                      
                    <template v-slot:label="{ item }">                      
                        <div @click="switchPanoOff(null)" v-if="item.children">{{ item.name }}</div>
                        <div @click="switchPanoOff(item.id)" v-else>{{ item.name }}</div>                    
                    </template>
                  </v-treeview>                                

                </v-list-item-group>
              </v-list>

          </v-card>          

      </v-row>      

    <Logos v-if="template !== 'icampus'"></Logos>

    </v-container>    

  </div>
</template>

<script>

import Krpano from '../components/content/Krpano';
//import Xmlformat from '../components/tools/xmlformat';
import PanoRadar from '../components/controls/PanoRadar';
import Logos from '../components/elements/Logos';
import Loading from 'vue-loading-overlay';
import { eventBus } from '../main.js';

export default {
  name: 'PanoramaFrame',
  props: ['query','map','tour','controls'],

  components: {
    //Xmlformat
    //xmlbuilder    
    Krpano,
    PanoRadar,    
    Loading,
    Logos
  },

  data(){
    //const vm = this;
    return {              
      scene: "scene name",
      hooks:{
          sceneChanged(scene){
              console.log(scene);
              //event handler
          }
      },                 
      route: this.$route,
      destroyComponent:true,      
      isLoading: true,
      tree: [],
      spotDialog: null,
      inset: true,
      panoitem: 1,
      panoitems: null,
      panocategories: null,
      panoenters: [],
      right: null,
      panotitle: null,
      panoinfo: null,
      panocurrent: null,            
      locationdata: null,      
      tourdata: null,
      touritemActive: null,
      touritemPrev: null,
      touritemNext: null,
      sheet: false,
      tourstatus: {
        id: null,
        active: false
      },
      currentitem: {
        spotcontent: "",
        spottitle: "",
        panoid: null
      },
      dialog: false,
      // Lock dialog window deactived
      lockdialog: false,      

      unlocked: false,
      password: '',
      queryid_to_unlock: null,
      show1: false,
      position: {},
      coltype2: false,
      coltype3: false,
      coltype4: false,
      coltype6: false,
      coltype7: false,
      coltype8: false,
      coltype9: false,
      controlsHidden: false,
      pdfCurrentPage: 0,
      numPages: 0,
      loadedRatio: 0,
      pwrules: {
          required: value => !!value || 'Passwort wird benötigt'
      },
      pwerror: '',
      template: null
    }
  },

  methods: {

      displayData(data) {
        console.log(data);
      },
      
      getPanoData() {          
          let self = this;
          this.axios.get( this.$config.host+"/panos" ).then(function (response) {
              self.panoitems = response.data;                 
              self.panotitle = self.panoitems[0].name;
              self.panoinfo = self.panoitems[0].content;
              self.panocurrent = self.panoitems[0];                            
              setTimeout(() => self.getPanoCategories(), 1500);            

              //self.getPanoCategories();
              self.checkTour();
          });
      },
      getPanoCategories() {          
          let self = this;
          this.axios.get( this.$config.host+"/panocategories" ).then(response => (
            self.panocategories = response.data,
            self.getPanoEnters()            
          ));
      },
      getTourData( tourid, spotid) {
          this.sheet = true;
          let self = this;
          this.axios.get( this.$config.host+"/tourdata/"+tourid ).then(response => (
            self.tourdata = response.data,                        
            self.setTourStatus(tourid),
            // Get index of active tour item
            self.getTourIndex(spotid)                     
          ));
      },
      getTourIndex (spotid) {        
        var length = this.tourdata.length;
        if(length > 0) {          
          this.touritemActive = this.tourdata.findIndex(item => item.id === spotid);
          this.touritemPrev = (this.touritemActive - 1) < 0 ? (length - 1) : (this.touritemActive - 1);
          this.touritemNext = (this.touritemActive + 1) > (length - 1) ? 0 : (this.touritemActive + 1);
        }
      },
      setTourStatus (tourid) {
        this.tourstatus.id = tourid;        
      },
      spotIsInTour(tourid) {                
        if(this.currentitem.tours) {
          var active = this.currentitem.tours.find(x => x.id === tourid);      
          return active
        } else {
          return false
        }         
      },
      resetTourData () {          
          this.tourdata = null;
          this.touritemActive = null;
          this.touritemPrev = null;
          this.touritemNext = null;
          this.sheet = false;
      },    
      getLocationData() {
        let self = this;
        this.axios.get( this.$config.host+"/locationsdata" ).then(function (response) {                    
          self.locationdata = response.data;                 
        });
      }, 
      getPanoEnters() {                    

          let self = this;

          if(this.panoitems && this.panocategories) {
                                  
            this.panocategories.forEach((value) => {
              
              var children =  this.panoitems.filter(function(item) { 

                return ( item.cat_id == value.id && 
                    self.panocurrent.loc_key == item.loc_key &&
                    (
                      (self.template == 'innoxr' && (item.enterx == true || item.enterb == true)) ||
                      (self.template == 'default' && (item.enterx == true || item.enterb == true)) ||
                      (self.template == 'icampus' && item.enter == true)
                    )
                    );
              });              

              children.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

              if(children.length > 0) {
                self.panoenters.push({
                  name:value.name,
                  children: children
                });              
              }            
            });            

          }
      },
      switchPanoOff(item){

          if(item == null) return false;          
        
          //this.isLoading = true;
          this.$refs.panoComponent.switchPano(item);
          
      },
      onClickTourSpot (spotid) {
        
        this.setInfospot(spotid);
        this.getTourIndex(spotid);
      },
      onClickSpot (title, content, spotid, icon, tours, cat, pdf) {

        if(!title) {

          // Close all hotspots in VR mode
          this.$refs.panoComponent.closePois();

          this.dialog = false;
          this.destroyComponent = false;

        } else {

          if(icon === 'intro') {
 
            spotid = parseInt(spotid);
            var activePano = this.panoitems.find(pano => pano.spots.some(item => item.id === spotid));

            if(activePano) {

              var activeSpot = activePano.spots.find(spot => spot.id === spotid);    
                          
              this.currentitem.spotcontent = this.renderHtmlContent(activeSpot.body);
              this.currentitem.spottitle = activeSpot.linktitle;
              this.currentitem.spotid = activeSpot.id;
              this.currentitem.tours = (activeSpot.tours && activeSpot.tours.length > 0) ? activeSpot.tours : null;
              this.currentitem.cat = activeSpot.cat;
              this.currentitem.pdfpath = activeSpot.pdfpath 

              this.dialog = true;
              this.destroyComponent = true;
              
            }

          } else {
                        
            if(!title) {
              //console.log("no title");
              //this.resetTourData();
            } else {
              var thistours = (tours && tours.length > 0) ? tours : null;
              this.currentitem.spotcontent = this.renderHtmlContent(content);
              this.currentitem.spottitle = title;
              this.currentitem.spotid = spotid;            
              this.currentitem.tours = thistours ? thistours : null;
              this.currentitem.cat = cat ? cat : null;
              this.currentitem.pdfpath = pdf ? pdf : 'null';
            }            

            this.dialog = true;
            this.destroyComponent = true;
            
          }
        
          /* 
           * Activated control bar when tourdata is available
          */         
          if( this.spotIsInTour(this.tourstatus.id) && this.tourdata !== null && this.dialog) {            
            setTimeout(() => this.sheet = !this.sheet, 500);            
          }                            

        }          
          
      },
      setInfospot (spotid) {
        var activePano = this.panoitems.find(pano => pano.spots.some(item => item.id === spotid));
        var activeSpot = activePano.spots.find(spot => spot.id === spotid);      
        this.currentitem.spottitle = activeSpot.linktitle;
        this.currentitem.spotcontent = activeSpot.body;
        this.currentitem.panoid = activePano.id;
        this.currentitem.tours = activeSpot.tours;
        this.currentitem.pdfpath = activeSpot.pdfpath;

        // switch pano:
        this.switchPanoOff(activePano.id)
      },
      checkTour () {
        if(this.tour !== undefined) {          
          this.onClickSpot ('Titel', 'Content', this.tour, 'intro')
        } else {
          //console.log("tour id is NOT set");
        }
      },
      onScroll () {
          //console.log("scrolling");
      },      
      getState (value) {                
          this.position = value;
      },
      panoloaded() {
          //console.log("panoLoaded!");
      },
      getTitle (value) {
          this.panotitle = value;
      },
      getCurrent (value) {
          this.panocurrent = value;          
      },
      getInfo (value) {
          this.panoinfo = value;
      },
      getLoading (value) {
          this.isLoading = value;
      },
      unLock ( queryid ) {        
        this.isLoading = false;
        this.lockdialog = true;
        this.queryid_to_unlock = queryid;
      },
      breakunlock () {
        this.lockdialog = false;
        this.isLoading = false;
      },
      focus () {                
        this.$nextTick(() => this.$refs.pw.$el.focus())        
      },
      submitUnlock () {        

        if(this.password === 'ebs2021wildau') {          
          this.unlocked = true;
          this.lockdialog = false;

          if(this.queryid_to_unlock) {
            setTimeout(() => {
              this.switchPanoOff(this.queryid_to_unlock)            
            }, 200);          
          }        

        } else {

          this.pwerror = 'Sie haben ein falsches Passwort eingegeben.'

        }

      },      
      setSpotsVisibility (value) {          
          console.log(value);
          //this.$refs.panoComponent.setSpots(value);
      },      
      toggleMenu() {
          this.controls.visible = !this.controls.visible;          
      },
      renderHtmlContent(html) {
          if(html) {
            return html.replaceAll('/vr/bilder/', '/vr/files/images/');
          } else {
            return html
          }         
      },
      progress() {
        //console.log("progress!");
        //console.log(ev);
      }
  },

  beforeMount() {
      this.getPanoData();
      this.getLocationData()      
  },

  mounted() {      
      this.template = this.$template;      
  },

  created() {        
    eventBus.$on('controls-visible', clickCount => {            
      this.controls.visible = clickCount
    });        
  },

  watch: {
    locationdata: function (e) {         
      
      this.locationdata = e;   

    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.v-icon {
  margin-right:0 !important;
}

.v-list-item--link::before {
  background-color:transparent;
}
.v-item--active {
    background-color:transparent;
}

.menuitem:hover {
  cursor: pointer;
}
.controlsopen {
  position:fixed;
  top:70px;
  right:16px;  
  display:none;
}
.controlsopen.active {
  display:block;
}
.controls {
  position:fixed;
  display:none;
  top:0;
  right:0;
  width:28%;
  height:100%;
  padding-top:62px;
  padding-left:10px;
  padding-right:10px;
  z-index: 4;
  background-color: rgba(13, 29, 62, 0.86);
}
.controls >>> a {
  color:#79a7cf;
  text-decoration: none;
}
.controls >>> a:hover {  
  text-decoration: underline;
}
.controls .controlsclose {
  position:fixed;
  left:auto;
  bottom:6px;
}
.controls .v-card,
.controls .v-list {
  background-color: transparent;
  color:#f7f7f7;
}
.controls.active {
  display:block;
  width:100%;
}
.controlsinner {
  margin-left:0;
  margin-right:0;
  padding-bottom:30px;
}
.panoinfo {
  padding-top:8px;
  padding-bottom:8px;
  margin-bottom:8px;
  width:100%;
  min-height:3rem;  
}
.panoinfo >>> .list-header {
  font-weight: normal;
  font-size:1.4rem !important;
}
.pano .list-header {
  font-size: 0.92rem;
}
.list-header {
  padding:10px 16px 10px;
}
.infocontent {
  padding-bottom:20px;
  margin:0px 16px;
  font-size:0.92rem;
  border-bottom:1px solid #999;
}
.infocontent >>> p {  
  font-size:0.92rem;
  margin: 0 0 10px;
}
.news {
  padding:0;
}
.news .img {
  padding:0 16px;  
}
.news .img img {  
  max-width:100%;
}
.panomenu {
  position:static;
  top:56px;
  right:20px;
  width:100%;
  padding-bottom:14px;
  margin-bottom:1.2rem;  
}
.panomenu .v-btn {
  padding: 0 12px !important;
  height:28px !important;
  font-size:0.7rem;
}

.panoinfo div,
.panoinfo p,
.panomenu >>> .v-treeview-node__label,
.panomenu >>> .theme--light.v-icon {
  color:#e1e4e5 !important;
}
.panomenu >>> .list-header {
  color:orange;
  font-weight: bold;
  font-size:1.2rem;
}

.controls >>> .v-treeview {
  padding:0 14px;
}
.controls >>> .v-treeview-node__root {
  min-height: auto;
  padding:4px 0px 
}
.controls >>> .v-treeview-node__level {
  width:12px;  
}
.controls >>> .v-treeview-node__label {
  white-space: normal;
  font-size:0.92rem;  
}
.controls >>> .v-treeview-node__children {
  background-color: rgba(255, 255, 255, 0.08);
}
.spacer {
  height:20%;
}
.v-dialog {
  max-width: 820px;
}
.v-list-item--dense {
  min-height:28px;  
}
.v-list-item .v-list-item__title {
  padding:10px 10px;
}

@media only screen and (min-width: 760px) {
    .controls {
        width: 26%;
        display:none;
    }
    .controls.active {
        width:26%;
    }
}

@media only screen and (min-width: 1080px) {
    .controls {
        width: 24%;
    }
    .controls.active {
        width:24%;
    }
}

@media only screen and (min-width: 1480px) {
    .controls {
        width: 22%;
    }
    .controls.active {
        width:22%;
    }
}
</style>

<style>

.nbsp {
  white-space: nowrap;
}

#panoframe {
  width: 100%;
  height: 100%;
}
.v-dialog.main-dialog {  
  margin-bottom: 60px !important;
  max-height: 92%;
  max-width: 100%;
  margin-left:4px;
  margin-right:4px;
}
.v-dialog.pdf-wide {  
  margin-bottom: 60px !important;
  max-height: 92%;
  height:90%;  
  max-width: 100%;
  margin-left:4px;
  margin-right:4px;
}
.v-dialog img {
  max-width:100%;
}
.v-dialog.pdf-wide iframe { 
  height:100%;
}
.v-dialog.main-dialog iframe {
  height:450px;
} 
.v-dialog .poi-action {
  text-align:right;
  align-self: flex-end;
  padding:6px;
}
.v-dialog .poi-action.bottom {
  text-align:center;
  align-self: normal;
  width:100%;
}
.v-dialog .v-card {
  position:relative;
}
.v-dialog .titlewrap {  
  display: flex;
}
.v-dialog .wiwo21 {
  margin-left:12px;
}
.v-dialog .wiwo21 img {
  height:60px;
}
.v-dialog .headline {
  padding: 14px 24px 14px !important;
  font-size: 1.2rem !important;
  font-weight: 500;
}
.v-dialog .headline.padding {
  padding: 2px 24px 2px !important;
  margin-left:0px;
}
.v-dialog .actionsmall {
  padding:10px;
  margin-left:auto;
}
.v-dialog .tourwrap {
  width:100%;
  background-color:#f7f7f7;
  margin: 0 6px;
}
.poi-action .v-btn {  
  background-color:transparent !important;
  /*background-image: linear-gradient(to right, #f49e30, #ed7724);*/
}
.tourcontrol {
  border:0px solid red;  
  padding:4px;
}
.tourcontrol .v-sheet {
  border: 0px solid green;
  padding:4px;
}
.tourinfo {
  display: inline-block;
  padding:12px 12px 10px;
  margin-right: 12px;
}
.controlswrap button {
  margin-top:-4px;
}
.d-prev {
  float:left;
}
.d-next {
  float:right;
}
.d-prev,
.d-next {    
    width:30px;
    margin:10px 20px;
    background-color: rgba(255, 255, 255, 0);
    display:inline-block;
}
.d-prev:hover,
.d-next:hover {    
    background-color: rgba(255, 255, 255, 0.3);
}
.ql-align-right {
  float:right;
}
#panoSpot .v-card__text {
  padding: 16px 12px;
}
.login {
  display:none;
}
.pwinput {
  margin-bottom:20px;  
}
.menu {
  display:flex;
}

/* PDF viewer */
#viewerContainer .textLayer {
  display:none;
}
.easter {
  display:none;
}

@media only screen and (min-width: 760px) {
    .v-dialog.main-dialog {      
      max-height: 92%;
      max-width: 64%;
      margin-right:24px;
      margin-left:24px;
    }
    .v-dialog.pdf-wide {  
      max-width: 80%;
      margin-right:24px;
      margin-left:24px;
    }
    .v-dialog .headline {
      font-size: 1.5rem;
    }
    .v-dialog .headline.padding {
      padding: 14px 24px 14px !important;
      margin-left:0px;
    }
    .controls {
        width: 26%;
    }
    .login {
      display:flex;
    }
    .menu {
      display:none;
    }
    .easter {
      display:block;
    }

}
@media only screen and (min-width: 1080px) {
    .controls {
        width: 21%;
    }
}
@media only screen and (min-width: 1480px) {
    .controls {
        width: 20%;
    }
}
</style>