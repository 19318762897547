<template>
  <div>

    <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition" content-class="settings cms" :retain-focus="false">

      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          class="appbtn"
          ref="openBtn"
          depressed
        >        
        <img src="../../assets/nao-icon.png" class="icon-image">
        <span>CMS</span>
        </v-btn>
      </template>

      <v-card class="text-center" height="99%">

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="m-2 btn-right"
            @click="dialog = !dialog"
            color="#F1948A"
          ><v-icon>mdi-close</v-icon></v-btn>

        </v-card-actions>

        <!--
        <v-btn
          class="m-2"
          color="#7DCEA0"
          @click="reloadframe"
        ><v-icon>mdi-reload</v-icon></v-btn>
        -->

        <iframe width="99%" height="900px" src="https://icampus.th-wildau.de/naoleseroboter-testbed/autologinurl/164/feXoJkMuQ7Wsmkml-zKWG58QOPOVS3JMVBy6lQ7ATMM"></iframe>        

      </v-card>
    </v-dialog>

  </div>
</template>

<script>
  export default {
    data () {
      return {
        dialog: false,
        notifications: false,
        sound: true,
        widgets: false,
      }
    },
    methods:{
      openApp() {
        console.log("hier klick open");
        this.$refs.openBtn.$el.click();
      }
    }
  }
</script>

<style>

.v-dialog.cms {
  max-width:none;
  height:96%;
}
.v-dialog.cms iframe {
  overflow:hidden;
  height:80%;
}
iframe {

}
</style>
