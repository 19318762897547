<template>
    <div>
        <v-slide-group multiple show-arrows class="slider uc">
            <v-slide-item
              v-for="(usecase,index) in usecases"
              :key="index"
              v-slot:default="{ active, toggle }"
            >
              <div
                class="mx-2 item"
                :input-value="active"
                active-class="white--text"                
                @click="toggle"
              >
                <router-link :to="'/p/usecase/'+usecase.id">
                <div class="img" v-if="usecase.src"><img :src="usecase.paththumb" alt="" /></div>
                <div class="title">{{ usecase.name }}</div>
                <div class="preview">{{ usecase.preview }}</div>              
                </router-link>
              </div>
            </v-slide-item>
        </v-slide-group>
    </div>
</template>

<script>

    export default {
        name: 'Field_Usecase',
        data() {
            return {
            };
        },
        props: {
            usecases: {
                type: Array
            }
        },
        components: {
        },
        methods: {

        },
        mounted() {
            
        },
        watch: {
          /*
            fieldid: function (values) {
                console.log("watch Field Usecases:");
                console.log(values);
                this.fieldid = values;
                this.getUsecasesByTestfieldId();
            }
          */
        },
    }
</script>

<style>
div.preview {
  color:#ffffff;
}
</style>

<style scoped>
div.item {
  width:32%;
}
div.preview {
  white-space: normal;
  font-size:.88rem;
}

</style>
