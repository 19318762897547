<template>

  <v-card class="admincontent">

    <v-card-title
      class="stickysub"
      >
      Touren
      <v-btn 
        icon
        class="ml-4"
        :to="'/'+$home+'node/add/'+ct"
        title="Tour hinzufügen"
        >
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>          
    <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Suchen"
        single-line
        hide-details
        class="pt-0"
      ></v-text-field>
    </v-card-title>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="tours"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50]
      }"
      :items-per-page="30"
      :single-select="singleSelect"
      :search="search"
      item-key="id"
      class="elevation-1"
    >

    <template v-slot:body="{ items }">
            <tbody>
              <tr                
                v-for="(item, index) in items"
                :key="item.id"
              >
                <!--
                <td>
                <v-checkbox
                style="margin:0px;padding:0px"
                hide-details
                />
                </td>
                -->
                <td>{{ item.id }}</td>
                  <td>
                  <v-icon 
                    size="18" 
                    v-if="item.published == true"
                    color="green"
                    >mdi-check</v-icon>
                  <v-icon 
                    size="18" 
                    v-if="item.published == false"
                    color="red"
                    >mdi-eye-off-outline</v-icon>                  
                </td>
                <td>
                  <router-link :to="'/'+$home+'node/tour/' + item.id" text small>
                  {{ item.name }}
                  </router-link>
                </td>              
                <td>{{ item.user_id }}</td>
                <td>
                  <v-btn @click="deleteTour(index, item.id)" class="" text small>
                    <v-icon size="18" >mdi-delete</v-icon>
                    {{ index }}
                  </v-btn>
                </td>          
              </tr>
            </tbody>
          </template>

    </v-data-table>

  </v-card>
</template>

<script>
export default {
  name: 'AdminTestFields',
  props: ['ct'],
  data () {
    return {
      singleSelect: false,
      selected: [],
      search: '',
      headers: [
        { text: 'ID',
          align: 'start',
          value: 'id'
        },
        {
          text: 'veröffentl.',
          value: 'published'
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'LDAP ID',
          value: '',
        },
        {
          text: '',
          value: ''
        }
      ],
      tours: [],
      overlaydata: null
    }
  },
  methods: {
      getTours() {
          console.log("Fkt getTours");
          this.axios.get( this.$config.host+"/tours" ).then(response => (
            this.tours = response.data,
            console.log(this.tours)
          ));
      },
      deleteTour(index, id) {
          if(confirm('Soll der Datensatz mit der ID ' + id + 'wirklich gelöscht werden?')) {
            console.log("Fkt deleteTour");
            this.axios.post( this.$config.host+"/tour/delete/"+id ).then(response => (
              console.log(response),
              this.removeTour(index)
              //console.log(this.pois)
            ));
          }
      },
      removeTour(index) {          
          console.log("REMOVE index from tours" + index);
          //this.$delete(this.tours, index)
      }            
  },
  mounted() {

    this.getTours();

  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  margin:0 0 40px 0;
  padding: 40px;
  background-color:#f5f5f5;
}
.item {
  display:block;
  width:100%;
  background-color:lightblue;
  margin-bottom:4px;
}
.p >>> p {
  margin:0;
}
a {
  color: #42b983;
}
</style>
