<template>
  <div id="chatbot" style="overflow:hidden;">    
    
      <loading :active="isLoading"
        loader="dots"
        :height="100"
        :width="200"
        color="#005CA9"
        :opacity="0.98"
        :is-full-page="true"
        ></loading>

      <!-- Stage overlay -->
      <div class="chatbot-overlay" v-if="stageoverlay"></div>              
      
      <iframe 
        id="naoapp" 
        v-if="url"
        width="100%" 
        height="100%" 
        :src="url"
        ></iframe>

  </div>
</template>

<script>

import Loading from 'vue-loading-overlay';

export default {
  name: 'UsecaseFrame',
  props: ['query'],
  components: {
    Loading
    //StageSettings
  },

  data: function () {
    return {
      isLoading: true,
      stageoverlay: false,
      url: null
    }
  },
  methods:{

    leave () {
      console.log("LEAVE");
      //this.$refs.speechAnswer.cancel();
      //:to="'/p/usecase/1'" 
      this.$router.push("/p/usecase/"+this.query);
    },
    getNode() {
        let self = this;
        this.axios.get( this.$config.host+"/usecase/" + this.query ).then(function (response) {

                console.log("get UsecaseFrame NODE");
                //console.log(response.data);
                console.log(response.data.testbed);
                self.url = response.data.testbed;
                //self.node = response.data;

        });
    }    

  },
  mounted() {
    this.getNode()
  },
  created() {
    setTimeout(() => {
      this.isLoading = false
    },2000)
  }

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.vld-overlay {
  z-index:4 !important;
}
#chatbot iframe {
  border:0;
  margin-right:7%;
}
#chatbot #controls {
  position:fixed;
  top:0;
  right:0;
  width:7%;
  height:100%;
  padding-top:60px;
  z-index: 1;
  background-color:rgba(45,89,134,0.96);
}

#chatbot #controls .ucnav {
  float:none;
  text-align:center;
}
#chatbot {
  height:100%;
  width:100%;
  padding-top:48px;  
  position:static;
  border:0px solid green;    
}
.chatbot-overlay {
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  /*background-color: rgba(255, 153, 0, 0.8);*/
  background-color: rgba(92,175,254,0.8);
}

</style>
