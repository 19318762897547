<template>
  <div>
    <div class="contact" v-if="contacts">    
      <h4>Ansprechpartner</h4>
      <v-row >
          <v-col cols="4" sm="4" align="left"
              v-for="(contact, key) in contacts"
              :key="key"                          
              class="item"       
            >         
              <div class="img">
              <img :src="contact.pathimg" alt="" class="staff"/>
              <!--
              <v-img
                :src="contact.pathimg"
                class="staff"
                width="240"
                height="400"
                >
              </v-img>
              -->
              </div>
              <div>
              <h5>{{ contact.name}}</h5>
              <h6 v-if="contact.organisation">{{ contact.organisation }}</h6>
              <div v-html="contact.contact"></div>          
              </div>
          </v-col>
          <v-col cols="6" sm="6" offset-sm="1">    
            <iframe
            v-if="contacts.length > 0 && contacts[0].map"
            :src="contacts[0].map"            
            width="100%"
            height="400px"
              >
            </iframe>

          </v-col>
      </v-row>      
    </div>
    <div class="contact main" v-else>
      <v-row>
          <v-col cols="12" sm="12" align="left" class="elem">         
            <h4>Fragen, Anregungen oder<br>konkrete Vorhaben?<br>Wir freuen uns auf ein Gespräch.</h4>    
          </v-col>          
      </v-row>      
      <v-row>
          <v-col cols="6" sm="6" align="left" class="elem">                     
            <h5>Technische Hochschule Wildau</h5>
            <p>Hochschulring 1<br>15745 Wildau<br>www.th-wildau.de</p>
          </v-col>
          <v-col cols="6" sm="6" align="left" class="elem">         
            <h5>Brandenburgische Technische Universität Cottbus-Senftenberg</h5>
            <p>Platz der Deutschen Einheit 1<br>03046 Cottbus<br>www.b-tu.de</p>           
          </v-col>          
      </v-row>      
 
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
      }
    },
    props: {
      contacts: {
          type: Array
      }
    },    
    methods:{

    },
    mounted() {
        //console.log("Mounted contacts module");
        //console.log(this.contacts);            
    } 
  }
</script>

<style>
.contact {
  padding-bottom:30px;
}
.contact .item {
  border:0;
}
.contact .img {  
  height:auto;
  width:100%;
}
img.staff,
.v-image.staff {
  width:70%;
  max-width:100%;
  height:auto;
  border:1px solid white;
  margin-bottom:20px;
}
.contact h5,
.contact h6 {
  font-size:1rem;
  color:#ffffff;
}

.contact p a {
  color: #ffffff !important;
  text-decoration: underline;
}

.contact .elem h4 {
  font-size:1.6rem;
}
.contact.main .elem h5 {
  font-size:1.2rem;
  font-weight: bold;
  margin-right:20px;
  border-top:2px solid #ffffff;
  padding-top:10px;
}
.contact .elem h6 {
  font-size:1rem;
  font-style: italic;
  color: #fff;
}
.contact p {
  margin-bottom:0 !important;
}
</style>
