<template>
<div>

    <v-slide-group class="slider uc" multiple show-arrows>
          <v-slide-item
            v-for="(testfield, index) in testfields"
            :key="index"
            disabled
          >
            <div
              class="ma-2 item"
            >
                <router-link :to="'/p/field/'+testfield.id">
                  <div class="img" v-if="testfield.src"><img :src="testfield.paththumb" alt="" /></div>
                  <div class="title">{{ testfield.title }}</div>
                  <div class="keywords">...</div>
                </router-link>
            </div>
          </v-slide-item>
        </v-slide-group>

    <!--
    <v-col cols="6" sm="6">
      <v-btn :to="{ name: 'Stage' }">
        NAO-Leseroboter
      </v-btn>
    </v-col>
    <v-col cols="6" sm="6">
        <v-btn :to="{ name: 'NLP' }">
          NLP
        </v-btn>
    </v-col>
    -->

</div>

</template>

<script>

    export default {
        name: 'Usecase_Testfields',
        data() {
            return {
                testfields: {}
            };
        },
        props: {
            usecaseid: {
                type: Number
            }
        },

        components: {

        },

        methods: {

            getConnectedByUsecaseId() {
                //console.log("getConnectedByUsecaseId()");
                let self = this;
                this.axios.get( this.$config.host+"/testfields/usecase/" + this.usecaseid ).then(function (response) {

                        //console.log("get TESTFIELDS by usecase id");
                        //console.log(response.data);
                        self.testfields = response.data;

                });
            },
            link(x) {
              console.log("link fkt:")
              console.log(x);
              this.$router.push({path: x});
            }

        },
        mounted() {
            //console.log("mount Usecase Testfields - Usecaseid: ");
            //console.log(this.usecaseid);
            this.getConnectedByUsecaseId();
        },
        watch: {
            usecaseid: function (values) {
                console.log("watch Field Usecases:");
                console.log(values);
                this.usecaseid = values;
                this.getConnectedByUsecaseId();
            }
        },
    }
</script>

<style>
.slider {
  color:#ffffff;
}
.iwhite .slider {
  color:#000000;
}
.slider.uc {
  margin-bottom:50px;
  border:0px solid rgba(255,255,255,0.4);
  border-radius:6px;
}
.iwhite .slider.uc {
  border:0px solid rgba(0,0,0,0.4);
  margin-left:-20px;
  margin-right:-20px;
}
.slider.uc .v-slide-group__wrapper {
  padding-top:10px;
  padding-bottom:10px;
}
.v-slide-group__next--disabled, 
.v-slide-group__prev--disabled {
  opacity:0.2;
}
.slider.uc .item a {
  background-color:rgba(255,255,255,0.1);
  padding:10px;
  display:block;
  border-radius:6px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;  
  box-shadow: 0 2px 6px -2px rgba(27, 33, 58, 0.4);
}
.slider.uc .item a:hover {
  background-color:rgba(255,255,255,0.1);
}
.slider.uc .img img {
    width:400px;
    height:auto;
}
.slider.uc .title {
    color:#ffffff;
    font-size:1.1rem !important;
}
.iwhite .slider.uc .title {
    color:#000000;
}
.slider.uc .keywords {
}
.slider.uc .v-icon.mdi::before {
  font-size:3rem;
  color:#ffffff;
}
.iwhite .slider.uc .v-icon.mdi::before {
  font-size:3rem;
  color:#000000;
}
</style>
