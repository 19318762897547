<template>

  <div class="admincontent">

  <v-card-title
    class="stickysub"
    >
    Layout-Element
    <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Suchen"
        single-line
        hide-details
        class="pt-0"
      ></v-text-field>
  </v-card-title>

  <v-data-table
      v-model="selected"
      :headers="headers"
      :items="elements"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50]
      }"
      :items-per-page="30"
      :single-select="singleSelect"
      show-select  
      :search="search"
      item-key="id"
      class="elevation-1"
    >

    <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="item in items"
                :key="item.id"
              >                
                <td>
                <v-checkbox
                style="margin:0px;padding:0px"
                hide-details
                />
                </td>                                
                <td>
                  <router-link :to="'/node/element/' + item.id" text small>
                  {{ item.name }}
                  </router-link>
                </td>
                <td v-html="item.description" class="p"></td>
                <td class="p">

                    <div class="">
                        <v-chip
                          class="ma-0"
                          v-for="(content, key) in item.content"
                               :key="key"

                          :to="'/p/' + content.contenttype + '/' + content.content_id"
                        >
                          {{ content.title }} 
                        </v-chip>
                    </div>

                </td>
                <td>
                  <v-btn :to="'/node/element/' + item.id" class="" text small>
                    <v-icon size="18" >mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn @click="deleteEl(index,item.id)" class="" text small>
                    <v-icon size="18" >mdi-delete</v-icon>
                  </v-btn>                  
                </td>
              </tr>
            </tbody>
          </template>

    </v-data-table>

  </div>
</template>

<script>
export default {
  name: 'AdminElement',
  props: ['ct'],
  data () {
    return {
      singleSelect: false,
      selected: [],
      search: '',
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'name',
        },
        {
          text: 'Beschreibung',
          value: 'description'
        },
        {
          text: 'Testfelder',
          value: 'content'
        },
        {
          text: ''
        }
      ],
      elements: [],
      overlaydata: null
    }
  },
  methods: {
      getElements() {
          console.log("Fkt getElements");
          this.axios.get( this.$config.host+"/elements" ).then(response => (
              this.elements = response.data,              
              console.log(this.elements)
          ));
      },
      deleteEl(index, id) {
          if(confirm('Soll der Datensatz wirklich gelöscht werden?')) {
            console.log("Fkt deleteUsecase");
            console.log(index + ' ' + id);
            /*
            this.axios.post( this.$config.host+"/usecase/delete/"+id ).then(response => (
              console.log(response),
              this.removeIndex(index)
            ));
            */
          }
      },      
  },
  mounted() {

    this.getElements();

  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  margin:0 0 40px 0;
  padding: 40px;
  background-color:#f5f5f5;
}
.item {
  display:block;
  width:100%;
  background-color:lightblue;
  margin-bottom:4px;
}
.p >>> p {
  margin:0;
}
a {
  color: #42b983;
}
</style>
