<template>
  <v-app>
  <div>CONTENT FRAME: {{ nodeid }}
      <div v-if="nodetype == 'usecase'">      
        <Page_Usecase :node="node" ></Page_Usecase>
      </div>
      <div v-if="nodetype == 'field'">
        <Page_Testfield :node="node" ></Page_Testfield>
      </div>
      <div v-if="nodetype == 'device'">
        <Page_Device :node="node" ></Page_Device>
      </div>
  </div>
  <Footer></Footer>
  </v-app>
</template>

<script>

import Page_Testfield from './content/Page_Testfield';
import Page_Usecase from './content/Page_Usecase';
import Page_Device from './content/Page_Device';
import Footer from './elements/Footer';

export default {
  name: 'ContentFront',

  components: {
    Page_Testfield,
    Page_Usecase,
    Page_Device,
    Footer
  },
  data() {
      return {
        nodetype: this.$route.params.nodetype,
        //nodeid: this.$route.params.nodeid,        
        node: {},
        usecases: null,
        testfields: null,
        alias: [
          {
            id: 4,
            alias: 'node-chatbot'
          },
          {
            id: 5,
            alias: 'node-autonomfahren'
          },
          {
            id: 7,
            alias: 'node-dashboard'
          },
          {
            id: 1,
            alias: 'node-nlp'
          },
          {
            id: 11,
            alias: 'node-knn'
          }                                        
        ],
        nodeid: this.calcURL(this.$route.params.nodeid)
      }
  },
  methods: {

      calcURL (nodeid) {        
        console.log(nodeid);        
        //var index = alias.find(x => x.id === nodeid);
        //console.log(index);
        
        nodeid = nodeid.replace('was-auch-immer-url',24);  

        //console.log("return nodeid:");
        //console.log(nodeid);
        
        return nodeid;
      },

      getNode() {
          let self = this;
          this.axios.get( this.$config.host+"/" + this.nodetype + "/" + this.nodeid ).then(function (response) {
                  console.log("get flex CONTENT FRONT");
                  console.log(response.data);
                  self.node = response.data;                  
                  self.$store.commit('setNode', response.data);
          });
      },

  },
  mounted() {
      this.getNode();
  },
  watch: {

  },
  computed: {    

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

#content {
    margin-top:0px;
}
.modul {
  margin:0 0 30px;
}
.modul .body {
  padding: 0 6px;
}
.modul .body p {
  margin-bottom:2px;
  font-size:0.94rem;
}
.topicheader {
  display: block;
  padding:10px 8px;
  margin:0;
  font-size:1rem;
  font-weight: 500;
  text-transform: uppercase;
}
.modul .link {
  display:inline-block;
  padding:6px 8px 6px 6px;
  color:#333333 !important;  
}
.modul .link:hover {
  text-decoration: none;
  background-color:#f3f3f3;
}
.page {
    padding-top:40px;
}
.page .category {
  font-size:0.8rem;
  padding:0;
}
.page .header {
    padding-top:4px !important;
}
.page .headtitle {
    color:#ffffff;
}
.page .header h3 {
    color:#ffffff;
    font-size:2.4rem;    
}
.page .header .wrap {
    /*padding:0;*/
}
.page .header .container .row {
  padding-top:0px;
}
.page .main {
  padding-right:10px;
}
.page.list .item {
  margin-bottom:20px;
  background-color: #f7f7f7;
}
.page.list a {
  color:#333;
}
.page.list a:hover {
  text-decoration: none;
}
.page.list .item-desc {
  font-size:.9rem;
}
.page.list .features {
  margin:0 0 8px;
}
.page.list .manu {
  font-size: .84rem;
}
.page.list .year {
  font-size: .84rem;
}
.page.list .count {
  font-size: .84rem;
}
.page.list .item-desc .type {
  font-style: italic;
}
.page.list .item h3 {
  font-size: 1.2rem;
  margin-top:.3rem;
  margin-bottom:.6rem;
}

.page.list .wrap {
  margin-bottom:40px;
}

.pagetoolbar {
  padding-top:0px;
}
/*
.page .header .logocol {
  display: flex;
  flex-direction: column-reverse;  
}
*/
.page .header .logocol {  
  background-color: rgba(255,255,255, 0.9);
  text-align: center;
  border-radius: 40px;
  padding:14px;
  width:60px;
}
.page .header .logo {
}
.logosub {
    padding-top:12px;
}
.page .header h4 {
  margin:0;
  padding:10px 0;
  font-size:1.4rem;
}
.page .content h3 {
    font-size:1.4rem;
    margin-bottom:1.5rem;
    margin-top:.5rem;
    font-weight:600;
    color:#333;
}
.page .content h4 {
    font-size:1.5rem;
    margin-bottom:1.5rem;
    margin-top:.5rem;
    color:#333;
}
.page .content li {
    padding-left:20px;
}
.page .imgsection {
    margin-bottom: 40px;
}
.pbackground {        
    width:100%;
    background-size: cover;
    background-position: center;
    min-height: 220px;
} 
.device .pbackground {
  background-color: #f7f7f7;
  padding-top:10px;
}
.pbackground > .container,
.pbackground .row,
.pbackground .row .wrap,
.pbackground .row .wrap .row {
  height:100%;
}

.pbackground .row .wrap .row .title {
  display: flex;
  flex-direction: column-reverse;
}
.pbackground .row .wrap .row .title > div {
  min-height: 86px;
}

.header > div {
    /*flex-basis: 0;*/
}
.header .title {
    padding-top:0px;    
}
.header .title span {
    font-size: 1rem;
}

.modul .v-btn {
  color: #333 !important;
  background-color: #ddd !important;
}
.modul .field-btn,
.modul .usecase-btn {
  background-color: #F39325 !important;
  color:#ffffff !important;
  text-transform: none !important;
  height:30px !important;
  padding:0 12px !important;
}
.modul .listwrap {
  display:block;
  margin-bottom:2px;
}
.modul .url-btn {
  background-color: #eaf3fa !important;
  color:#000000 !important;
  text-transform: none !important;
  height:26px !important;
  padding:0 12px !important;
}
.modul .url-desc {
  font-size: .88rem;
}
.modul .tools-btn {
  background-color: #0095DB !important;
  color:#ffffff !important;
  text-transform: none !important;
  height:30px !important;
  padding:0 12px !important;
}


@media only screen and (min-width: 760px) {
  .pbackground {
    height: 220px;
  }
  .pbackground .row .wrap .row .title > div {
    min-height: 96px;
  }
  .page .header h3 {
    color:#ffffff;
    font-size:3.2rem;    
  }
}

@media only screen and (min-width: 1080px) {
  .pbackground {
    height: 260px;
  }
  .pbackground .row .wrap .row .title > div {
    min-height: 106px;
  }
}

@media only screen and (min-width: 1480px) {
  .pbackground {
    min-height: 300px;
  }
  .pbackground .row .wrap .row .title > div {
    min-height: 106px;
  }
}

</style>
