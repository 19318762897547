<template>
  <v-app>

  <TopNav :drawer="drawer"></TopNav>
  <SidebarNav :drawer="drawer"></SidebarNav>

  <List :products="products" :maximum="maximum" :cart="cart"></List>

  </v-app>
</template>

<script>
import TopNav from '../components/TopNav';
import SidebarNav from '../components/SidebarNav';
//import HelloWorld from './components/HelloWorld';
import List from './content/List';

export default {
  name: 'Profile',

  components: {
    TopNav,
    SidebarNav,
    //HelloWorld,
    List
  },

  data: function () {
    return {
      drawer: {
        visible: false,
      },
      maximum: 99,
      products: null,
      cart: []
    }
  },
  mounted: function() {
    fetch('https://hplussport.com/api/products/order/price')
    .then(response => response.json())
    .then(data => {
      this.products = data;
    })
  }
};
</script>
