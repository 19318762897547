<template>
  <v-app>
    <v-row justify="center">
      <v-col cols="2" sm="2">
        <SideMenuFront v-if="selection.partners.length > 0" class="" :selection="selection"></SideMenuFront>
      </v-col>
      <v-col cols="10" sm="10">
        <div class="wrap">

          <v-container px-4>

              <v-row no-gutters>

                <v-col cols="1">                            
                </v-col>
                <v-col cols="7">
                  <div class="search-wrapper">
                    <div class='tag-input'>
                      <div v-for='(tag) in selectedTags' :key='tag.id' class='tag-input__tag'>                        
                        {{ tag.name }}
                        <span class="closetag" @click="remove(tag)">x</span>
                      </div>
                      <input 
                        type='text' 
                        v-model="searchtmp" 
                        placeholder="Testbed durchsuchen" 
                        class='tag-input__text' 
                        v-on:keyup.enter="submitsearch"
                      />
                      <v-btn @click="submitsearch" style="height:48px;" depressed>
                        finden
                      <!--<v-icon>mdi-check</v-icon>-->
                      </v-btn>
                    </div>
                    <!--
                    <input type="text" v-model="search" placeholder="Testbed durchsuchen"/>
                    -->
                  </div>
                  <div v-if="filtertags.length > 0 && tagsdisplay" class="tagcloud">
                    <a v-for="(tag, index) in filtertags" :key="index" @click="addTag(tag.id, tag.name)">{{ tag.name }}</a>
                  </div>
                </v-col>
                <v-col cols="1">
                  <v-btn @click="reset" style="height:48px;border-radius:0;background-color:transparent" depressed>
                    <v-icon>mdi-autorenew</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="3" align="right">                  
                    <v-btn-toggle
                        v-model="searchMode"
                        background-color="#ffffff"
                        mandatory
                        borderless
                        tile                        
                        color="rgba(0,118,188,0.7)"                                                
                      >
                        <v-btn
                          @click="setSearchMode(0)"
                          >
                          <v-icon>mdi-view-grid</v-icon>
                        </v-btn>

                        <v-btn
                          @click="setSearchMode(1)"
                          >
                          <v-icon>mdi-table-of-contents</v-icon>
                        </v-btn>

                    </v-btn-toggle>
                </v-col>
              </v-row>

<!--
              <v-row class="mx-0"> second row
                <tags-input
                    v-model="selectedTags"
                    style="width:100%"
                    :existing-tags="tags"
                    id-field="id"
                    placeholder="Schlagwort eingeben"
                    text-field="name"
                    @tags-updated="onTagsUpdated"
                    :typeahead="true"></tags-input>
              </v-row>
-->

          </v-container>

          <!--  FILTER RESULTS  -->

          <v-container class="d-flex">

              <v-row v-if="searchMode === 0" style="padding-top:20px">

              <!--<v-row v-if="!svitch" align="center" justify="center" style="height:100vh" dense>-->
              <!--<div v-if="!svitch">-->

                  <transition-group name="fade" mode="out-in">
                  
                  <v-col cols="12" sm="6" md="4" lg="3" v-for="(post, key1) in filteralltypes"
                      :key="key1+0"
                      class="d-flex card"
                  >
                    
                    <div contain class="card-content h-100">
                        
                          <div v-if="post.src">
                              <router-link :to="'/p/'+post.type+'/'+post.id" >
                              <img :src="post.paththumb" :alt="post.title" class="img-responsive">
                              </router-link>
                          </div>
                          <div class="item-desc">                              
                              <span class="type">{{ $t(post.type) }}</span>
                              <router-link :to="'/p/'+post.type+'/'+post.id">
                                  <h3>{{ post.title }}</h3>
                              </router-link>
                              <div class="tags">
                              <a v-for="(tag, key2) in post.tags" :key="key2+0" @click="addTag(tag.id, tag.name)">{{ tag.name }}</a>
                              </div>

                              <!--
                              <a v-for="category in post.categories" :key="category.id" :href="'/blog?category=' + category.slug">{{ category.title }} <br></a>
                              <p class="font-12">Posted on {{ post.published_at }}</p>
                              -->
                          </div>

                    </div>

                  </v-col>
                  </transition-group>
              <!--</div>-->
              </v-row>

              <v-row v-if="searchMode === 1">
                  <v-data-table
                      :headers="headers"
                      :items="filteralltypes"
                      :footer-props="{
                        'items-per-page-options': [10, 20, 30, 40, 50]
                      }"
                      :items-per-page="30"                      
                      :search="search"
                      item-key="title"
                      class="elevation-1 search-table"
                    >

                    <template v-slot:body="{ items }">
                            <tbody>
                              <tr
                                v-for="(item, index) in items"
                                :key="index"
                              >
                                <td>
                                  <img :src="item.paththumb" title="" v-if="item.paththumb" height="40px" />                                                                                                            
                                </td>
                                <td class="name">                                                                    
                                  <router-link :to="'/p/'+item.type+'/'+item.id" >
                                  {{ item.title }}
                                  </router-link>                                   
                                  <span class="toptitle"><i>{{ $t(item.type) }}</i></span>                             
                                </td>
                                <td>                                  
                                    <span v-for="(ar) in item.area" :key="ar.name">
                                      <i>{{ ar.name }}</i>
                                    </span>                                  
                                </td>
                                <td style="padding:10px 6px;">                                                        
                                    <div class="tags">
                                      <i v-for="(tag) in item.tags" :key="tag.id" @click="addTag(tag.id, tag.name)" class="tag">
                                      {{ tag.name }}                                
                                      </i>
                                    </div>
                                </td>
                                <td>
                                  {{ item.region }}
                                    <div v-for="(partner) in item.partners" :key="partner.id" class="partner">
                                    <img :src="'/'+$home+'graf/'+partner.src" style="width:50px;">
                                    </div>
                                </td>
                                <td>
                                  <div v-if="item.usecase && item.usecase.length > 0">
                                    
                                    <v-menu 
                                      bottom
                                      left
                                      :offset-x="true"
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <span
                                          v-bind="attrs"
                                          v-on="on"
                                        >Anwendungen ({{ item.usecase.length }})</span>
                                      </template>
                                      <v-list>
                                        <v-list-item
                                          v-for="(item, index) in item.usecase"
                                          :key="index"
                                        >
                                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                                        </v-list-item>
                                      </v-list>
                                    </v-menu>

                                  </div>
                                </td>                                
                                <td>
                                  <router-link :to="'/p/devices/'+item.id" v-if="item.devices && item.devices.length > 0">
                                  ({{ item.devices.length }})
                                  </router-link>                                                               
                                </td>                                                                                      
                              </tr>
                            </tbody>
                          </template>

                    </v-data-table>
              </v-row>
            
          </v-container>

        </div>        
        <Footer></Footer>
      </v-col>
    </v-row>
    
  </v-app>
</template>

<script>

//import Setting from '../components/footer/Setting';
import SideMenuFront from './navigation/SideMenuFront';
//import VoerroTagsInput from '@voerro/vue-tagsinput';
import Footer from './elements/Footer';

export default {
  name: 'Layout',
  props: ['query','searchx','pagex'],
  components: {
      SideMenuFront,
      Footer,
      //"tags-input": VoerroTagsInput
  },
  data() {
    return {
      loaded: false,
      selectedTags: [],
      existingTags: [
        {key: 'web-development', value: 'Web Development' },
        {key: 'php', value: 'PHP' },
        {key: 'javascript', value: 'JavaScript' }
      ],
      headers: [
        { text: '',
          align: 'start',
          value: 'paththumb',
          width: '10%'
        },
        { text: 'Name',          
          value: 'title',
          align: 'start',
          width: '10%'
        },
        {
          text: 'Bereich',
          value: 'area',
        },                        
        {
          text: 'Themen',
          value: 'tags'
        },
        {
          text: 'Partner',
          value: 'partner',
          sortable: false
        },        
        {
          text: 'Anwendungen',
          value: 'fields'
        },        
        {
          text: 'Equipment',
          value: 'responsible',
          sortable: false
        }                     
      ],
      search: '',
      searchtmp: '',
      searcht: null,
      usecase: false,
      testfield: false,
      testfields: [],
      devices: [],
      tags: [],
      tagstest: ['hello', 'world'],
      //partners: [],
      tagssearch: [],
      tagsdisplay: false,
      tagsselected: [],
      usecases: [],      
      //svitch: this.searchMode,
      selection: {
        field:false,
        usecase:false,
        device:false,
        partners: [],
        areas: []
      },
      alltypes: []
    }
  },

  methods: {
      getTestfields() {
          this.axios.get( this.$config.host+"/testfieldsf" ).then(response => (
            this.testfields = response.data,
            this.getUsecases()
          ));
      },
      getUsecases() {
          this.axios.get( this.$config.host+"/usecasesf" ).then(response => (
            this.usecases = response.data,
            this.getDevices()
          ));
      },
      getDevices() {
          this.axios.get( this.$config.host+"/devices" ).then(response => (
            this.devices = response.data,                        
            this.merge(this.testfields, this.usecases, this.devices)            
          ));
      },
      getTags() {
          this.axios.get( this.$config.host+"/tags" ).then(response => (
            this.tags = response.data,
            this.getQuery(this.query)
          ));
      },
      getPartners() {
        var self = this;
        this.axios.get( this.$config.host+"/partners" ).then(function (response) {                  
          response.data.forEach(function (pt) {            
            self.selection.partners.push({name: pt.name, active: false})
          });    
          self.loaded = true                          
        });
      },      
      getAreas() {
        var self = this;
        this.axios.get( this.$config.host+"/areas" ).then(function (response) {                
          response.data.forEach(function (ar) {                        
            self.selection.areas.push({name: ar.name, active: false})
          });                              
        });
      },            
      merge(a,b,c) {
          this.alltypes = a.concat(b,c);
      },
      getQuery(query) {
        var result = this.tags.find(el => el.name == query);
        if(result && result != -1) {
          this.addTag(result.id, result.name);
        }
      },
      reset() {        
        this.search = "";
        this.searchtmp = "";
        this.selectedTags = [];
      },
      remove (tag) {
        const index = this.selectedTags.map(function(item) { return item.id; }).indexOf(tag.id);

        // remove object
        this.selectedTags.splice(index, 1);      
      },
      addTag(id, name) {                
        // Add item to array
        this.selectedTags.push({id:id,name:name});

        // Find duplicates
        const ids = this.selectedTags.map(o => o.id);
        const filtered = this.selectedTags.filter(({id}, index) => !ids.includes(id, index + 1));
        this.selectedTags = filtered;
        this.search = "";       
        this.searchtmp = "";
      },
      setSearchMode (mode) {        
        this.$store.commit("setSearchMode", mode);
      },
      submitsearch () {        
        this.tagsdisplay = false;
        this.search = this.searchtmp;        
      }

  },
  mounted() {
    this.getTestfields();
    this.getTags();
    this.getPartners();
    this.getAreas();
    //this.getUsecases();

  },
  computed: {
      searchMode: {      
          get() {            
            return this.$store.state.search.mode;
          },
          set(value) {
              this.$store.state.search.mode = value
          }
      },

      filtertags() {        
          return this.tags.filter(post => {
              return ( post.name.toLowerCase().includes(this.searchtmp.toLowerCase()) && this.searchtmp !== '' )
          })
      },

      filteralltypes() {

        console.log("FILTER ALL TYPES initially");

        var categories = [];        
        var areas = [];
        var partners = [];        
        
        this.selection.areas.forEach(function (ar) {        
          if(ar.active == true) areas.push(ar.name)
        });

        //console.log(areas);
        this.selection.partners.forEach(function (pt) {        
          if(pt.active == true) partners.push(pt.name)
        });

        //console.log(partners);
        
        if(this.selection.usecase == true) categories.push('usecase');
        if(this.selection.field == true) categories.push('field');
        if(this.selection.device == true) categories.push('device');

                  
        if(this.selectedTags.length > 0) {            

            return this.alltypes.filter(post => {

              var found = false;

              this.selectedTags.forEach(function (tag) {

                  if( post.tags && post.tags.find(el => el.id == tag.id) ) {                    
                    found = true;
                  }

              });
              
              return ( post.title.toLowerCase().includes(this.search.toLowerCase()) || post.type.toLowerCase().includes(this.search.toLowerCase()) ) &&
                found == true &&
                (
                  (categories.indexOf(post.type) > -1 && categories.length > 0 ) || categories.length == 0
                ) &&
                ( 
                  ( post.area && post.area.filter(ar => areas.includes(ar.name)).length > 0 && areas.length > 0 ) || areas.length == 0
                ) &&
                ( 
                  ( post.partners && post.partners.filter(pt => partners.includes(pt.name)).length > 0 && partners.length > 0 ) || partners.length == 0
                ) &&
                post.published == true 

            })

        } else {

            return this.alltypes.filter(post => {
              
              return ( post.title.toLowerCase().includes(this.search.toLowerCase()) || post.type.toLowerCase().includes(this.search.toLowerCase()) ) &&
                (
                  ( categories.indexOf(post.type) > -1 && categories.length > 0 ) || categories.length == 0
                ) &&
                ( 
                  ( post.area && post.area.filter(ar => areas.includes(ar.name)).length > 0 && areas.length > 0 ) || areas.length == 0
                ) &&
                ( 
                  ( post.partners && post.partners.filter(pt => partners.includes(pt.name)).length > 0 && partners.length > 0 ) || partners.length == 0
                ) &&
                post.published == true

            })

        }
        
      }

  },
  watch: {
    searcht(val) {
      if(val !== '') {
        this.tagssearch = this.tags;
      } else {
        this.tagssearch = []
      }
    },
    filtertags() {      
      this.tagsdisplay = true
    }    
  }

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>

.wrap {  
  padding-top:60px;
  padding-bottom:20px;
  min-height: 100%;
}
.container {
  background-color:#ffffff;
  max-width:1300px;
}
.container.topic {
  background-color:transparent;
}
.container .row {
  padding-top:30px;
}
.col img {
  max-width:100%;
}
.bordertop {
  border-top:2px solid #000;
}
.search-wrapper {
  padding-right:0px;
  background-color:#f5f5f5;
  height:48px;
}

/* tags */

.tag-input {
  width: 100%;  
  font-size: 0.9em;
  height: 48px;
  box-sizing: border-box;
  padding: 0 0 0 10px;
  display: flex;
}

.tag-input__tag {
  height: 30px;
  display: flex;
  justify-content: flex-start;
  margin-right: 10px;
  background-color: #e9e9e9;
  margin-top: 10px;
  line-height: 30px;
  padding: 0 5px 0 8px;
  border-radius: 5px;
  white-space: nowrap;
}

.tag-input__tag > span {
  cursor: pointer;
  opacity: 0.75;
}

.tag-input__text {
  border: none;
  outline: none;
  font-size: 0.9rem;
  line-height: 48px;
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  width:100%;
  padding:1px 4px 0;
}
.closetag {  
  display: inline-block;
  margin:0;
  padding:2px 0 0 5px;
  line-height: .8rem;
  font-size:.76rem;
}
.tagcloud {
  min-height:10px;
  border-bottom:1px solid #f5f5f5;  
  padding:6px 4px;
}
.tagcloud .tag {

}

/* /tags */

.v-data-table {
  width:100%;
}
.v-item-group .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.card-content {
  padding:0px;
  background-color:#f7f7f7;
  min-height:290px;
}
.card-content img {
  max-width:100%;
}
.card-content .item-desc {
  padding:4px 8px 12px;
}
.card {
  border:0px solid red;
  float:left;
  width:33.33%;
}
.card a h3 {
  font-size:1rem;
  color:#333;
  padding:4px 2px 8px;
}
.tags a,
.tagcloud a {
  display:inline-block;
  font-size:0.8rem;
  color:#999;
  font-style:italic;
  padding:1px 10px;
  margin:2px 2px;
  white-space: nowrap;
  color:#fff;
  background-color:#0076bc;
  border-radius:14px;
}
.tags a:hover,
.tagcloud a:hover {
  text-decoration:none;
  background-color: rgb(0,118,188,0.8);
}
.tags i,
.tagcloud i {
    background-color: #f5f5f5;
    margin-right:10px;
    display:inline-block;
    margin:2px 4px;
    padding: 2px 4px;
}
.type {
  font-size:0.76rem;
  color:#999;
  font-style:italic;
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 1s;
}
.fade-leave-active {
  transition: 1s;
  opacity: 0;
}
.row span {
  width:100%;
}
.tablogo {
  float:left;
  margin:-6px 20px 0 0;
}
.tablogo img {
  height:30px;
}
.search-table {
  margin:0 16px;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  width: auto;
  padding:0 6px 0 0;
}

</style>

<style>

.search-table th {
  padding:0 2px !important;  
}
.search-table td.name a {
  font-size:.8rem;
}
.search-table .toptitle {
  display: block;
  font-size: 0.8rem;
}
.search-table .toparea {
  display: block;
  font-size: 0.8rem;
}
</style>
