<template>
  <div>
    <v-img :src="'/'+$home+'graf/tropfen.svg'"
      :width="size"
      :height="size*1.3"
      class="drop"
      :style="'top:'+top+'%;left:'+left+'%'"
    ></v-img>    
  </div>
</template>

<script>
  export default {
    data () {
      return {
      }
    },
    props: {
      top: {
        type: Number
      },
      left: {
        type: Number
      },
      size: {
        type: Number
      }
    },    
    methods:{

    },
    mounted() {
        //console.log("Mounted contacts module");
        //console.log(this.contacts);            
    } 
  }
</script>

<style scoped>

.drop {
  position:absolute;
}

</style>

