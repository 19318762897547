<template>
  <div class="list" style="margin:0 80px;">

    <h4>LISTE</h4>
    <div class="text-right">
    CART: <span class="badge badge-pill badge-success">{{cart.length}}</span>
    </div>

    <div class="form-inline mr-auto">
    <label class="font-weight-bold mr-2" for="formMax">max</label>
    <input type="text" id="formMax" class="form-control w-25" v-model="maximum">
    </div>

    <input type="range" class="custom-range" min="0" max="1000" v-model="maximum">

    <div class="row">

      <div class="item" v-for="item in products" :key="item.id">

        <div v-if="item.id>maximum">
        <p>{{item.name}} | {{item.id}} | max: {{maximum}}</p>
        <button class="btn btn-info" v-on:click="addItem(item)">+++</button>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'List',
  props: ["products", "maximum", "cart"],
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  margin:0 0 40px 0;
  padding: 40px;
  background-color:#f5f5f5;
}
.item {
  display:block;
  width:100%;
  background-color:lightblue;
  margin-bottom:4px;
}
a {
  color: #42b983;
}
</style>
