<template>
  <div>
    <div class="logo" v-if="locationkey == 'thwi'">      
      <img :src="$config.graphpath + 'logo_thwi_sm.png'" alt="" title="TH Wildau" />                        
    </div>
    <div class="logo" v-else>
      <img :src="$config.graphpath + 'logo_btu_sm.png'" alt="" title="BTU Cottbus-Senftenberg" />
    </div>
  </div>

</template>

<script>
  export default {
    data () {
      return {
      }
    },
    props: ['locationkey'],    
    methods:{

    },
    mounted() {
        //console.log("Mounted contacts module");
        //console.log(this.contacts);            
    } 
  }
</script>

<style scoped>
.logo img {
  width:60px;
  height:auto;
}
</style>
