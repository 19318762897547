<template>
  <div class="landing">

      <v-layout class="first flex-grow-1">
          <v-container>
                <v-row justify="center" align="center">
                     <v-col class="wrap" cols="9" sm="9">
                        <img :src="$config.imagepath+'rocket1.png'" alt=""/>
                        <h4>Was ist das Testbed?</h4>
                        <p>
                        Das Testbed des Inovation Hub 13 ist eine interaktive Plattform für jedermann. Hier präsentieren Forscher*innen ihre Arbeit auf verständliche Art und Weise und bieten diese zum Ausprobieren und Weiterentwickeln an. Dabei ist es egal, wer das Testbed nutzen möchte, hier ist für alle etwas dabei.
                        </p>
                     </v-col>
                </v-row>
          </v-container>
      </v-layout>

      <v-layout class="iblue">
          <v-container>
                <v-row justify="center" align="center">
                    <v-col class="wrap" cols="9" sm="9">
                        <h4>Was bieten wir an?</h4>
                        <v-row >
                            <v-col cols="4" sm="4" align="center">
                              <h5>Testfelder</h5>
                              <v-icon
                              color="#ffffff"
                              size="80"
                              class="m-2"
                              >mdi-cog-outline</v-icon>
                              <p>Thematisches Komplettpaket zum direkten Einstieg</p>
                            </v-col>
                            <v-col cols="4" sm="4" align="center">
                              <h5>Testfelder</h5>
                              <v-icon
                              color="#ffffff"
                              size="80"
                              class="m-2"
                              >mdi-airplane-takeoff</v-icon>
                              <p>Hochmoderne Geräte und Applikationen</p>
                            </v-col>
                            <v-col cols="4" sm="4" align="center">
                              <h5>Testfelder</h5>
                              <v-icon
                              color="#ffffff"
                              size="80"
                              class="m-2"
                              >mdi-application-import</v-icon>
                              <p>Demonstration aktueller Technologien an realen Beispielen</p>
                            </v-col>
                            <v-col cols="12" sm="12" align="center" v-if="host.indexOf('testbed.') === -1">
                             <span class="btn" @click="$router.push({ name: 'Suche'})">Finde, was Du suchst!</span>
                             <span class="btn" @click="$router.push({ name: 'PanoramaFrame'})">Erkunde das Testbed</span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
          </v-container>
      </v-layout>

      <v-layout>
          <v-container>
              <v-row justify="center" align="center">
                  <v-col class="wrap" cols="9" sm="9">
                      <div class="servicewrap">
                          <h4>Angebote für Unternehmen</h4>
                          <v-row >
                              <v-col cols="4" sm="4">
                                <img :src="$config.imagepath+'collaborate1.png'" alt=""/>
                               
                               </v-col>
                               <v-col cols="8" sm="8">
                                 <p>Unternehmen finden im Testbed Ideen für Entwicklungsmöglichkeiten und erhalten niedrigschwelligen Zugang zu neuen Technologien, Entwicklungen und Methoden.</p>
                                 <p>Erproben Sie neue Technologien, Entwicklungen und Methoden. Finden Sie Kooperationsmöglichkeiten zur Initiierung von neuen Projekten.</p>
                               </v-col>
                          </v-row>
                          <!--
                          <v-row >
                               <v-col cols="8" sm="8">
                               Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt.
                               </v-col>
                               <v-col cols="4" sm="4">
                                <img :src="$config.imagepath+'kontakt1.png'" alt=""/>
                                </v-col>
                          </v-row>
                          -->
                      </div>
                      <div class="servicewrap">
                          <h4>Angebote für die Forschung</h4>
                          <v-row >
                              <v-col cols="4" sm="4">
                               <img :src="$config.imagepath+'angebot_wirtschaft.png'" alt=""/>
                               </v-col>
                               <v-col cols="8" sm="8">
                                 <p>
                                   Überblick über vorhandenen und verfügbare Ressourcen aus den Testfeldern - als Anregungen für Kooperationsmöglichkeiten. 
                                 </p>
                                 <p>
                                   Projektkooperation und Partnerschaften zwischen Hochschulen ermöglichen kosteneffiziente Forschung durch Mitnutzung von Ressourcen. Ein Weg zur Etablierung einer zukunftsorientierten Art der Forschung.
                                 </p>
                               </v-col>
                          </v-row>
                          <!--
                          <v-row >
                               <v-col cols="8" sm="8">
                               Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt.
                               </v-col>
                               <v-col cols="4" sm="4">
                                <img :src="$config.imagepath+'collaborate1.png'" alt=""/>
                                </v-col>
                          </v-row>
                          -->
                      </div>
                      <div class="servicewrap">
                          <h4>Angebote für die Bürger:innen</h4>
                          <v-row >
                              <v-col cols="4" sm="4">
                               <img :src="$config.imagepath+'kontakt1.png'" alt=""/>
                               </v-col>
                               <v-col cols="8" sm="8">
                                 <p>
                                   Niedrigschwelliger Zugang zu und vertieftes Verständnis von neuen Technologien. Sensibilisierung für aktuelle Themen aus Wissenschaft und Forschung. Zugang zu Ressourcen, die in der Regel schwer zugänglich sind.
                                 </p>
                                 <p>
                                   Höhere Lerneffekte für Schülerinnen und Schüler durch Zugang zu interessanten Themenbereichen. Förderung des wissenschaftlichen Interesses. 
                                  </p>                                                                
                               </v-col>
                          </v-row>
                          <!--
                          <v-row >
                               <v-col cols="8" sm="8">
                               Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt.
                               </v-col>
                               <v-col cols="4" sm="4">
                                <img :src="$config.imagepath+'collaborate1.png'" alt=""/>
                                </v-col>
                          </v-row>
                          -->
                      </div>
                  </v-col>
              </v-row>
          </v-container>
      </v-layout>

      <v-layout class="iblue">
          <v-container>
                <v-row justify="center" align="center">
                    <v-col class="wrap" cols="9" sm="9">
                      <Contact></Contact>
                    </v-col>
                </v-row>
          </v-container>
      </v-layout>

      <Footer></Footer>

  </div>

</template>

<script>

//import Setting from '../components/footer/Setting';
import Contact from '../components/elements/Contact';
import Footer from './elements/Footer';

export default {
  name: 'Admin',

  components: {
    Contact,
    Footer
  },
  props: {    
    template: String
  },  
  data() {
    return {
      lorem: 'xxx',
      host: window.location.host,
      contacts: null
    }
  },
  created: function() {

    this.getHome();
  
    if(this.template == 'icampus') {
      setTimeout(this.fireStart, 100);      
      this.fireStart('icampus');
    } else if ( this.template == 'innoxr') {
      setTimeout(this.fireStart, 100);      
      this.fireStart('innoxr');      
    }

  },
  methods: {    
    getHome() {    
        let self = this;
        this.axios.get( this.$config.host+"/resps" ).then(function (response) {
          //console.log("get HOME filter");                                            
          self.contacts = response.data.filter(r => r.category === 3 );
        });
    },
    fireStart(template) {

      switch (template) {
        case 'icampus':
            this.$router.push({ name: 'PanoramaFrame' }, () => {} );                  
          break;

        case 'innoxr':
            this.$router.push({ name: 'Virtuell' }, () => {} );                
          break;

      }                        

    }

  }
};
</script>

<style scoped>
.landing {
  height:100%;
  background-color: #ffffff;
}

.region {
  position:relative;
  margin-top:0px;
  text-align:center;
}
.region button,
.region a {
  position:absolute;
  box-shadow:none;
}
.welcome {
  color:#fff;
}
.btn {
  padding:14px 20px;
  border:1px solid #fff;
  border-radius:6px;
  margin-right:20px;
  margin-bottom:20px;
  color:#fff;
}
.bordertop {
  border-top:2px solid #000;
}
#s01 {
  top:62%;
  left:62%
}
#s02 {
  top:20%;
  left:52%
}

</style>

<style>

.layout.first {
  padding-top:20px;
  padding-bottom:20px;
}
.container {
  background-color:transparent;
  max-width:1560px;
}
.container.topic {
  background-color:transparent;
}
.container .row {
  padding-top:0px;
}
.wrap img {
  max-width:100%;
}
.iblue {
  background-color: #005CA9;
}
.iblue h5,
.iblue h4,
.iblue h3,
.iblue h2,
.iblue p {
  color:#ffffff;
}

.iblue h5 {
  font-size:1rem;
}

.servicewrap {
  padding-top:40px;
  padding-bottom:40px;
}
.servicewrap .row {
  padding-top:10px;
}

.servicewrap p > img {
  margin-right: 2rem;
}


</style>
