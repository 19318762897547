<template>

  <v-card class="dashboardcontent">
    <v-card-actions>  
    <v-btn      
      icon
      :to="'/'+$home+'content/poi'"
      >      
      <v-icon>mdi-table-of-contents</v-icon>
    </v-btn>
    </v-card-actions>    

  <v-list-item two-line>
      <v-list-item-icon
        class="dash-icon"
        >
        <v-icon
          size="24"          
          >mdi-map-marker-radius-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="d-title">
          POIs
        </v-list-item-title>        
      </v-list-item-content>
    </v-list-item>

  <v-data-table
      v-model="selected"
      :headers="headers"
      :items="pois"
      :footer-props="{
        'disable-items-per-page': true        
      }"      
      :items-per-page="5"
      :single-select="singleSelect"
      :search="search"
      item-key="id"
      class="elevation-1 list-blank"
    >

    <template v-slot:body="{ items }">
      <tbody>
        <tr
          v-for="(item) in items"
          :key="item.id"
        >
          <td>{{ item.id }}</td>
          <td>
            <router-link :to="'/'+$home+'node/poi/' + item.id" text small>
            {{ item.name }}
            </router-link>
          </td>                                             
          <td>
            <router-link :to="'/'+$home+'node/pano/' + item.pano_id" text small v-if="$store.state.access.read['pano']">
            {{ item.pano_name }}
            <span style="display:block"><i>{{ item.name_admin }}</i></span>
            </router-link>
            <div v-else>
              {{ item.pano_name }}
              <span style="display:block"><i>{{ item.name_admin }}</i></span>
            </div>
          </td>
        </tr>
      </tbody>
    </template>

  </v-data-table>

  </v-card>
</template>

<script>

import UserContent from '../../helper/UserContent.js';

export default {
  name: 'DashboardPois',  
  props: ['type'],
  data () {
    return {
      singleSelect: false,
      selected: [],
      search: '',
      headers: [
        { text: 'ID',
          align: 'start',
          value: 'id',
          sortable: false
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Panorama',
          value: 'pano_name',
          width: '40%'      
        }
      ],
      pois: [],
      overlaydata: null
    }
  },
  methods: {
      getPois() {          
          this.axios.get( this.$config.host+"/pois" ).then(response => (
            this.pois = response.data,
            this.pois = UserContent.getContent(response.data, 'poi'),
            this.pois = this.pois.filter(item => item.icon != 1)
          ));
      }
  },
  mounted() {        
    //console.log("mounted Dash pois");
    this.getPois();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
