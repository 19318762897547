<template> 
  <div class="admincontent">
    <v-row>            

        <v-col cols="6" md="6" sm="12" align="center" v-if="$store.state.access.read['pano']" >
           <DashboardPanos :type="'pano'"></DashboardPanos>
        </v-col>
        <v-col cols="6" md="6" sm="12" align="center" v-if="$store.state.access.read['poi']">
           <DashboardPois :type="'poi'"></DashboardPois>
        </v-col>                        
        <v-col cols="4" md="4" sm="12" align="center" v-if="$store.state.access.read['testfield']" >
          <DashboardTestfields :type="'testfield'"></DashboardTestfields>
        </v-col>
        <v-col cols="4" md="4" sm="12" align="center" v-if="$store.state.access.read['usecase']">
          <DashboardUsecases :type="'usecase'"></DashboardUsecases>
        </v-col>
        <v-col cols="4" md="4" sm="12" align="center" v-if="$store.state.access.read['device']">
          <DashboardDevices :type="'device'"></DashboardDevices>
        </v-col>        
        <v-col cols="6" md="6" sm="12" align="center" v-if="$store.state.access.read['user']">
          <DashboardUser :type="'user'"></DashboardUser>          
        </v-col>
        <v-col cols="6" md="6" sm="12" align="center" v-if="$store.state.access.read['resp']">
          <DashboardResponsibles :type="'resp'"></DashboardResponsibles>          
        </v-col>

    </v-row>
  </div>
</template>

<script>

import DashboardTestfields from '../dashboard/DashboardTestfields';
import DashboardUsecases from '../dashboard/DashboardUsecases';
import DashboardUser from '../dashboard/DashboardUser';
import DashboardResponsibles from '../dashboard/DashboardResponsibles';
import DashboardPanos from '../dashboard/DashboardPanos';
import DashboardPois from '../dashboard/DashboardPois';
import DashboardDevices from '../dashboard/DashboardDevices';

export default {
  name: 'Admin',
  components: {
    DashboardTestfields,
    DashboardUsecases,
    DashboardUser,
    DashboardPanos,
    DashboardResponsibles,
    DashboardPois,
    DashboardDevices
  },
  data () {
    return {
      singleSelect: false,
      selected: [],
      search: '',
      testfields: [],
      overlaydata: null
    }
  },
  /*
  methods: {
      getTestfields() {
          console.log("Fkt getTestfields");
          this.axios.get( this.$config.host+"/testfields" ).then(response => (
            this.testfields = response.data,
            console.log(this.testfields)
          ));
      }
  },
  */
  mounted() {

    //this.getTestfields();

  },
  computed: {
      user() {
        return this.$store.state.user
      }
  }  

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  margin:0 0 40px 0;
  padding: 40px;
  background-color:#f5f5f5;
}
.item {
  display:block;
  width:100%;
  background-color:lightblue;
  margin-bottom:4px;
}
.p >>> p {
  margin:0;
}
a {
  color: #42b983;
}
</style>

<style>
.admincontent .dashboardcontent {
  padding: 0 6px;
}
.admincontent .dashboardcontent .d-title {
  text-align: left;  
  font-size: 1.2rem;
}
.admincontent .dashboardcontent .v-data-table {  
  box-shadow: none !important;
}
.admincontent .dashboardcontent .v-card__actions {
  float:right;
  justify-content: flex-end;
}
</style>
