<template>

      <v-card id="connector">
      <!--
       <label>Sprich mit mir!<br>
       <input type="text" class="testinput" v-model="msgcontent"></label>
       -->

       <v-row align="center">
           <v-col cols="12">
             <v-select
               :items="items1"
               v-model="eventName1"
               label="Willkommen"
             ></v-select>
           </v-col>
       </v-row>

       <div style="margin:0 0 40px 0;text-align:right;">
       <input type="button" class="button" value="Anfrage senden" @click="clickButton1()">
       </div>

       <v-divider class="mt-10 mb-10"></v-divider>

       <v-row align="center">
           <v-col cols="12">
             <v-select
               :items="items2"
               v-model="eventName2"
               label="Aufgaben"
             ></v-select>
           </v-col>
       </v-row>

       <div style="margin:0 0 40px 0;text-align:right;">
       <input type="button" class="button" value="Anfrage senden" @click="clickButton2()">
       </div>

       <!--
       <label>NAO-Server<br>
       <textarea class="testinput" v-model="msg2"></textarea>
       </label>
       -->
       </v-card>

</template>

<script>

  export default {
    name: 'Connector',

    data() {
      return {
        msg: 'Welcome to Your Vue.js App',
        msg2: null,
        msgcontent: null,
        items1: [
          'Greeting','FirstMeeting', 'Alphabet', 'Reading', 'PositiveFeedback', 'ReceivedBadge', 'QuizQuestion', 'QuizAnswers','QuizRight', 'QuizWrong', 'Goodbye'
        ],
        items2: [
          'Greeting','FirstMeeting', 'Alphabet', 'Reading', 'PositiveFeedback', 'QuizRight', 'Goodbye'
        ],
        helpstrings: [
            /*
            {
              motive: "Greeting",
              string: "Herzlich Willkommen!"
            },            
            {
              motive: "FirstMeeting",
              string: ""
            },
            */
            {
              motive: "Alphabet",
              string: "Wähle den Schwierigkeitsgrad."
            },
            {
              motive: "Reading",
              string: "Lass Dir Zeit beim Lesen. Wenn Du fertig bist, klick den Button."
            },
            {
              motive: "PositiveFeedback",
              string: ""
            },
            {
              motive: "ReceivedBadge",
              string: "Bestätige das Abzeichen"
            },
            {
              motive: "QuizQuestion",
              string: ""
            },
            {
              motive: "QuizAnswers",
              string: "Lass Dir ruhig Zeit mit der Antwort."
            },
            {
              motive: "QuizRight",
              string: ""
            },
            {
              motive: "QuizWrong",
              string: "Das Quiz kann jederzeit neu gestartet werden."
            },
            {
              motive: "Goodbye",
              string: ""
            },
        ],
        eventName1: '',
        eventName2: '',
        finishedData: null
      }
    },
    created() {
        console.log("COMPONENT CREATED")
    },
    sockets: {

      connect: function() {

        console.log("yep connect!");

      },
      setanswer: function (data) {

        console.log(data)
        this.msg2 = data.message

      },
      customEmit: function() {

      },
      ActionTrigger: function (data) {

        //NAO answers / speaks
        console.log("NAO server answers!");
        //console.log("NAO answers: " + data.outputText)
        console.log("data object:")
        console.log(data);
                    
        // Send message text to parent
        this.$emit('setanswer', data.outputText, data.eventname, data.languageSetting)
        this.$emit('setdisplay', data.outputText)
        this.$emit('setlang', data.languageSetting)

        var helpstring = this.helpstrings.find( file => file.motive === data.eventname );

        if(!helpstring) {
          helpstring = '';
        } else {
          helpstring = helpstring.string;
        }

        this.$emit('sethelp', helpstring)

        // Sende audio file to parent
        /*
        if(audiofile) {

          console.log("audiofile gefunden!")
          //this.$emit('play', audiofile)

        } else {

          console.log("no audiofile to play")

        }
        */

      },
      ActionFinished: function (data) {

        /**
         * NAO answers: action finished received
         * Method triggered when NAOserver got message from UI that speak action is finished 
         */

        //console.log("NAO answers: action finished received: ");
        console.log(data);
        //console.log("nach data")

      }

    },
    methods: {

      clickButton1: function(content) {

        this.eventName = this.eventName1
        //console.log("socket ID:")
        //console.log(this.$socket.id);
        //console.log("event name: " + this.eventName1);
        this.$socket.emit(this.eventName1, content);

      },
      clickButton2: function(content) {

        //alert(this.eventName);
        this.eventName = this.eventName2
        //console.log("socket ID:")
        //console.log(this.$socket.id);
        this.$socket.emit(this.eventName2, content);

      },
      sendFinished: function(eventname) {

        var self = this;
        // Broadcast: Words finished
        console.log("Send finished: " + eventname);

        self.finishedData = {
          eventname: eventname,
          socketID: this.$socket.id
        }

        console.log("finished data:");
        console.log(self.finishedData);

        console.log("cookie?");
        console.log(self.$cookies.get('USERID'));                          

        //self.$socket.emit("Finished", self.$cookies.get('USERID'), self.finishedData);
        //self.$socket.emit("Finished", self.$cookies.get('USERID'), self.finishedData);
        self.$socket.emit("Finished", null, self.finishedData);

      }
    },
    mounted () {

      console.log("connector MOUNTED");
      setTimeout(() => {

        //console.log("fire timeout")
        //console.log(this.$socket.id)
        //this.$socket.emit("FirstMeeting", this.$socket.id, ['', 'test'])
        
      }, 4000);
      

    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#connector {

  background-color:#f7f7f7;
  padding:20px;
}
.testinput {
  background-color:#f5f5f5;
  border:1px solid #999;
}
.button {
  background-color:lightblue;
  border:1px solid #999;
  border-radius:8px;
  padding:4px 12px;
}
</style>
