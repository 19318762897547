<template>
  <div :class="'wrap ' + classes">
    <v-tooltip top color="#333" max-width="300">
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
          class="helpicon"
          size="22"
          >
          {{ icon }} mehr icon
        </v-icon>                            
      </template>
      <span>{{ $t(helptext) }}</span>
    </v-tooltip>
  </div>
</template>

<script>

    //import { ImageDrop } from 'quill-image-drop-module';

    export default {

        name: 'Tooltip',
        props: {
            icon: {
                type: String
            },
            helptext: {
                type: String
            },
            classes: {
                type: String
            }
        },
        data() {
            return {
                //content: null
            };
        },
        methods: {
        },
        computed: {
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wrap {
  float:right;
}
</style>>

