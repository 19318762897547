<template>

    <div class="">

        <v-form
           id="nodeform"
           ref="form"
           v-model="valid"
           :lazy-validation="lazy">

            <!-- CARD ACTIONS -->

            <v-toolbar
              flat
              style="border-bottom:2px solid #ddd"              
              >
        
                <v-toolbar-title>Nutzer bearbeiten</v-toolbar-title>
                  
                    <v-checkbox
                    v-model="node.published"
                    label="Veröffentlicht"
                    dense
                    class="check-published"
                    ></v-checkbox>
                  

                  <v-spacer></v-spacer>                    

                    <v-btn class="mr-4"
                    v-if="node.id"
                    :disabled="!valid"
                    @click="formSubmit"
                    style="display:inline-block"
                    >Aktualisieren</v-btn>

                    <v-btn class="mr-4"
                    v-else
                    :disabled="!valid"
                    @click="formSubmit"
                    style="display:inline-block"
                    >Speichern</v-btn>

                  
                    <v-btn-toggle                        
                      borderless                    
                      dense    
                      class="mr-2"
                      >
                      <v-btn      
                        icon
                        :to="'/'+$home+'content/'+nodetype"
                        >      
                        <v-icon>mdi-table-of-contents</v-icon>
                      </v-btn>

                      <v-btn 
                        class="small" 
                        value="right" 
                        :to="'/p/'+nodetype+'/'+nodeid" 
                        target="_blank" 
                        v-if="nodetype === 'usecase' || nodetype === 'testfield'"
                        >
                        <span class="hidden-sm-and-down">Preview</span>
                        <v-icon right>
                          mdi-open-in-new
                        </v-icon>
                      </v-btn>

                      <v-btn 
                        class="small" 
                        value="right" 
                        :to="'/panorama/?q='+nodeid" 
                        target="_blank" 
                        v-if="nodetype === 'pano'"
                        >
                        <span class="hidden-sm-and-down">Preview</span>
                        <v-icon right>
                          mdi-open-in-new
                        </v-icon>
                      </v-btn>

                    </v-btn-toggle>
  
            </v-toolbar>

            <!-- / CARD ACTIONS --->

          <v-row class="nodecontent">

               <v-col cols="7" sm="7" >

                     <v-text-field
                       v-model="node.name"
                       :counter="200"
                       :rules="nameRules"
                       label="Name"
                       background-color="#f7f7f7"
                       required
                       color="#333333"
                       height=auto
                       class="my-5 py-2"
                     ></v-text-field>

                     <v-subheader>Veröffentlicht</v-subheader>

                     <!--
                     <v-checkbox
                      v-model="node.published"
                      label="Veröffentlicht"
                      ></v-checkbox>
                      -->

                      <v-text-field
                        v-model="node.id"
                        type="hidden"
                      ></v-text-field>

                     <v-btn class="mr-4"
                      v-if="node.id"
                      :disabled="!valid"
                     @click="formSubmit"
                     >Aktualisieren</v-btn>

                     <v-btn class="mr-4"
                      v-else
                      :disabled="!valid"
                     @click="formSubmit"
                     >Speichern</v-btn>

               </v-col>

               <v-col cols="5" sm="5" >

                   <v-text-field
                     v-model="node.ldap_id"                                          
                     label="LDAP ID"
                     background-color="#f7f7f7"
                     required
                     color="#333333"
                     height=auto
                     class="my-5 py-2"
                   ></v-text-field>

                   <v-autocomplete
                       v-model="node.role_id"
                       :items="roles"
                       :rules="[v => !!v || 'Rolle ist erforderlich']"
                       item-value="id"
                       item-text="name"
                       label="Rolle"
                       class="mt-5 mb-0 py-2"
                       background-color="#f7f7f7"
                       required
                        >
                        <template v-slot:item='{item}'> <div v-html='item.name'/> </template>
                        <template v-slot:selection='{item}'> <div v-html='item.name'/></template>
                    </v-autocomplete>

                   <v-autocomplete
                       v-model="node.team_id"
                       :items="teams"                       
                       item-value="id"
                       item-text="name"
                       label="Team"
                       class="mt-5 mb-0 py-2"
                       background-color="#f7f7f7"                       
                        >
                        <template v-slot:item='{item}'> <div v-html='item.name'/> </template>
                        <template v-slot:selection='{item}'> <div v-html='item.name'/></template>
                    </v-autocomplete>                    

               </v-col>

        </v-row>

        </v-form>

    </div>

</template>

<script>

    export default {
        name: 'UserContent',
        data() {
            return {
                componentKey: 0,
                published: false,
                errors: [],
                valid: true,
                file: '',
                resps: [],
                roles: [],
                teams: [],
                nodeRespRef: null,
                nameRules: [
                  v => !!v || 'Name erforderlich',
                  v => (v && v.length <= 200) || 'Name darf max. 200 Zeichen enthalten',
                ],
                lazy: false
            };
        },
        props: {
            nodetype: {
              type: String
            },
            node: {
                type: Object
            }
        },
        components: {
        },
        methods: {
          checkForm (data) {
            console.log(data);
          },
          validate () {
            this.$refs.form.validate()
          },
          reset () {
            this.$refs.form.reset()
          },
          resetValidation () {
            this.$refs.form.resetValidation()
          },
          onChangeResponsibility(e){
            this.nodeRespRef = this.resps.find(resp => resp.id === e);
            this.componentKey += 1;
          },
          formSubmit() {
              //e.preventDefault();

              const config = { headers: { 'Content-Type': 'multipart/form-data' } }

              const formData = new FormData();
              formData.set('name', this.node.name);                        
              formData.set('ldap_id', this.node.ldap_id);
              formData.set('role_id', this.node.role_id);
              formData.set('team_id', this.node.team_id);
              //formData.set('published', this.node.published);
              formData.set('process', this.node.id ? 'update' : 'generate');
              formData.set('id', this.node.id ? this.node.id : null);
            
              // Was ist das hier??
              let currentObj = this
              let self = this;

              this.axios.post( this.$config.host+"/buser", formData, config)
              .then(function (response) {
                  console.log("hier then..");
                  console.log(response);
                  self.$router.push({path: '/'+self.$home+'content/user'});
                  self.$emit('usersaved', null)

              })
              .catch(function (error) {
                  currentObj.output = error;
              });
          },

          getUser() {
              this.axios.get( this.$config.host+"/resps" ).then(response => (
                this.resps = response.data,
                console.log("respsContent: resps Referenzen erhalten:"),
                console.log(this.resps)
              ));
          },
          getRoles() {
              this.axios.get( this.$config.host+"/roles" ).then(response => (
                this.roles = response.data,
                console.log("Roles:"),
                console.log(this.roles)
              ));
          },          
          getTeams() {
              this.axios.get( this.$config.host+"/teams" ).then(response => (
                this.teams = response.data,
                console.log("Teams:"),
                console.log(this.teams)
              ));
          }

        },

        created() {
            this.getRoles();
            this.getTeams();
        },
        computed: {
            editor() {
              return this.$refs.myQuillEditor.quill
            }
        },
        mounted() {
            console.log("UserContent: node wert (pano_id) on mounted?");
            console.log(this.node);
        },

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.form-wrap {
  margin:30px 0;
}
#nodeform .v-subheader {
  padding-left:0;
  padding-right:0;
}
#nodeform .v-label {
  color:#666666;
}

.txtwrap {
  padding: 10px 0;
  margin: 0px 0;
}
.preview {
  display:block;
  max-height:500px;
  max-width:100%;
}
.selecticon {
  height:20px;
  width: auto;
  margin-right:20px;
}
.ck-editor__editable {
  min-height: 300px;
}

.ql-container {
  font-size:1rem;
}
.ql-editor .ql-video {
  width:100%;
  height:450px;
}

</style>
