<template>
  <div class="wrap usecaseinfo" :style="'width:'+sizeWidth+'%'">
      <v-row>
        <v-col cols="12" sm="12" class="timelinefirst">
          <div class="timestephead">
            <div class="triangle-down"></div>                  
            <h6 style="margin-top:6px;">Chatbot: Was passiert hier?</h6>                
          </div>          
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" class="timeline" >                      
            <div class="timestep">
              <div class="ptitle">Schritt 1: Tokenization</div>
            </div>
            <div class="stepcontent">
              Manche Worte sind ein bisschen kompliziert. Deswegen zerlege ich sie in kleinere Teile, die Tokens genannt werden. Das Ergebnis siehst Du auf der rechten Seite. Mit den Tokens kann ich weiter arbeiten und ihren Sinn entschlüsseln.<br><br>              
              <!--
              <v-btn fab depressed small class="helpbtn" @click="help('audiosource')">                      
                <v-icon size="18" >mdi-information-variant</v-icon> 
              </v-btn>
              -->
            </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" class="timeline" >
            <div class="timestep">
              <div class="ptitle">Schritt 2: Attentions</div>                    
            </div>
            <div class="stepcontent">
              Nun versuche ich herauszufinden, wie die Wörter im Verhältnis zueinander stehen. Dazu nutze ich ein neuronales Netz. Das ist ein Programm, das darauf trainiert wurde, genau diese Verhältnisse zwischen Wörtern zu erkennen. Neuronale Netze, die auch künstliche Intelligenzen genannt werden, gehören zum Bereich des maschinellen Lernens. Programmierer:innen geben dem Programm eine riesige Sammlung an Wörtern und den dazugehörigen Verhältnissen. Das neuronale Netz lernt damit und erkennt Zusammenhänge. Ich kann das Netz dann nutzen, um neue Wörter, die nicht in den Trainingsdaten waren, in Bezug zueinander zu setzen.<br><br>
              <!--
              <v-btn fab depressed small class="helpbtn" @click="help('processingmethod')">
                <v-icon size="18" >mdi-information-variant</v-icon> 
              </v-btn>              
              -->
            </div>            
        </v-col>
      </v-row>  

      <v-row>
        <v-col cols="12" sm="12" class="timeline" >
            <div class="timestep">
              <div class="ptitle">Schritt 3: Entities</div>
            </div>
            <div class="stepcontent">
              Als nächstes möchte ich die Objekte erkennen, die in Deiner Frage enthalten sind. Diese Objekte werden auch Entities genannt. Auch hier hilft mir wieder ein neuronales Netz, das speziell für diesen Zweck trainiert wurde. Es erkennt sozusagen den Kern der Frage, sodass ich verstehe, zu welchem Thema Du etwas wissen möchtest.<br><br>
              <!--
              <v-btn class="helpbtn" small fab depressed @click="help('modus')">
                <v-icon size="18" >mdi-information-variant</v-icon> 
              </v-btn>
              -->
            </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" class="timeline" >
            <div class="timestep">
              <div class="ptitle">Schritt 4: Intents</div>
            </div>
            <div class="stepcontent">
              Nun versuche ich, auch Deine Absichten zu erkennen. Das Fachwort hierzu lautet Intents. Du hast es Dir sicherlich schon gedacht, auch hier kommt wieder ein neuronales Netz zum Einsatz. Aus den Wörtern der Frage, ihrer Stellung zueinander und im Kontext der erkannten Entities, versuche ich zu erkennen, was genau Du wissen möchtest.<br><br>
              <!--
              <v-btn class="helpbtn" small fab depressed @click="help('modus')">
                <v-icon size="18" >mdi-information-variant</v-icon> 
              </v-btn>
              -->
            </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" class="timeline" >
            <div class="timestep">
              <div class="ptitle">Schritt 5: Wissensdatenbank</div>
            </div>
            <div class="stepcontent">
              Jetzt, wo ich Deine Frage genau verstehe, kann ich in meiner Datenbank nach einer passenden Antwort such. Dazu haben meine Entwickler:innen eine große Tabelle angelegt, in denen systematisch viele Antworten zusammengetragen wurden.
In den letzten Schritten habe ich Deine Frage in Teile zerlegt, mit denen ich rechnen kann (denn das können Computersysteme besonders gut). Jetzt errechne ich, welche Antwort die größte Wahrscheinlichkeit hat, die richtige zu sein.
Sollten mehrere Möglichkeiten gleich wahrscheinlich sein, kann es sein, dass ich noch einmal bei Dir nachfrage.
Am Ende zeige ich Dir dann die Antwort, die meinen Berechnungen nach am besten zu Deiner Frage passt.<br><br>
              <!--
              <v-btn class="helpbtn" small fab depressed @click="help('modus')">
                <v-icon size="18" >mdi-information-variant</v-icon> 
              </v-btn>
              -->
              <br><br>
              Wenn Du magst, kannst Du mir gerne noch eine Frage stellen. Wenn Du mehr über Natural Language Processing erfahren möchtest, <a href="https://wissenstransfer.innohub13.de/nlp/" target="_blank">schau mal hier vorbei</a>.<br><br>
            </div>
        </v-col>
      </v-row>

      <HelpDialog ref="help" :content="helpcontent" class="mr-4"></HelpDialog>

  </div>
</template>

<script>

  import HelpDialog from '../monitor/HelpDialog';  

  export default {
    name: 'info_chatbot',

    components: {
      HelpDialog
    },
    data () {
      return {
        helpcontent: null
      }
    },
    props: {
      infocontent: {
          type: String
      },
      sizeWidth: {
          type: Number
      }
    },    
    methods:{         
      help(name) {        
        this.helpcontent = name;        
        this.$refs.help.openhelp();
      }
    },
    mounted() {        
        //console.log("on mounted");            
    } 
  }
</script>

<style scoped>
.wrap {
  position:fixed;
  top:0;
  bottom:0;
  padding-top:48px;  
  overflow-y: scroll;  
  background-color: rgba(45,89,134,0.86);
}

.wrap .row {
  margin-top:0;
  margin-left:0;
  margin-right:0;
}

.timeline,
.timelinefirst {
  background: linear-gradient( 90deg, transparent, transparent 14px,  #f5b041  14px,  #f5b041  22px, transparent 22px);
  padding:12px 12px 12px 36px;
  font-size:.88rem;
  color:white;
}
.timelinefirst {
  padding:12px 12px 12px 36px;
  font-size:.88rem;
}
.timestep,
.timestephead {
  margin:0;
  padding:10px 0;
  position:sticky;  
  top:0;
  background-color: #486e95;  
  border-bottom:1px solid #ccc;
}
.triangle-down {
  position:absolute;
  left:-29px;
  top:6px;
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-top: 22px solid  #f5b041 ;  
}
.timestep::before {
  content: " ";
  width:22px;
  height:22px;
  background-color:  #f5b041 ;
  border-radius: 14px;
  display:inline-block;
  margin-right:10px;
  margin-left:-29px;
}
.timestep .ptitle {
  font-size:1rem;
  color:white;
  font-weight: bold;
  display:block;
  margin-left:6px;
  margin-top:-28px;
}
.stepcontent {
  padding:10px 16px 10px 6px;
}
.timestephead h6 {
  font-size:1rem;
  color:white;
  font-weight: bold;
  display:block;
  margin-left:8px;
  margin-top:-28px;
}

</style>
