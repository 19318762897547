<template>
<div>

    <v-slide-group class="slider uc small" multiple show-arrows>
          <v-slide-item
            v-for="(testfield, index) in testfields"
            :key="index"
          >
            <div
              class="ma-2 item"
            >
                <router-link :to="'/p/field/'+testfield.id">
                  <div class="img" v-if="testfield.src"><img :src="testfield.paththumb" alt="" /></div>
                  <div class="title">{{ testfield.title }}</div>
                  <div class="keywords"></div>
                </router-link>
            </div>
          </v-slide-item>
        </v-slide-group>

</div>

</template>

<script>

    export default {
        name: 'Testfields',
        data() {
            return {
                testfields: {}
            };
        },
        props: {
          current: {
            type: Number
          }
        },
        components: {
        },
        methods: {
            getTestfields() {                
                let self = this;
                this.axios.get( this.$config.host+"/testfieldsf" ).then(function (response) {
                        //console.log("get testfields");
                        //console.log(response.data);
                        //console.log("was ist current?");
                        //console.log(self.current);
                        var filtered = response.data.filter((item) => item.id !== self.current);
                        self.testfields = filtered;
                });
            },
            link(x) {
              this.$router.push({path: x});
            }
        },
        mounted() {
            //console.log("mount Testfields: ");
            this.getTestfields();
        },
        watch: {
        },
    }
</script>

<style>

.slider.uc.small .img img {
    width:200px;
    height:auto;
}
.slider.uc.small .title {
    font-size:0.9rem !important;
}

</style>
