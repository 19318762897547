<template>
    <div class="page list">
        
            <!-- Header -->
            <v-layout class="first header">
                <div class="pbackground" :style="{ 'background-image': 'linear-gradient(0deg, rgba(9, 93, 169, 0.74), rgba(9, 93, 169, 0.74)), url(' + testfield.pathimg + ')' }">
                    <v-container>
                        <v-row justify="center" align="center">
                            <v-col class="wrap" cols="10" sm="10">
                                <v-row >
                                    <v-col cols="10" sm="10" class="title">     
                                        <div>
                                            <span class="headtitle">{{ $t('testfield') }}</span>
                                            <h3 v-if="testfield.title">{{ testfield.title }}</h3>
                                        </div>
                                        <!--
                                        <router-link
                                            :to="'/p/field/'+testfield.id" 
                                            >                                            
                                        <h3 v-if="testfield.title">{{ testfield.title }}</h3>
                                        </router-link>
                                        -->
                                    </v-col>   
                                    <v-col cols="2" sm="2" class="titleright">
                                        <div v-if="testfield.id == 2" class="logocol">  
                                            <img src="/graf/Testbed_AutonomesFahren_Icon_klein.svg" title="" width="50px" class="logo" />                                            
                                        </div> 
                                    </v-col>
                                </v-row>
                                </v-col>
                        </v-row>
                    </v-container>
                </div>
            </v-layout>                

            <!-- Description -->
            <v-layout class="content">
                <v-container class="">
                      <v-row justify="center" align="center">
                           <v-col class="wrap" cols="10" sm="10">
                               <v-row>
                                   <v-col cols="11">
                                       <h3>
                                       <v-icon
                                            size="24"
                                            color="#666"
                                            left                                            
                                            >mdi-tools</v-icon>
                                       Hardware und Ressourcen</h3>                                       
                                   </v-col>
                                   <v-col cols="1">
                                        <v-toolbar
                                            dense                                            
                                            flat  
                                            color="transparent"                           
                                            height="auto"      
                                            class="pagetoolbar"                                                     
                                        >                                                                                                                                                                                                 
                                            <v-btn 
                                                icon
                                                large
                                                :to="'/p/field/'+testfield.id" 
                                                >
                                                <v-icon color="#333">mdi-arrow-left-circle-outline</v-icon>
                                            </v-btn>                                                                                   
                                        
                                        </v-toolbar>                                       
                                   </v-col>                                   
                               </v-row>
                               <v-row>
                                    <v-col
                                        cols="6"
                                        >
                                    </v-col>
                                    <v-col class="6" style="margin:0;padding:0;">
                                        <div class="search-wrapper">
                                            <v-text-field                                                
                                                v-model="search"
                                                class="mt-0 pt-0"
                                                prepend-icon="mdi-magnify"
                                                placeholder="Equipment durchsuchen"
                                                >
                                            ></v-text-field>                                            
                                        </div>
                                    </v-col>
                               </v-row>
<!--
                                <v-row style="padding-top:20px">
                                                    
                                    <v-col cols="12" sm="6" md="4" lg="3" v-for="(post, key1) in testfield.devices"
                                        :key="key1+0"
                                        class=" card"
                                    >                                        
                                        <div contain class="card-content h-100">                                                                                        
                                            <div v-if="post.src">
                                                <router-link :to="'/p/device/'+post.dev_id" >
                                                <img :src="post.paththumb" :alt="post.name" class="img-responsive">
                                                </router-link>
                                            </div>
                                            <div class="item-desc">                         
                                                <span class="type">{{ post.category }}</span>                       
                                                <router-link :to="'/p/device/'+post.dev_id">
                                                    <h3>{{ post.name }}</h3>
                                                </router-link>                                                                                                
                                                {{ post.features }}
                                                <div class="tags">
                                                    Anzahl: {{ post.count }}
                                                </div>
                                            </div>
                                        </div>
                                    </v-col>
                                                
                                </v-row>
-->                                
                                                    
                                <v-row class="item" v-for="(post, key1) in filteredContent"
                                    :key="key1+0"                                    
                                    >
                                    <v-col
                                        cols="12" md="4"
                                        >
                                        <div v-if="post.src">
                                            <router-link :to="'/p/device/'+post.dev_id" >
                                                <img :src="post.paththumb" :alt="post.name" class="img-responsive">
                                            </router-link>
                                        </div>
                                    </v-col>
                                    <v-col
                                        cols="12" md="8"                                        
                                        >                                        
                                            <div class="item-desc">                      
                                                <router-link :to="'/p/device/'+post.dev_id" >   
                                                    <span class="type">{{ post.category }}</span>                       
                                                    <router-link :to="'/p/device/'+post.dev_id">
                                                        <h3>{{ post.name }}</h3>
                                                    </router-link>     
                                                    <div class="features">
                                                    {{ post.features }}
                                                    </div>
                                                    <div class="manu">
                                                        Hersteller: {{ post.manufacturer }}
                                                    </div>
                                                    <div class="year" v-if="post.purchasedate">
                                                        Anschaffung: {{ post.purchasedate | dateParse('YYYY-MM-DD') | dateFormat('YYYY') }}
                                                    </div>
                                                    <div class="count">
                                                        Anzahl: {{ post.count }}
                                                    </div>
                                                </router-link>
                                            </div>
                                        
                                    </v-col>
                                </v-row>                                                                                

                                <!--
                                <v-row class="imgsection" align="end">
                                </v-row>                            
                                -->

                           </v-col>
                      </v-row>
                </v-container>
            </v-layout>

            <v-layout class="iblue">
                <v-container>
                      <v-row justify="center" align="center">
                          <v-col class="wrap" cols="9" sm="9">
                                <Contact></Contact>
                          </v-col>
                      </v-row>
                </v-container>
            </v-layout>

            <v-layout class="iwhite">
                <v-container>
                      <v-row justify="center" align="center">
                          <v-col class="wrap" cols="10" sm="10">
                              <h4>Das könnte Sie auch interessieren</h4>
                              <Testfields></Testfields>
                              <br><br><br>
                          </v-col>
                      </v-row>
                </v-container>
            </v-layout>

    </div>
</template>

<script>

//import FieldUsecases from './FieldUsecases';
import Testfields from './Testfields';
import Contact from '../elements/Contact';

    export default {
        name: 'Page_Devices',
        data() {
            return {       
                search: ''                
            };
        },
        props: {
            testfield: {
                type: Object
            }
        },
        components: {
          //FieldUsecases,
          Testfields,
          Contact
        },
        methods: {

        },
        created() {
        },
        computed: {
            filteredContent() {                      
                return this.testfield.devices.filter(post => {
                    return (post.name.toLowerCase().includes(this.search.toLowerCase()) ||
                        post.features.toLowerCase().includes(this.search.toLowerCase()) ||
                        post.category.toLowerCase().includes(this.search.toLowerCase())
                    ) && post.published == true
                })
            }
        },
        mounted() {
            console.log("Page_Devices: devices wert on mounted?");
            console.log(this.testfield);            
        },
        watch: {
            /*
            nodeid: function (values) {
                //console.log("watch Testfield:");
                //console.log(values);
                this.nodeid = values;                
                this.getDevicesByTestfieldId();
            }
            */
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
